/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    CrfCondition,
    ICrfConditionParameters,
    FieldGroup,
    StaticGrid,
    StaticGridRow,
    IStaticGridHeaderProps,
    FormLabel,
    IStaticGridCellProps,
    Input,
    GetFieldLookupItem,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import SourceDocuments from '../../components/SourceDocuments';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IDiagnosticAndPathologyProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */
const isLeft = ({ formState }: ICrfConditionParameters<Dtos.DiagnosticAndPathology>) => formState?.values.breastCancerConfirmLeft === 1;

const isRight = ({ formState }: ICrfConditionParameters<Dtos.DiagnosticAndPathology>) => formState?.values.breastCancerConfirmRight === 1;

const isPrimaryDiagnosisMethodOther = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiagnosticAndPathology>) => GetFieldLookupItem(lookups, 'primaryDiagnosisMethod', formState?.values.primaryDiagnosisMethod)?.type === Dtos.LookupPrimaryDiagnosisMethodType.Other;

const isLeftHistopathologyType = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiagnosticAndPathology>) => GetFieldLookupItem(lookups, 'histopathologyTypeLeft', formState?.values.histopathologyTypeLeft)?.type === Dtos.LookupHistopathologyType.Other && formState?.values.breastCancerConfirmLeft === 1;

const isRightHistopathologyType = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiagnosticAndPathology>) => GetFieldLookupItem(lookups, 'histopathologyTypeRight', formState?.values.histopathologyTypeRight)?.type === Dtos.LookupHistopathologyType.Other && formState?.values.breastCancerConfirmRight === 1;

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const diagnoticPathologyHeader: IStaticGridHeaderProps[] = [
    {
        columnName: 'question',
        content: null,
        width: '50%',
        minWidth: 300
    },
    {
        columnName: 'leftbreast',
        content: 'Left Breast',
        width: '25%',
        minWidth: 200
    },
    {
        columnName: 'rightbreast',
        content: 'Right Breast',
        width: '25%',
        minWidth: 200
    }
];

const rowHistopathologyType: IStaticGridCellProps[] = [
    {
        columnName: 'question',
        content: (
            <FormLabel />
        ),
        fieldName: 'histopathologyTypeLeft'
    },
    {
        columnName: 'leftbreast',
        content: (
            <CrfCondition
                type={Dtos.DiagnosticAndPathology}
                condition={isLeft}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Select}
                />
            </CrfCondition>
        ),
        fieldName: 'histopathologyTypeLeft'
    },
    {
        columnName: 'rightbreast',
        content: (
            <CrfCondition
                type={Dtos.DiagnosticAndPathology}
                condition={isRight}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Select}
                />
            </CrfCondition>
        ),
        fieldName: 'histopathologyTypeRight'
    }
];

const rowHistopathologyTypeSpecify: IStaticGridCellProps[] = [
    {
        columnName: 'question',
        content: (
            <FormLabel />
        ),
        fieldName: 'histopathologyTypeSpecifyLeft'
    },
    {
        columnName: 'leftbreast',
        content: (
            <CrfCondition
                type={Dtos.DiagnosticAndPathology}
                condition={isLeftHistopathologyType}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Text}
                />
            </CrfCondition>
        ),
        fieldName: 'histopathologyTypeSpecifyLeft'
    },
    {
        columnName: 'rightbreast',
        content: (
            <CrfCondition
                type={Dtos.DiagnosticAndPathology}
                condition={isRightHistopathologyType}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Text}
                />
            </CrfCondition>
        ),
        fieldName: 'histopathologyTypeSpecifyRight'
    }
];

const rowHistologicalGrade: IStaticGridCellProps[] = [
    {
        columnName: 'question',
        content: (
            <FormLabel />
        ),
        fieldName: 'histologicalGradeLeft'
    },
    {
        columnName: 'leftbreast',
        content: (
            <CrfCondition
                type={Dtos.DiagnosticAndPathology}
                condition={isLeft}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Select}
                />
            </CrfCondition>
        ),
        fieldName: 'histologicalGradeLeft'
    },
    {
        columnName: 'rightbreast',
        content: (
            <CrfCondition
                type={Dtos.DiagnosticAndPathology}
                condition={isRight}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Select}
                />
            </CrfCondition>
        ),
        fieldName: 'histologicalGradeRight'
    }
];

const rowOestrogenReceptor: IStaticGridCellProps[] = [
    {
        columnName: 'question',
        content: (
            <FormLabel />
        ),
        fieldName: 'oestrogenReceptorLeft'
    },
    {
        columnName: 'leftbreast',
        content: (
            <CrfCondition
                type={Dtos.DiagnosticAndPathology}
                condition={isLeft}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Select}
                />
            </CrfCondition>
        ),
        fieldName: 'oestrogenReceptorLeft'
    },
    {
        columnName: 'rightbreast',
        content: (
            <CrfCondition
                type={Dtos.DiagnosticAndPathology}
                condition={isRight}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Select}
                />
            </CrfCondition>
        ),
        fieldName: 'oestrogenReceptorRight'
    }
];

const rowProgesteroneReceptor: IStaticGridCellProps[] = [
    {
        columnName: 'question',
        content: (
            <FormLabel />
        ),
        fieldName: 'progesteroneReceptorLeft'
    },
    {
        columnName: 'leftbreast',
        content: (
            <CrfCondition
                type={Dtos.DiagnosticAndPathology}
                condition={isLeft}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Select}
                /></CrfCondition>
        ),
        fieldName: 'progesteroneReceptorLeft'
    },
    {
        columnName: 'rightbreast',
        content: (
            <CrfCondition
                type={Dtos.DiagnosticAndPathology}
                condition={isRight}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Select}
                />
            </CrfCondition>
        ),
        fieldName: 'progesteroneReceptorRight'
    }
];

const rowIHCScore: IStaticGridCellProps[] = [
    {
        columnName: 'question',
        content: (
            <FormLabel />
        ),
        fieldName: 'ihcScoreLeft'
    },
    {
        columnName: 'leftbreast',
        content: (
            <CrfCondition
                type={Dtos.DiagnosticAndPathology}
                condition={isLeft}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Select}
                />
            </CrfCondition>
        ),
        fieldName: 'ihcScoreLeft'
    },
    {
        columnName: 'rightbreast',
        content: (
            <CrfCondition
                type={Dtos.DiagnosticAndPathology}
                condition={isRight}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Select}
                />
            </CrfCondition>
        ),
        fieldName: 'ihcScoreRight'
    }
];

const rowISHScore: IStaticGridCellProps[] = [
    {
        columnName: 'question',
        content: (
            <FormLabel />
        ),
        fieldName: 'ishScoreLeft'
    },
    {
        columnName: 'leftbreast',
        content: (
            <CrfCondition
                type={Dtos.DiagnosticAndPathology}
                condition={isLeft}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Select}
                />
            </CrfCondition>
        ),
        fieldName: 'ishScoreLeft'
    },
    {
        columnName: 'rightbreast',
        content: (
            <CrfCondition
                type={Dtos.DiagnosticAndPathology}
                condition={isRight}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Select}
                />
            </CrfCondition>
        ),
        fieldName: 'ishScoreRight'
    }
];

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate
];

const DiagnosticAndPathology: React.FunctionComponent<IDiagnosticAndPathologyProps> = () => {

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    const canEdit = canUpdateCrf &&
        !!institution &&
        !institution.recruitmentDisabled;

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.DiagnosticAndPathology}
                validateOn="onChange"
                canEdit={canEdit}
            >
                <Field
                    name="dateOfHistopathologicDiagnosis"
                    component={DatePicker}
                />
                <FieldGroup>
                    <Field
                        name="primaryDiagnosisMethod"
                        component={Select}
                    />
                    <CrfCondition
                        type={Dtos.DiagnosticAndPathology}
                        condition={isPrimaryDiagnosisMethodOther}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="primaryDiagnosisMethodSpecify"
                            component={Text}
                        />
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <StaticGrid
                        headers={diagnoticPathologyHeader}
                    >
                        <StaticGridRow cells={rowHistopathologyType} />
                        <StaticGridRow cells={rowHistopathologyTypeSpecify} />
                        <StaticGridRow cells={rowHistologicalGrade} />
                        <StaticGridRow cells={rowOestrogenReceptor} />
                        <StaticGridRow cells={rowProgesteroneReceptor} />
                        <StaticGridRow cells={rowIHCScore} />
                        <StaticGridRow cells={rowISHScore} />
                    </StaticGrid>
                </FieldGroup>
                <SourceDocuments />
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default DiagnosticAndPathology;
