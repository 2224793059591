/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae landing page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Button, makeStyles, Theme, Typography } from '@material-ui/core';

import { useParams } from 'react-router-dom';

import {
    InstitutionsContext,
    InstitutionBreadcrumbs,
    IFormLabel
} from '@ngt/opms';

import {
    ScreeningLogsCollapsibleTable
} from '@ngt/opms-screeninglog';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import useScreeningLogColumns from '../../hooks/screeningLogs/useScreeningLogColumns';
import ScreeningLogFieldGroup from './ScreeningLogFieldGroup';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IScreeningLogPageParams {
    institutionCode?: string;
}

interface IScreeningLogPageProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    ml1: {
        marginLeft: theme.spacing(1),
    },
    mt1: {
        marginTop: theme.spacing(1),
    },
    mt3: {
        marginTop: theme.spacing(3)
    },
    mr1: {
        marginRight: theme.spacing(1)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const ScreeningLogPage: React.FunctionComponent<IScreeningLogPageProps> = () =>
{
    const classes = useStyles();

    const { institutionCode } = useParams<Record<string, string>>();

    const contextInstitutions = React.useContext(InstitutionsContext);

    const institutions = contextInstitutions?.institutions;

    const showInstitutions = React.useMemo(() => {
        return !institutionCode
    }, [institutionCode]);

    const screeningLogDataColumns = useScreeningLogColumns(institutions, showInstitutions);

    const labels: IFormLabel[] = [
        {
            name: 'screeningDate',
            label: 'Screening Date',
            detailedLabel: ''
        },
        {
            name: 'initials',
            label: 'Initials',
            detailedLabel: ''
        },
        {
            name: 'dateOfBirth',
            label: 'Date of Birth',
            detailedLabel: ''
        },
        {
            name: 'reason',
            label: 'Reason',
            detailedLabel: ''
        }
        ,
        {
            name: 'reasonSpecify',
            label: 'Please list each failed eligibility criteria',
            detailedLabel: ''
        }
    ];

    const downloadButtons =
        <>
            <Button
                variant="contained"
                color="primary"
                size="medium"
                component="a"
                className={classes.mr1}
                href={`/print/screeninglogs/${(institutionCode ?? 'all-institutions')}/pdf`}
            >
                PDF
            </Button>
            <Button
                variant="contained"
                color="primary"
                size="medium"
                component="a"
                href={`/print/screeninglogs/${(institutionCode ?? 'all-institutions')}/csv`}
            >
                CSV
            </Button>
        </>;

    return (
        <>
            <InstitutionBreadcrumbs />
            <div
                className={classes.container}
            >
                <Typography
                    variant="h1"
                    color="secondary"
                >
                    Screening Log
                </Typography>

                <Typography
                    className={classes.mt1}
                >
                    Click 'Add Screening Log Entry' to add a screened patient to the log and enter the available information. To update a previously screened patient click 'edit' and amend 
                    the relevant information. Should a patient not be entered to the log prior to registration, this patient will be automatically added to the log by the online registration 
                    system. Please ensure you return to the log to enter the date the patient was originally screened. For patients who do not continue to registration, please ensure the 
                    reason for non-registration is specified.
                    <br /><br />
                    Download and print a Screening Log for filing using the 'PDF' or 'CSV' button.
                </Typography>

                <ScreeningLogsCollapsibleTable
                    columns={screeningLogDataColumns}
                    formFieldsComponent={<ScreeningLogFieldGroup />}
                    formLabels={labels}
                    title={"Screening Log"}
                    downloadButtons={downloadButtons}
                />
            </div>
        </>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default ScreeningLogPage;