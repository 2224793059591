/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/**
 * Used for the basic page layout.
 */
import {
    PatientValidationContext,

    EventDefinitionsContext,
    FormDefinitionsContext,
    FormType
} from '@ngt/opms';

import { Switch, Redirect, Link, useParams } from 'react-router-dom';

import Alert from '@material-ui/lab/Alert';

import AlertTitle from '@material-ui/lab/AlertTitle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';

import { faSignOutAlt } from '@fortawesome/pro-duotone-svg-icons/faSignOutAlt';

import { faChevronRight } from '@fortawesome/pro-duotone-svg-icons/faChevronRight';

import { Button, makeStyles, Typography, Paper } from '@material-ui/core';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */
/*
 * Used to type patient state.
 */

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IPatientProsParams {
    masterGroupCode?: string
    collaboratingGroupCode?: string
    countryCode?: string
    institutionCode?: string
}

interface IPatientProsProps {
    showAdminPage?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(3, 3, 0, 3),

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0),
            border: 'none',
            boxShadow: 'none'
        }
    },
    paragraph: {
        paddingBottom: theme.spacing(1.5),
    },
    paragraphBold: {
        paddingBottom: theme.spacing(1.5),
        textAlign: 'center'
    },
    wrapper: {
        [theme.breakpoints.up('md')]: {
            background: theme.palette.grey[100]
        }
    },
    welcome: {
        padding: theme.spacing(3),

        [theme.breakpoints.up('md')]: {
            maxWidth: 800,
            margin: 'auto'
        }
    },
    continue: {
        padding: theme.spacing(0, 3, 3, 3),
        textAlign: 'center',
    },
    button: {
        padding: theme.spacing(3),
        textAlign: 'center',

        [theme.breakpoints.up('md')]: {
            textAlign: 'right'
        }
    }
}));

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

//const permissions: Dtos.Permission[] = [
//    Dtos.Permission.OpmsAdminister,
//    Dtos.Permission.OpmsPatientUpdate,
//    Dtos.Permission.OpmsPatientImpersonate,
//    Dtos.Permission.OpmsPatientAccountManagement
//];

//const patientCaption: PatientInformationFn = (patient) => (patient as Dtos.Patient)?.initials
//const patientStateCaption: PatientInformationFn = (patient) => DateTime.fromISO((patient as Dtos.Patient)?.registrationDate ?? patient?.enteredDate ?? DateTime.local().toISO({ includeOffset: false })).toFormat('dd/MM/yyyy')


const PatientPros: React.FunctionComponent<IPatientProsProps> = ({
    showAdminPage
}) => {
    const { validation } = React.useContext(PatientValidationContext);
    const { eventDefinitions } = React.useContext(EventDefinitionsContext);
    const { formDefinitions } = React.useContext(FormDefinitionsContext);

    const classes = useStyles();
    const params = useParams<{ next: string; }>();

    const next = params.next === 'next';

    const latestPros = React.useMemo(() => {
        if (!validation || !validation.eventResults) {
            return [];
        }

        const proEvents = validation.eventResults.filter(er => {
            if (!er.formResults) {
                return false;
            }

            if (!er.write) {
                return false;
            }

            return er.formResults.some(fr => {
                const formDefinition = formDefinitions?.find(fd => fd.id === fr.formDefinitionId);

                return formDefinition?.type === FormType.Pro;
            })
        });

        if (proEvents.length === 0) {
            return [];
        }

        const latestProEvent = proEvents[proEvents.length - 1];

        const eventDefinition = eventDefinitions?.find(ed => ed.id === latestProEvent.eventDefinitionId);

        return latestProEvent.formResults
            .map(fr => {
                const formDefinition = formDefinitions?.find(fd => fd.id === fr.formDefinitionId)

                return {
                    eventDefinition,
                    formDefinition,
                    eventRepeat: latestProEvent.eventRepeat,
                    formRepeat: fr.formRepeat,
                    completed: fr.completed
                }
            })
            .filter(forms => forms.formDefinition?.type === FormType.Pro);
    }, [validation, eventDefinitions, formDefinitions]);

    const completed = React.useMemo(() => {
        return !latestPros.some(lp => !lp.completed);
    }, [latestPros]);

    const nextPro = React.useMemo(() => {
        if (!latestPros) {
            return null;
        }

        const nextPro = latestPros.find(lp => !lp.completed);

        if (!nextPro) {
            return null;
        }

        return nextPro;
    }, [latestPros])

    return (
        <>
            {
                !completed && !!next && (
                    <Switch>
                        {
                            latestPros.map((pro, i) => {
                                if (!pro.completed) {
                                    return <Redirect to={`${pro.eventDefinition?.code}/${pro.eventRepeat}/${pro.formDefinition?.code}/${pro.formRepeat}`} />
                                }

                                return null;
                            })
                        }
                    </Switch>
                )
            }
            {
                !completed && !next && (
                    <div className={classes.wrapper}>
                        <div
                            className={classes.welcome}
                        >
                            <Paper elevation={3} className={classes.paper}>
                            <Typography
                                className={classes.paragraphBold}
                            >
                                <strong>Welcome to the 'BCT 2001 &ndash; BREAST MRI' Patient questionnaire platform.</strong>
                            </Typography>
                            <Typography
                                className={classes.paragraph}
                            >
                                On the following screens will be a series of topics with related questions which you have agreed to complete as part of your participation in the <strong>Breast-MRI</strong> research study. 
                            </Typography>
                            <Typography
                                className={classes.paragraph}
                            >
                                As you complete each series of questions the platform will automatically progress you through the required topics until you reach the end.
                            </Typography>
                            <Typography
                                className={classes.paragraph}
                            >
                                You can monitor your progress through the questions via the progress bar at the bottom of the screen.  You will be presented with a completion screen once all questions have been answered.
                            </Typography>
                            <Typography
                                className={classes.paragraphBold}
                            >
                                <strong>Thank you for taking the time to complete this series of questions.</strong>
                            </Typography>
                            <div
                                className={classes.continue}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    component={Link}
                                    to={`/${nextPro?.eventDefinition?.code}/${nextPro?.eventRepeat}/${nextPro?.formDefinition?.code}/${nextPro?.formRepeat}`}
                                >
                                    Continue <FontAwesomeIcon fixedWidth icon={faChevronRight} />
                                </Button>
                            </div>
                            </Paper>
                        </div>
                    </div>
                )
            }
            {
                !!completed && (
                    <>

                        <Alert severity="success" icon={<FontAwesomeIcon fixedWidth icon={faCheckCircle} />} >
                            <AlertTitle>
                                PROs Complete
                            </AlertTitle>
                            Thank you for taking the time to complete the PRO forms.
                        </Alert>
                        <div
                            className={classes.button}
                        >
                            <Button
                                color="primary"
                                variant="contained"
                                component="a"
                                href="/auth/logout"
                            >
                                Logout <FontAwesomeIcon fixedWidth icon={faSignOutAlt} />
                            </Button>
                        </div>
                    </>
                )
            }
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default PatientPros;
