/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    ToggleButtonGroup,
    Condition,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    Password
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import { Typography, makeStyles } from '@material-ui/core';
import SourceDocuments from '../../components/SourceDocuments';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IPreregistrationProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const hasAustralianInstitution = (params: ICrfConditionParameters<Dtos.Preregistration>) => params?.country?.code === 'australia';
const hasSignedMainConsentYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.Preregistration>) => GetFieldLookupItem(lookups, 'signedMainConsentId', formState?.values.signedMainConsentId)?.type === Dtos.LookupYesNoType.Yes;
const hasPatientHasEmailYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.Preregistration>) => GetFieldLookupItem(lookups, 'patientHasEmail', formState?.values.patientHasEmail)?.type === Dtos.LookupYesNoType.Yes;
const hasPatientHasEmailNo = ({ lookups, formState }: ICrfConditionParameters<Dtos.Preregistration>) => GetFieldLookupItem(lookups, 'patientHasEmail', formState?.values.patientHasEmail)?.type === Dtos.LookupYesNoType.No;

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const Preregistration: React.FunctionComponent<IPreregistrationProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const { form } = React.useContext(FormContext)

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    const canEdit = canUpdateCrf &&
        !!institution &&
        !institution.recruitmentDisabled;

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.Preregistration}
                validateOn="onChange"
                canEdit={canEdit}
            >
                <CrfCondition
                    type={Dtos.Preregistration}
                    condition={hasAustralianInstitution}
                    mode="Show"
                    subscription={{}}
                >
                    <Field
                        name="patientInitials"
                        component={Text}
                    />
                    <Field
                        name="dateOfBirth"
                        component={DatePicker}
                    />
                    <Field
                        name="postcodeOfUsualResidence"
                        component={Text}
                    />
                    <Field
                        name="indigenousStatusId"
                        component={Select}
                        xl={5}
                        lg={5}
                        md={6}
                    />
                    <Field
                        name="countryOfBirth"
                        component={Text}
                    />
                    <Field
                        name="mainLanguageForCommunication"
                        component={Text}
                    />
                </CrfCondition>
                <Field
                    name="signedMainConsentId"
                    component={ToggleButtonGroup}
                />
                <Condition
                    type={Dtos.Preregistration}
                    condition={hasSignedMainConsentYes}
                    subscription={{ values: true }}
                >
                    <Field
                        name="dateSignedMainConsent"
                        component={DatePicker}
                    />
                </Condition>
                <Field name="patientHasEmail" component={ToggleButtonGroup} />
                <Condition
                    type={Dtos.Preregistration}
                    mode="Show"
                    condition={hasPatientHasEmailYes}
                    subscription={{ values: true }}
                >
                    <Field
                        name="patientEmailForPROMs"
                        component={canAdminsterOpms && !!form?.id ? Password : Text}
                    />
                </Condition>
                <Condition
                    type={Dtos.Preregistration}
                    mode="Show"
                    condition={hasPatientHasEmailNo}
                    subscription={{ values: true }}
                >
                    <FieldGroup>
                        <Typography className={classes.container}>
                            Patient will need to complete paper PROMs
                        </Typography>
                    </FieldGroup>
                </Condition>
                <SourceDocuments />
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default Preregistration;
