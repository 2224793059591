/* Options:
Date: 2022-05-26 09:30:57
Version: 6.02
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44359

//GlobalNamespace: 
//MakePropertiesOptional: False
AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
ExcludeTypes: GetInstitutionPersonnelEmailAddresses,InstitutionPersonnelEmailAddressesResponse,GetUserPersonnelEmailAddress,UserPersonnelEmailAddressResponse,SendRegistrationEmail,SendRandomisationEmail
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IGet
{
}

export interface IDelete
{
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export interface IHasPatientId
{
    patientId?: number;
}

export interface IReportRequest
{
    token: string;
}

export interface ISaeGetCollection extends IGet
{
}

export interface ISaeGetCollectionByInstitutionCode extends IGet
{
    institutionCode: string;
}

export interface ISaeGetSingleByPatientStudyNumber extends IGet
{
    patientStudyNumber: string;
    saeNumber?: number;
}

export enum SaeStatus
{
    Added = 0,
    New = 1,
    AwaitingForReview = 2,
    Approved = 3,
    Rejected = 4,
    Incorrect = 5,
}

export enum SaeWebActionTypeEnum
{
    ApprovalNotRequired = 0,
    NotifiedSiteInvestigators = 1,
    Approved = 2,
    Rejected = 3,
    NotifiedSiteTc = 4,
    Reset = 5,
    NotifiedMedicalReviewers = 6,
    SubmittedMedicalReview = 7,
    NotifiedTeam = 8,
}

export class SaeWebActionType
{
    public id?: number;
    public value: string;

    public constructor(init?: Partial<SaeWebActionType>) { (Object as any).assign(this, init); }
}

export class WebAction
{
    public id?: number;
    public patientStudyNumber: string;
    public saeNumber?: number;
    public saeFollowUpNumber?: number;
    public actionType?: SaeWebActionTypeEnum;
    public actionDate?: string;
    public userName: string;
    public notes: string;
    public recipients: string;
    public saeWebActionType: SaeWebActionType;

    public constructor(init?: Partial<WebAction>) { (Object as any).assign(this, init); }
}

export class SaeStudyDrugReviewForm
{
    public studyDrug: string;
    public investigatorAssessment: string;
    public assessment: string;
    public comments: string;

    public constructor(init?: Partial<SaeStudyDrugReviewForm>) { (Object as any).assign(this, init); }
}

export class SaeExpectednessForm
{
    public assessment: string;
    public comments: string;

    public constructor(init?: Partial<SaeExpectednessForm>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewForm
{
    public studyDrugReviews: SaeStudyDrugReviewForm[];
    public expectedness: SaeExpectednessForm[];
    public isEventStatusDeath: boolean;

    public constructor(init?: Partial<SaeMedicalReviewForm>) { (Object as any).assign(this, init); }
}

export class FileUploadTemporary implements IFileUploadTemporary
{
    public guid: string;
    public name: string;
    public extension: string;
    public fileSize?: number;
    public contentType: string;
    public image: boolean;

    public constructor(init?: Partial<FileUploadTemporary>) { (Object as any).assign(this, init); }
}

export class File
{
    public id?: number;
    public patientId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyName: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    // @Ignore()
    public upload: FileUploadTemporary;

    // @Ignore()
    public extension: string;

    // @Ignore()
    public fileSize?: number;

    // @Ignore()
    public contentType: string;

    // @Ignore()
    public exists: boolean;

    // @Ignore()
    public image: boolean;

    public constructor(init?: Partial<File>) { (Object as any).assign(this, init); }
}

export class SaeDocumentForm
{
    public name: string;
    public file: File;

    public constructor(init?: Partial<SaeDocumentForm>) { (Object as any).assign(this, init); }
}

export enum SaeNotificationType
{
    NotifyInvestigator = 1,
    NotifyMedicalReviewer = 2,
}

export class SaeNotificationForm
{
    public recipients: number[];
    public ccRecipients: number[];
    public notes: string;
    public attachments: SaeDocumentForm[];
    public notificationType: SaeNotificationType;

    public constructor(init?: Partial<SaeNotificationForm>) { (Object as any).assign(this, init); }
}

export interface IScreeningLogGetSingleById extends IGet
{
    id?: number;
}

export interface IScreeningLogGetCollection extends IGet
{
}

export interface IScreeningLogGetCollectionByInstitutionId extends IGet
{
    institutionId?: number;
}

export class ScreeningLog implements IScreeningLog
{
    public id?: number;
    public institutionId?: number;
    public patientId?: number;
    public initials: string;
    public dateOfBirth?: string;
    public screeningDate?: string;
    public reason?: number;
    public reasonSpecify: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ScreeningLog>) { (Object as any).assign(this, init); }
}

export interface IScreeningLogDelete extends IDelete
{
    screeningLogId?: number;
}

export interface ILookupGetCollectionByFormDefinitionId extends IGet
{
    formDefinitionId?: number;
}

export interface IEventDefinitionGetSingleById extends IGet
{
    id?: number;
}

export interface IEventDefinitionGetSingleByCode extends IGet
{
    code: string;
}

export interface IEventDefinitionGetCollection extends IGet
{
}

export interface IFormDefinitionGetSingleById extends IGet
{
    id?: number;
}

export interface IFormDefinitionGetSingleByCode extends IGet
{
    code: string;
}

export interface IFormDefinitionGetCollection extends IGet
{
}

export interface IPatientStateGetSingleById extends IGet
{
    id?: number;
}

export interface IPatientStateGetCollection extends IGet
{
}

export interface ITreatmentGetSingleById extends IGet
{
    id?: number;
}

export interface ITreatmentGetCollection extends IGet
{
}

export interface IMasterGroupGetSingleById extends IGet
{
    id?: number;
}

export interface IMasterGroupGetSingleByCode extends IGet
{
    code: string;
}

export interface IMasterGroupGetCollection extends IGet
{
}

export interface ICollaboratingGroupGetSingleById extends IGet
{
    id?: number;
}

export interface ICollaboratingGroupGetSingleByCode extends IGet
{
    code: string;
}

export interface ICollaboratingGroupGetCollection extends IGet
{
}

export interface ICollaboratingGroupGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
}

export interface ICollaboratingGroupGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
}

export interface ICountryGetSingleById extends IGet
{
    id?: number;
}

export interface ICountryGetSingleByCode extends IGet
{
    code: string;
}

export interface ICountryGetCollection extends IGet
{
}

export interface ICountryGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
}

export interface ICountryGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
}

export interface ICountryGetCollectionByCollaboratingGroupId extends IGet
{
    collaboratingGroupId?: number;
}

export interface ICountryGetCollectionByCollaboratingGroupCode extends IGet
{
    collaboratingGroupCode: string;
}

export interface IInstitutionGetSingleById extends IGet
{
    id?: number;
}

export interface IInstitutionGetSingleByCode extends IGet
{
    code: string;
}

export interface IInstitutionGetCollection extends IGet
{
}

export interface IInstitutionGetCollectionByCountryId extends IGet
{
    countryId?: number;
}

export interface IInstitutionGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
    countryId?: number;
}

export interface IInstitutionGetCollectionByCollaboratingGroupId extends IGet
{
    collaboratingGroupId?: number;
    countryId?: number;
}

export interface IInstitutionGetCollectionByCountryCode extends IGet
{
    countryCode: string;
}

export interface IInstitutionGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
    countryCode: string;
}

export interface IInstitutionGetCollectionByCollaboratingGroupCode extends IGet
{
    collaboratingGroupCode: string;
    countryCode: string;
}

export interface IPatientGetSingleById extends IGet
{
    id?: number;
}

export interface IPatientGetSingleByStudyNumber extends IGet
{
    studyNumber: string;
}

export interface IPatientGetValidationById extends IGet
{
    id?: number;
}

export interface IPatientGetValidationByStudyNumber extends IGet
{
    studyNumber: string;
}

export interface IPatientGetCollection extends IGet
{
    patientStateIds: number[];
}

export interface IPatientGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
    countryId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCollaboratingGroupId extends IGet
{
    collaboratingGroupId?: number;
    countryId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCountryId extends IGet
{
    countryId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByInstitutionId extends IGet
{
    institutionId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
    countryCode: string;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCollaboratingGroupCode extends IGet
{
    collaboratingGroupCode: string;
    countryCode: string;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCountryCode extends IGet
{
    countryCode: string;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByInstitutionCode extends IGet
{
    institutionCode: string;
    patientStateIds: number[];
}

export class Patient implements IOpenClinicaPatient, IPreregistrationPatient, IRegistrationPatient
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Data.Institution)")
    public institutionId?: number;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.PatientState)")
    public patientStateId?: number;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.Treatment)")
    public treatmentId?: number;

    public studyNumber: string;
    public institutionCode: string;
    public preregistrationNumber: string;
    public preregistrationDate?: string;
    public registrationNumber: string;
    public registrationDate?: string;
    public initials: string;
    public age?: number;
    public account?: boolean;
    public email: string;
    // @Ignore()
    public skipEmailingPatient?: boolean;

    public exportedToOpenClinica?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Patient>) { (Object as any).assign(this, init); }
}

export interface IEventGetSingleById extends IGet
{
    id?: number;
}

export interface IEventGetSingleByIds extends IGet
{
    institutionId?: number;
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
}

export interface IEventGetSingleByCodes extends IGet
{
    institutionCode: string;
    patientStudyNumber: string;
    eventDefinitionCode: string;
    eventRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
}

export interface IEventGetCollection extends IGet
{
}

export interface IEventGetCollectionByPatientId extends IGet
{
    patientId?: number;
}

export interface IEventGetCollectionByPatientStudyNumber extends IGet
{
    patientStudyNumber: string;
}

export class Event implements IEvent
{
    public id?: number;
    public patientId?: number;
    public eventDefinitionId?: number;
    public repeat?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Event>) { (Object as any).assign(this, init); }
}

export interface IFormGetSingleById extends IGet
{
    id?: number;
}

export interface IFormGetSingleByIds extends IGet
{
    institutionId?: number;
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
    formRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
    createForm?: boolean;
}

export interface IFormGetSingleByCodes extends IGet
{
    institutionCode: string;
    patientStudyNumber: string;
    eventDefinitionCode: string;
    eventRepeat?: number;
    formRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
    createForm?: boolean;
}

export interface IFormGetCollection extends IGet
{
}

export interface IFormGetCollectionByIds extends IGet
{
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
}

export interface IFormGetCollectionByCodes extends IGet
{
    patientStudyNumber: string;
    eventDefinitionCode: string;
    eventRepeat?: number;
}

export class SourceDocument implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public name: string;
    public fileTypeId?: number;
    public file: File;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SourceDocument>) { (Object as any).assign(this, init); }
}

export class PatientTransferEntry implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public transferDate?: string;
    public institutionId?: number;
    public comments: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PatientTransferEntry>) { (Object as any).assign(this, init); }
}

export class PatientTransfer implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public entries: PatientTransferEntry[];
    public sourceDocuments: SourceDocument[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PatientTransfer>) { (Object as any).assign(this, init); }
}

export class DecisionConflictScale implements IProForm, IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public preferedTreatmentOptionId?: number;
    public availableOptionsKnownId?: number;
    public benefitsKnownId?: number;
    public risksAndSideEffectsKnownId?: number;
    public benefitsMatterMostId?: number;
    public risksAndSideEffectsMatterMostId?: number;
    public moreImportantId?: number;
    public enoughSupportToChooseId?: number;
    public choosingWithoutPressureId?: number;
    public enoughAdviceToChooseId?: number;
    public bestDecisionClearId?: number;
    public sureAboutDecisionId?: number;
    public decisionEasyId?: number;
    public informedDecisionId?: number;
    public decisionShowsImportanceId?: number;
    public stickWithDecisionId?: number;
    public satisfiedWithDecisionId?: number;
    public sourceDocuments: SourceDocument[];
    public completed?: boolean;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<DecisionConflictScale>) { (Object as any).assign(this, init); }
}

export class DecisionRegretScale implements IProForm, IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public rightDecisionId?: number;
    public regretDecisionId?: number;
    public sameDecisionAgainId?: number;
    public decisionCausedHarmId?: number;
    public decisionWiseId?: number;
    public sourceDocuments: SourceDocument[];
    public completed?: boolean;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<DecisionRegretScale>) { (Object as any).assign(this, init); }
}

export class Eq5d5l implements IProForm, IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public mobilityId?: number;
    public selfCareId?: number;
    public usualActivitiesId?: number;
    public painAndDiscomfortId?: number;
    public anxietyAndDepressionId?: number;
    public healthRating?: number;
    public sourceDocuments: SourceDocument[];
    public completed?: boolean;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<Eq5d5l>) { (Object as any).assign(this, init); }
}

export class ImpactOfMri implements IProForm, IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public improveConfidenceInTreatmentDecisionId?: number;
    public sourceDocuments: SourceDocument[];
    public completed?: boolean;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<ImpactOfMri>) { (Object as any).assign(this, init); }
}

export class PreferenceForSurgery implements IProForm, IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public preferedTreatmentOptionId?: number;
    public reasonForDecision: string;
    public sourceDocuments: SourceDocument[];
    public completed?: boolean;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<PreferenceForSurgery>) { (Object as any).assign(this, init); }
}

export class BreastImagingLesion implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public lesionNumber?: number;
    public side?: number;
    public location?: number;
    public size?: number;
    public density?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<BreastImagingLesion>) { (Object as any).assign(this, init); }
}

export class BreastMammogramLesion implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public lesionNumber?: number;
    public side?: number;
    public location?: number;
    public size?: number;
    public sizeNotAvailable?: boolean;
    public density?: number;
    public densityNotAvailable?: boolean;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<BreastMammogramLesion>) { (Object as any).assign(this, init); }
}

export class UltrasoundImagingLesion implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public lesionNumber?: number;
    public side?: number;
    public location?: number;
    public size?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<UltrasoundImagingLesion>) { (Object as any).assign(this, init); }
}

export class PetScanImagingLesion implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public lesionNumber?: number;
    public side?: number;
    public location?: number;
    public size?: number;
    public sizeNotSpecified?: boolean;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PetScanImagingLesion>) { (Object as any).assign(this, init); }
}

export class OtherImagingLesion implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public lesionNumber?: number;
    public side?: number;
    public location?: number;
    public size?: number;
    public sizeNotSpecified?: boolean;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<OtherImagingLesion>) { (Object as any).assign(this, init); }
}

export class DiagnosticAndImaging implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public breastCancerConfirmLeft?: number;

    // @Ignore()
    public breastCancerConfirmRight?: number;

    public mammogramDate?: string;
    public mammogramNotDone?: boolean;
    public mammogramType?: number;
    public indexLesionsIdentifiableOnMammogram?: number;
    public mammogramLesionLocationLeft?: boolean;
    public mammogramLesionLocationRight?: boolean;
    public mammogramFocalityLeft?: number;
    public mammogramFocalityRight?: number;
    public spectralMammogramPerformed?: number;
    public spectralMammogramDate?: string;
    public ultrasoundMammogramPerformed?: number;
    public ultrasoundMammogramDate?: string;
    public indexLesionsIdentifiableOnUltrasound?: number;
    public ultrasoundMammogramLesionLocationLeft?: boolean;
    public ultrasoundMammogramLesionLocationRight?: boolean;
    public ultrasoundMammogramFocalityLeft?: number;
    public ultrasoundMammogramFocalityRight?: number;
    public otherImagingPerformed?: number;
    public otherImagingDate?: string;
    public otherImagingType: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public breastMammogramLesions: BreastMammogramLesion[];
    public breastImagingLesions: BreastImagingLesion[];
    public ultrasoundImagingLesions: UltrasoundImagingLesion[];
    public petScanImagingLesions: PetScanImagingLesion[];
    public otherImagingLesions: OtherImagingLesion[];
    public sourceDocuments: SourceDocument[];
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<DiagnosticAndImaging>) { (Object as any).assign(this, init); }
}

export class DiagnosticAndPathology implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public breastCancerConfirmLeft?: number;

    // @Ignore()
    public breastCancerConfirmRight?: number;

    public dateOfHistopathologicDiagnosis?: string;
    public primaryDiagnosisMethod?: number;
    public primaryDiagnosisMethodSpecify: string;
    public histopathologyTypeLeft?: number;
    public histopathologyTypeRight?: number;
    public histopathologyTypeSpecifyLeft: string;
    public histopathologyTypeSpecifyRight: string;
    public histologicalGradeLeft?: number;
    public histologicalGradeRight?: number;
    public oestrogenReceptorLeft?: number;
    public oestrogenReceptorRight?: number;
    public progesteroneReceptorLeft?: number;
    public progesteroneReceptorRight?: number;
    public ihcScoreLeft?: number;
    public ihcScoreRight?: number;
    public ishScoreLeft?: number;
    public ishScoreRight?: number;
    public sourceDocuments: SourceDocument[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<DiagnosticAndPathology>) { (Object as any).assign(this, init); }
}

export class EligibilityChecklist implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public isFemaleOlderThanEighteenNewlyDiagnosed?: number;
    public withUltrasoundMammography?: boolean;
    public lessThanSeventyWithLobularCancer?: boolean;
    public lessThanFiftyYears?: boolean;
    public withReportedExtremeBreastDensity?: boolean;
    public hasPreMriTreatmentPlanConfirmedAndDocumented?: number;
    public isAccessibleForFollowUp?: number;
    public hasCompletedBaselinePROMs?: number;
    public hasDistantMetastaticDisease?: number;
    public hasLocallyAdvancedInoperableBreastCancer?: number;
    public hasNonMalignantSystemicDiseases?: number;
    public hasUndergoneStudySpecificMRIBefore?: number;
    public hasHadPrevioudBreastCancerDiagnosisOnSameSide?: number;
    public sourceDocuments: SourceDocument[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<EligibilityChecklist>) { (Object as any).assign(this, init); }
}

export class General implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public breastCancerConfirmLeft?: number;
    public breastCancerConfirmRight?: number;
    public symptomaticPresentationLeft?: number;
    public symptomaticPresentationRight?: number;
    public symptomaticSelectionPalpableMassLeft?: boolean;
    public symptomaticSelectionPalpableMassRight?: boolean;
    public symptomaticSelectionPalpableMassSizeLeft?: number;
    public symptomaticSelectionPalpableMassSizeRight?: number;
    public symptomaticSelectionNippleChangeDischargeLeft?: boolean;
    public symptomaticSelectionNippleChangeDischargeRight?: boolean;
    public symptomaticSelectionBreastDistortionLeft?: boolean;
    public symptomaticSelectionBreastDistortionRight?: boolean;
    public symptomaticSelectionAxillaryMassLeft?: boolean;
    public symptomaticSelectionAxillaryMassRight?: boolean;
    public symptomaticSelectionOtherLeft?: boolean;
    public symptomaticSelectionOtherRight?: boolean;
    public otherModeOfDetection?: boolean;
    public otherModeOfDetectionLeft: string;
    public otherModeOfDetectionRight: string;
    public sourceDocuments: SourceDocument[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<General>) { (Object as any).assign(this, init); }
}

export class PreMriPlanning implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public dateStudySpecificMriPlanned?: string;
    public mriNotScheduled?: boolean;
    public plannedBreastAxillarySurgery?: number;
    public plannedSurgeryMastectomyUnilateral?: boolean;
    public plannedSurgeryMastectomyBilateral?: boolean;
    public plannedSurgeryMastectomyBilateralReason?: number;
    public plannedSurgeryReconstruction?: boolean;
    public plannedSurgeryBreastConservationSurgery?: boolean;
    public plannedSurgeryBCSPlusLevelTwoOrHigherOncoplasticSurgery?: boolean;
    public plannedTypeOfAxillarySurgery?: number;
    public plannedTypeSentinelLymphNodeBiopsy?: boolean;
    public plannedTypeAxillaryClearance?: boolean;
    public plannedTypeOther?: boolean;
    public plannedTypeOtherSpecify: string;
    public plannedRadiotherapy?: number;
    public plannedRadiotherapyChestWall?: boolean;
    public plannedRadiotherapyBreast?: boolean;
    public plannedRadiotherapyRegionalNodes?: boolean;
    public plannedRadiotherapyOther?: boolean;
    public plannedRadiotherapyOtherSpecify: string;
    public plannedSystemicTherapy?: number;
    public plannedSystemicTherapyChemotherapy?: boolean;
    public plannedSystemicTherapyHERDirected?: boolean;
    public plannedSystemicTherapyEndocrine?: boolean;
    public plannedSystemicTherapyNeoadjuvant?: boolean;
    public plannedSystemicTherapyOther?: boolean;
    public plannedSystemicTherapyOtherSpecify: string;
    public whyWasMRISuggested?: number;
    public whyWasMRISuggestedYoungAge?: boolean;
    public whyWasMRISuggestedSizeDiscrepancy?: boolean;
    public whyWasMRISuggestedDenseBreasts?: boolean;
    public whyWasMRISuggestedMultifocality?: boolean;
    public whyWasMRISuggestedLobularCancer?: boolean;
    public whyWasMRISuggestedMutationCarrier?: boolean;
    public whyWasMRISuggestedNeoadjuvantTherapyPlanned?: boolean;
    public whyWasMRISuggestedPatientRequest?: boolean;
    public sourceDocuments: SourceDocument[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<PreMriPlanning>) { (Object as any).assign(this, init); }
}

export class Preregistration implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public patientInitials: string;
    public dateOfBirth?: string;
    public postcodeOfUsualResidence: string;
    public indigenousStatusId?: number;
    public countryOfBirth: string;
    public mainLanguageForCommunication: string;
    public signedMainConsentId?: number;
    public dateSignedMainConsent?: string;
    public patientHasEmail?: number;
    public patientEmailForPROMs: string;
    public sourceDocuments: SourceDocument[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<Preregistration>) { (Object as any).assign(this, init); }
}

export class ProcomidaIncomplete implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public formDefinitionId?: number;
    public patientRefusal?: boolean;
    public patientIll?: boolean;
    public other?: boolean;
    public otherSpecify: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ProcomidaIncomplete>) { (Object as any).assign(this, init); }
}

export class ProcomidaAssistance implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public formDefinitionId?: number;
    public assistanceReadAllowed?: boolean;
    public assistanceClarificationRequired?: boolean;
    public assistanceIndependentlyCompleted?: boolean;
    public assistanceOther?: boolean;
    public reasonCouldNotRead?: boolean;
    public reasonOther?: boolean;
    public reasonOtherSpecify: string;
    public assistedByStaff?: boolean;
    public assistedByFamily?: boolean;
    public assistedByFriend?: boolean;
    public assistedByOther?: boolean;
    public assistedByOtherSpecify: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ProcomidaAssistance>) { (Object as any).assign(this, init); }
}

export class Procomida implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public completedId?: number;
    public assistanceId?: number;
    public incompleteForms: ProcomidaIncomplete[];
    public assistedForms: ProcomidaAssistance[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<Procomida>) { (Object as any).assign(this, init); }
}

export class EligibilityAssessment implements IOpenClinicaForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public patientEligible?: boolean;
    public patientEvaluability?: boolean;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public exportedToOpenClinica?: string;

    public constructor(init?: Partial<EligibilityAssessment>) { (Object as any).assign(this, init); }
}

export enum Permission
{
    OpmsAccess = 1,
    OpmsInstitutionView = 2,
    OpmsAdminister = 3,
    OpmsPatientView = 4,
    SystemAdminister = 5,
    OpmsPatientUpdate = 6,
    OpmsMasterGroupAdminister = 7,
    OpmsCoordinatingGroupAdminister = 8,
    PathologyTestAdminister = 9,
    BlockShipmentDetailsView = 10,
    OpmsPatientRegister = 11,
    OpmsPatientRandomise = 12,
    SaeView = 13,
    SaeReview = 14,
    ScreeningLogView = 15,
    ScreeningLogUpdate = 16,
    TeleformView = 17,
    TeleformManage = 18,
    EcrfView = 19,
    SaeAdminister = 20,
    MedicalReviewAccess = 21,
    MedicalReviewPerform = 22,
    MedicalReviewAdminister = 23,
    HistopathologyAccess = 24,
    DrugManagementView = 25,
    DrugManagementAdminister = 26,
    DrugManagementDispenseDrug = 27,
    OpmsProView = 28,
    OpmsProUpdate = 29,
    OpmsProAdminister = 30,
    OpmsPatientImpersonate = 31,
    OpmsPatientAccountManagement = 32,
    OpmsCrfView = 33,
    OpmsCrfUpdate = 34,
    OpmsEventView = 35,
    OpmsEventUpdate = 36,
    OpmsConsentAdminister = 37,
    OpmsCoordinatingGroupContactView = 38,
    OpmsSaeInvestigatorNotify = 39,
    EConsentView = 40,
    EConsentAdminister = 41,
    EConsentManage = 42,
    PISignOffView = 43,
    PISignOffPerform = 44,
    PISignOffAdminister = 45,
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class EmailAddress
{
    public displayName: string;
    public address: string;

    public constructor(init?: Partial<EmailAddress>) { (Object as any).assign(this, init); }
}

export enum PatientStateType
{
    NewPatient = 1,
    Preregistered = 2,
    Registered = 3,
    PostMri = 4,
    PostSurgery = 5,
    TwelveMonthFollowUp = 6,
    Ineligible = 7,
    Waivered = 8,
    Removed = 9,
}

export enum EventDefinitionType
{
    Preregistration = 1,
    Baseline = 2,
    PostSurgery = 3,
    TwelveMonthFollowUp = 4,
    PostMri = 5,
    Staff = 6,
}

export enum FormDefinitionType
{
    Preregistration = 1,
    EligibilityChecklist = 2,
    General = 3,
    PreMriPlanning = 4,
    DiagnosticAndPathology = 5,
    DiagnosticAndImaging = 6,
    BreastMammogramLesion = 7,
    BreastImagingLesion = 8,
    UltrasoundImagingLesion = 9,
    PetScanImagingLesion = 10,
    OtherImagingLesion = 11,
    SourceDocument = 12,
    DecisionConflictScale = 13,
    DecisionRegretScale = 14,
    Eq5d5l = 15,
    ImpactOfMri = 16,
    PreferenceOfSurgery = 17,
    Procomida = 18,
    ProcomidaIncomplete = 19,
    ProcomidaAssistance = 20,
    PatientTransfer = 21,
    PatientTransferEntry = 22,
    EligibilityAssessment = 23,
}

export enum TreatmentType
{
    Default = 1,
}

export enum LookupAbilityType
{
    NoProblem = 1,
    SlightProblem = 2,
    ModerateProblem = 3,
    SevereProblem = 4,
    Unable = 5,
}

export enum LookupAgreeType
{
    StronglyDisagree = 1,
    Disagree = 2,
    NeitherAgreeOrDisagree = 3,
    Agree = 4,
    StronglyAgree = 5,
}

export enum LookupBiradsDensityType
{
    A = 1,
    B = 2,
    C = 3,
    D = 4,
    NotDone = 5,
}

export enum LookupConditionalIneligibleType
{
    Ineligible = 1,
    Eligible = 2,
    ConditionalIneligible = 3,
}

export enum LookupFocalityType
{
    Unifocal = 1,
    Multifocal = 2,
    Multicentric = 3,
    NotSpecified = 4,
}

export enum LookupGreaterThanLessThanType
{
    GreaterThan = 1,
    LessThan = 2,
    Equal = 3,
}

export enum LookupHistologicalGradeType
{
    Grade1 = 1,
    Grade1To2 = 2,
    Grade2 = 3,
    Grade2To3 = 4,
    Grade3 = 5,
    NA = 6,
}

export enum LookupHistopathologyType
{
    IDC = 1,
    ILC = 2,
    Mixed = 3,
    DCIS = 4,
    PLCIS = 5,
    Other = 6,
    NA = 7,
}

export enum LookupIhcScoreType
{
    Zero = 1,
    OnePlus = 2,
    TwoPlus = 3,
    ThreePlus = 4,
    NotDone = 5,
}

export enum LookupIndigenousStatusType
{
    Indigenous = 1,
    NotIndigenous = 2,
    Unknown = 3,
}

export enum LookupIneligibleType
{
    Ineligible = 1,
    Eligible = 2,
}

export enum LookupIshScoreType
{
    Positive = 1,
    Negative = 2,
    NotDone = 3,
}

export enum LookupLeftRightType
{
    Left = 1,
    Right = 2,
}

export enum LookupLocationQuadrantType
{
    UpperOuterQuadrant = 1,
    UpperInnerQuadrant = 2,
    LowerOuterQuadrant = 3,
    LowerInnerQuadrant = 4,
    Central = 5,
    OverlappingLesion = 6,
    NotSpecified = 6,
    Nipple = 7,
    AxillaryTail = 8,
    BreastNos = 9,
}

export enum LookupPlannedSurgeryMastectomyBilateralReasonType
{
    BilateralBreastCancer = 1,
    UnilateralBreastCancerWithProphylacticMastectomy = 2,
}

export enum LookupPrimaryDiagnosisMethodType
{
    CoreBiposy = 1,
    FNA = 2,
    OpenDiagnostic = 3,
    Other = 4,
}

export enum LookupReceptorType
{
    Positive = 1,
    Negative = 2,
    NA = 3,
}

export enum LookupSurgeryPreferenceType
{
    BreastConservingSurgery = 1,
    Mastectomy = 2,
    NeoAdjuvantChemotherapy = 3,
    NotKnown = 4,
}

export enum LookupTypeOfMammogramType
{
    TomosynthesisMammography = 1,
    TwoDMammogram = 2,
}

export enum LookupUntypedType
{
    NoType = 1,
}

export enum LookupYesNoNaType
{
    Yes = 1,
    No = 2,
    NotApplicableOrNotAnnounced = 3,
}

export enum LookupYesNoType
{
    Yes = 1,
    No = 2,
}

export enum LookupScreeningLogReasonType
{
    PatientIsIneligible = 1,
    ClinicianUnsuitable = 2,
    PatientDeclinedTrial = 3,
    PatientDeclinedNoReason = 4,
}

export class FormsDueSummary
{
    public initials: string;
    public studyNumber: string;
    public institutionCode: string;
    public institutionName: string;
    public eventName: string;
    public formsDueForEvent: string;
    public visitDate: string;
    public dueDate: string;

    public constructor(init?: Partial<FormsDueSummary>) { (Object as any).assign(this, init); }
}

export class IdentityServerExceptionInfo
{
    public message: string;
    public stackTrace: string;
    public innerException: IdentityServerExceptionInfo;

    public constructor(init?: Partial<IdentityServerExceptionInfo>) { (Object as any).assign(this, init); }
}

export class IdentityServerDiagnostic
{
    public test: string;
    public pass: boolean;
    public exception: IdentityServerExceptionInfo;
    public values: { [index: string]: Object; };

    public constructor(init?: Partial<IdentityServerDiagnostic>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key: string;

    // @DataMember(Order=2)
    public keyType: string;

    // @DataMember(Order=3)
    public expiryDate?: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UserApiKey>) { (Object as any).assign(this, init); }
}

export class Sae<TSaeForm, TPatient>
{
    public patient: TPatient;
    public form: TSaeForm;
    public status: SaeStatus;
    public ecrfs: TSaeForm[];
    public saeFormItems: { [index: string]: string; };
    public actionHistory: WebAction[];

    public constructor(init?: Partial<Sae<TSaeForm, TPatient>>) { (Object as any).assign(this, init); }
}

export enum ValidationResultType
{
    Valid = 1,
    Ineligible = 2,
    Error = 3,
    StratificationFailure = 4,
    Critical = 5,
}

export enum ValidationErrorType
{
    Warning = 1,
    Ineligible = 2,
    Normal = 3,
    StratificationFailure = 4,
    Critical = 5,
}

export class SaeFormValidationError implements ISaeFormValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<SaeFormValidationError>) { (Object as any).assign(this, init); }
}

export class SaeFormValidationResult
{
    public result: ValidationResultType;
    public errors: SaeFormValidationError[];

    public constructor(init?: Partial<SaeFormValidationResult>) { (Object as any).assign(this, init); }
}

export class ScreeningLogFormValidationError implements IScreeningLogFormValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<ScreeningLogFormValidationError>) { (Object as any).assign(this, init); }
}

export class ScreeningLogFormValidationResult
{
    public result: ValidationResultType;
    public errors: ScreeningLogFormValidationError[];

    public constructor(init?: Partial<ScreeningLogFormValidationResult>) { (Object as any).assign(this, init); }
}

export interface IFileUploadTemporary
{
    guid: string;
    name: string;
    extension: string;
    fileSize?: number;
    contentType: string;
    image: boolean;
}

export enum UserType
{
    TrialPersonnel = 1,
    Patient = 2,
}

export class LookupItem implements ILookupItem
{
    public id?: number;
    public value: string;
    public order?: number;
    public type?: number;

    public constructor(init?: Partial<LookupItem>) { (Object as any).assign(this, init); }
}

export class Lookup
{
    public propertyName: string;
    public items: LookupItem[];

    public constructor(init?: Partial<Lookup>) { (Object as any).assign(this, init); }
}

export class FormMetadata
{
    public formDefinitionId?: number;
    public formDefinitionCode: string;
    public name: string;
    public propertyName: string;
    public title: string;
    public instructions: string;

    public constructor(init?: Partial<FormMetadata>) { (Object as any).assign(this, init); }
}

export class EventDefinitionFormDefinitionState implements IEventDefinitionFormDefinitionState
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinitionFormDefinition)")
    public eventDefinitionFormDefinitionId?: number;

    public patientStateId?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;

    public constructor(init?: Partial<EventDefinitionFormDefinitionState>) { (Object as any).assign(this, init); }
}

export class EventDefinitionFormDefinition
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinition)")
    public eventDefinitionId?: number;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public repeating?: boolean;
    public minimumRepeat?: number;
    public maximumRepeat?: number;
    public order?: number;
    public states: EventDefinitionFormDefinitionState[];

    public constructor(init?: Partial<EventDefinitionFormDefinition>) { (Object as any).assign(this, init); }
}

export class EventDefinitionState implements IEventDefinitionState
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinition)")
    public eventDefinitionId?: number;

    public patientStateId?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;

    public constructor(init?: Partial<EventDefinitionState>) { (Object as any).assign(this, init); }
}

export class EventDefinitionErrorMessage implements IEventDefinitionErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinition)")
    public eventDefinitionId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<EventDefinitionErrorMessage>) { (Object as any).assign(this, init); }
}

export class EventDefinition
{
    public id?: number;
    public name: string;
    public code: string;
    public repeating?: boolean;
    public minimumRepeat?: number;
    public maximumRepeat?: number;
    public order?: number;
    public formDefinitions: EventDefinitionFormDefinition[];
    public states: EventDefinitionState[];
    public errorMessages: EventDefinitionErrorMessage[];

    public constructor(init?: Partial<EventDefinition>) { (Object as any).assign(this, init); }
}

export enum FormType
{
    Crf = 1,
    Pro = 2,
    Staff = 3,
}

export class FormDefinitionPropertyErrorMessage implements IFormDefinitionPropertyErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.FormDefinitionProperty)")
    public formDefinitionPropertyId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormDefinitionPropertyErrorMessage>) { (Object as any).assign(this, init); }
}

export class FormDefinitionProperty
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public propertyName: string;
    public label: string;
    public detailedLabel: string;
    public errorMessages: FormDefinitionPropertyErrorMessage[];

    public constructor(init?: Partial<FormDefinitionProperty>) { (Object as any).assign(this, init); }
}

export class FormDefinition
{
    public id?: number;
    public code: string;
    public type?: FormType;
    public name: string;
    public instructions: string;
    public patientInstructions: string;
    public properties: FormDefinitionProperty[];
    // @Ignore()
    public subformProperties: { [index: string]: FormDefinitionProperty[]; };

    public constructor(init?: Partial<FormDefinition>) { (Object as any).assign(this, init); }
}

export class PatientState implements IPatientState
{
    public id?: number;
    public name: string;
    public order?: number;

    public constructor(init?: Partial<PatientState>) { (Object as any).assign(this, init); }
}

export class Treatment implements ITreatment
{
    public id?: number;
    public name: string;

    public constructor(init?: Partial<Treatment>) { (Object as any).assign(this, init); }
}

export class MasterGroup implements IMasterGroup
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MasterGroup>) { (Object as any).assign(this, init); }
}

export class CollaboratingGroup implements ICollaboratingGroup
{
    public id?: number;
    public masterGroupId?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CollaboratingGroup>) { (Object as any).assign(this, init); }
}

export class Country implements ICountry
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Country>) { (Object as any).assign(this, init); }
}

export class Institution implements IInstitution
{
    public id?: number;
    public countryId?: number;
    public masterGroupId?: number;
    public collaboratingGroupId?: number;
    public code: string;
    public name: string;
    public enabled?: boolean;
    public activatedDate?: string;
    public recruitmentDisabled?: boolean;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Institution>) { (Object as any).assign(this, init); }
}

export class PatientValidationError implements IPatientValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<PatientValidationError>) { (Object as any).assign(this, init); }
}

export class EventValidationError implements IEventValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<EventValidationError>) { (Object as any).assign(this, init); }
}

export class FormValidationError implements IFormValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormValidationError>) { (Object as any).assign(this, init); }
}

export class FormValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public formId?: number;
    public formDefinitionId?: number;
    public formRepeat?: number;
    public completed?: boolean;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;
    public errors: FormValidationError[];

    public constructor(init?: Partial<FormValidationResult>) { (Object as any).assign(this, init); }
}

export class EventValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public eventId?: number;
    public eventDefinitionId?: number;
    public eventRepeat?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;
    public errors: EventValidationError[];
    public formResults: FormValidationResult[];

    public constructor(init?: Partial<EventValidationResult>) { (Object as any).assign(this, init); }
}

export class PatientValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public patientId?: number;
    public patientDefinitionId?: number;
    public patientRepeat?: number;
    public errors: PatientValidationError[];
    public eventResults: EventValidationResult[];

    public constructor(init?: Partial<PatientValidationResult>) { (Object as any).assign(this, init); }
}

export interface IScreeningLog extends IDataModel
{
    institutionId?: number;
    patientId?: number;
    initials: string;
    dateOfBirth?: string;
    screeningDate?: string;
}

export interface IDataModel extends IModel, IHasAudit
{
}

export interface IModel
{
    id?: number;
}

export interface IHasAudit
{
    enteredDate?: string;
    enteredBy: string;
    modifiedDate?: string;
    modifiedBy: string;
}

export interface IOpenClinicaPatient extends IPatient
{
    exportedToOpenClinica?: string;
}

export interface IPatient extends IDataModel, IHasInstitutionId, IHasPatientStateId, IHasTreatmentId
{
    studyNumber: string;
}

export interface IHasInstitutionId
{
    institutionId?: number;
}

export interface IHasPatientStateId
{
    patientStateId?: number;
}

export interface IHasTreatmentId
{
    treatmentId?: number;
}

export interface IPreregistrationPatient
{
    preregistrationNumber: string;
    preregistrationDate?: string;
}

export interface IRegistrationPatient
{
    registrationNumber: string;
    registrationDate?: string;
}

export interface IEvent extends IDataModel, IHasPatientId, IHasEventDefinitionId
{
    repeat?: number;
}

export interface IHasEventDefinitionId
{
    eventDefinitionId?: number;
}

export interface IForm extends IDataModel, IHasPatientId, IHasEventId, IHasParentFormDefinitionId, IHasParentFormId
{
    repeat?: number;
}

export interface IHasEventId
{
    eventId?: number;
}

export interface IHasParentFormDefinitionId
{
    parentFormDefinitionId?: number;
}

export interface IHasParentFormId
{
    parentFormId?: number;
}

export interface IProForm extends IForm
{
    completed?: boolean;
}

export interface IOpenClinicaForm extends IForm
{
    exportedToOpenClinica?: string;
}

export class TreatmentOfEvent implements ISaeSubform, ITreatmentOfEvent
{
    public ordinal: number;
    public treatmentName: string;
    public treatmentDateCommenced?: string;
    public treatmentDateCeased?: string;
    public treatmentOngoing?: boolean;
    public treatmentDose: string;
    public treatmentUnits: string;

    public constructor(init?: Partial<TreatmentOfEvent>) { (Object as any).assign(this, init); }
}

export class SaeForm implements ISaeForm
{
    public id?: number;
    public patientId?: number;
    public saeNumber?: number;
    public followUpNumber?: number;
    public dateOfSAE?: string;
    public mainEvent: string;
    public mainEventGrade?: number;
    public ctcaeTerm: string;
    public descriptionOfEvent: string;
    public reportingResultsInDeath?: boolean;
    public reportingLifeThreatening?: boolean;
    public requiresHospitalisation?: boolean;
    public reportingDisability?: boolean;
    public reportingDefect?: boolean;
    public reportingSignificant?: boolean;
    public eventStatus: string;
    public eventStatusDate?: string;
    public additionalInfo: string;
    public investigatorName: string;
    public dateCreated?: string;
    public dateUpdated?: string;
    public treatment: TreatmentOfEvent[];
    public unassignedProperties: { [index: string]: Object; };
    public inconvertableProperties: { [index: string]: string; };

    public constructor(init?: Partial<SaeForm>) { (Object as any).assign(this, init); }
}

export interface ISaeForm extends IModel
{
    patientId?: number;
    saeNumber?: number;
    followUpNumber?: number;
    eventStatus: string;
    dateCreated?: string;
    dateUpdated?: string;
    unassignedProperties: { [index: string]: Object; };
    inconvertableProperties: { [index: string]: string; };
}

export interface ISaeFormValidationError extends IValidationError
{
    property: string;
}

export interface ISaeFormValidationResult extends IValidationResult
{
    errors: ISaeFormValidationError[];
}

export interface IValidationError
{
    message: string;
    detailedMessage: string;
    code: string;
    type?: ValidationErrorType;
}

export interface IValidationResult
{
    result: ValidationResultType;
    errors: IValidationError[];
}

export interface IScreeningLogFormValidationError extends IValidationError
{
    property: string;
}

export interface IScreeningLogFormValidationResult extends IValidationResult
{
    errors: IScreeningLogFormValidationError[];
}

export interface ILookupItem
{
    id?: number;
    value: string;
    order?: number;
    type?: number;
}

export interface ILookup
{
    propertyName: string;
    items: ILookupItem[];
}

export interface IEventDefinitionFormDefinitionState extends IConfigurationModel, IHasEventDefinitionFormDefinitionId, IHasPatientStateId
{
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
}

export interface IEventDefinitionFormDefinition extends IConfigurationModel, IHasEventDefinitionId, IHasFormDefinitionId
{
    repeating?: boolean;
    minimumRepeat?: number;
    maximumRepeat?: number;
    order?: number;
    states: IEventDefinitionFormDefinitionState[];
}

export interface IEventDefinitionState extends IConfigurationModel, IHasEventDefinitionId, IHasPatientStateId
{
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
}

export interface IEventDefinitionErrorMessage extends IConfigurationModel, IHasEventDefinitionId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IEventDefinition extends IConfigurationModel
{
    name: string;
    code: string;
    repeating?: boolean;
    minimumRepeat?: number;
    maximumRepeat?: number;
    order?: number;
    formDefinitions: IEventDefinitionFormDefinition[];
    states: IEventDefinitionState[];
    errorMessages: IEventDefinitionErrorMessage[];
}

export interface IConfigurationModel extends IModel
{
}

export interface IFormDefinitionPropertyErrorMessage extends IConfigurationModel, IHasFormDefinitionPropertyId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IFormDefinitionProperty extends IConfigurationModel, IHasFormDefinitionId
{
    propertyName: string;
    label: string;
    detailedLabel: string;
    errorMessages: IFormDefinitionPropertyErrorMessage[];
}

export interface IFormDefinition extends IConfigurationModel
{
    type?: FormType;
    name: string;
    code: string;
    instructions: string;
    patientInstructions: string;
    properties: IFormDefinitionProperty[];
    subformProperties: { [index: string]: IFormDefinitionProperty[]; };
}

export interface IPatientState extends IConfigurationModel
{
    name: string;
    order?: number;
}

export interface ITreatment extends IConfigurationModel
{
    name: string;
}

export interface IMasterGroup extends IDataModel
{
    name: string;
    code: string;
}

export interface ICollaboratingGroup extends IDataModel, IHasMasterGroupId
{
    name: string;
    code: string;
}

export interface IHasMasterGroupId
{
    masterGroupId?: number;
}

export interface ICountry extends IDataModel
{
    name: string;
    code: string;
}

export interface IInstitution extends IDataModel, IHasMasterGroupId, IHasCollaboratingGroupId, IHasCountryId
{
    name: string;
    code: string;
    enabled?: boolean;
    activatedDate?: string;
    recruitmentDisabled?: boolean;
}

export interface IHasCollaboratingGroupId
{
    collaboratingGroupId?: number;
}

export interface IHasCountryId
{
    countryId?: number;
}

export interface IPatientValidationError extends IValidationError
{
}

export interface IEventValidationError extends IValidationError
{
}

export interface IFormValidationError extends IValidationError
{
    property: string;
}

export interface IFormValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    formId?: number;
    formDefinitionId?: number;
    formRepeat?: number;
    completed?: boolean;
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
    errors: IFormValidationError[];
}

export interface IEventValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    eventDefinitionId?: number;
    eventId?: number;
    eventRepeat?: number;
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
    errors: IEventValidationError[];
    formResults: IFormValidationResult[];
}

export interface IPatientValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    patientId?: number;
    errors: IPatientValidationError[];
    eventResults: IEventValidationResult[];
}

export interface IFile extends IDataModel, IHasPatientId, IHasFormDefinitionId, IHasFormId, IFileUpload
{
    propertyName: string;
}

export interface IHasFormDefinitionId
{
    formDefinitionId?: number;
}

export interface IHasFormId
{
    formId?: number;
}

export interface IFileUpload
{
    extension: string;
    fileSize?: number;
    contentType: string;
    exists: boolean;
    image: boolean;
    upload: IFileUploadTemporary;
}

export interface ISaeSubform
{
    ordinal: number;
}

export interface ITreatmentOfEvent
{
}

export interface IHasEventDefinitionFormDefinitionId
{
    eventDefinitionFormDefinitionId?: number;
}

export interface IHasFormDefinitionPropertyId
{
    formDefinitionPropertyId?: number;
}

export class SendNewPatientEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendNewPatientEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendPatientWelcomeEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPatientWelcomeEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendPatientReminderResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SendPatientReminderResponse>) { (Object as any).assign(this, init); }
}

export class SendPatientReminderEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPatientReminderEmailResponse>) { (Object as any).assign(this, init); }
}

export class QueuePatientOcTransferResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<QueuePatientOcTransferResponse>) { (Object as any).assign(this, init); }
}

export class QueuePatientsOcTransferResponse
{
    public responseStatus: ResponseStatus;
    public hasMore: boolean;

    public constructor(init?: Partial<QueuePatientsOcTransferResponse>) { (Object as any).assign(this, init); }
}

export class GetEnumerationTypesResponse
{
    public patientStateType?: PatientStateType;
    public eventDefinitionType?: EventDefinitionType;
    public formDefinitionType?: FormDefinitionType;
    public treatmentType?: TreatmentType;
    public lookupAbilityType?: LookupAbilityType;
    public lookupAgreeType?: LookupAgreeType;
    public lookupBiradsDensityType?: LookupBiradsDensityType;
    public lookupConditionalIneligibleType?: LookupConditionalIneligibleType;
    public lookupFocalityType?: LookupFocalityType;
    public lookupGreaterThanLessThanType?: LookupGreaterThanLessThanType;
    public lookupHistologicalGradeType?: LookupHistologicalGradeType;
    public lookupHistopathologyType?: LookupHistopathologyType;
    public lookupIhcScoreType?: LookupIhcScoreType;
    public lookupIndigenousStatusType?: LookupIndigenousStatusType;
    public lookupIneligibleType?: LookupIneligibleType;
    public lookupIshScoreType?: LookupIshScoreType;
    public lookupLeftRightType?: LookupLeftRightType;
    public lookupLocationQuadrantType?: LookupLocationQuadrantType;
    public lookupPlannedSurgeryMastectomyBilateralReasonType?: LookupPlannedSurgeryMastectomyBilateralReasonType;
    public lookupPrimaryDiagnosisMethodType?: LookupPrimaryDiagnosisMethodType;
    public lookupReceptorType?: LookupReceptorType;
    public lookupSurgeryPreferenceType?: LookupSurgeryPreferenceType;
    public lookupTypeOfMammogramType?: LookupTypeOfMammogramType;
    public lookupUntypedType?: LookupUntypedType;
    public lookupYesNoNaType?: LookupYesNoNaType;
    public lookupYesNoType?: LookupYesNoType;
    public lookupScreeningLogReasonType?: LookupScreeningLogReasonType;

    public constructor(init?: Partial<GetEnumerationTypesResponse>) { (Object as any).assign(this, init); }
}

export class ActiveDirectoryResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ActiveDirectoryResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeNotifyInvestigatorEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeNotifyInvestigatorEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeNotifyMedicalReviewerEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeNotifyMedicalReviewerEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSaeInvestigatorReviewEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSaeInvestigatorReviewEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendNewSaeEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendNewSaeEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendUnapprovedSaeEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendUnapprovedSaeEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendUnresolvedSaeEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendUnresolvedSaeEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendScreeningLogReminderEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendScreeningLogReminderEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendFormsDueSummaryEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendFormsDueSummaryEmailResponse>) { (Object as any).assign(this, init); }
}

export class FormsDueSummaryReportResponse
{
    public responseStatus: ResponseStatus;
    public formsDueSummary: FormsDueSummary[];

    public constructor(init?: Partial<FormsDueSummaryReportResponse>) { (Object as any).assign(this, init); }
}

export class IdentityServerDiagnosticsResponse
{
    public responseStatus: ResponseStatus;
    public diagnostics: IdentityServerDiagnostic[];

    public constructor(init?: Partial<IdentityServerDiagnosticsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RegenerateApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SaeCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved saes.
    */
    // @DataMember(Order=2)
    public saes: Sae<SaeForm,Patient>[];

    public constructor(init?: Partial<SaeCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SaeSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved sae.
    */
    // @DataMember(Order=2)
    public sae: Sae<SaeForm, Patient>;

    public constructor(init?: Partial<SaeSingleResponse>) { (Object as any).assign(this, init); }
}

export class SaeGetCollectionByStatusResponse
{
    public responseStatus: ResponseStatus;
    public saes: Sae<ISaeForm,IPatient>[];

    public constructor(init?: Partial<SaeGetCollectionByStatusResponse>) { (Object as any).assign(this, init); }
}

export class SaeWebActionSaveResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SaeWebActionSaveResponse>) { (Object as any).assign(this, init); }
}

export class SaeMedicalReviewWebActionSaveResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SaeMedicalReviewWebActionSaveResponse>) { (Object as any).assign(this, init); }
}

export class SaeSendNotificationResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SaeSendNotificationResponse>) { (Object as any).assign(this, init); }
}

export class SaeValidateNotificationFormResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: SaeFormValidationResult;

    public constructor(init?: Partial<SaeValidateNotificationFormResponse>) { (Object as any).assign(this, init); }
}

export class SaeValidateRejectionFormResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: SaeFormValidationResult;

    public constructor(init?: Partial<SaeValidateRejectionFormResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningLogSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved screeninglog.
    */
    // @DataMember(Order=2)
    public screeningLog: ScreeningLog;

    public constructor(init?: Partial<ScreeningLogSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningLogCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved screeninglogs.
    */
    // @DataMember(Order=2)
    public screeningLogs: ScreeningLog[];

    public constructor(init?: Partial<ScreeningLogCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningLogDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ScreeningLogDeleteResponse>) { (Object as any).assign(this, init); }
}

export class ScreeningLogFormValidationResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: ScreeningLogFormValidationResult;

    public constructor(init?: Partial<ScreeningLogFormValidationResponse>) { (Object as any).assign(this, init); }
}

export class UploadResponse
{
    public responseStatus: ResponseStatus;
    public upload: IFileUploadTemporary;

    public constructor(init?: Partial<UploadResponse>) { (Object as any).assign(this, init); }
}

export class GetUserResponse
{
    public responseStatus: ResponseStatus;
    public userId: string;
    public sessionId: string;
    public userName: string;
    public displayName: string;
    public type?: UserType;

    public constructor(init?: Partial<GetUserResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved lookups.
    */
    // @DataMember(Order=2)
    public lookups: Lookup[];

    public constructor(init?: Partial<LookupCollectionResponse>) { (Object as any).assign(this, init); }
}

export class GetFormMetadataResponse
{
    public responseStatus: ResponseStatus;
    public formMetadata: FormMetadata[];

    public constructor(init?: Partial<GetFormMetadataResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventDefinitionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved event definition.
    */
    // @DataMember(Order=2)
    public eventDefinition: EventDefinition;

    public constructor(init?: Partial<EventDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventDefinitionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved event definitions.
    */
    // @DataMember(Order=2)
    public eventDefinitions: EventDefinition[];

    public constructor(init?: Partial<EventDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definition.
    */
    // @DataMember(Order=2)
    public formDefinition: FormDefinition;

    public constructor(init?: Partial<FormDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved form definitions.
    */
    // @DataMember(Order=2)
    public formDefinitions: FormDefinition[];

    public constructor(init?: Partial<FormDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStateSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient state.
    */
    // @DataMember(Order=2)
    public patientState: PatientState;

    public constructor(init?: Partial<PatientStateSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStateCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient states.
    */
    // @DataMember(Order=2)
    public patientStates: PatientState[];

    public constructor(init?: Partial<PatientStateCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved treatment.
    */
    // @DataMember(Order=2)
    public treatment: Treatment;

    public constructor(init?: Partial<TreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved treatments.
    */
    // @DataMember(Order=2)
    public treatments: Treatment[];

    public constructor(init?: Partial<TreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved master group.
    */
    // @DataMember(Order=2)
    public masterGroup: MasterGroup;

    public constructor(init?: Partial<MasterGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved master groups.
    */
    // @DataMember(Order=2)
    public masterGroups: MasterGroup[];

    public constructor(init?: Partial<MasterGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CollaboratingGroupSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved collaborating group.
    */
    // @DataMember(Order=2)
    public collaboratingGroup: CollaboratingGroup;

    public constructor(init?: Partial<CollaboratingGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CollaboratingGroupCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved collaborating groups.
    */
    // @DataMember(Order=2)
    public collaboratingGroups: CollaboratingGroup[];

    public constructor(init?: Partial<CollaboratingGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountrySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved country.
    */
    // @DataMember(Order=2)
    public country: Country;

    public constructor(init?: Partial<CountrySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved countrys.
    */
    // @DataMember(Order=2)
    public countries: Country[];

    public constructor(init?: Partial<CountryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved institution.
    */
    // @DataMember(Order=2)
    public institution: Institution;

    public constructor(init?: Partial<InstitutionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved institutions.
    */
    // @DataMember(Order=2)
    public institutions: Institution[];

    public constructor(init?: Partial<InstitutionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient.
    */
    // @DataMember(Order=2)
    public patient: Patient;

    public constructor(init?: Partial<PatientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: PatientValidationResult;

    public constructor(init?: Partial<PatientValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patients.
    */
    // @DataMember(Order=2)
    public patients: Patient[];

    public constructor(init?: Partial<PatientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved event.
    */
    // @DataMember(Order=2)
    public event: Event;

    public constructor(init?: Partial<EventSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved events.
    */
    // @DataMember(Order=2)
    public events: Event[];

    public constructor(init?: Partial<EventCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved source document.
    */
    // @DataMember(Order=2)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved source documents.
    */
    // @DataMember(Order=2)
    public forms: SourceDocument[];

    public constructor(init?: Partial<SourceDocumentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SourceDocumentDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<SourceDocumentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientTransferSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient transfer.
    */
    // @DataMember(Order=2)
    public form: PatientTransfer;

    public constructor(init?: Partial<PatientTransferSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientTransferCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient transfers.
    */
    // @DataMember(Order=2)
    public forms: PatientTransfer[];

    public constructor(init?: Partial<PatientTransferCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientTransferDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PatientTransferDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientTransferValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<PatientTransferValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientTransferEntrySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient transfer entry.
    */
    // @DataMember(Order=2)
    public form: PatientTransferEntry;

    public constructor(init?: Partial<PatientTransferEntrySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientTransferEntryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved patient transfer entrys.
    */
    // @DataMember(Order=2)
    public forms: PatientTransferEntry[];

    public constructor(init?: Partial<PatientTransferEntryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientTransferEntryDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PatientTransferEntryDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientTransferEntryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<PatientTransferEntryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DecisionConflictScaleSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved decision conflict scale.
    */
    // @DataMember(Order=2)
    public form: DecisionConflictScale;

    public constructor(init?: Partial<DecisionConflictScaleSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DecisionConflictScaleCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved decision conflict scales.
    */
    // @DataMember(Order=2)
    public forms: DecisionConflictScale[];

    public constructor(init?: Partial<DecisionConflictScaleCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DecisionConflictScaleDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DecisionConflictScaleDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DecisionConflictScaleValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<DecisionConflictScaleValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DecisionRegretScaleSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved decision regret scale.
    */
    // @DataMember(Order=2)
    public form: DecisionRegretScale;

    public constructor(init?: Partial<DecisionRegretScaleSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DecisionRegretScaleCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved decision regret scales.
    */
    // @DataMember(Order=2)
    public forms: DecisionRegretScale[];

    public constructor(init?: Partial<DecisionRegretScaleCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DecisionRegretScaleDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DecisionRegretScaleDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DecisionRegretScaleValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<DecisionRegretScaleValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class Eq5d5lSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved eq5d5l.
    */
    // @DataMember(Order=2)
    public form: Eq5d5l;

    public constructor(init?: Partial<Eq5d5lSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class Eq5d5lCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved eq5d5ls.
    */
    // @DataMember(Order=2)
    public forms: Eq5d5l[];

    public constructor(init?: Partial<Eq5d5lCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class Eq5d5lDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<Eq5d5lDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class Eq5d5lValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<Eq5d5lValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ImpactOfMriSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved impact of mri.
    */
    // @DataMember(Order=2)
    public form: ImpactOfMri;

    public constructor(init?: Partial<ImpactOfMriSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ImpactOfMriCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved impact of mris.
    */
    // @DataMember(Order=2)
    public forms: ImpactOfMri[];

    public constructor(init?: Partial<ImpactOfMriCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ImpactOfMriDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ImpactOfMriDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ImpactOfMriValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ImpactOfMriValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PreferenceForSurgerySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved preference for surgery.
    */
    // @DataMember(Order=2)
    public form: PreferenceForSurgery;

    public constructor(init?: Partial<PreferenceForSurgerySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PreferenceForSurgeryCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved preference for surgerys.
    */
    // @DataMember(Order=2)
    public forms: PreferenceForSurgery[];

    public constructor(init?: Partial<PreferenceForSurgeryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PreferenceForSurgeryDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PreferenceForSurgeryDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PreferenceForSurgeryValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<PreferenceForSurgeryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastImagingLesionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved breast imaging lesion.
    */
    // @DataMember(Order=2)
    public form: BreastImagingLesion;

    public constructor(init?: Partial<BreastImagingLesionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastImagingLesionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved breast imaging lesions.
    */
    // @DataMember(Order=2)
    public forms: BreastImagingLesion[];

    public constructor(init?: Partial<BreastImagingLesionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastImagingLesionDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<BreastImagingLesionDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastImagingLesionValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<BreastImagingLesionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastMammogramLesionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved breast mammogram lesion.
    */
    // @DataMember(Order=2)
    public form: BreastMammogramLesion;

    public constructor(init?: Partial<BreastMammogramLesionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastMammogramLesionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved breast mammogram lesions.
    */
    // @DataMember(Order=2)
    public forms: BreastMammogramLesion[];

    public constructor(init?: Partial<BreastMammogramLesionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastMammogramLesionDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<BreastMammogramLesionDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class BreastMammogramLesionValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<BreastMammogramLesionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiagnosticAndImagingSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved diagnostic and imaging.
    */
    // @DataMember(Order=2)
    public form: DiagnosticAndImaging;

    public constructor(init?: Partial<DiagnosticAndImagingSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiagnosticAndImagingCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved diagnostic and imagings.
    */
    // @DataMember(Order=2)
    public forms: DiagnosticAndImaging[];

    public constructor(init?: Partial<DiagnosticAndImagingCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiagnosticAndImagingDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DiagnosticAndImagingDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiagnosticAndImagingValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<DiagnosticAndImagingValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiagnosticAndPathologySingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved diagnostic and pathology.
    */
    // @DataMember(Order=2)
    public form: DiagnosticAndPathology;

    public constructor(init?: Partial<DiagnosticAndPathologySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiagnosticAndPathologyCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved diagnostic and pathologys.
    */
    // @DataMember(Order=2)
    public forms: DiagnosticAndPathology[];

    public constructor(init?: Partial<DiagnosticAndPathologyCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiagnosticAndPathologyDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DiagnosticAndPathologyDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DiagnosticAndPathologyValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<DiagnosticAndPathologyValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityChecklistSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved eligibility checklist.
    */
    // @DataMember(Order=2)
    public form: EligibilityChecklist;

    public constructor(init?: Partial<EligibilityChecklistSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityChecklistCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved eligibility checklists.
    */
    // @DataMember(Order=2)
    public forms: EligibilityChecklist[];

    public constructor(init?: Partial<EligibilityChecklistCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityChecklistDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<EligibilityChecklistDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityChecklistValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<EligibilityChecklistValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved general.
    */
    // @DataMember(Order=2)
    public form: General;

    public constructor(init?: Partial<GeneralSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved generals.
    */
    // @DataMember(Order=2)
    public forms: General[];

    public constructor(init?: Partial<GeneralCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GeneralDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GeneralValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<GeneralValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OtherImagingLesionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved other imaging lesion.
    */
    // @DataMember(Order=2)
    public form: OtherImagingLesion;

    public constructor(init?: Partial<OtherImagingLesionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OtherImagingLesionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved other imaging lesions.
    */
    // @DataMember(Order=2)
    public forms: OtherImagingLesion[];

    public constructor(init?: Partial<OtherImagingLesionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OtherImagingLesionDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<OtherImagingLesionDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OtherImagingLesionValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<OtherImagingLesionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PetScanImagingLesionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pet scan imaging lesion.
    */
    // @DataMember(Order=2)
    public form: PetScanImagingLesion;

    public constructor(init?: Partial<PetScanImagingLesionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PetScanImagingLesionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pet scan imaging lesions.
    */
    // @DataMember(Order=2)
    public forms: PetScanImagingLesion[];

    public constructor(init?: Partial<PetScanImagingLesionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PetScanImagingLesionDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PetScanImagingLesionDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PetScanImagingLesionValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<PetScanImagingLesionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PreMriPlanningSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pre mri planning.
    */
    // @DataMember(Order=2)
    public form: PreMriPlanning;

    public constructor(init?: Partial<PreMriPlanningSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PreMriPlanningCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved pre mri plannings.
    */
    // @DataMember(Order=2)
    public forms: PreMriPlanning[];

    public constructor(init?: Partial<PreMriPlanningCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PreMriPlanningDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PreMriPlanningDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PreMriPlanningValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<PreMriPlanningValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PreregistrationSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved preregistration.
    */
    // @DataMember(Order=2)
    public form: Preregistration;

    public constructor(init?: Partial<PreregistrationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PreregistrationCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved preregistrations.
    */
    // @DataMember(Order=2)
    public forms: Preregistration[];

    public constructor(init?: Partial<PreregistrationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PreregistrationDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PreregistrationDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PreregistrationValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<PreregistrationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved procomida.
    */
    // @DataMember(Order=2)
    public form: Procomida;

    public constructor(init?: Partial<ProcomidaSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved procomidas.
    */
    // @DataMember(Order=2)
    public forms: Procomida[];

    public constructor(init?: Partial<ProcomidaCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ProcomidaDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ProcomidaValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaAssistanceSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved procomida assistance.
    */
    // @DataMember(Order=2)
    public form: ProcomidaAssistance;

    public constructor(init?: Partial<ProcomidaAssistanceSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaAssistanceCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved procomida assistances.
    */
    // @DataMember(Order=2)
    public forms: ProcomidaAssistance[];

    public constructor(init?: Partial<ProcomidaAssistanceCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaAssistanceDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ProcomidaAssistanceDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaAssistanceValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ProcomidaAssistanceValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaIncompleteSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved procomida incomplete.
    */
    // @DataMember(Order=2)
    public form: ProcomidaIncomplete;

    public constructor(init?: Partial<ProcomidaIncompleteSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaIncompleteCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved procomida incompletes.
    */
    // @DataMember(Order=2)
    public forms: ProcomidaIncomplete[];

    public constructor(init?: Partial<ProcomidaIncompleteCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaIncompleteDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ProcomidaIncompleteDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaIncompleteValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ProcomidaIncompleteValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UltrasoundImagingLesionSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved ultrasound imaging lesion.
    */
    // @DataMember(Order=2)
    public form: UltrasoundImagingLesion;

    public constructor(init?: Partial<UltrasoundImagingLesionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UltrasoundImagingLesionCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved ultrasound imaging lesions.
    */
    // @DataMember(Order=2)
    public forms: UltrasoundImagingLesion[];

    public constructor(init?: Partial<UltrasoundImagingLesionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UltrasoundImagingLesionDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UltrasoundImagingLesionDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UltrasoundImagingLesionValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<UltrasoundImagingLesionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityAssessmentSingleResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved eligibility assessment.
    */
    // @DataMember(Order=2)
    public form: EligibilityAssessment;

    public constructor(init?: Partial<EligibilityAssessmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityAssessmentCollectionResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The retrieved eligibility assessments.
    */
    // @DataMember(Order=2)
    public forms: EligibilityAssessment[];

    public constructor(init?: Partial<EligibilityAssessmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityAssessmentDeleteResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<EligibilityAssessmentDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EligibilityAssessmentValidationResponse
{
    /**
    * The information about any issues that may have occurred while attempting to process the request.
    */
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    /**
    * The validation result.
    */
    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<EligibilityAssessmentValidationResponse>) { (Object as any).assign(this, init); }
}

export class HasOpmsPermissionCollectionResponse
{
    public responseStatus: ResponseStatus;
    public hasPermission: { [index: number]: boolean; };

    public constructor(init?: Partial<HasOpmsPermissionCollectionResponse>) { (Object as any).assign(this, init); }
}

export class InstitutionPersonnelNamesResponse
{
    public responseStatus: ResponseStatus;
    public personnelNames: { [index: number]: string; };

    public constructor(init?: Partial<InstitutionPersonnelNamesResponse>) { (Object as any).assign(this, init); }
}

export class TrialPersonnelActiveRoleResponse
{
    public responseStatus: ResponseStatus;
    public hasRole: { [index: number]: boolean; };

    public constructor(init?: Partial<TrialPersonnelActiveRoleResponse>) { (Object as any).assign(this, init); }
}

export class SendNewPatientEmail implements IReturn<SendNewPatientEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendNewPatientEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendNewPatientEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendNewPatientEmailResponse(); }
}

export class SendPatientWelcomeEmail implements IReturn<SendPatientWelcomeEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendPatientWelcomeEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPatientWelcomeEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPatientWelcomeEmailResponse(); }
}

export class SendPatientReminder implements IReturn<SendPatientReminderResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendPatientReminder>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPatientReminder'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPatientReminderResponse(); }
}

export class SendPatientReminderEmail implements IReturn<SendPatientReminderEmailResponse>
{
    public patientId?: number;
    public resetPassword?: boolean;

    public constructor(init?: Partial<SendPatientReminderEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPatientReminderEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPatientReminderEmailResponse(); }
}

// @Route("/{PatientId}/forms-due-schedule", "GET")
export class GetFormsDueSchedule implements IHasPatientId
{
    public patientId?: number;

    public constructor(init?: Partial<GetFormsDueSchedule>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFormsDueSchedule'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

// @Route("/print/screeninglogs/{InstitutionCode}/{DownloadFormat}/", "GET")
export class GetScreeningLogs
{
    public institutionCode: string;
    public downloadFormat: string;

    public constructor(init?: Partial<GetScreeningLogs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetScreeningLogs'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

export class QueuePatientOcTransfer implements IReturn<QueuePatientOcTransferResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<QueuePatientOcTransfer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueuePatientOcTransfer'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueuePatientOcTransferResponse(); }
}

export class QueuePatientsOcTransfer implements IReturn<QueuePatientsOcTransferResponse>
{
    public skip?: number;
    public take?: number;

    public constructor(init?: Partial<QueuePatientsOcTransfer>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueuePatientsOcTransfer'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueuePatientsOcTransferResponse(); }
}

export class GetEnumerationTypes implements IReturn<GetEnumerationTypesResponse>
{

    public constructor(init?: Partial<GetEnumerationTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEnumerationTypes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEnumerationTypesResponse(); }
}

export class PatientAccountCreate implements IReturn<ActiveDirectoryResponse>
{
    public patientId?: number;
    public email: string;
    public password: string;

    public constructor(init?: Partial<PatientAccountCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientAccountCreate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ActiveDirectoryResponse(); }
}

export class PatientAccountResendWelcome implements IReturn<ActiveDirectoryResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<PatientAccountResendWelcome>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientAccountResendWelcome'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ActiveDirectoryResponse(); }
}

export class PatientAccountSetPassword implements IReturn<ActiveDirectoryResponse>
{
    public patientId?: number;
    public password: string;

    public constructor(init?: Partial<PatientAccountSetPassword>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientAccountSetPassword'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ActiveDirectoryResponse(); }
}

export class PatientAccountUpdateEmail implements IReturn<ActiveDirectoryResponse>
{
    public patientId?: number;
    public email: string;

    public constructor(init?: Partial<PatientAccountUpdateEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientAccountUpdateEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ActiveDirectoryResponse(); }
}

export class SendSaeNotifyInvestigatorEmail implements IReturn<SendSaeNotifyInvestigatorEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;
    public initialEmail: boolean;
    public recipientIds: number[];
    public ccRecipientIds: number[];
    public attachmentNames: string[];
    public attachmentGuids: string[];
    public includedNotes: string;

    public constructor(init?: Partial<SendSaeNotifyInvestigatorEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeNotifyInvestigatorEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeNotifyInvestigatorEmailResponse(); }
}

export class SendSaeNotifyMedicalReviewerEmail implements IReturn<SendSaeNotifyMedicalReviewerEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;
    public initialEmail: boolean;
    public recipientIds: number[];
    public ccRecipientIds: number[];
    public attachmentNames: string[];
    public attachmentGuids: string[];
    public includedNotes: string;

    public constructor(init?: Partial<SendSaeNotifyMedicalReviewerEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeNotifyMedicalReviewerEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeNotifyMedicalReviewerEmailResponse(); }
}

export class SendSaeInvestigatorReviewEmail implements IReturn<SendSaeInvestigatorReviewEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;
    public approved: boolean;
    public reasonForRejection: string;

    public constructor(init?: Partial<SendSaeInvestigatorReviewEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSaeInvestigatorReviewEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSaeInvestigatorReviewEmailResponse(); }
}

export class SendNewSaeEmail implements IReturn<SendNewSaeEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;
    public initialEmail: boolean;

    public constructor(init?: Partial<SendNewSaeEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendNewSaeEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendNewSaeEmailResponse(); }
}

export class SendUnapprovedSaeEmail implements IReturn<SendUnapprovedSaeEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;

    public constructor(init?: Partial<SendUnapprovedSaeEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendUnapprovedSaeEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendUnapprovedSaeEmailResponse(); }
}

export class SendUnresolvedSaeEmail implements IReturn<SendUnresolvedSaeEmailResponse>
{
    public patientStudyNumber: string;
    public saeNumber: number;

    public constructor(init?: Partial<SendUnresolvedSaeEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendUnresolvedSaeEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendUnresolvedSaeEmailResponse(); }
}

export class SendScreeningLogReminderEmail implements IReturn<SendScreeningLogReminderEmailResponse>
{
    public code: string;

    public constructor(init?: Partial<SendScreeningLogReminderEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendScreeningLogReminderEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendScreeningLogReminderEmailResponse(); }
}

export class SendFormsDueSummaryEmail implements IReturn<SendFormsDueSummaryEmailResponse>
{
    public instCode: string;

    public constructor(init?: Partial<SendFormsDueSummaryEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendFormsDueSummaryEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendFormsDueSummaryEmailResponse(); }
}

// @Route("/api/reports/forms-due-summary", "GET,POST")
export class GetFormsDueSummaryReport implements IReturn<FormsDueSummaryReportResponse>, IReportRequest
{
    public token: string;
    public institutionCode: string;
    public studyNumber: string;
    public cutOffDate?: string;

    public constructor(init?: Partial<GetFormsDueSummaryReport>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFormsDueSummaryReport'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FormsDueSummaryReportResponse(); }
}

// @Route("/identity-server/authorized")
export class IdentityServerAuthorized implements IReturn<IdentityServerDiagnosticsResponse>
{

    public constructor(init?: Partial<IdentityServerAuthorized>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'IdentityServerAuthorized'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new IdentityServerDiagnosticsResponse(); }
}

// @Route("/identity-server/test")
export class IdentityServerDiagnostics implements IReturn<IdentityServerDiagnosticsResponse>
{
    public code: string;
    public state: string;
    public error: string;

    public constructor(init?: Partial<IdentityServerDiagnostics>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'IdentityServerDiagnostics'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new IdentityServerDiagnosticsResponse(); }
}

/**
* Sign In
*/
// @Route("/auth")
// @Route("/auth/{provider}")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /**
    * AuthProvider, e.g. credentials
    */
    // @DataMember(Order=1)
    // @ApiMember(Description="AuthProvider, e.g. credentials")
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/assignroles")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AssignRolesResponse(); }
}

// @Route("/unassignroles")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnAssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnAssignRolesResponse(); }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApiKeys'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetApiKeysResponse(); }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegenerateApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegenerateApiKeys'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegenerateApiKeysResponse(); }
}

/**
* Sae
*/
// @Route("/api/sae/collection", "GET")
// @Api(Description="Sae")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=500)
// @DataContract
export class SaeGetCollection implements IReturn<SaeCollectionResponse>, ISaeGetCollection
{

    public constructor(init?: Partial<SaeGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeCollectionResponse(); }
}

/**
* Sae
*/
// @Route("/api/sae/collection/institution/{InstitutionCode}", "GET")
// @Api(Description="Sae")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Sae.Sae.SaeCollectionResponse)", StatusCode=500)
// @DataContract
export class SaeGetCollectionByInstitutionCode implements IReturn<SaeCollectionResponse>, ISaeGetCollectionByInstitutionCode
{
    /**
    * The code of the Institution associated with the sae to retrieve.
    */
    // @DataMember(Order=1)
    public institutionCode: string;

    public constructor(init?: Partial<SaeGetCollectionByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetCollectionByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeCollectionResponse(); }
}

/**
* Sae
*/
// @Route("/api/sae/single/patient-study-number/{PatientStudyNumber}/sae-number/{SaeNumber}", "GET")
// @Api(Description="Sae")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Sae.Sae.SaeSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Sae.Sae.SaeSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Sae.Sae.SaeSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Sae.Sae.SaeSingleResponse)", StatusCode=500)
// @DataContract
export class SaeGetSingleByPatientStudyNumber implements IReturn<SaeSingleResponse>, ISaeGetSingleByPatientStudyNumber
{
    /**
    * The study number of the Patient associated with the sae to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The sae number of the Sae associated with the sae to retrieve.
    */
    // @DataMember(Order=2)
    public saeNumber: number;

    public constructor(init?: Partial<SaeGetSingleByPatientStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetSingleByPatientStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeSingleResponse(); }
}

// @Route("/api/sae/collection/status", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeGetCollectionByStatus implements IReturn<SaeGetCollectionByStatusResponse>
{
    /**
    * The status of the saes
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="integer", Description="The status of the saes", IsRequired=true, Name="Status", ParameterType="body")
    public status: SaeStatus;

    public constructor(init?: Partial<SaeGetCollectionByStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeGetCollectionByStatus'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SaeGetCollectionByStatusResponse(); }
}

// @Route("/api/sae/webaction", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeWebActionSave implements IReturn<SaeWebActionSaveResponse>
{
    /**
    * The sae web action
    */
    // @DataMember(Order=1)
    // @ApiMember(Description="The sae web action", IsRequired=true, Name="WebAction", ParameterType="model")
    public webAction: WebAction;

    public constructor(init?: Partial<SaeWebActionSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeWebActionSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeWebActionSaveResponse(); }
}

// @Route("/api/sae/medicalreview", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeMedicalReviewWebActionSave implements IReturn<SaeMedicalReviewWebActionSaveResponse>
{
    /**
    * The patient study number of the sae
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="string", Description="The patient study number of the sae", IsRequired=true, Name="PatientStudyNumber", ParameterType="body")
    public patientStudyNumber: string;

    /**
    * The sae number of the sae
    */
    // @DataMember(Order=2)
    // @ApiMember(DataType="integer", Description="The sae number of the sae", IsRequired=true, Name="SaeNumber", ParameterType="body")
    public saeNumber: number;

    /**
    * The medical review form from which the sae web action will be based off
    */
    // @DataMember(Order=3)
    // @ApiMember(Description="The medical review form from which the sae web action will be based off", IsRequired=true, Name="SaeMedicalReviewForm", ParameterType="model")
    public medicalReview: SaeMedicalReviewForm;

    public constructor(init?: Partial<SaeMedicalReviewWebActionSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeMedicalReviewWebActionSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeMedicalReviewWebActionSaveResponse(); }
}

// @Route("/api/sae/send-notification", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeSendNotification implements IReturn<SaeSendNotificationResponse>
{
    /**
    * The patient study number of the sae
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="string", Description="The patient study number of the sae", IsRequired=true, Name="PatientStudyNumber", ParameterType="body")
    public patientStudyNumber: string;

    /**
    * The sae number of the sae
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="integer", Description="The sae number of the sae", IsRequired=true, Name="SaeNumber", ParameterType="body")
    public saeNumber: number;

    /**
    * The notification form
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="SaeNotificationForm", Description="The notification form", IsRequired=true, Name="NotificationForm", ParameterType="body")
    public notificationForm: SaeNotificationForm;

    public constructor(init?: Partial<SaeSendNotification>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeSendNotification'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeSendNotificationResponse(); }
}

// @Route("/api/sae/validate-notification", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeValidateNotificationForm implements IReturn<SaeValidateNotificationFormResponse>
{
    /**
    * The notification form
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="SaeNotificationForm", Description="The notification form", IsRequired=true, Name="NotificationForm", ParameterType="body")
    public form: SaeNotificationForm;

    public constructor(init?: Partial<SaeValidateNotificationForm>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeValidateNotificationForm'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeValidateNotificationFormResponse(); }
}

// @Route("/api/sae/validate-rejection", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
// @DataContract
export class SaeValidateRejectionForm implements IReturn<SaeValidateRejectionFormResponse>
{
    /**
    * The rejection form
    */
    // @DataMember(Order=1)
    // @ApiMember(DataType="WebAction", Description="The rejection form", IsRequired=true, Name="RejectionForm", ParameterType="body")
    public form: WebAction;

    public constructor(init?: Partial<SaeValidateRejectionForm>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SaeValidateRejectionForm'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SaeValidateRejectionFormResponse(); }
}

/**
* ScreeningLog
*/
// @Route("/opms/screeninglog/single/id/{Id}", "GET")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogGetSingleById implements IReturn<ScreeningLogSingleResponse>, IScreeningLogGetSingleById
{
    /**
    * The ID of the screeninglog to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ScreeningLogGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningLogSingleResponse(); }
}

/**
* ScreeningLog
*/
// @Route("/opms/screeninglog/collection", "GET")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogGetCollection implements IReturn<ScreeningLogCollectionResponse>, IScreeningLogGetCollection
{

    public constructor(init?: Partial<ScreeningLogGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningLogCollectionResponse(); }
}

/**
* ScreeningLog
*/
// @Route("/opms/screeninglog/collection/institution/id/{InstitutionId}", "GET")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogGetCollectionByInstitutionId implements IReturn<ScreeningLogCollectionResponse>, IScreeningLogGetCollectionByInstitutionId
{
    /**
    * The ID of the Institution associated with the screeninglog to retrieve.
    */
    // @DataMember(Order=1)
    public institutionId?: number;

    public constructor(init?: Partial<ScreeningLogGetCollectionByInstitutionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogGetCollectionByInstitutionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningLogCollectionResponse(); }
}

/**
* ScreeningLog
*/
// @Route("/opms/screeninglog/save", "POST")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogPostSave implements IReturn<ScreeningLogSingleResponse>
{
    /**
    * The screeninglog to save.
    */
    // @DataMember(Order=1)
    public screeningLog: ScreeningLog;

    public constructor(init?: Partial<ScreeningLogPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ScreeningLogSingleResponse(); }
}

/**
* ScreeningLog
*/
// @Route("/opms/screeninglog/delete/{ScreeningLogId}", "DELETE")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogDeleteResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogDelete implements IReturn<ScreeningLogDeleteResponse>, IScreeningLogDelete
{
    /**
    * The screeninglog ID to delete.
    */
    // @DataMember(Order=1)
    public screeningLogId: number;

    public constructor(init?: Partial<ScreeningLogDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ScreeningLogDeleteResponse(); }
}

/**
* ScreeningLog
*/
// @Route("/opms/screeninglog/validate", "POST")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningLogFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningLogFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningLogFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningLogFormValidationResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogPostValidate implements IReturn<ScreeningLogFormValidationResponse>
{
    /**
    * The screeninglog to validate.
    */
    // @DataMember(Order=1)
    public screeningLog: ScreeningLog;

    public constructor(init?: Partial<ScreeningLogPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ScreeningLogFormValidationResponse(); }
}

// @Route("/opms/file-upload", "POST")
export class PostUploadFile implements IReturn<UploadResponse>, IPost
{

    public constructor(init?: Partial<PostUploadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostUploadFile'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/opms/file-upload/info/{Guid}", "GET")
export class GetUploadInfo implements IReturn<UploadResponse>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUploadInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUploadInfo'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/opms/file-upload/{Guid}", "GET")
export class GetUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file-upload/download/{Guid}", "GET")
export class DownloadUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<DownloadUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file-upload/{Guid}", "DELETE")
export class DeleteUpload implements IReturn<UploadResponse>, IDelete
{
    public guid: string;

    public constructor(init?: Partial<DeleteUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteUpload'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/opms/user")
export class GetUser implements IReturn<GetUserResponse>, IGet
{

    public constructor(init?: Partial<GetUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUser'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetUserResponse(); }
}

/**
* Lookup
*/
// @Route("/opms/lookup/collection/form-definition/{FormDefinitionId}", "GET")
// @Api(Description="Lookup")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=500)
// @DataContract
export class LookupGetCollectionByFormDefinitionId implements IReturn<LookupCollectionResponse>, ILookupGetCollectionByFormDefinitionId
{
    /**
    * The ID of the Form Definition associated with the lookup to retrieve.
    */
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    public constructor(init?: Partial<LookupGetCollectionByFormDefinitionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LookupGetCollectionByFormDefinitionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LookupCollectionResponse(); }
}

// @Route("/opms/form-names")
export class GetFormMetadata implements IReturn<GetFormMetadataResponse>
{

    public constructor(init?: Partial<GetFormMetadata>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFormMetadata'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetFormMetadataResponse(); }
}

/**
* Event Definition
*/
// @Route("/opms/event-definition/single/id/{Id}", "GET")
// @Api(Description="Event Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class EventDefinitionGetSingleById implements IReturn<EventDefinitionSingleResponse>, IEventDefinitionGetSingleById
{
    /**
    * The ID of the event definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EventDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventDefinitionSingleResponse(); }
}

/**
* Event Definition
*/
// @Route("/opms/event-definition/single/code/{Code}", "GET")
// @Api(Description="Event Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class EventDefinitionGetSingleByCode implements IReturn<EventDefinitionSingleResponse>, IEventDefinitionGetSingleByCode
{
    /**
    * The ID of the event definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<EventDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventDefinitionSingleResponse(); }
}

/**
* Event Definition
*/
// @Route("/opms/event-definition/collection", "GET")
// @Api(Description="Event Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class EventDefinitionGetCollection implements IReturn<EventDefinitionCollectionResponse>, IEventDefinitionGetCollection
{

    public constructor(init?: Partial<EventDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventDefinitionCollectionResponse(); }
}

/**
* Form Definition
*/
// @Route("/opms/form-definition/single/id/{Id}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleById implements IReturn<FormDefinitionSingleResponse>, IFormDefinitionGetSingleById
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

/**
* Form Definition
*/
// @Route("/opms/form-definition/single/code/{Code}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleByCode implements IReturn<FormDefinitionSingleResponse>, IFormDefinitionGetSingleByCode
{
    /**
    * The ID of the form definition to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

/**
* Form Definition
*/
// @Route("/opms/form-definition/collection", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetCollection implements IReturn<FormDefinitionCollectionResponse>, IFormDefinitionGetCollection
{

    public constructor(init?: Partial<FormDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionCollectionResponse(); }
}

/**
* Patient State
*/
// @Route("/opms/patient-state/single/id/{Id}", "GET")
// @Api(Description="Patient State")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=500)
// @DataContract
export class PatientStateGetSingleById implements IReturn<PatientStateSingleResponse>, IPatientStateGetSingleById
{
    /**
    * The ID of the patient state to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientStateGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStateGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStateSingleResponse(); }
}

/**
* Patient State
*/
// @Route("/opms/patient-state/collection", "GET")
// @Api(Description="Patient State")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientStateGetCollection implements IReturn<PatientStateCollectionResponse>, IPatientStateGetCollection
{

    public constructor(init?: Partial<PatientStateGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStateGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStateCollectionResponse(); }
}

/**
* Treatment
*/
// @Route("/opms/treatment/single/id/{Id}", "GET")
// @Api(Description="Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetSingleById implements IReturn<TreatmentSingleResponse>, ITreatmentGetSingleById
{
    /**
    * The ID of the treatment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentSingleResponse(); }
}

/**
* Treatment
*/
// @Route("/opms/treatment/collection", "GET")
// @Api(Description="Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetCollection implements IReturn<TreatmentCollectionResponse>, ITreatmentGetCollection
{

    public constructor(init?: Partial<TreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentCollectionResponse(); }
}

/**
* Master Group
*/
// @Route("/opms/master-group/single/id/{Id}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleById implements IReturn<MasterGroupSingleResponse>, IMasterGroupGetSingleById
{
    /**
    * The ID of the master group to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MasterGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

/**
* Master Group
*/
// @Route("/opms/master-group/single/code/{Code}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleByCode implements IReturn<MasterGroupSingleResponse>, IMasterGroupGetSingleByCode
{
    /**
    * The ID of the master group to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<MasterGroupGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

/**
* Master Group
*/
// @Route("/opms/master-group/collection", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetCollection implements IReturn<MasterGroupCollectionResponse>, IMasterGroupGetCollection
{

    public constructor(init?: Partial<MasterGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupCollectionResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/single/id/{Id}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetSingleById implements IReturn<CollaboratingGroupSingleResponse>, ICollaboratingGroupGetSingleById
{
    /**
    * The ID of the collaborating group to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CollaboratingGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupSingleResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/single/code/{Code}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetSingleByCode implements IReturn<CollaboratingGroupSingleResponse>, ICollaboratingGroupGetSingleByCode
{
    /**
    * The ID of the collaborating group to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<CollaboratingGroupGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupSingleResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/collection", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetCollection implements IReturn<CollaboratingGroupCollectionResponse>, ICollaboratingGroupGetCollection
{

    public constructor(init?: Partial<CollaboratingGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupCollectionResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetCollectionByMasterGroupId implements IReturn<CollaboratingGroupCollectionResponse>, ICollaboratingGroupGetCollectionByMasterGroupId
{
    /**
    * The ID of the Master Group associated with the collaborating group to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupId?: number;

    public constructor(init?: Partial<CollaboratingGroupGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupCollectionResponse(); }
}

/**
* Collaborating Group
*/
// @Route("/opms/collaborating-group/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetCollectionByMasterGroupCode implements IReturn<CollaboratingGroupCollectionResponse>, ICollaboratingGroupGetCollectionByMasterGroupCode
{
    /**
    * The Code of the Master Group associated with the collaborating group to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupCode: string;

    public constructor(init?: Partial<CollaboratingGroupGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/single/id/{Id}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleById implements IReturn<CountrySingleResponse>, ICountryGetSingleById
{
    /**
    * The ID of the country to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CountryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/single/code/{Code}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleByCode implements IReturn<CountrySingleResponse>, ICountryGetSingleByCode
{
    /**
    * The ID of the country to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<CountryGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollection implements IReturn<CountryCollectionResponse>, ICountryGetCollection
{

    public constructor(init?: Partial<CountryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByMasterGroupId implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByMasterGroupId
{
    /**
    * The ID of the Master Group associated with the country to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupId?: number;

    public constructor(init?: Partial<CountryGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByMasterGroupCode implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByMasterGroupCode
{
    /**
    * The Code of the Master Group associated with the country to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupCode: string;

    public constructor(init?: Partial<CountryGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection/collaborating-group/id/{CollaboratingGroupId}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByCollaboratingGroupId implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByCollaboratingGroupId
{
    /**
    * The ID of the Collaborating Group associated with the country to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupId?: number;

    public constructor(init?: Partial<CountryGetCollectionByCollaboratingGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByCollaboratingGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Country
*/
// @Route("/opms/country/collection/collaborating-group/code/{CollaboratingGroupCode}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByCollaboratingGroupCode implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByCollaboratingGroupCode
{
    /**
    * The Code of the Collaborating Group associated with the country to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupCode: string;

    public constructor(init?: Partial<CountryGetCollectionByCollaboratingGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByCollaboratingGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/single/id/{Id}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleById implements IReturn<InstitutionSingleResponse>, IInstitutionGetSingleById
{
    /**
    * The ID of the institution to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<InstitutionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/single/code/{Code}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleByCode implements IReturn<InstitutionSingleResponse>, IInstitutionGetSingleByCode
{
    /**
    * The ID of the institution to retrieve.
    */
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<InstitutionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollection implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollection
{

    public constructor(init?: Partial<InstitutionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/country/id/{CountryId}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCountryId implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCountryId
{
    /**
    * The ID of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public countryId?: number;

    public constructor(init?: Partial<InstitutionGetCollectionByCountryId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCountryId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByMasterGroupId implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByMasterGroupId
{
    /**
    * The ID of the Master Group associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupId: number;

    /**
    * The ID of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=2)
    public countryId?: number;

    public constructor(init?: Partial<InstitutionGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/collaborating-group/id/{CollaboratingGroupId}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCollaboratingGroupId implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCollaboratingGroupId
{
    /**
    * The ID of the Collaborating Group associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupId: number;

    /**
    * The ID of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=2)
    public countryId?: number;

    public constructor(init?: Partial<InstitutionGetCollectionByCollaboratingGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCollaboratingGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/country/code/{CountryCode}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCountryCode implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCountryCode
{
    /**
    * The Code of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public countryCode: string;

    public constructor(init?: Partial<InstitutionGetCollectionByCountryCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCountryCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByMasterGroupCode implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByMasterGroupCode
{
    /**
    * The Code of the Master Group associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupCode: string;

    /**
    * The Code of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=2)
    public countryCode: string;

    public constructor(init?: Partial<InstitutionGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Institution
*/
// @Route("/opms/institution/collection/collaborating-group/code/{CollaboratingGroupCode}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCollaboratingGroupCode implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCollaboratingGroupCode
{
    /**
    * The Code of the Collaborating Group associated with the institution to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupCode: string;

    /**
    * The Code of the Country associated with the institution to retrieve.
    */
    // @DataMember(Order=2)
    public countryCode: string;

    public constructor(init?: Partial<InstitutionGetCollectionByCollaboratingGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCollaboratingGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/single/id/{Id}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleById implements IReturn<PatientSingleResponse>, IPatientGetSingleById
{
    /**
    * The ID of the patient to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/single/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleByStudyNumber implements IReturn<PatientSingleResponse>, IPatientGetSingleByStudyNumber
{
    /**
    * The Study Number of the patient to retrieve.
    */
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetSingleByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/validation/id/{Id}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientGetValidationById implements IReturn<PatientValidationResponse>, IPatientGetValidationById
{
    /**
    * The ID of the patient to validate.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetValidationById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetValidationById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientValidationResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/validation/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientGetValidationByStudyNumber implements IReturn<PatientValidationResponse>, IPatientGetValidationByStudyNumber
{
    /**
    * The Study Number of the patient to validate.
    */
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetValidationByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetValidationByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientValidationResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollection implements IReturn<PatientCollectionResponse>, IPatientGetCollection
{
    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByMasterGroupId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByMasterGroupId
{
    /**
    * The ID of the Master Group associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupId: number;

    /**
    * The ID of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public countryId?: number;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/collaborating-group/id/{CollaboratingGroupId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCollaboratingGroupId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCollaboratingGroupId
{
    /**
    * The ID of the Collaborating Group associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupId: number;

    /**
    * The ID of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public countryId?: number;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCollaboratingGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCollaboratingGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/country/id/{CountryId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCountryId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCountryId
{
    /**
    * The ID of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public countryId: number;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCountryId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCountryId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/institution/id/{InstitutionId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByInstitutionId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByInstitutionId
{
    /**
    * The ID of the Institution associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public institutionId: number;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByInstitutionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByInstitutionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByMasterGroupCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByMasterGroupCode
{
    /**
    * The Code of the Master Group associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public masterGroupCode: string;

    /**
    * The Code of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public countryCode: string;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/collaborating-group/code/{CollaboratingGroupCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCollaboratingGroupCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCollaboratingGroupCode
{
    /**
    * The Code of the Collaborating Group associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public collaboratingGroupCode: string;

    /**
    * The Code of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public countryCode: string;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCollaboratingGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCollaboratingGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/country/code/{CountryCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCountryCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCountryCode
{
    /**
    * The Code of the Country associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public countryCode: string;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCountryCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCountryCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/collection/institution/code/{InstitutionCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByInstitutionCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByInstitutionCode
{
    /**
    * The Code of the Institution associated with the patient to retrieve.
    */
    // @DataMember(Order=1)
    public institutionCode: string;

    /**
    * The IDs of the Patient States associated with the patient to retrieve.
    */
    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/Save", "POST")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSave implements IReturn<PatientSingleResponse>
{
    /**
    * The patient to save.
    */
    // @DataMember(Order=1)
    public patient: Patient;

    public constructor(init?: Partial<PatientPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/save/ids", "POST")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSaveWithIds implements IReturn<PatientSingleResponse>
{
    /**
    * The patient to save.
    */
    // @DataMember(Order=1)
    public patient: Patient;

    /**
    * The ID of the Institution associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    public constructor(init?: Partial<PatientPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Patient
*/
// @Route("/opms/patient/save/codes", "POST")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSaveWithCodes implements IReturn<PatientSingleResponse>
{
    /**
    * The patient to save.
    */
    // @DataMember(Order=1)
    public patient: Patient;

    /**
    * The ID of the Institution associated with the patient to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    public constructor(init?: Partial<PatientPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/single/id/{Id}", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventGetSingleById implements IReturn<EventSingleResponse>, IEventGetSingleById
{
    /**
    * The ID of the event to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EventGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/single/ids", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventGetSingleByIds implements IReturn<EventSingleResponse>, IEventGetSingleByIds
{
    /**
    * The Patient ID of the event to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Id of the Institution associated to the event to retrieve.
    */
    // @DataMember(Order=4)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=5)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=6)
    public createEvent?: boolean;

    public constructor(init?: Partial<EventGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/single/codes", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventGetSingleByCodes implements IReturn<EventSingleResponse>, IEventGetSingleByCodes
{
    /**
    * The Patient Study Number of the event to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Code of the Institution associated to the event to retrieve.
    */
    // @DataMember(Order=4)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=5)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=6)
    public createEvent?: boolean;

    public constructor(init?: Partial<EventGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/collection", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=500)
// @DataContract
export class EventGetCollection implements IReturn<EventCollectionResponse>, IEventGetCollection
{

    public constructor(init?: Partial<EventGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventCollectionResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/collection/patient/id/{PatientId}", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=500)
// @DataContract
export class EventGetCollectionByPatientId implements IReturn<EventCollectionResponse>, IEventGetCollectionByPatientId
{
    /**
    * The ID of the Patient associated with the event to retrieve.
    */
    // @DataMember(Order=1)
    public patientId: number;

    public constructor(init?: Partial<EventGetCollectionByPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetCollectionByPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventCollectionResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/collection/patient/study-number/{PatientStudyNumber}", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=500)
// @DataContract
export class EventGetCollectionByPatientStudyNumber implements IReturn<EventCollectionResponse>, IEventGetCollectionByPatientStudyNumber
{
    /**
    * The Study Number of the Patient associated with the event to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    public constructor(init?: Partial<EventGetCollectionByPatientStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetCollectionByPatientStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventCollectionResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/Save", "POST")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventPostSave implements IReturn<EventSingleResponse>
{
    /**
    * The event to save.
    */
    // @DataMember(Order=1)
    public event: Event;

    public constructor(init?: Partial<EventPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/save/ids", "POST")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventPostSaveWithIds implements IReturn<EventSingleResponse>
{
    /**
    * The event to save.
    */
    // @DataMember(Order=1)
    public event: Event;

    /**
    * The ID of the Institution associated with the event to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the event to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition associated with the event to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Repeat number of the event to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    public constructor(init?: Partial<EventPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Event
*/
// @Route("/opms/event/save/codes", "POST")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventPostSaveWithCodes implements IReturn<EventSingleResponse>
{
    /**
    * The event to save.
    */
    // @DataMember(Order=1)
    public event: Event;

    /**
    * The Code of the Institution associated with the event to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the event to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the event to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Repeat number of the event to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    public constructor(init?: Partial<EventPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EventSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/single/id/{Id}", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleById implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/single/ids", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleByIds implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the source document to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new source document if no source document is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<SourceDocumentGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/single/codes", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleByCodes implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the source document to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new source document if no source document is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<SourceDocumentGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/collection", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollection implements IReturn<SourceDocumentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<SourceDocumentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollectionByIds implements IReturn<SourceDocumentCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollectionByCodes implements IReturn<SourceDocumentCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/save", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSave implements IReturn<SourceDocumentSingleResponse>
{
    /**
    * The source document to save.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/save/ids", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSaveWithIds implements IReturn<SourceDocumentSingleResponse>
{
    /**
    * The source document to save.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    /**
    * The ID of the Institution associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<SourceDocumentPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/save/codes", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSaveWithCodes implements IReturn<SourceDocumentSingleResponse>
{
    /**
    * The source document to save.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    /**
    * The Code of the Institution associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the source document to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<SourceDocumentPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/delete", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDelete implements IReturn<SourceDocumentDeleteResponse>
{
    /**
    * The source document to delete.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDeleteWithId implements IReturn<SourceDocumentDeleteResponse>
{
    /**
    * The ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/delete/ids", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDeleteWithIds implements IReturn<SourceDocumentDeleteResponse>
{
    /**
    * The Patient ID of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<SourceDocumentDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/delete/codes", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDeleteByCodes implements IReturn<SourceDocumentDeleteResponse>
{
    /**
    * The Patient Study Number of the source document to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the source document to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<SourceDocumentDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/validate", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidate implements IReturn<SourceDocumentValidationResponse>
{
    /**
    * The source document to validate.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/validate/ids", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidateWithIds implements IReturn<SourceDocumentValidationResponse>
{
    /**
    * The source document to validate.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    /**
    * The ID of the Institution associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

/**
* Form - Source Document
*/
// @Route("/opms/form/source-document/validate/codes", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidateWithCodes implements IReturn<SourceDocumentValidationResponse>
{
    /**
    * The source document to validate.
    */
    // @DataMember(Order=1)
    public form: SourceDocument;

    /**
    * The Code of the Institution associated with the source document to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the source document to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the source document to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the source document to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

/**
* Form - Patient Transfer
*/
// @Route("/opms/form/patient-transfer/single/id/{Id}", "GET")
// @Api(Description="Form - Patient Transfer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=500)
// @DataContract
export class PatientTransferGetSingleById implements IReturn<PatientTransferSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the patient transfer to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientTransferGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientTransferSingleResponse(); }
}

/**
* Form - Patient Transfer
*/
// @Route("/opms/form/patient-transfer/single/ids", "GET")
// @Api(Description="Form - Patient Transfer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=500)
// @DataContract
export class PatientTransferGetSingleByIds implements IReturn<PatientTransferSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the patient transfer to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the patient transfer to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the patient transfer to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new patient transfer if no patient transfer is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PatientTransferGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientTransferSingleResponse(); }
}

/**
* Form - Patient Transfer
*/
// @Route("/opms/form/patient-transfer/single/codes", "GET")
// @Api(Description="Form - Patient Transfer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=500)
// @DataContract
export class PatientTransferGetSingleByCodes implements IReturn<PatientTransferSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the patient transfer to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the patient transfer to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the patient transfer to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new patient transfer if no patient transfer is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PatientTransferGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientTransferSingleResponse(); }
}

/**
* Form - Patient Transfer
*/
// @Route("/opms/form/patient-transfer/collection", "GET")
// @Api(Description="Form - Patient Transfer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientTransferGetCollection implements IReturn<PatientTransferCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<PatientTransferGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientTransferCollectionResponse(); }
}

/**
* Form - Patient Transfer
*/
// @Route("/opms/form/patient-transfer/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Patient Transfer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientTransferGetCollectionByIds implements IReturn<PatientTransferCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the patient transfer to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PatientTransferGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientTransferCollectionResponse(); }
}

/**
* Form - Patient Transfer
*/
// @Route("/opms/form/patient-transfer/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Patient Transfer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientTransferGetCollectionByCodes implements IReturn<PatientTransferCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PatientTransferGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientTransferCollectionResponse(); }
}

/**
* Form - Patient Transfer
*/
// @Route("/opms/form/patient-transfer/save", "POST")
// @Api(Description="Form - Patient Transfer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=500)
// @DataContract
export class PatientTransferPostSave implements IReturn<PatientTransferSingleResponse>
{
    /**
    * The patient transfer to save.
    */
    // @DataMember(Order=1)
    public form: PatientTransfer;

    public constructor(init?: Partial<PatientTransferPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientTransferSingleResponse(); }
}

/**
* Form - Patient Transfer
*/
// @Route("/opms/form/patient-transfer/save/ids", "POST")
// @Api(Description="Form - Patient Transfer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=500)
// @DataContract
export class PatientTransferPostSaveWithIds implements IReturn<PatientTransferSingleResponse>
{
    /**
    * The patient transfer to save.
    */
    // @DataMember(Order=1)
    public form: PatientTransfer;

    /**
    * The ID of the Institution associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the patient transfer to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PatientTransferPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientTransferSingleResponse(); }
}

/**
* Form - Patient Transfer
*/
// @Route("/opms/form/patient-transfer/save/codes", "POST")
// @Api(Description="Form - Patient Transfer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferSingleResponse)", StatusCode=500)
// @DataContract
export class PatientTransferPostSaveWithCodes implements IReturn<PatientTransferSingleResponse>
{
    /**
    * The patient transfer to save.
    */
    // @DataMember(Order=1)
    public form: PatientTransfer;

    /**
    * The Code of the Institution associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the patient transfer to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PatientTransferPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientTransferSingleResponse(); }
}

/**
* Form - Patient Transfer
*/
// @Route("/opms/form/patient-transfer/delete", "DELETE")
// @Api(Description="Form - Patient Transfer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientTransferDelete implements IReturn<PatientTransferDeleteResponse>
{
    /**
    * The patient transfer to delete.
    */
    // @DataMember(Order=1)
    public form: PatientTransfer;

    public constructor(init?: Partial<PatientTransferDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientTransferDeleteResponse(); }
}

/**
* Form - Patient Transfer
*/
// @Route("/opms/form/patient-transfer/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Patient Transfer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientTransferDeleteWithId implements IReturn<PatientTransferDeleteResponse>
{
    /**
    * The ID of the patient transfer to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientTransferDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientTransferDeleteResponse(); }
}

/**
* Form - Patient Transfer
*/
// @Route("/opms/form/patient-transfer/delete/ids", "DELETE")
// @Api(Description="Form - Patient Transfer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientTransferDeleteWithIds implements IReturn<PatientTransferDeleteResponse>
{
    /**
    * The Patient ID of the patient transfer to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the patient transfer to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PatientTransferDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientTransferDeleteResponse(); }
}

/**
* Form - Patient Transfer
*/
// @Route("/opms/form/patient-transfer/delete/codes", "DELETE")
// @Api(Description="Form - Patient Transfer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientTransferDeleteByCodes implements IReturn<PatientTransferDeleteResponse>
{
    /**
    * The Patient Study Number of the patient transfer to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the patient transfer to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PatientTransferDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientTransferDeleteResponse(); }
}

/**
* Form - Patient Transfer
*/
// @Route("/opms/form/patient-transfer/validate", "POST")
// @Api(Description="Form - Patient Transfer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferValidationResponse)", StatusCode=500)
// @DataContract
export class PatientTransferPostValidate implements IReturn<PatientTransferValidationResponse>
{
    /**
    * The patient transfer to validate.
    */
    // @DataMember(Order=1)
    public form: PatientTransfer;

    public constructor(init?: Partial<PatientTransferPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientTransferValidationResponse(); }
}

/**
* Form - Patient Transfer
*/
// @Route("/opms/form/patient-transfer/validate/ids", "POST")
// @Api(Description="Form - Patient Transfer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferValidationResponse)", StatusCode=500)
// @DataContract
export class PatientTransferPostValidateWithIds implements IReturn<PatientTransferValidationResponse>
{
    /**
    * The patient transfer to validate.
    */
    // @DataMember(Order=1)
    public form: PatientTransfer;

    /**
    * The ID of the Institution associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PatientTransferPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientTransferValidationResponse(); }
}

/**
* Form - Patient Transfer
*/
// @Route("/opms/form/patient-transfer/validate/codes", "POST")
// @Api(Description="Form - Patient Transfer")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferValidationResponse)", StatusCode=500)
// @DataContract
export class PatientTransferPostValidateWithCodes implements IReturn<PatientTransferValidationResponse>
{
    /**
    * The patient transfer to validate.
    */
    // @DataMember(Order=1)
    public form: PatientTransfer;

    /**
    * The Code of the Institution associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the patient transfer to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PatientTransferPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientTransferValidationResponse(); }
}

/**
* Form - Patient Transfer Entry
*/
// @Route("/opms/form/patient-transfer-entry/single/id/{Id}", "GET")
// @Api(Description="Form - Patient Transfer Entry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=500)
// @DataContract
export class PatientTransferEntryGetSingleById implements IReturn<PatientTransferEntrySingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the patient transfer entry to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientTransferEntryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferEntryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientTransferEntrySingleResponse(); }
}

/**
* Form - Patient Transfer Entry
*/
// @Route("/opms/form/patient-transfer-entry/single/ids", "GET")
// @Api(Description="Form - Patient Transfer Entry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=500)
// @DataContract
export class PatientTransferEntryGetSingleByIds implements IReturn<PatientTransferEntrySingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the patient transfer entry to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the patient transfer entry to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the patient transfer entry to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new patient transfer entry if no patient transfer entry is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PatientTransferEntryGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferEntryGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientTransferEntrySingleResponse(); }
}

/**
* Form - Patient Transfer Entry
*/
// @Route("/opms/form/patient-transfer-entry/single/codes", "GET")
// @Api(Description="Form - Patient Transfer Entry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=500)
// @DataContract
export class PatientTransferEntryGetSingleByCodes implements IReturn<PatientTransferEntrySingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the patient transfer entry to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the patient transfer entry to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the patient transfer entry to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new patient transfer entry if no patient transfer entry is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PatientTransferEntryGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferEntryGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientTransferEntrySingleResponse(); }
}

/**
* Form - Patient Transfer Entry
*/
// @Route("/opms/form/patient-transfer-entry/collection", "GET")
// @Api(Description="Form - Patient Transfer Entry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferEntryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferEntryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferEntryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferEntryCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientTransferEntryGetCollection implements IReturn<PatientTransferEntryCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<PatientTransferEntryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferEntryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientTransferEntryCollectionResponse(); }
}

/**
* Form - Patient Transfer Entry
*/
// @Route("/opms/form/patient-transfer-entry/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Patient Transfer Entry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferEntryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferEntryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferEntryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferEntryCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientTransferEntryGetCollectionByIds implements IReturn<PatientTransferEntryCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the patient transfer entry to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PatientTransferEntryGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferEntryGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientTransferEntryCollectionResponse(); }
}

/**
* Form - Patient Transfer Entry
*/
// @Route("/opms/form/patient-transfer-entry/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Patient Transfer Entry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferEntryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferEntryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferEntryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferEntryCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientTransferEntryGetCollectionByCodes implements IReturn<PatientTransferEntryCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PatientTransferEntryGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferEntryGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientTransferEntryCollectionResponse(); }
}

/**
* Form - Patient Transfer Entry
*/
// @Route("/opms/form/patient-transfer-entry/save", "POST")
// @Api(Description="Form - Patient Transfer Entry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=500)
// @DataContract
export class PatientTransferEntryPostSave implements IReturn<PatientTransferEntrySingleResponse>
{
    /**
    * The patient transfer entry to save.
    */
    // @DataMember(Order=1)
    public form: PatientTransferEntry;

    public constructor(init?: Partial<PatientTransferEntryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferEntryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientTransferEntrySingleResponse(); }
}

/**
* Form - Patient Transfer Entry
*/
// @Route("/opms/form/patient-transfer-entry/save/ids", "POST")
// @Api(Description="Form - Patient Transfer Entry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=500)
// @DataContract
export class PatientTransferEntryPostSaveWithIds implements IReturn<PatientTransferEntrySingleResponse>
{
    /**
    * The patient transfer entry to save.
    */
    // @DataMember(Order=1)
    public form: PatientTransferEntry;

    /**
    * The ID of the Institution associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the patient transfer entry to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PatientTransferEntryPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferEntryPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientTransferEntrySingleResponse(); }
}

/**
* Form - Patient Transfer Entry
*/
// @Route("/opms/form/patient-transfer-entry/save/codes", "POST")
// @Api(Description="Form - Patient Transfer Entry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferEntrySingleResponse)", StatusCode=500)
// @DataContract
export class PatientTransferEntryPostSaveWithCodes implements IReturn<PatientTransferEntrySingleResponse>
{
    /**
    * The patient transfer entry to save.
    */
    // @DataMember(Order=1)
    public form: PatientTransferEntry;

    /**
    * The Code of the Institution associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the patient transfer entry to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PatientTransferEntryPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferEntryPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientTransferEntrySingleResponse(); }
}

/**
* Form - Patient Transfer Entry
*/
// @Route("/opms/form/patient-transfer-entry/delete", "DELETE")
// @Api(Description="Form - Patient Transfer Entry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferEntryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferEntryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferEntryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferEntryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferEntryDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientTransferEntryDelete implements IReturn<PatientTransferEntryDeleteResponse>
{
    /**
    * The patient transfer entry to delete.
    */
    // @DataMember(Order=1)
    public form: PatientTransferEntry;

    public constructor(init?: Partial<PatientTransferEntryDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferEntryDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientTransferEntryDeleteResponse(); }
}

/**
* Form - Patient Transfer Entry
*/
// @Route("/opms/form/patient-transfer-entry/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Patient Transfer Entry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferEntryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferEntryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferEntryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferEntryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferEntryDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientTransferEntryDeleteWithId implements IReturn<PatientTransferEntryDeleteResponse>
{
    /**
    * The ID of the patient transfer entry to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientTransferEntryDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferEntryDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientTransferEntryDeleteResponse(); }
}

/**
* Form - Patient Transfer Entry
*/
// @Route("/opms/form/patient-transfer-entry/delete/ids", "DELETE")
// @Api(Description="Form - Patient Transfer Entry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferEntryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferEntryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferEntryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferEntryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferEntryDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientTransferEntryDeleteWithIds implements IReturn<PatientTransferEntryDeleteResponse>
{
    /**
    * The Patient ID of the patient transfer entry to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the patient transfer entry to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PatientTransferEntryDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferEntryDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientTransferEntryDeleteResponse(); }
}

/**
* Form - Patient Transfer Entry
*/
// @Route("/opms/form/patient-transfer-entry/delete/codes", "DELETE")
// @Api(Description="Form - Patient Transfer Entry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferEntryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferEntryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferEntryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferEntryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferEntryDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientTransferEntryDeleteByCodes implements IReturn<PatientTransferEntryDeleteResponse>
{
    /**
    * The Patient Study Number of the patient transfer entry to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the patient transfer entry to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PatientTransferEntryDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferEntryDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientTransferEntryDeleteResponse(); }
}

/**
* Form - Patient Transfer Entry
*/
// @Route("/opms/form/patient-transfer-entry/validate", "POST")
// @Api(Description="Form - Patient Transfer Entry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferEntryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferEntryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferEntryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferEntryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferEntryValidationResponse)", StatusCode=500)
// @DataContract
export class PatientTransferEntryPostValidate implements IReturn<PatientTransferEntryValidationResponse>
{
    /**
    * The patient transfer entry to validate.
    */
    // @DataMember(Order=1)
    public form: PatientTransferEntry;

    public constructor(init?: Partial<PatientTransferEntryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferEntryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientTransferEntryValidationResponse(); }
}

/**
* Form - Patient Transfer Entry
*/
// @Route("/opms/form/patient-transfer-entry/validate/ids", "POST")
// @Api(Description="Form - Patient Transfer Entry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferEntryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferEntryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferEntryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferEntryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferEntryValidationResponse)", StatusCode=500)
// @DataContract
export class PatientTransferEntryPostValidateWithIds implements IReturn<PatientTransferEntryValidationResponse>
{
    /**
    * The patient transfer entry to validate.
    */
    // @DataMember(Order=1)
    public form: PatientTransferEntry;

    /**
    * The ID of the Institution associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PatientTransferEntryPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferEntryPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientTransferEntryValidationResponse(); }
}

/**
* Form - Patient Transfer Entry
*/
// @Route("/opms/form/patient-transfer-entry/validate/codes", "POST")
// @Api(Description="Form - Patient Transfer Entry")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PatientTransferEntryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PatientTransferEntryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PatientTransferEntryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PatientTransferEntryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PatientTransferEntryValidationResponse)", StatusCode=500)
// @DataContract
export class PatientTransferEntryPostValidateWithCodes implements IReturn<PatientTransferEntryValidationResponse>
{
    /**
    * The patient transfer entry to validate.
    */
    // @DataMember(Order=1)
    public form: PatientTransferEntry;

    /**
    * The Code of the Institution associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the patient transfer entry to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PatientTransferEntryPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientTransferEntryPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientTransferEntryValidationResponse(); }
}

/**
* Form - Decision Conflict Scale
*/
// @Route("/opms/form/decision-conflict-scale/single/id/{Id}", "GET")
// @Api(Description="Form - Decision Conflict Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=500)
// @DataContract
export class DecisionConflictScaleGetSingleById implements IReturn<DecisionConflictScaleSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the decision conflict scale to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DecisionConflictScaleGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionConflictScaleGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DecisionConflictScaleSingleResponse(); }
}

/**
* Form - Decision Conflict Scale
*/
// @Route("/opms/form/decision-conflict-scale/single/ids", "GET")
// @Api(Description="Form - Decision Conflict Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=500)
// @DataContract
export class DecisionConflictScaleGetSingleByIds implements IReturn<DecisionConflictScaleSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the decision conflict scale to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the decision conflict scale to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the decision conflict scale to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new decision conflict scale if no decision conflict scale is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DecisionConflictScaleGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionConflictScaleGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DecisionConflictScaleSingleResponse(); }
}

/**
* Form - Decision Conflict Scale
*/
// @Route("/opms/form/decision-conflict-scale/single/codes", "GET")
// @Api(Description="Form - Decision Conflict Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=500)
// @DataContract
export class DecisionConflictScaleGetSingleByCodes implements IReturn<DecisionConflictScaleSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the decision conflict scale to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the decision conflict scale to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the decision conflict scale to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new decision conflict scale if no decision conflict scale is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DecisionConflictScaleGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionConflictScaleGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DecisionConflictScaleSingleResponse(); }
}

/**
* Form - Decision Conflict Scale
*/
// @Route("/opms/form/decision-conflict-scale/collection", "GET")
// @Api(Description="Form - Decision Conflict Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleCollectionResponse)", StatusCode=500)
// @DataContract
export class DecisionConflictScaleGetCollection implements IReturn<DecisionConflictScaleCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<DecisionConflictScaleGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionConflictScaleGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DecisionConflictScaleCollectionResponse(); }
}

/**
* Form - Decision Conflict Scale
*/
// @Route("/opms/form/decision-conflict-scale/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Decision Conflict Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleCollectionResponse)", StatusCode=500)
// @DataContract
export class DecisionConflictScaleGetCollectionByIds implements IReturn<DecisionConflictScaleCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the decision conflict scale to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DecisionConflictScaleGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionConflictScaleGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DecisionConflictScaleCollectionResponse(); }
}

/**
* Form - Decision Conflict Scale
*/
// @Route("/opms/form/decision-conflict-scale/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Decision Conflict Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleCollectionResponse)", StatusCode=500)
// @DataContract
export class DecisionConflictScaleGetCollectionByCodes implements IReturn<DecisionConflictScaleCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DecisionConflictScaleGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionConflictScaleGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DecisionConflictScaleCollectionResponse(); }
}

/**
* Form - Decision Conflict Scale
*/
// @Route("/opms/form/decision-conflict-scale/save", "POST")
// @Api(Description="Form - Decision Conflict Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=500)
// @DataContract
export class DecisionConflictScalePostSave implements IReturn<DecisionConflictScaleSingleResponse>
{
    /**
    * The decision conflict scale to save.
    */
    // @DataMember(Order=1)
    public form: DecisionConflictScale;

    public constructor(init?: Partial<DecisionConflictScalePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionConflictScalePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DecisionConflictScaleSingleResponse(); }
}

/**
* Form - Decision Conflict Scale
*/
// @Route("/opms/form/decision-conflict-scale/save/ids", "POST")
// @Api(Description="Form - Decision Conflict Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=500)
// @DataContract
export class DecisionConflictScalePostSaveWithIds implements IReturn<DecisionConflictScaleSingleResponse>
{
    /**
    * The decision conflict scale to save.
    */
    // @DataMember(Order=1)
    public form: DecisionConflictScale;

    /**
    * The ID of the Institution associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the decision conflict scale to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DecisionConflictScalePostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionConflictScalePostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DecisionConflictScaleSingleResponse(); }
}

/**
* Form - Decision Conflict Scale
*/
// @Route("/opms/form/decision-conflict-scale/save/codes", "POST")
// @Api(Description="Form - Decision Conflict Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleSingleResponse)", StatusCode=500)
// @DataContract
export class DecisionConflictScalePostSaveWithCodes implements IReturn<DecisionConflictScaleSingleResponse>
{
    /**
    * The decision conflict scale to save.
    */
    // @DataMember(Order=1)
    public form: DecisionConflictScale;

    /**
    * The Code of the Institution associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the decision conflict scale to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DecisionConflictScalePostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionConflictScalePostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DecisionConflictScaleSingleResponse(); }
}

/**
* Form - Decision Conflict Scale
*/
// @Route("/opms/form/decision-conflict-scale/delete", "DELETE")
// @Api(Description="Form - Decision Conflict Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleDeleteResponse)", StatusCode=500)
// @DataContract
export class DecisionConflictScaleDelete implements IReturn<DecisionConflictScaleDeleteResponse>
{
    /**
    * The decision conflict scale to delete.
    */
    // @DataMember(Order=1)
    public form: DecisionConflictScale;

    public constructor(init?: Partial<DecisionConflictScaleDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionConflictScaleDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DecisionConflictScaleDeleteResponse(); }
}

/**
* Form - Decision Conflict Scale
*/
// @Route("/opms/form/decision-conflict-scale/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Decision Conflict Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleDeleteResponse)", StatusCode=500)
// @DataContract
export class DecisionConflictScaleDeleteWithId implements IReturn<DecisionConflictScaleDeleteResponse>
{
    /**
    * The ID of the decision conflict scale to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DecisionConflictScaleDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionConflictScaleDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DecisionConflictScaleDeleteResponse(); }
}

/**
* Form - Decision Conflict Scale
*/
// @Route("/opms/form/decision-conflict-scale/delete/ids", "DELETE")
// @Api(Description="Form - Decision Conflict Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleDeleteResponse)", StatusCode=500)
// @DataContract
export class DecisionConflictScaleDeleteWithIds implements IReturn<DecisionConflictScaleDeleteResponse>
{
    /**
    * The Patient ID of the decision conflict scale to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the decision conflict scale to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DecisionConflictScaleDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionConflictScaleDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DecisionConflictScaleDeleteResponse(); }
}

/**
* Form - Decision Conflict Scale
*/
// @Route("/opms/form/decision-conflict-scale/delete/codes", "DELETE")
// @Api(Description="Form - Decision Conflict Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleDeleteResponse)", StatusCode=500)
// @DataContract
export class DecisionConflictScaleDeleteByCodes implements IReturn<DecisionConflictScaleDeleteResponse>
{
    /**
    * The Patient Study Number of the decision conflict scale to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the decision conflict scale to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DecisionConflictScaleDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionConflictScaleDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DecisionConflictScaleDeleteResponse(); }
}

/**
* Form - Decision Conflict Scale
*/
// @Route("/opms/form/decision-conflict-scale/validate", "POST")
// @Api(Description="Form - Decision Conflict Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleValidationResponse)", StatusCode=500)
// @DataContract
export class DecisionConflictScalePostValidate implements IReturn<DecisionConflictScaleValidationResponse>
{
    /**
    * The decision conflict scale to validate.
    */
    // @DataMember(Order=1)
    public form: DecisionConflictScale;

    public constructor(init?: Partial<DecisionConflictScalePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionConflictScalePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DecisionConflictScaleValidationResponse(); }
}

/**
* Form - Decision Conflict Scale
*/
// @Route("/opms/form/decision-conflict-scale/validate/ids", "POST")
// @Api(Description="Form - Decision Conflict Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleValidationResponse)", StatusCode=500)
// @DataContract
export class DecisionConflictScalePostValidateWithIds implements IReturn<DecisionConflictScaleValidationResponse>
{
    /**
    * The decision conflict scale to validate.
    */
    // @DataMember(Order=1)
    public form: DecisionConflictScale;

    /**
    * The ID of the Institution associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DecisionConflictScalePostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionConflictScalePostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DecisionConflictScaleValidationResponse(); }
}

/**
* Form - Decision Conflict Scale
*/
// @Route("/opms/form/decision-conflict-scale/validate/codes", "POST")
// @Api(Description="Form - Decision Conflict Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionConflictScaleValidationResponse)", StatusCode=500)
// @DataContract
export class DecisionConflictScalePostValidateWithCodes implements IReturn<DecisionConflictScaleValidationResponse>
{
    /**
    * The decision conflict scale to validate.
    */
    // @DataMember(Order=1)
    public form: DecisionConflictScale;

    /**
    * The Code of the Institution associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the decision conflict scale to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DecisionConflictScalePostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionConflictScalePostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DecisionConflictScaleValidationResponse(); }
}

/**
* Form - Decision Regret Scale
*/
// @Route("/opms/form/decision-regret-scale/single/id/{Id}", "GET")
// @Api(Description="Form - Decision Regret Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=500)
// @DataContract
export class DecisionRegretScaleGetSingleById implements IReturn<DecisionRegretScaleSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the decision regret scale to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DecisionRegretScaleGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionRegretScaleGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DecisionRegretScaleSingleResponse(); }
}

/**
* Form - Decision Regret Scale
*/
// @Route("/opms/form/decision-regret-scale/single/ids", "GET")
// @Api(Description="Form - Decision Regret Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=500)
// @DataContract
export class DecisionRegretScaleGetSingleByIds implements IReturn<DecisionRegretScaleSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the decision regret scale to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the decision regret scale to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the decision regret scale to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new decision regret scale if no decision regret scale is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DecisionRegretScaleGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionRegretScaleGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DecisionRegretScaleSingleResponse(); }
}

/**
* Form - Decision Regret Scale
*/
// @Route("/opms/form/decision-regret-scale/single/codes", "GET")
// @Api(Description="Form - Decision Regret Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=500)
// @DataContract
export class DecisionRegretScaleGetSingleByCodes implements IReturn<DecisionRegretScaleSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the decision regret scale to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the decision regret scale to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the decision regret scale to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new decision regret scale if no decision regret scale is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DecisionRegretScaleGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionRegretScaleGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DecisionRegretScaleSingleResponse(); }
}

/**
* Form - Decision Regret Scale
*/
// @Route("/opms/form/decision-regret-scale/collection", "GET")
// @Api(Description="Form - Decision Regret Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleCollectionResponse)", StatusCode=500)
// @DataContract
export class DecisionRegretScaleGetCollection implements IReturn<DecisionRegretScaleCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<DecisionRegretScaleGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionRegretScaleGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DecisionRegretScaleCollectionResponse(); }
}

/**
* Form - Decision Regret Scale
*/
// @Route("/opms/form/decision-regret-scale/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Decision Regret Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleCollectionResponse)", StatusCode=500)
// @DataContract
export class DecisionRegretScaleGetCollectionByIds implements IReturn<DecisionRegretScaleCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the decision regret scale to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DecisionRegretScaleGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionRegretScaleGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DecisionRegretScaleCollectionResponse(); }
}

/**
* Form - Decision Regret Scale
*/
// @Route("/opms/form/decision-regret-scale/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Decision Regret Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleCollectionResponse)", StatusCode=500)
// @DataContract
export class DecisionRegretScaleGetCollectionByCodes implements IReturn<DecisionRegretScaleCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DecisionRegretScaleGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionRegretScaleGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DecisionRegretScaleCollectionResponse(); }
}

/**
* Form - Decision Regret Scale
*/
// @Route("/opms/form/decision-regret-scale/save", "POST")
// @Api(Description="Form - Decision Regret Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=500)
// @DataContract
export class DecisionRegretScalePostSave implements IReturn<DecisionRegretScaleSingleResponse>
{
    /**
    * The decision regret scale to save.
    */
    // @DataMember(Order=1)
    public form: DecisionRegretScale;

    public constructor(init?: Partial<DecisionRegretScalePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionRegretScalePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DecisionRegretScaleSingleResponse(); }
}

/**
* Form - Decision Regret Scale
*/
// @Route("/opms/form/decision-regret-scale/save/ids", "POST")
// @Api(Description="Form - Decision Regret Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=500)
// @DataContract
export class DecisionRegretScalePostSaveWithIds implements IReturn<DecisionRegretScaleSingleResponse>
{
    /**
    * The decision regret scale to save.
    */
    // @DataMember(Order=1)
    public form: DecisionRegretScale;

    /**
    * The ID of the Institution associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the decision regret scale to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DecisionRegretScalePostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionRegretScalePostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DecisionRegretScaleSingleResponse(); }
}

/**
* Form - Decision Regret Scale
*/
// @Route("/opms/form/decision-regret-scale/save/codes", "POST")
// @Api(Description="Form - Decision Regret Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleSingleResponse)", StatusCode=500)
// @DataContract
export class DecisionRegretScalePostSaveWithCodes implements IReturn<DecisionRegretScaleSingleResponse>
{
    /**
    * The decision regret scale to save.
    */
    // @DataMember(Order=1)
    public form: DecisionRegretScale;

    /**
    * The Code of the Institution associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the decision regret scale to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DecisionRegretScalePostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionRegretScalePostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DecisionRegretScaleSingleResponse(); }
}

/**
* Form - Decision Regret Scale
*/
// @Route("/opms/form/decision-regret-scale/delete", "DELETE")
// @Api(Description="Form - Decision Regret Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleDeleteResponse)", StatusCode=500)
// @DataContract
export class DecisionRegretScaleDelete implements IReturn<DecisionRegretScaleDeleteResponse>
{
    /**
    * The decision regret scale to delete.
    */
    // @DataMember(Order=1)
    public form: DecisionRegretScale;

    public constructor(init?: Partial<DecisionRegretScaleDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionRegretScaleDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DecisionRegretScaleDeleteResponse(); }
}

/**
* Form - Decision Regret Scale
*/
// @Route("/opms/form/decision-regret-scale/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Decision Regret Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleDeleteResponse)", StatusCode=500)
// @DataContract
export class DecisionRegretScaleDeleteWithId implements IReturn<DecisionRegretScaleDeleteResponse>
{
    /**
    * The ID of the decision regret scale to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DecisionRegretScaleDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionRegretScaleDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DecisionRegretScaleDeleteResponse(); }
}

/**
* Form - Decision Regret Scale
*/
// @Route("/opms/form/decision-regret-scale/delete/ids", "DELETE")
// @Api(Description="Form - Decision Regret Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleDeleteResponse)", StatusCode=500)
// @DataContract
export class DecisionRegretScaleDeleteWithIds implements IReturn<DecisionRegretScaleDeleteResponse>
{
    /**
    * The Patient ID of the decision regret scale to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the decision regret scale to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DecisionRegretScaleDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionRegretScaleDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DecisionRegretScaleDeleteResponse(); }
}

/**
* Form - Decision Regret Scale
*/
// @Route("/opms/form/decision-regret-scale/delete/codes", "DELETE")
// @Api(Description="Form - Decision Regret Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleDeleteResponse)", StatusCode=500)
// @DataContract
export class DecisionRegretScaleDeleteByCodes implements IReturn<DecisionRegretScaleDeleteResponse>
{
    /**
    * The Patient Study Number of the decision regret scale to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the decision regret scale to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DecisionRegretScaleDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionRegretScaleDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DecisionRegretScaleDeleteResponse(); }
}

/**
* Form - Decision Regret Scale
*/
// @Route("/opms/form/decision-regret-scale/validate", "POST")
// @Api(Description="Form - Decision Regret Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleValidationResponse)", StatusCode=500)
// @DataContract
export class DecisionRegretScalePostValidate implements IReturn<DecisionRegretScaleValidationResponse>
{
    /**
    * The decision regret scale to validate.
    */
    // @DataMember(Order=1)
    public form: DecisionRegretScale;

    public constructor(init?: Partial<DecisionRegretScalePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionRegretScalePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DecisionRegretScaleValidationResponse(); }
}

/**
* Form - Decision Regret Scale
*/
// @Route("/opms/form/decision-regret-scale/validate/ids", "POST")
// @Api(Description="Form - Decision Regret Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleValidationResponse)", StatusCode=500)
// @DataContract
export class DecisionRegretScalePostValidateWithIds implements IReturn<DecisionRegretScaleValidationResponse>
{
    /**
    * The decision regret scale to validate.
    */
    // @DataMember(Order=1)
    public form: DecisionRegretScale;

    /**
    * The ID of the Institution associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DecisionRegretScalePostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionRegretScalePostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DecisionRegretScaleValidationResponse(); }
}

/**
* Form - Decision Regret Scale
*/
// @Route("/opms/form/decision-regret-scale/validate/codes", "POST")
// @Api(Description="Form - Decision Regret Scale")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DecisionRegretScaleValidationResponse)", StatusCode=500)
// @DataContract
export class DecisionRegretScalePostValidateWithCodes implements IReturn<DecisionRegretScaleValidationResponse>
{
    /**
    * The decision regret scale to validate.
    */
    // @DataMember(Order=1)
    public form: DecisionRegretScale;

    /**
    * The Code of the Institution associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the decision regret scale to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DecisionRegretScalePostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DecisionRegretScalePostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DecisionRegretScaleValidationResponse(); }
}

/**
* Form - Eq5d5l
*/
// @Route("/opms/form/eq5d5l/single/id/{Id}", "GET")
// @Api(Description="Form - Eq5d5l")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=500)
// @DataContract
export class Eq5d5lGetSingleById implements IReturn<Eq5d5lSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the eq5d5l to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<Eq5d5lGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5d5lGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Eq5d5lSingleResponse(); }
}

/**
* Form - Eq5d5l
*/
// @Route("/opms/form/eq5d5l/single/ids", "GET")
// @Api(Description="Form - Eq5d5l")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=500)
// @DataContract
export class Eq5d5lGetSingleByIds implements IReturn<Eq5d5lSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the eq5d5l to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eq5d5l to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the eq5d5l to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new eq5d5l if no eq5d5l is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<Eq5d5lGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5d5lGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Eq5d5lSingleResponse(); }
}

/**
* Form - Eq5d5l
*/
// @Route("/opms/form/eq5d5l/single/codes", "GET")
// @Api(Description="Form - Eq5d5l")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=500)
// @DataContract
export class Eq5d5lGetSingleByCodes implements IReturn<Eq5d5lSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the eq5d5l to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eq5d5l to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the eq5d5l to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new eq5d5l if no eq5d5l is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<Eq5d5lGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5d5lGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Eq5d5lSingleResponse(); }
}

/**
* Form - Eq5d5l
*/
// @Route("/opms/form/eq5d5l/collection", "GET")
// @Api(Description="Form - Eq5d5l")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5d5lCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5d5lCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5d5lCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5d5lCollectionResponse)", StatusCode=500)
// @DataContract
export class Eq5d5lGetCollection implements IReturn<Eq5d5lCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<Eq5d5lGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5d5lGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Eq5d5lCollectionResponse(); }
}

/**
* Form - Eq5d5l
*/
// @Route("/opms/form/eq5d5l/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Eq5d5l")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5d5lCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5d5lCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5d5lCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5d5lCollectionResponse)", StatusCode=500)
// @DataContract
export class Eq5d5lGetCollectionByIds implements IReturn<Eq5d5lCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the eq5d5l to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<Eq5d5lGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5d5lGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Eq5d5lCollectionResponse(); }
}

/**
* Form - Eq5d5l
*/
// @Route("/opms/form/eq5d5l/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Eq5d5l")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5d5lCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5d5lCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5d5lCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5d5lCollectionResponse)", StatusCode=500)
// @DataContract
export class Eq5d5lGetCollectionByCodes implements IReturn<Eq5d5lCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<Eq5d5lGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5d5lGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Eq5d5lCollectionResponse(); }
}

/**
* Form - Eq5d5l
*/
// @Route("/opms/form/eq5d5l/save", "POST")
// @Api(Description="Form - Eq5d5l")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=500)
// @DataContract
export class Eq5d5lPostSave implements IReturn<Eq5d5lSingleResponse>
{
    /**
    * The eq5d5l to save.
    */
    // @DataMember(Order=1)
    public form: Eq5d5l;

    public constructor(init?: Partial<Eq5d5lPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5d5lPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Eq5d5lSingleResponse(); }
}

/**
* Form - Eq5d5l
*/
// @Route("/opms/form/eq5d5l/save/ids", "POST")
// @Api(Description="Form - Eq5d5l")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=500)
// @DataContract
export class Eq5d5lPostSaveWithIds implements IReturn<Eq5d5lSingleResponse>
{
    /**
    * The eq5d5l to save.
    */
    // @DataMember(Order=1)
    public form: Eq5d5l;

    /**
    * The ID of the Institution associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the eq5d5l to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<Eq5d5lPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5d5lPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Eq5d5lSingleResponse(); }
}

/**
* Form - Eq5d5l
*/
// @Route("/opms/form/eq5d5l/save/codes", "POST")
// @Api(Description="Form - Eq5d5l")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5d5lSingleResponse)", StatusCode=500)
// @DataContract
export class Eq5d5lPostSaveWithCodes implements IReturn<Eq5d5lSingleResponse>
{
    /**
    * The eq5d5l to save.
    */
    // @DataMember(Order=1)
    public form: Eq5d5l;

    /**
    * The Code of the Institution associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the eq5d5l to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<Eq5d5lPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5d5lPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Eq5d5lSingleResponse(); }
}

/**
* Form - Eq5d5l
*/
// @Route("/opms/form/eq5d5l/delete", "DELETE")
// @Api(Description="Form - Eq5d5l")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5d5lDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5d5lDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5d5lDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5d5lDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5d5lDeleteResponse)", StatusCode=500)
// @DataContract
export class Eq5d5lDelete implements IReturn<Eq5d5lDeleteResponse>
{
    /**
    * The eq5d5l to delete.
    */
    // @DataMember(Order=1)
    public form: Eq5d5l;

    public constructor(init?: Partial<Eq5d5lDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5d5lDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new Eq5d5lDeleteResponse(); }
}

/**
* Form - Eq5d5l
*/
// @Route("/opms/form/eq5d5l/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Eq5d5l")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5d5lDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5d5lDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5d5lDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5d5lDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5d5lDeleteResponse)", StatusCode=500)
// @DataContract
export class Eq5d5lDeleteWithId implements IReturn<Eq5d5lDeleteResponse>
{
    /**
    * The ID of the eq5d5l to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<Eq5d5lDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5d5lDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new Eq5d5lDeleteResponse(); }
}

/**
* Form - Eq5d5l
*/
// @Route("/opms/form/eq5d5l/delete/ids", "DELETE")
// @Api(Description="Form - Eq5d5l")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5d5lDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5d5lDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5d5lDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5d5lDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5d5lDeleteResponse)", StatusCode=500)
// @DataContract
export class Eq5d5lDeleteWithIds implements IReturn<Eq5d5lDeleteResponse>
{
    /**
    * The Patient ID of the eq5d5l to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eq5d5l to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<Eq5d5lDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5d5lDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new Eq5d5lDeleteResponse(); }
}

/**
* Form - Eq5d5l
*/
// @Route("/opms/form/eq5d5l/delete/codes", "DELETE")
// @Api(Description="Form - Eq5d5l")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5d5lDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5d5lDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5d5lDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5d5lDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5d5lDeleteResponse)", StatusCode=500)
// @DataContract
export class Eq5d5lDeleteByCodes implements IReturn<Eq5d5lDeleteResponse>
{
    /**
    * The Patient Study Number of the eq5d5l to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eq5d5l to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<Eq5d5lDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5d5lDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new Eq5d5lDeleteResponse(); }
}

/**
* Form - Eq5d5l
*/
// @Route("/opms/form/eq5d5l/validate", "POST")
// @Api(Description="Form - Eq5d5l")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5d5lValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5d5lValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5d5lValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5d5lValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5d5lValidationResponse)", StatusCode=500)
// @DataContract
export class Eq5d5lPostValidate implements IReturn<Eq5d5lValidationResponse>
{
    /**
    * The eq5d5l to validate.
    */
    // @DataMember(Order=1)
    public form: Eq5d5l;

    public constructor(init?: Partial<Eq5d5lPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5d5lPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Eq5d5lValidationResponse(); }
}

/**
* Form - Eq5d5l
*/
// @Route("/opms/form/eq5d5l/validate/ids", "POST")
// @Api(Description="Form - Eq5d5l")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5d5lValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5d5lValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5d5lValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5d5lValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5d5lValidationResponse)", StatusCode=500)
// @DataContract
export class Eq5d5lPostValidateWithIds implements IReturn<Eq5d5lValidationResponse>
{
    /**
    * The eq5d5l to validate.
    */
    // @DataMember(Order=1)
    public form: Eq5d5l;

    /**
    * The ID of the Institution associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<Eq5d5lPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5d5lPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Eq5d5lValidationResponse(); }
}

/**
* Form - Eq5d5l
*/
// @Route("/opms/form/eq5d5l/validate/codes", "POST")
// @Api(Description="Form - Eq5d5l")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5d5lValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5d5lValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5d5lValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5d5lValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5d5lValidationResponse)", StatusCode=500)
// @DataContract
export class Eq5d5lPostValidateWithCodes implements IReturn<Eq5d5lValidationResponse>
{
    /**
    * The eq5d5l to validate.
    */
    // @DataMember(Order=1)
    public form: Eq5d5l;

    /**
    * The Code of the Institution associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eq5d5l to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<Eq5d5lPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5d5lPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Eq5d5lValidationResponse(); }
}

/**
* Form - Impact Of Mri
*/
// @Route("/opms/form/impact-of-mri/single/id/{Id}", "GET")
// @Api(Description="Form - Impact Of Mri")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=500)
// @DataContract
export class ImpactOfMriGetSingleById implements IReturn<ImpactOfMriSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the impact of mri to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ImpactOfMriGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImpactOfMriGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImpactOfMriSingleResponse(); }
}

/**
* Form - Impact Of Mri
*/
// @Route("/opms/form/impact-of-mri/single/ids", "GET")
// @Api(Description="Form - Impact Of Mri")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=500)
// @DataContract
export class ImpactOfMriGetSingleByIds implements IReturn<ImpactOfMriSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the impact of mri to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the impact of mri to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the impact of mri to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new impact of mri if no impact of mri is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ImpactOfMriGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImpactOfMriGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImpactOfMriSingleResponse(); }
}

/**
* Form - Impact Of Mri
*/
// @Route("/opms/form/impact-of-mri/single/codes", "GET")
// @Api(Description="Form - Impact Of Mri")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=500)
// @DataContract
export class ImpactOfMriGetSingleByCodes implements IReturn<ImpactOfMriSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the impact of mri to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the impact of mri to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the impact of mri to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new impact of mri if no impact of mri is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ImpactOfMriGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImpactOfMriGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImpactOfMriSingleResponse(); }
}

/**
* Form - Impact Of Mri
*/
// @Route("/opms/form/impact-of-mri/collection", "GET")
// @Api(Description="Form - Impact Of Mri")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImpactOfMriCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImpactOfMriCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImpactOfMriCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImpactOfMriCollectionResponse)", StatusCode=500)
// @DataContract
export class ImpactOfMriGetCollection implements IReturn<ImpactOfMriCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ImpactOfMriGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImpactOfMriGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImpactOfMriCollectionResponse(); }
}

/**
* Form - Impact Of Mri
*/
// @Route("/opms/form/impact-of-mri/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Impact Of Mri")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImpactOfMriCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImpactOfMriCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImpactOfMriCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImpactOfMriCollectionResponse)", StatusCode=500)
// @DataContract
export class ImpactOfMriGetCollectionByIds implements IReturn<ImpactOfMriCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the impact of mri to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ImpactOfMriGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImpactOfMriGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImpactOfMriCollectionResponse(); }
}

/**
* Form - Impact Of Mri
*/
// @Route("/opms/form/impact-of-mri/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Impact Of Mri")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImpactOfMriCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImpactOfMriCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImpactOfMriCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImpactOfMriCollectionResponse)", StatusCode=500)
// @DataContract
export class ImpactOfMriGetCollectionByCodes implements IReturn<ImpactOfMriCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ImpactOfMriGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImpactOfMriGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ImpactOfMriCollectionResponse(); }
}

/**
* Form - Impact Of Mri
*/
// @Route("/opms/form/impact-of-mri/save", "POST")
// @Api(Description="Form - Impact Of Mri")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=500)
// @DataContract
export class ImpactOfMriPostSave implements IReturn<ImpactOfMriSingleResponse>
{
    /**
    * The impact of mri to save.
    */
    // @DataMember(Order=1)
    public form: ImpactOfMri;

    public constructor(init?: Partial<ImpactOfMriPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImpactOfMriPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImpactOfMriSingleResponse(); }
}

/**
* Form - Impact Of Mri
*/
// @Route("/opms/form/impact-of-mri/save/ids", "POST")
// @Api(Description="Form - Impact Of Mri")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=500)
// @DataContract
export class ImpactOfMriPostSaveWithIds implements IReturn<ImpactOfMriSingleResponse>
{
    /**
    * The impact of mri to save.
    */
    // @DataMember(Order=1)
    public form: ImpactOfMri;

    /**
    * The ID of the Institution associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the impact of mri to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ImpactOfMriPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImpactOfMriPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImpactOfMriSingleResponse(); }
}

/**
* Form - Impact Of Mri
*/
// @Route("/opms/form/impact-of-mri/save/codes", "POST")
// @Api(Description="Form - Impact Of Mri")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImpactOfMriSingleResponse)", StatusCode=500)
// @DataContract
export class ImpactOfMriPostSaveWithCodes implements IReturn<ImpactOfMriSingleResponse>
{
    /**
    * The impact of mri to save.
    */
    // @DataMember(Order=1)
    public form: ImpactOfMri;

    /**
    * The Code of the Institution associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the impact of mri to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ImpactOfMriPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImpactOfMriPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImpactOfMriSingleResponse(); }
}

/**
* Form - Impact Of Mri
*/
// @Route("/opms/form/impact-of-mri/delete", "DELETE")
// @Api(Description="Form - Impact Of Mri")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImpactOfMriDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImpactOfMriDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImpactOfMriDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImpactOfMriDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImpactOfMriDeleteResponse)", StatusCode=500)
// @DataContract
export class ImpactOfMriDelete implements IReturn<ImpactOfMriDeleteResponse>
{
    /**
    * The impact of mri to delete.
    */
    // @DataMember(Order=1)
    public form: ImpactOfMri;

    public constructor(init?: Partial<ImpactOfMriDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImpactOfMriDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ImpactOfMriDeleteResponse(); }
}

/**
* Form - Impact Of Mri
*/
// @Route("/opms/form/impact-of-mri/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Impact Of Mri")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImpactOfMriDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImpactOfMriDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImpactOfMriDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImpactOfMriDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImpactOfMriDeleteResponse)", StatusCode=500)
// @DataContract
export class ImpactOfMriDeleteWithId implements IReturn<ImpactOfMriDeleteResponse>
{
    /**
    * The ID of the impact of mri to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ImpactOfMriDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImpactOfMriDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ImpactOfMriDeleteResponse(); }
}

/**
* Form - Impact Of Mri
*/
// @Route("/opms/form/impact-of-mri/delete/ids", "DELETE")
// @Api(Description="Form - Impact Of Mri")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImpactOfMriDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImpactOfMriDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImpactOfMriDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImpactOfMriDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImpactOfMriDeleteResponse)", StatusCode=500)
// @DataContract
export class ImpactOfMriDeleteWithIds implements IReturn<ImpactOfMriDeleteResponse>
{
    /**
    * The Patient ID of the impact of mri to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the impact of mri to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ImpactOfMriDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImpactOfMriDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ImpactOfMriDeleteResponse(); }
}

/**
* Form - Impact Of Mri
*/
// @Route("/opms/form/impact-of-mri/delete/codes", "DELETE")
// @Api(Description="Form - Impact Of Mri")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImpactOfMriDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImpactOfMriDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImpactOfMriDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImpactOfMriDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImpactOfMriDeleteResponse)", StatusCode=500)
// @DataContract
export class ImpactOfMriDeleteByCodes implements IReturn<ImpactOfMriDeleteResponse>
{
    /**
    * The Patient Study Number of the impact of mri to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the impact of mri to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ImpactOfMriDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImpactOfMriDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ImpactOfMriDeleteResponse(); }
}

/**
* Form - Impact Of Mri
*/
// @Route("/opms/form/impact-of-mri/validate", "POST")
// @Api(Description="Form - Impact Of Mri")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImpactOfMriValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImpactOfMriValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImpactOfMriValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImpactOfMriValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImpactOfMriValidationResponse)", StatusCode=500)
// @DataContract
export class ImpactOfMriPostValidate implements IReturn<ImpactOfMriValidationResponse>
{
    /**
    * The impact of mri to validate.
    */
    // @DataMember(Order=1)
    public form: ImpactOfMri;

    public constructor(init?: Partial<ImpactOfMriPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImpactOfMriPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImpactOfMriValidationResponse(); }
}

/**
* Form - Impact Of Mri
*/
// @Route("/opms/form/impact-of-mri/validate/ids", "POST")
// @Api(Description="Form - Impact Of Mri")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImpactOfMriValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImpactOfMriValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImpactOfMriValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImpactOfMriValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImpactOfMriValidationResponse)", StatusCode=500)
// @DataContract
export class ImpactOfMriPostValidateWithIds implements IReturn<ImpactOfMriValidationResponse>
{
    /**
    * The impact of mri to validate.
    */
    // @DataMember(Order=1)
    public form: ImpactOfMri;

    /**
    * The ID of the Institution associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ImpactOfMriPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImpactOfMriPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImpactOfMriValidationResponse(); }
}

/**
* Form - Impact Of Mri
*/
// @Route("/opms/form/impact-of-mri/validate/codes", "POST")
// @Api(Description="Form - Impact Of Mri")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ImpactOfMriValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ImpactOfMriValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ImpactOfMriValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ImpactOfMriValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ImpactOfMriValidationResponse)", StatusCode=500)
// @DataContract
export class ImpactOfMriPostValidateWithCodes implements IReturn<ImpactOfMriValidationResponse>
{
    /**
    * The impact of mri to validate.
    */
    // @DataMember(Order=1)
    public form: ImpactOfMri;

    /**
    * The Code of the Institution associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the impact of mri to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ImpactOfMriPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ImpactOfMriPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ImpactOfMriValidationResponse(); }
}

/**
* Form - Preference For Surgery
*/
// @Route("/opms/form/preference-for-surgery/single/id/{Id}", "GET")
// @Api(Description="Form - Preference For Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=500)
// @DataContract
export class PreferenceForSurgeryGetSingleById implements IReturn<PreferenceForSurgerySingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the preference for surgery to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PreferenceForSurgeryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreferenceForSurgeryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreferenceForSurgerySingleResponse(); }
}

/**
* Form - Preference For Surgery
*/
// @Route("/opms/form/preference-for-surgery/single/ids", "GET")
// @Api(Description="Form - Preference For Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=500)
// @DataContract
export class PreferenceForSurgeryGetSingleByIds implements IReturn<PreferenceForSurgerySingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the preference for surgery to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the preference for surgery to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the preference for surgery to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new preference for surgery if no preference for surgery is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PreferenceForSurgeryGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreferenceForSurgeryGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreferenceForSurgerySingleResponse(); }
}

/**
* Form - Preference For Surgery
*/
// @Route("/opms/form/preference-for-surgery/single/codes", "GET")
// @Api(Description="Form - Preference For Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=500)
// @DataContract
export class PreferenceForSurgeryGetSingleByCodes implements IReturn<PreferenceForSurgerySingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the preference for surgery to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the preference for surgery to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the preference for surgery to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new preference for surgery if no preference for surgery is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PreferenceForSurgeryGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreferenceForSurgeryGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreferenceForSurgerySingleResponse(); }
}

/**
* Form - Preference For Surgery
*/
// @Route("/opms/form/preference-for-surgery/collection", "GET")
// @Api(Description="Form - Preference For Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryCollectionResponse)", StatusCode=500)
// @DataContract
export class PreferenceForSurgeryGetCollection implements IReturn<PreferenceForSurgeryCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<PreferenceForSurgeryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreferenceForSurgeryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreferenceForSurgeryCollectionResponse(); }
}

/**
* Form - Preference For Surgery
*/
// @Route("/opms/form/preference-for-surgery/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Preference For Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryCollectionResponse)", StatusCode=500)
// @DataContract
export class PreferenceForSurgeryGetCollectionByIds implements IReturn<PreferenceForSurgeryCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the preference for surgery to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PreferenceForSurgeryGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreferenceForSurgeryGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreferenceForSurgeryCollectionResponse(); }
}

/**
* Form - Preference For Surgery
*/
// @Route("/opms/form/preference-for-surgery/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Preference For Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryCollectionResponse)", StatusCode=500)
// @DataContract
export class PreferenceForSurgeryGetCollectionByCodes implements IReturn<PreferenceForSurgeryCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PreferenceForSurgeryGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreferenceForSurgeryGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreferenceForSurgeryCollectionResponse(); }
}

/**
* Form - Preference For Surgery
*/
// @Route("/opms/form/preference-for-surgery/save", "POST")
// @Api(Description="Form - Preference For Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=500)
// @DataContract
export class PreferenceForSurgeryPostSave implements IReturn<PreferenceForSurgerySingleResponse>
{
    /**
    * The preference for surgery to save.
    */
    // @DataMember(Order=1)
    public form: PreferenceForSurgery;

    public constructor(init?: Partial<PreferenceForSurgeryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreferenceForSurgeryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreferenceForSurgerySingleResponse(); }
}

/**
* Form - Preference For Surgery
*/
// @Route("/opms/form/preference-for-surgery/save/ids", "POST")
// @Api(Description="Form - Preference For Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=500)
// @DataContract
export class PreferenceForSurgeryPostSaveWithIds implements IReturn<PreferenceForSurgerySingleResponse>
{
    /**
    * The preference for surgery to save.
    */
    // @DataMember(Order=1)
    public form: PreferenceForSurgery;

    /**
    * The ID of the Institution associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the preference for surgery to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PreferenceForSurgeryPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreferenceForSurgeryPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreferenceForSurgerySingleResponse(); }
}

/**
* Form - Preference For Surgery
*/
// @Route("/opms/form/preference-for-surgery/save/codes", "POST")
// @Api(Description="Form - Preference For Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreferenceForSurgerySingleResponse)", StatusCode=500)
// @DataContract
export class PreferenceForSurgeryPostSaveWithCodes implements IReturn<PreferenceForSurgerySingleResponse>
{
    /**
    * The preference for surgery to save.
    */
    // @DataMember(Order=1)
    public form: PreferenceForSurgery;

    /**
    * The Code of the Institution associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the preference for surgery to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PreferenceForSurgeryPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreferenceForSurgeryPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreferenceForSurgerySingleResponse(); }
}

/**
* Form - Preference For Surgery
*/
// @Route("/opms/form/preference-for-surgery/delete", "DELETE")
// @Api(Description="Form - Preference For Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryDeleteResponse)", StatusCode=500)
// @DataContract
export class PreferenceForSurgeryDelete implements IReturn<PreferenceForSurgeryDeleteResponse>
{
    /**
    * The preference for surgery to delete.
    */
    // @DataMember(Order=1)
    public form: PreferenceForSurgery;

    public constructor(init?: Partial<PreferenceForSurgeryDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreferenceForSurgeryDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PreferenceForSurgeryDeleteResponse(); }
}

/**
* Form - Preference For Surgery
*/
// @Route("/opms/form/preference-for-surgery/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Preference For Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryDeleteResponse)", StatusCode=500)
// @DataContract
export class PreferenceForSurgeryDeleteWithId implements IReturn<PreferenceForSurgeryDeleteResponse>
{
    /**
    * The ID of the preference for surgery to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PreferenceForSurgeryDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreferenceForSurgeryDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PreferenceForSurgeryDeleteResponse(); }
}

/**
* Form - Preference For Surgery
*/
// @Route("/opms/form/preference-for-surgery/delete/ids", "DELETE")
// @Api(Description="Form - Preference For Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryDeleteResponse)", StatusCode=500)
// @DataContract
export class PreferenceForSurgeryDeleteWithIds implements IReturn<PreferenceForSurgeryDeleteResponse>
{
    /**
    * The Patient ID of the preference for surgery to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the preference for surgery to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PreferenceForSurgeryDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreferenceForSurgeryDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PreferenceForSurgeryDeleteResponse(); }
}

/**
* Form - Preference For Surgery
*/
// @Route("/opms/form/preference-for-surgery/delete/codes", "DELETE")
// @Api(Description="Form - Preference For Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryDeleteResponse)", StatusCode=500)
// @DataContract
export class PreferenceForSurgeryDeleteByCodes implements IReturn<PreferenceForSurgeryDeleteResponse>
{
    /**
    * The Patient Study Number of the preference for surgery to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the preference for surgery to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PreferenceForSurgeryDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreferenceForSurgeryDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PreferenceForSurgeryDeleteResponse(); }
}

/**
* Form - Preference For Surgery
*/
// @Route("/opms/form/preference-for-surgery/validate", "POST")
// @Api(Description="Form - Preference For Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryValidationResponse)", StatusCode=500)
// @DataContract
export class PreferenceForSurgeryPostValidate implements IReturn<PreferenceForSurgeryValidationResponse>
{
    /**
    * The preference for surgery to validate.
    */
    // @DataMember(Order=1)
    public form: PreferenceForSurgery;

    public constructor(init?: Partial<PreferenceForSurgeryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreferenceForSurgeryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreferenceForSurgeryValidationResponse(); }
}

/**
* Form - Preference For Surgery
*/
// @Route("/opms/form/preference-for-surgery/validate/ids", "POST")
// @Api(Description="Form - Preference For Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryValidationResponse)", StatusCode=500)
// @DataContract
export class PreferenceForSurgeryPostValidateWithIds implements IReturn<PreferenceForSurgeryValidationResponse>
{
    /**
    * The preference for surgery to validate.
    */
    // @DataMember(Order=1)
    public form: PreferenceForSurgery;

    /**
    * The ID of the Institution associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PreferenceForSurgeryPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreferenceForSurgeryPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreferenceForSurgeryValidationResponse(); }
}

/**
* Form - Preference For Surgery
*/
// @Route("/opms/form/preference-for-surgery/validate/codes", "POST")
// @Api(Description="Form - Preference For Surgery")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreferenceForSurgeryValidationResponse)", StatusCode=500)
// @DataContract
export class PreferenceForSurgeryPostValidateWithCodes implements IReturn<PreferenceForSurgeryValidationResponse>
{
    /**
    * The preference for surgery to validate.
    */
    // @DataMember(Order=1)
    public form: PreferenceForSurgery;

    /**
    * The Code of the Institution associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the preference for surgery to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PreferenceForSurgeryPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreferenceForSurgeryPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreferenceForSurgeryValidationResponse(); }
}

/**
* Form - Breast Imaging Lesion
*/
// @Route("/opms/form/breast-imaging-lesion/single/id/{Id}", "GET")
// @Api(Description="Form - Breast Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BreastImagingLesionGetSingleById implements IReturn<BreastImagingLesionSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BreastImagingLesionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingLesionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastImagingLesionSingleResponse(); }
}

/**
* Form - Breast Imaging Lesion
*/
// @Route("/opms/form/breast-imaging-lesion/single/ids", "GET")
// @Api(Description="Form - Breast Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BreastImagingLesionGetSingleByIds implements IReturn<BreastImagingLesionSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new breast imaging lesion if no breast imaging lesion is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<BreastImagingLesionGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingLesionGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastImagingLesionSingleResponse(); }
}

/**
* Form - Breast Imaging Lesion
*/
// @Route("/opms/form/breast-imaging-lesion/single/codes", "GET")
// @Api(Description="Form - Breast Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BreastImagingLesionGetSingleByCodes implements IReturn<BreastImagingLesionSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new breast imaging lesion if no breast imaging lesion is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<BreastImagingLesionGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingLesionGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastImagingLesionSingleResponse(); }
}

/**
* Form - Breast Imaging Lesion
*/
// @Route("/opms/form/breast-imaging-lesion/collection", "GET")
// @Api(Description="Form - Breast Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastImagingLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastImagingLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastImagingLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastImagingLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class BreastImagingLesionGetCollection implements IReturn<BreastImagingLesionCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<BreastImagingLesionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingLesionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastImagingLesionCollectionResponse(); }
}

/**
* Form - Breast Imaging Lesion
*/
// @Route("/opms/form/breast-imaging-lesion/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Breast Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastImagingLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastImagingLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastImagingLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastImagingLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class BreastImagingLesionGetCollectionByIds implements IReturn<BreastImagingLesionCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<BreastImagingLesionGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingLesionGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastImagingLesionCollectionResponse(); }
}

/**
* Form - Breast Imaging Lesion
*/
// @Route("/opms/form/breast-imaging-lesion/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Breast Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastImagingLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastImagingLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastImagingLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastImagingLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class BreastImagingLesionGetCollectionByCodes implements IReturn<BreastImagingLesionCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<BreastImagingLesionGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingLesionGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastImagingLesionCollectionResponse(); }
}

/**
* Form - Breast Imaging Lesion
*/
// @Route("/opms/form/breast-imaging-lesion/save", "POST")
// @Api(Description="Form - Breast Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BreastImagingLesionPostSave implements IReturn<BreastImagingLesionSingleResponse>
{
    /**
    * The breast imaging lesion to save.
    */
    // @DataMember(Order=1)
    public form: BreastImagingLesion;

    public constructor(init?: Partial<BreastImagingLesionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingLesionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastImagingLesionSingleResponse(); }
}

/**
* Form - Breast Imaging Lesion
*/
// @Route("/opms/form/breast-imaging-lesion/save/ids", "POST")
// @Api(Description="Form - Breast Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BreastImagingLesionPostSaveWithIds implements IReturn<BreastImagingLesionSingleResponse>
{
    /**
    * The breast imaging lesion to save.
    */
    // @DataMember(Order=1)
    public form: BreastImagingLesion;

    /**
    * The ID of the Institution associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<BreastImagingLesionPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingLesionPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastImagingLesionSingleResponse(); }
}

/**
* Form - Breast Imaging Lesion
*/
// @Route("/opms/form/breast-imaging-lesion/save/codes", "POST")
// @Api(Description="Form - Breast Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BreastImagingLesionPostSaveWithCodes implements IReturn<BreastImagingLesionSingleResponse>
{
    /**
    * The breast imaging lesion to save.
    */
    // @DataMember(Order=1)
    public form: BreastImagingLesion;

    /**
    * The Code of the Institution associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<BreastImagingLesionPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingLesionPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastImagingLesionSingleResponse(); }
}

/**
* Form - Breast Imaging Lesion
*/
// @Route("/opms/form/breast-imaging-lesion/delete", "DELETE")
// @Api(Description="Form - Breast Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastImagingLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastImagingLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastImagingLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastImagingLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastImagingLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class BreastImagingLesionDelete implements IReturn<BreastImagingLesionDeleteResponse>
{
    /**
    * The breast imaging lesion to delete.
    */
    // @DataMember(Order=1)
    public form: BreastImagingLesion;

    public constructor(init?: Partial<BreastImagingLesionDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingLesionDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BreastImagingLesionDeleteResponse(); }
}

/**
* Form - Breast Imaging Lesion
*/
// @Route("/opms/form/breast-imaging-lesion/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Breast Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastImagingLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastImagingLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastImagingLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastImagingLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastImagingLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class BreastImagingLesionDeleteWithId implements IReturn<BreastImagingLesionDeleteResponse>
{
    /**
    * The ID of the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BreastImagingLesionDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingLesionDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BreastImagingLesionDeleteResponse(); }
}

/**
* Form - Breast Imaging Lesion
*/
// @Route("/opms/form/breast-imaging-lesion/delete/ids", "DELETE")
// @Api(Description="Form - Breast Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastImagingLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastImagingLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastImagingLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastImagingLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastImagingLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class BreastImagingLesionDeleteWithIds implements IReturn<BreastImagingLesionDeleteResponse>
{
    /**
    * The Patient ID of the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<BreastImagingLesionDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingLesionDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BreastImagingLesionDeleteResponse(); }
}

/**
* Form - Breast Imaging Lesion
*/
// @Route("/opms/form/breast-imaging-lesion/delete/codes", "DELETE")
// @Api(Description="Form - Breast Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastImagingLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastImagingLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastImagingLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastImagingLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastImagingLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class BreastImagingLesionDeleteByCodes implements IReturn<BreastImagingLesionDeleteResponse>
{
    /**
    * The Patient Study Number of the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<BreastImagingLesionDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingLesionDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BreastImagingLesionDeleteResponse(); }
}

/**
* Form - Breast Imaging Lesion
*/
// @Route("/opms/form/breast-imaging-lesion/validate", "POST")
// @Api(Description="Form - Breast Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastImagingLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastImagingLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastImagingLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastImagingLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastImagingLesionValidationResponse)", StatusCode=500)
// @DataContract
export class BreastImagingLesionPostValidate implements IReturn<BreastImagingLesionValidationResponse>
{
    /**
    * The breast imaging lesion to validate.
    */
    // @DataMember(Order=1)
    public form: BreastImagingLesion;

    public constructor(init?: Partial<BreastImagingLesionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingLesionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastImagingLesionValidationResponse(); }
}

/**
* Form - Breast Imaging Lesion
*/
// @Route("/opms/form/breast-imaging-lesion/validate/ids", "POST")
// @Api(Description="Form - Breast Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastImagingLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastImagingLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastImagingLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastImagingLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastImagingLesionValidationResponse)", StatusCode=500)
// @DataContract
export class BreastImagingLesionPostValidateWithIds implements IReturn<BreastImagingLesionValidationResponse>
{
    /**
    * The breast imaging lesion to validate.
    */
    // @DataMember(Order=1)
    public form: BreastImagingLesion;

    /**
    * The ID of the Institution associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<BreastImagingLesionPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingLesionPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastImagingLesionValidationResponse(); }
}

/**
* Form - Breast Imaging Lesion
*/
// @Route("/opms/form/breast-imaging-lesion/validate/codes", "POST")
// @Api(Description="Form - Breast Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastImagingLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastImagingLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastImagingLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastImagingLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastImagingLesionValidationResponse)", StatusCode=500)
// @DataContract
export class BreastImagingLesionPostValidateWithCodes implements IReturn<BreastImagingLesionValidationResponse>
{
    /**
    * The breast imaging lesion to validate.
    */
    // @DataMember(Order=1)
    public form: BreastImagingLesion;

    /**
    * The Code of the Institution associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the breast imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<BreastImagingLesionPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastImagingLesionPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastImagingLesionValidationResponse(); }
}

/**
* Form - Breast Mammogram Lesion
*/
// @Route("/opms/form/breast-mammogram-lesion/single/id/{Id}", "GET")
// @Api(Description="Form - Breast Mammogram Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BreastMammogramLesionGetSingleById implements IReturn<BreastMammogramLesionSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BreastMammogramLesionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastMammogramLesionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastMammogramLesionSingleResponse(); }
}

/**
* Form - Breast Mammogram Lesion
*/
// @Route("/opms/form/breast-mammogram-lesion/single/ids", "GET")
// @Api(Description="Form - Breast Mammogram Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BreastMammogramLesionGetSingleByIds implements IReturn<BreastMammogramLesionSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new breast mammogram lesion if no breast mammogram lesion is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<BreastMammogramLesionGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastMammogramLesionGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastMammogramLesionSingleResponse(); }
}

/**
* Form - Breast Mammogram Lesion
*/
// @Route("/opms/form/breast-mammogram-lesion/single/codes", "GET")
// @Api(Description="Form - Breast Mammogram Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BreastMammogramLesionGetSingleByCodes implements IReturn<BreastMammogramLesionSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new breast mammogram lesion if no breast mammogram lesion is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<BreastMammogramLesionGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastMammogramLesionGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastMammogramLesionSingleResponse(); }
}

/**
* Form - Breast Mammogram Lesion
*/
// @Route("/opms/form/breast-mammogram-lesion/collection", "GET")
// @Api(Description="Form - Breast Mammogram Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class BreastMammogramLesionGetCollection implements IReturn<BreastMammogramLesionCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<BreastMammogramLesionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastMammogramLesionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastMammogramLesionCollectionResponse(); }
}

/**
* Form - Breast Mammogram Lesion
*/
// @Route("/opms/form/breast-mammogram-lesion/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Breast Mammogram Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class BreastMammogramLesionGetCollectionByIds implements IReturn<BreastMammogramLesionCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<BreastMammogramLesionGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastMammogramLesionGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastMammogramLesionCollectionResponse(); }
}

/**
* Form - Breast Mammogram Lesion
*/
// @Route("/opms/form/breast-mammogram-lesion/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Breast Mammogram Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class BreastMammogramLesionGetCollectionByCodes implements IReturn<BreastMammogramLesionCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<BreastMammogramLesionGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastMammogramLesionGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new BreastMammogramLesionCollectionResponse(); }
}

/**
* Form - Breast Mammogram Lesion
*/
// @Route("/opms/form/breast-mammogram-lesion/save", "POST")
// @Api(Description="Form - Breast Mammogram Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BreastMammogramLesionPostSave implements IReturn<BreastMammogramLesionSingleResponse>
{
    /**
    * The breast mammogram lesion to save.
    */
    // @DataMember(Order=1)
    public form: BreastMammogramLesion;

    public constructor(init?: Partial<BreastMammogramLesionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastMammogramLesionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastMammogramLesionSingleResponse(); }
}

/**
* Form - Breast Mammogram Lesion
*/
// @Route("/opms/form/breast-mammogram-lesion/save/ids", "POST")
// @Api(Description="Form - Breast Mammogram Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BreastMammogramLesionPostSaveWithIds implements IReturn<BreastMammogramLesionSingleResponse>
{
    /**
    * The breast mammogram lesion to save.
    */
    // @DataMember(Order=1)
    public form: BreastMammogramLesion;

    /**
    * The ID of the Institution associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<BreastMammogramLesionPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastMammogramLesionPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastMammogramLesionSingleResponse(); }
}

/**
* Form - Breast Mammogram Lesion
*/
// @Route("/opms/form/breast-mammogram-lesion/save/codes", "POST")
// @Api(Description="Form - Breast Mammogram Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionSingleResponse)", StatusCode=500)
// @DataContract
export class BreastMammogramLesionPostSaveWithCodes implements IReturn<BreastMammogramLesionSingleResponse>
{
    /**
    * The breast mammogram lesion to save.
    */
    // @DataMember(Order=1)
    public form: BreastMammogramLesion;

    /**
    * The Code of the Institution associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<BreastMammogramLesionPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastMammogramLesionPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastMammogramLesionSingleResponse(); }
}

/**
* Form - Breast Mammogram Lesion
*/
// @Route("/opms/form/breast-mammogram-lesion/delete", "DELETE")
// @Api(Description="Form - Breast Mammogram Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class BreastMammogramLesionDelete implements IReturn<BreastMammogramLesionDeleteResponse>
{
    /**
    * The breast mammogram lesion to delete.
    */
    // @DataMember(Order=1)
    public form: BreastMammogramLesion;

    public constructor(init?: Partial<BreastMammogramLesionDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastMammogramLesionDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BreastMammogramLesionDeleteResponse(); }
}

/**
* Form - Breast Mammogram Lesion
*/
// @Route("/opms/form/breast-mammogram-lesion/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Breast Mammogram Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class BreastMammogramLesionDeleteWithId implements IReturn<BreastMammogramLesionDeleteResponse>
{
    /**
    * The ID of the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<BreastMammogramLesionDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastMammogramLesionDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BreastMammogramLesionDeleteResponse(); }
}

/**
* Form - Breast Mammogram Lesion
*/
// @Route("/opms/form/breast-mammogram-lesion/delete/ids", "DELETE")
// @Api(Description="Form - Breast Mammogram Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class BreastMammogramLesionDeleteWithIds implements IReturn<BreastMammogramLesionDeleteResponse>
{
    /**
    * The Patient ID of the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<BreastMammogramLesionDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastMammogramLesionDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BreastMammogramLesionDeleteResponse(); }
}

/**
* Form - Breast Mammogram Lesion
*/
// @Route("/opms/form/breast-mammogram-lesion/delete/codes", "DELETE")
// @Api(Description="Form - Breast Mammogram Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class BreastMammogramLesionDeleteByCodes implements IReturn<BreastMammogramLesionDeleteResponse>
{
    /**
    * The Patient Study Number of the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<BreastMammogramLesionDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastMammogramLesionDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new BreastMammogramLesionDeleteResponse(); }
}

/**
* Form - Breast Mammogram Lesion
*/
// @Route("/opms/form/breast-mammogram-lesion/validate", "POST")
// @Api(Description="Form - Breast Mammogram Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionValidationResponse)", StatusCode=500)
// @DataContract
export class BreastMammogramLesionPostValidate implements IReturn<BreastMammogramLesionValidationResponse>
{
    /**
    * The breast mammogram lesion to validate.
    */
    // @DataMember(Order=1)
    public form: BreastMammogramLesion;

    public constructor(init?: Partial<BreastMammogramLesionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastMammogramLesionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastMammogramLesionValidationResponse(); }
}

/**
* Form - Breast Mammogram Lesion
*/
// @Route("/opms/form/breast-mammogram-lesion/validate/ids", "POST")
// @Api(Description="Form - Breast Mammogram Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionValidationResponse)", StatusCode=500)
// @DataContract
export class BreastMammogramLesionPostValidateWithIds implements IReturn<BreastMammogramLesionValidationResponse>
{
    /**
    * The breast mammogram lesion to validate.
    */
    // @DataMember(Order=1)
    public form: BreastMammogramLesion;

    /**
    * The ID of the Institution associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<BreastMammogramLesionPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastMammogramLesionPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastMammogramLesionValidationResponse(); }
}

/**
* Form - Breast Mammogram Lesion
*/
// @Route("/opms/form/breast-mammogram-lesion/validate/codes", "POST")
// @Api(Description="Form - Breast Mammogram Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.BreastMammogramLesionValidationResponse)", StatusCode=500)
// @DataContract
export class BreastMammogramLesionPostValidateWithCodes implements IReturn<BreastMammogramLesionValidationResponse>
{
    /**
    * The breast mammogram lesion to validate.
    */
    // @DataMember(Order=1)
    public form: BreastMammogramLesion;

    /**
    * The Code of the Institution associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the breast mammogram lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<BreastMammogramLesionPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'BreastMammogramLesionPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new BreastMammogramLesionValidationResponse(); }
}

/**
* Form - Diagnostic And Imaging
*/
// @Route("/opms/form/diagnostic-and-imaging/single/id/{Id}", "GET")
// @Api(Description="Form - Diagnostic And Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndImagingGetSingleById implements IReturn<DiagnosticAndImagingSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DiagnosticAndImagingGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndImagingGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiagnosticAndImagingSingleResponse(); }
}

/**
* Form - Diagnostic And Imaging
*/
// @Route("/opms/form/diagnostic-and-imaging/single/ids", "GET")
// @Api(Description="Form - Diagnostic And Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndImagingGetSingleByIds implements IReturn<DiagnosticAndImagingSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new diagnostic and imaging if no diagnostic and imaging is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DiagnosticAndImagingGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndImagingGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiagnosticAndImagingSingleResponse(); }
}

/**
* Form - Diagnostic And Imaging
*/
// @Route("/opms/form/diagnostic-and-imaging/single/codes", "GET")
// @Api(Description="Form - Diagnostic And Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndImagingGetSingleByCodes implements IReturn<DiagnosticAndImagingSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new diagnostic and imaging if no diagnostic and imaging is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DiagnosticAndImagingGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndImagingGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiagnosticAndImagingSingleResponse(); }
}

/**
* Form - Diagnostic And Imaging
*/
// @Route("/opms/form/diagnostic-and-imaging/collection", "GET")
// @Api(Description="Form - Diagnostic And Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingCollectionResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndImagingGetCollection implements IReturn<DiagnosticAndImagingCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<DiagnosticAndImagingGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndImagingGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiagnosticAndImagingCollectionResponse(); }
}

/**
* Form - Diagnostic And Imaging
*/
// @Route("/opms/form/diagnostic-and-imaging/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Diagnostic And Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingCollectionResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndImagingGetCollectionByIds implements IReturn<DiagnosticAndImagingCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DiagnosticAndImagingGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndImagingGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiagnosticAndImagingCollectionResponse(); }
}

/**
* Form - Diagnostic And Imaging
*/
// @Route("/opms/form/diagnostic-and-imaging/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Diagnostic And Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingCollectionResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndImagingGetCollectionByCodes implements IReturn<DiagnosticAndImagingCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DiagnosticAndImagingGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndImagingGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiagnosticAndImagingCollectionResponse(); }
}

/**
* Form - Diagnostic And Imaging
*/
// @Route("/opms/form/diagnostic-and-imaging/save", "POST")
// @Api(Description="Form - Diagnostic And Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndImagingPostSave implements IReturn<DiagnosticAndImagingSingleResponse>
{
    /**
    * The diagnostic and imaging to save.
    */
    // @DataMember(Order=1)
    public form: DiagnosticAndImaging;

    public constructor(init?: Partial<DiagnosticAndImagingPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndImagingPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiagnosticAndImagingSingleResponse(); }
}

/**
* Form - Diagnostic And Imaging
*/
// @Route("/opms/form/diagnostic-and-imaging/save/ids", "POST")
// @Api(Description="Form - Diagnostic And Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndImagingPostSaveWithIds implements IReturn<DiagnosticAndImagingSingleResponse>
{
    /**
    * The diagnostic and imaging to save.
    */
    // @DataMember(Order=1)
    public form: DiagnosticAndImaging;

    /**
    * The ID of the Institution associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DiagnosticAndImagingPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndImagingPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiagnosticAndImagingSingleResponse(); }
}

/**
* Form - Diagnostic And Imaging
*/
// @Route("/opms/form/diagnostic-and-imaging/save/codes", "POST")
// @Api(Description="Form - Diagnostic And Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingSingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndImagingPostSaveWithCodes implements IReturn<DiagnosticAndImagingSingleResponse>
{
    /**
    * The diagnostic and imaging to save.
    */
    // @DataMember(Order=1)
    public form: DiagnosticAndImaging;

    /**
    * The Code of the Institution associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DiagnosticAndImagingPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndImagingPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiagnosticAndImagingSingleResponse(); }
}

/**
* Form - Diagnostic And Imaging
*/
// @Route("/opms/form/diagnostic-and-imaging/delete", "DELETE")
// @Api(Description="Form - Diagnostic And Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingDeleteResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndImagingDelete implements IReturn<DiagnosticAndImagingDeleteResponse>
{
    /**
    * The diagnostic and imaging to delete.
    */
    // @DataMember(Order=1)
    public form: DiagnosticAndImaging;

    public constructor(init?: Partial<DiagnosticAndImagingDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndImagingDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DiagnosticAndImagingDeleteResponse(); }
}

/**
* Form - Diagnostic And Imaging
*/
// @Route("/opms/form/diagnostic-and-imaging/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Diagnostic And Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingDeleteResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndImagingDeleteWithId implements IReturn<DiagnosticAndImagingDeleteResponse>
{
    /**
    * The ID of the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DiagnosticAndImagingDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndImagingDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DiagnosticAndImagingDeleteResponse(); }
}

/**
* Form - Diagnostic And Imaging
*/
// @Route("/opms/form/diagnostic-and-imaging/delete/ids", "DELETE")
// @Api(Description="Form - Diagnostic And Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingDeleteResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndImagingDeleteWithIds implements IReturn<DiagnosticAndImagingDeleteResponse>
{
    /**
    * The Patient ID of the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DiagnosticAndImagingDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndImagingDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DiagnosticAndImagingDeleteResponse(); }
}

/**
* Form - Diagnostic And Imaging
*/
// @Route("/opms/form/diagnostic-and-imaging/delete/codes", "DELETE")
// @Api(Description="Form - Diagnostic And Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingDeleteResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndImagingDeleteByCodes implements IReturn<DiagnosticAndImagingDeleteResponse>
{
    /**
    * The Patient Study Number of the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DiagnosticAndImagingDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndImagingDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DiagnosticAndImagingDeleteResponse(); }
}

/**
* Form - Diagnostic And Imaging
*/
// @Route("/opms/form/diagnostic-and-imaging/validate", "POST")
// @Api(Description="Form - Diagnostic And Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingValidationResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndImagingPostValidate implements IReturn<DiagnosticAndImagingValidationResponse>
{
    /**
    * The diagnostic and imaging to validate.
    */
    // @DataMember(Order=1)
    public form: DiagnosticAndImaging;

    public constructor(init?: Partial<DiagnosticAndImagingPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndImagingPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiagnosticAndImagingValidationResponse(); }
}

/**
* Form - Diagnostic And Imaging
*/
// @Route("/opms/form/diagnostic-and-imaging/validate/ids", "POST")
// @Api(Description="Form - Diagnostic And Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingValidationResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndImagingPostValidateWithIds implements IReturn<DiagnosticAndImagingValidationResponse>
{
    /**
    * The diagnostic and imaging to validate.
    */
    // @DataMember(Order=1)
    public form: DiagnosticAndImaging;

    /**
    * The ID of the Institution associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DiagnosticAndImagingPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndImagingPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiagnosticAndImagingValidationResponse(); }
}

/**
* Form - Diagnostic And Imaging
*/
// @Route("/opms/form/diagnostic-and-imaging/validate/codes", "POST")
// @Api(Description="Form - Diagnostic And Imaging")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndImagingValidationResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndImagingPostValidateWithCodes implements IReturn<DiagnosticAndImagingValidationResponse>
{
    /**
    * The diagnostic and imaging to validate.
    */
    // @DataMember(Order=1)
    public form: DiagnosticAndImaging;

    /**
    * The Code of the Institution associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the diagnostic and imaging to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DiagnosticAndImagingPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndImagingPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiagnosticAndImagingValidationResponse(); }
}

/**
* Form - Diagnostic And Pathology
*/
// @Route("/opms/form/diagnostic-and-pathology/single/id/{Id}", "GET")
// @Api(Description="Form - Diagnostic And Pathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndPathologyGetSingleById implements IReturn<DiagnosticAndPathologySingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DiagnosticAndPathologyGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndPathologyGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiagnosticAndPathologySingleResponse(); }
}

/**
* Form - Diagnostic And Pathology
*/
// @Route("/opms/form/diagnostic-and-pathology/single/ids", "GET")
// @Api(Description="Form - Diagnostic And Pathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndPathologyGetSingleByIds implements IReturn<DiagnosticAndPathologySingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new diagnostic and pathology if no diagnostic and pathology is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DiagnosticAndPathologyGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndPathologyGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiagnosticAndPathologySingleResponse(); }
}

/**
* Form - Diagnostic And Pathology
*/
// @Route("/opms/form/diagnostic-and-pathology/single/codes", "GET")
// @Api(Description="Form - Diagnostic And Pathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndPathologyGetSingleByCodes implements IReturn<DiagnosticAndPathologySingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new diagnostic and pathology if no diagnostic and pathology is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DiagnosticAndPathologyGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndPathologyGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiagnosticAndPathologySingleResponse(); }
}

/**
* Form - Diagnostic And Pathology
*/
// @Route("/opms/form/diagnostic-and-pathology/collection", "GET")
// @Api(Description="Form - Diagnostic And Pathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyCollectionResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndPathologyGetCollection implements IReturn<DiagnosticAndPathologyCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<DiagnosticAndPathologyGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndPathologyGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiagnosticAndPathologyCollectionResponse(); }
}

/**
* Form - Diagnostic And Pathology
*/
// @Route("/opms/form/diagnostic-and-pathology/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Diagnostic And Pathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyCollectionResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndPathologyGetCollectionByIds implements IReturn<DiagnosticAndPathologyCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DiagnosticAndPathologyGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndPathologyGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiagnosticAndPathologyCollectionResponse(); }
}

/**
* Form - Diagnostic And Pathology
*/
// @Route("/opms/form/diagnostic-and-pathology/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Diagnostic And Pathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyCollectionResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndPathologyGetCollectionByCodes implements IReturn<DiagnosticAndPathologyCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DiagnosticAndPathologyGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndPathologyGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DiagnosticAndPathologyCollectionResponse(); }
}

/**
* Form - Diagnostic And Pathology
*/
// @Route("/opms/form/diagnostic-and-pathology/save", "POST")
// @Api(Description="Form - Diagnostic And Pathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndPathologyPostSave implements IReturn<DiagnosticAndPathologySingleResponse>
{
    /**
    * The diagnostic and pathology to save.
    */
    // @DataMember(Order=1)
    public form: DiagnosticAndPathology;

    public constructor(init?: Partial<DiagnosticAndPathologyPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndPathologyPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiagnosticAndPathologySingleResponse(); }
}

/**
* Form - Diagnostic And Pathology
*/
// @Route("/opms/form/diagnostic-and-pathology/save/ids", "POST")
// @Api(Description="Form - Diagnostic And Pathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndPathologyPostSaveWithIds implements IReturn<DiagnosticAndPathologySingleResponse>
{
    /**
    * The diagnostic and pathology to save.
    */
    // @DataMember(Order=1)
    public form: DiagnosticAndPathology;

    /**
    * The ID of the Institution associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DiagnosticAndPathologyPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndPathologyPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiagnosticAndPathologySingleResponse(); }
}

/**
* Form - Diagnostic And Pathology
*/
// @Route("/opms/form/diagnostic-and-pathology/save/codes", "POST")
// @Api(Description="Form - Diagnostic And Pathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologySingleResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndPathologyPostSaveWithCodes implements IReturn<DiagnosticAndPathologySingleResponse>
{
    /**
    * The diagnostic and pathology to save.
    */
    // @DataMember(Order=1)
    public form: DiagnosticAndPathology;

    /**
    * The Code of the Institution associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DiagnosticAndPathologyPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndPathologyPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiagnosticAndPathologySingleResponse(); }
}

/**
* Form - Diagnostic And Pathology
*/
// @Route("/opms/form/diagnostic-and-pathology/delete", "DELETE")
// @Api(Description="Form - Diagnostic And Pathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyDeleteResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndPathologyDelete implements IReturn<DiagnosticAndPathologyDeleteResponse>
{
    /**
    * The diagnostic and pathology to delete.
    */
    // @DataMember(Order=1)
    public form: DiagnosticAndPathology;

    public constructor(init?: Partial<DiagnosticAndPathologyDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndPathologyDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DiagnosticAndPathologyDeleteResponse(); }
}

/**
* Form - Diagnostic And Pathology
*/
// @Route("/opms/form/diagnostic-and-pathology/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Diagnostic And Pathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyDeleteResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndPathologyDeleteWithId implements IReturn<DiagnosticAndPathologyDeleteResponse>
{
    /**
    * The ID of the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DiagnosticAndPathologyDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndPathologyDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DiagnosticAndPathologyDeleteResponse(); }
}

/**
* Form - Diagnostic And Pathology
*/
// @Route("/opms/form/diagnostic-and-pathology/delete/ids", "DELETE")
// @Api(Description="Form - Diagnostic And Pathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyDeleteResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndPathologyDeleteWithIds implements IReturn<DiagnosticAndPathologyDeleteResponse>
{
    /**
    * The Patient ID of the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DiagnosticAndPathologyDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndPathologyDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DiagnosticAndPathologyDeleteResponse(); }
}

/**
* Form - Diagnostic And Pathology
*/
// @Route("/opms/form/diagnostic-and-pathology/delete/codes", "DELETE")
// @Api(Description="Form - Diagnostic And Pathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyDeleteResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndPathologyDeleteByCodes implements IReturn<DiagnosticAndPathologyDeleteResponse>
{
    /**
    * The Patient Study Number of the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DiagnosticAndPathologyDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndPathologyDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DiagnosticAndPathologyDeleteResponse(); }
}

/**
* Form - Diagnostic And Pathology
*/
// @Route("/opms/form/diagnostic-and-pathology/validate", "POST")
// @Api(Description="Form - Diagnostic And Pathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyValidationResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndPathologyPostValidate implements IReturn<DiagnosticAndPathologyValidationResponse>
{
    /**
    * The diagnostic and pathology to validate.
    */
    // @DataMember(Order=1)
    public form: DiagnosticAndPathology;

    public constructor(init?: Partial<DiagnosticAndPathologyPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndPathologyPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiagnosticAndPathologyValidationResponse(); }
}

/**
* Form - Diagnostic And Pathology
*/
// @Route("/opms/form/diagnostic-and-pathology/validate/ids", "POST")
// @Api(Description="Form - Diagnostic And Pathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyValidationResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndPathologyPostValidateWithIds implements IReturn<DiagnosticAndPathologyValidationResponse>
{
    /**
    * The diagnostic and pathology to validate.
    */
    // @DataMember(Order=1)
    public form: DiagnosticAndPathology;

    /**
    * The ID of the Institution associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DiagnosticAndPathologyPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndPathologyPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiagnosticAndPathologyValidationResponse(); }
}

/**
* Form - Diagnostic And Pathology
*/
// @Route("/opms/form/diagnostic-and-pathology/validate/codes", "POST")
// @Api(Description="Form - Diagnostic And Pathology")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DiagnosticAndPathologyValidationResponse)", StatusCode=500)
// @DataContract
export class DiagnosticAndPathologyPostValidateWithCodes implements IReturn<DiagnosticAndPathologyValidationResponse>
{
    /**
    * The diagnostic and pathology to validate.
    */
    // @DataMember(Order=1)
    public form: DiagnosticAndPathology;

    /**
    * The Code of the Institution associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the diagnostic and pathology to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DiagnosticAndPathologyPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DiagnosticAndPathologyPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DiagnosticAndPathologyValidationResponse(); }
}

/**
* Form - Eligibility Checklist
*/
// @Route("/opms/form/eligibility-checklist/single/id/{Id}", "GET")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistGetSingleById implements IReturn<EligibilityChecklistSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the eligibility checklist to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EligibilityChecklistGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityChecklistSingleResponse(); }
}

/**
* Form - Eligibility Checklist
*/
// @Route("/opms/form/eligibility-checklist/single/ids", "GET")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistGetSingleByIds implements IReturn<EligibilityChecklistSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the eligibility checklist to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eligibility checklist to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the eligibility checklist to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new eligibility checklist if no eligibility checklist is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<EligibilityChecklistGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityChecklistSingleResponse(); }
}

/**
* Form - Eligibility Checklist
*/
// @Route("/opms/form/eligibility-checklist/single/codes", "GET")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistGetSingleByCodes implements IReturn<EligibilityChecklistSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the eligibility checklist to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eligibility checklist to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the eligibility checklist to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new eligibility checklist if no eligibility checklist is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<EligibilityChecklistGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityChecklistSingleResponse(); }
}

/**
* Form - Eligibility Checklist
*/
// @Route("/opms/form/eligibility-checklist/collection", "GET")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistGetCollection implements IReturn<EligibilityChecklistCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<EligibilityChecklistGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityChecklistCollectionResponse(); }
}

/**
* Form - Eligibility Checklist
*/
// @Route("/opms/form/eligibility-checklist/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistGetCollectionByIds implements IReturn<EligibilityChecklistCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the eligibility checklist to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityChecklistGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityChecklistCollectionResponse(); }
}

/**
* Form - Eligibility Checklist
*/
// @Route("/opms/form/eligibility-checklist/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistCollectionResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistGetCollectionByCodes implements IReturn<EligibilityChecklistCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityChecklistGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityChecklistCollectionResponse(); }
}

/**
* Form - Eligibility Checklist
*/
// @Route("/opms/form/eligibility-checklist/save", "POST")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistPostSave implements IReturn<EligibilityChecklistSingleResponse>
{
    /**
    * The eligibility checklist to save.
    */
    // @DataMember(Order=1)
    public form: EligibilityChecklist;

    public constructor(init?: Partial<EligibilityChecklistPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityChecklistSingleResponse(); }
}

/**
* Form - Eligibility Checklist
*/
// @Route("/opms/form/eligibility-checklist/save/ids", "POST")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistPostSaveWithIds implements IReturn<EligibilityChecklistSingleResponse>
{
    /**
    * The eligibility checklist to save.
    */
    // @DataMember(Order=1)
    public form: EligibilityChecklist;

    /**
    * The ID of the Institution associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the eligibility checklist to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<EligibilityChecklistPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityChecklistSingleResponse(); }
}

/**
* Form - Eligibility Checklist
*/
// @Route("/opms/form/eligibility-checklist/save/codes", "POST")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistPostSaveWithCodes implements IReturn<EligibilityChecklistSingleResponse>
{
    /**
    * The eligibility checklist to save.
    */
    // @DataMember(Order=1)
    public form: EligibilityChecklist;

    /**
    * The Code of the Institution associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the eligibility checklist to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<EligibilityChecklistPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityChecklistSingleResponse(); }
}

/**
* Form - Eligibility Checklist
*/
// @Route("/opms/form/eligibility-checklist/delete", "DELETE")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistDelete implements IReturn<EligibilityChecklistDeleteResponse>
{
    /**
    * The eligibility checklist to delete.
    */
    // @DataMember(Order=1)
    public form: EligibilityChecklist;

    public constructor(init?: Partial<EligibilityChecklistDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityChecklistDeleteResponse(); }
}

/**
* Form - Eligibility Checklist
*/
// @Route("/opms/form/eligibility-checklist/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistDeleteWithId implements IReturn<EligibilityChecklistDeleteResponse>
{
    /**
    * The ID of the eligibility checklist to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EligibilityChecklistDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityChecklistDeleteResponse(); }
}

/**
* Form - Eligibility Checklist
*/
// @Route("/opms/form/eligibility-checklist/delete/ids", "DELETE")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistDeleteWithIds implements IReturn<EligibilityChecklistDeleteResponse>
{
    /**
    * The Patient ID of the eligibility checklist to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eligibility checklist to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<EligibilityChecklistDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityChecklistDeleteResponse(); }
}

/**
* Form - Eligibility Checklist
*/
// @Route("/opms/form/eligibility-checklist/delete/codes", "DELETE")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistDeleteByCodes implements IReturn<EligibilityChecklistDeleteResponse>
{
    /**
    * The Patient Study Number of the eligibility checklist to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eligibility checklist to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<EligibilityChecklistDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityChecklistDeleteResponse(); }
}

/**
* Form - Eligibility Checklist
*/
// @Route("/opms/form/eligibility-checklist/validate", "POST")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistPostValidate implements IReturn<EligibilityChecklistValidationResponse>
{
    /**
    * The eligibility checklist to validate.
    */
    // @DataMember(Order=1)
    public form: EligibilityChecklist;

    public constructor(init?: Partial<EligibilityChecklistPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityChecklistValidationResponse(); }
}

/**
* Form - Eligibility Checklist
*/
// @Route("/opms/form/eligibility-checklist/validate/ids", "POST")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistPostValidateWithIds implements IReturn<EligibilityChecklistValidationResponse>
{
    /**
    * The eligibility checklist to validate.
    */
    // @DataMember(Order=1)
    public form: EligibilityChecklist;

    /**
    * The ID of the Institution associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityChecklistPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityChecklistValidationResponse(); }
}

/**
* Form - Eligibility Checklist
*/
// @Route("/opms/form/eligibility-checklist/validate/codes", "POST")
// @Api(Description="Form - Eligibility Checklist")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityChecklistValidationResponse)", StatusCode=500)
// @DataContract
export class EligibilityChecklistPostValidateWithCodes implements IReturn<EligibilityChecklistValidationResponse>
{
    /**
    * The eligibility checklist to validate.
    */
    // @DataMember(Order=1)
    public form: EligibilityChecklist;

    /**
    * The Code of the Institution associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eligibility checklist to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityChecklistPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityChecklistPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityChecklistValidationResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/single/id/{Id}", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralGetSingleById implements IReturn<GeneralSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the general to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<GeneralGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/single/ids", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralGetSingleByIds implements IReturn<GeneralSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the general to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the general to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new general if no general is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<GeneralGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/single/codes", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralGetSingleByCodes implements IReturn<GeneralSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the general to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the general to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new general if no general is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<GeneralGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/collection", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=500)
// @DataContract
export class GeneralGetCollection implements IReturn<GeneralCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<GeneralGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralCollectionResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=500)
// @DataContract
export class GeneralGetCollectionByIds implements IReturn<GeneralCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<GeneralGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralCollectionResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralCollectionResponse)", StatusCode=500)
// @DataContract
export class GeneralGetCollectionByCodes implements IReturn<GeneralCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<GeneralGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GeneralCollectionResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/save", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralPostSave implements IReturn<GeneralSingleResponse>
{
    /**
    * The general to save.
    */
    // @DataMember(Order=1)
    public form: General;

    public constructor(init?: Partial<GeneralPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/save/ids", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralPostSaveWithIds implements IReturn<GeneralSingleResponse>
{
    /**
    * The general to save.
    */
    // @DataMember(Order=1)
    public form: General;

    /**
    * The ID of the Institution associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the general to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<GeneralPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/save/codes", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralSingleResponse)", StatusCode=500)
// @DataContract
export class GeneralPostSaveWithCodes implements IReturn<GeneralSingleResponse>
{
    /**
    * The general to save.
    */
    // @DataMember(Order=1)
    public form: General;

    /**
    * The Code of the Institution associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the general to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the general to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<GeneralPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralSingleResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/delete", "DELETE")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=500)
// @DataContract
export class GeneralDelete implements IReturn<GeneralDeleteResponse>
{
    /**
    * The general to delete.
    */
    // @DataMember(Order=1)
    public form: General;

    public constructor(init?: Partial<GeneralDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new GeneralDeleteResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/delete/id/{Id}", "DELETE")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=500)
// @DataContract
export class GeneralDeleteWithId implements IReturn<GeneralDeleteResponse>
{
    /**
    * The ID of the general to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<GeneralDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new GeneralDeleteResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/delete/ids", "DELETE")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=500)
// @DataContract
export class GeneralDeleteWithIds implements IReturn<GeneralDeleteResponse>
{
    /**
    * The Patient ID of the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the general to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<GeneralDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new GeneralDeleteResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/delete/codes", "DELETE")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralDeleteResponse)", StatusCode=500)
// @DataContract
export class GeneralDeleteByCodes implements IReturn<GeneralDeleteResponse>
{
    /**
    * The Patient Study Number of the general to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the general to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<GeneralDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new GeneralDeleteResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/validate", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=500)
// @DataContract
export class GeneralPostValidate implements IReturn<GeneralValidationResponse>
{
    /**
    * The general to validate.
    */
    // @DataMember(Order=1)
    public form: General;

    public constructor(init?: Partial<GeneralPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralValidationResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/validate/ids", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=500)
// @DataContract
export class GeneralPostValidateWithIds implements IReturn<GeneralValidationResponse>
{
    /**
    * The general to validate.
    */
    // @DataMember(Order=1)
    public form: General;

    /**
    * The ID of the Institution associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<GeneralPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralValidationResponse(); }
}

/**
* Form - General
*/
// @Route("/opms/form/general/validate/codes", "POST")
// @Api(Description="Form - General")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.GeneralValidationResponse)", StatusCode=500)
// @DataContract
export class GeneralPostValidateWithCodes implements IReturn<GeneralValidationResponse>
{
    /**
    * The general to validate.
    */
    // @DataMember(Order=1)
    public form: General;

    /**
    * The Code of the Institution associated with the general to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the general to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the general to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the general to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<GeneralPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GeneralPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GeneralValidationResponse(); }
}

/**
* Form - Other Imaging Lesion
*/
// @Route("/opms/form/other-imaging-lesion/single/id/{Id}", "GET")
// @Api(Description="Form - Other Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class OtherImagingLesionGetSingleById implements IReturn<OtherImagingLesionSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the other imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<OtherImagingLesionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherImagingLesionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherImagingLesionSingleResponse(); }
}

/**
* Form - Other Imaging Lesion
*/
// @Route("/opms/form/other-imaging-lesion/single/ids", "GET")
// @Api(Description="Form - Other Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class OtherImagingLesionGetSingleByIds implements IReturn<OtherImagingLesionSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the other imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the other imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the other imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new other imaging lesion if no other imaging lesion is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<OtherImagingLesionGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherImagingLesionGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherImagingLesionSingleResponse(); }
}

/**
* Form - Other Imaging Lesion
*/
// @Route("/opms/form/other-imaging-lesion/single/codes", "GET")
// @Api(Description="Form - Other Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class OtherImagingLesionGetSingleByCodes implements IReturn<OtherImagingLesionSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the other imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the other imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the other imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new other imaging lesion if no other imaging lesion is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<OtherImagingLesionGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherImagingLesionGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherImagingLesionSingleResponse(); }
}

/**
* Form - Other Imaging Lesion
*/
// @Route("/opms/form/other-imaging-lesion/collection", "GET")
// @Api(Description="Form - Other Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherImagingLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherImagingLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherImagingLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherImagingLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class OtherImagingLesionGetCollection implements IReturn<OtherImagingLesionCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<OtherImagingLesionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherImagingLesionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherImagingLesionCollectionResponse(); }
}

/**
* Form - Other Imaging Lesion
*/
// @Route("/opms/form/other-imaging-lesion/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Other Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherImagingLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherImagingLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherImagingLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherImagingLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class OtherImagingLesionGetCollectionByIds implements IReturn<OtherImagingLesionCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the other imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<OtherImagingLesionGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherImagingLesionGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherImagingLesionCollectionResponse(); }
}

/**
* Form - Other Imaging Lesion
*/
// @Route("/opms/form/other-imaging-lesion/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Other Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherImagingLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherImagingLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherImagingLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherImagingLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class OtherImagingLesionGetCollectionByCodes implements IReturn<OtherImagingLesionCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<OtherImagingLesionGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherImagingLesionGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherImagingLesionCollectionResponse(); }
}

/**
* Form - Other Imaging Lesion
*/
// @Route("/opms/form/other-imaging-lesion/save", "POST")
// @Api(Description="Form - Other Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class OtherImagingLesionPostSave implements IReturn<OtherImagingLesionSingleResponse>
{
    /**
    * The other imaging lesion to save.
    */
    // @DataMember(Order=1)
    public form: OtherImagingLesion;

    public constructor(init?: Partial<OtherImagingLesionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherImagingLesionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherImagingLesionSingleResponse(); }
}

/**
* Form - Other Imaging Lesion
*/
// @Route("/opms/form/other-imaging-lesion/save/ids", "POST")
// @Api(Description="Form - Other Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class OtherImagingLesionPostSaveWithIds implements IReturn<OtherImagingLesionSingleResponse>
{
    /**
    * The other imaging lesion to save.
    */
    // @DataMember(Order=1)
    public form: OtherImagingLesion;

    /**
    * The ID of the Institution associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the other imaging lesion to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<OtherImagingLesionPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherImagingLesionPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherImagingLesionSingleResponse(); }
}

/**
* Form - Other Imaging Lesion
*/
// @Route("/opms/form/other-imaging-lesion/save/codes", "POST")
// @Api(Description="Form - Other Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class OtherImagingLesionPostSaveWithCodes implements IReturn<OtherImagingLesionSingleResponse>
{
    /**
    * The other imaging lesion to save.
    */
    // @DataMember(Order=1)
    public form: OtherImagingLesion;

    /**
    * The Code of the Institution associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the other imaging lesion to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<OtherImagingLesionPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherImagingLesionPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherImagingLesionSingleResponse(); }
}

/**
* Form - Other Imaging Lesion
*/
// @Route("/opms/form/other-imaging-lesion/delete", "DELETE")
// @Api(Description="Form - Other Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherImagingLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherImagingLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherImagingLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherImagingLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherImagingLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class OtherImagingLesionDelete implements IReturn<OtherImagingLesionDeleteResponse>
{
    /**
    * The other imaging lesion to delete.
    */
    // @DataMember(Order=1)
    public form: OtherImagingLesion;

    public constructor(init?: Partial<OtherImagingLesionDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherImagingLesionDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new OtherImagingLesionDeleteResponse(); }
}

/**
* Form - Other Imaging Lesion
*/
// @Route("/opms/form/other-imaging-lesion/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Other Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherImagingLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherImagingLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherImagingLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherImagingLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherImagingLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class OtherImagingLesionDeleteWithId implements IReturn<OtherImagingLesionDeleteResponse>
{
    /**
    * The ID of the other imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<OtherImagingLesionDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherImagingLesionDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new OtherImagingLesionDeleteResponse(); }
}

/**
* Form - Other Imaging Lesion
*/
// @Route("/opms/form/other-imaging-lesion/delete/ids", "DELETE")
// @Api(Description="Form - Other Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherImagingLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherImagingLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherImagingLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherImagingLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherImagingLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class OtherImagingLesionDeleteWithIds implements IReturn<OtherImagingLesionDeleteResponse>
{
    /**
    * The Patient ID of the other imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the other imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<OtherImagingLesionDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherImagingLesionDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new OtherImagingLesionDeleteResponse(); }
}

/**
* Form - Other Imaging Lesion
*/
// @Route("/opms/form/other-imaging-lesion/delete/codes", "DELETE")
// @Api(Description="Form - Other Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherImagingLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherImagingLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherImagingLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherImagingLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherImagingLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class OtherImagingLesionDeleteByCodes implements IReturn<OtherImagingLesionDeleteResponse>
{
    /**
    * The Patient Study Number of the other imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the other imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<OtherImagingLesionDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherImagingLesionDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new OtherImagingLesionDeleteResponse(); }
}

/**
* Form - Other Imaging Lesion
*/
// @Route("/opms/form/other-imaging-lesion/validate", "POST")
// @Api(Description="Form - Other Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherImagingLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherImagingLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherImagingLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherImagingLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherImagingLesionValidationResponse)", StatusCode=500)
// @DataContract
export class OtherImagingLesionPostValidate implements IReturn<OtherImagingLesionValidationResponse>
{
    /**
    * The other imaging lesion to validate.
    */
    // @DataMember(Order=1)
    public form: OtherImagingLesion;

    public constructor(init?: Partial<OtherImagingLesionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherImagingLesionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherImagingLesionValidationResponse(); }
}

/**
* Form - Other Imaging Lesion
*/
// @Route("/opms/form/other-imaging-lesion/validate/ids", "POST")
// @Api(Description="Form - Other Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherImagingLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherImagingLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherImagingLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherImagingLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherImagingLesionValidationResponse)", StatusCode=500)
// @DataContract
export class OtherImagingLesionPostValidateWithIds implements IReturn<OtherImagingLesionValidationResponse>
{
    /**
    * The other imaging lesion to validate.
    */
    // @DataMember(Order=1)
    public form: OtherImagingLesion;

    /**
    * The ID of the Institution associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<OtherImagingLesionPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherImagingLesionPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherImagingLesionValidationResponse(); }
}

/**
* Form - Other Imaging Lesion
*/
// @Route("/opms/form/other-imaging-lesion/validate/codes", "POST")
// @Api(Description="Form - Other Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherImagingLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherImagingLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherImagingLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherImagingLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherImagingLesionValidationResponse)", StatusCode=500)
// @DataContract
export class OtherImagingLesionPostValidateWithCodes implements IReturn<OtherImagingLesionValidationResponse>
{
    /**
    * The other imaging lesion to validate.
    */
    // @DataMember(Order=1)
    public form: OtherImagingLesion;

    /**
    * The Code of the Institution associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the other imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<OtherImagingLesionPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherImagingLesionPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherImagingLesionValidationResponse(); }
}

/**
* Form - Pet Scan Imaging Lesion
*/
// @Route("/opms/form/pet-scan-imaging-lesion/single/id/{Id}", "GET")
// @Api(Description="Form - Pet Scan Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class PetScanImagingLesionGetSingleById implements IReturn<PetScanImagingLesionSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PetScanImagingLesionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PetScanImagingLesionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PetScanImagingLesionSingleResponse(); }
}

/**
* Form - Pet Scan Imaging Lesion
*/
// @Route("/opms/form/pet-scan-imaging-lesion/single/ids", "GET")
// @Api(Description="Form - Pet Scan Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class PetScanImagingLesionGetSingleByIds implements IReturn<PetScanImagingLesionSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new pet scan imaging lesion if no pet scan imaging lesion is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PetScanImagingLesionGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PetScanImagingLesionGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PetScanImagingLesionSingleResponse(); }
}

/**
* Form - Pet Scan Imaging Lesion
*/
// @Route("/opms/form/pet-scan-imaging-lesion/single/codes", "GET")
// @Api(Description="Form - Pet Scan Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class PetScanImagingLesionGetSingleByCodes implements IReturn<PetScanImagingLesionSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new pet scan imaging lesion if no pet scan imaging lesion is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PetScanImagingLesionGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PetScanImagingLesionGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PetScanImagingLesionSingleResponse(); }
}

/**
* Form - Pet Scan Imaging Lesion
*/
// @Route("/opms/form/pet-scan-imaging-lesion/collection", "GET")
// @Api(Description="Form - Pet Scan Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class PetScanImagingLesionGetCollection implements IReturn<PetScanImagingLesionCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<PetScanImagingLesionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PetScanImagingLesionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PetScanImagingLesionCollectionResponse(); }
}

/**
* Form - Pet Scan Imaging Lesion
*/
// @Route("/opms/form/pet-scan-imaging-lesion/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Pet Scan Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class PetScanImagingLesionGetCollectionByIds implements IReturn<PetScanImagingLesionCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PetScanImagingLesionGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PetScanImagingLesionGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PetScanImagingLesionCollectionResponse(); }
}

/**
* Form - Pet Scan Imaging Lesion
*/
// @Route("/opms/form/pet-scan-imaging-lesion/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Pet Scan Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class PetScanImagingLesionGetCollectionByCodes implements IReturn<PetScanImagingLesionCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PetScanImagingLesionGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PetScanImagingLesionGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PetScanImagingLesionCollectionResponse(); }
}

/**
* Form - Pet Scan Imaging Lesion
*/
// @Route("/opms/form/pet-scan-imaging-lesion/save", "POST")
// @Api(Description="Form - Pet Scan Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class PetScanImagingLesionPostSave implements IReturn<PetScanImagingLesionSingleResponse>
{
    /**
    * The pet scan imaging lesion to save.
    */
    // @DataMember(Order=1)
    public form: PetScanImagingLesion;

    public constructor(init?: Partial<PetScanImagingLesionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PetScanImagingLesionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PetScanImagingLesionSingleResponse(); }
}

/**
* Form - Pet Scan Imaging Lesion
*/
// @Route("/opms/form/pet-scan-imaging-lesion/save/ids", "POST")
// @Api(Description="Form - Pet Scan Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class PetScanImagingLesionPostSaveWithIds implements IReturn<PetScanImagingLesionSingleResponse>
{
    /**
    * The pet scan imaging lesion to save.
    */
    // @DataMember(Order=1)
    public form: PetScanImagingLesion;

    /**
    * The ID of the Institution associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PetScanImagingLesionPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PetScanImagingLesionPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PetScanImagingLesionSingleResponse(); }
}

/**
* Form - Pet Scan Imaging Lesion
*/
// @Route("/opms/form/pet-scan-imaging-lesion/save/codes", "POST")
// @Api(Description="Form - Pet Scan Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class PetScanImagingLesionPostSaveWithCodes implements IReturn<PetScanImagingLesionSingleResponse>
{
    /**
    * The pet scan imaging lesion to save.
    */
    // @DataMember(Order=1)
    public form: PetScanImagingLesion;

    /**
    * The Code of the Institution associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PetScanImagingLesionPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PetScanImagingLesionPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PetScanImagingLesionSingleResponse(); }
}

/**
* Form - Pet Scan Imaging Lesion
*/
// @Route("/opms/form/pet-scan-imaging-lesion/delete", "DELETE")
// @Api(Description="Form - Pet Scan Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class PetScanImagingLesionDelete implements IReturn<PetScanImagingLesionDeleteResponse>
{
    /**
    * The pet scan imaging lesion to delete.
    */
    // @DataMember(Order=1)
    public form: PetScanImagingLesion;

    public constructor(init?: Partial<PetScanImagingLesionDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PetScanImagingLesionDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PetScanImagingLesionDeleteResponse(); }
}

/**
* Form - Pet Scan Imaging Lesion
*/
// @Route("/opms/form/pet-scan-imaging-lesion/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Pet Scan Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class PetScanImagingLesionDeleteWithId implements IReturn<PetScanImagingLesionDeleteResponse>
{
    /**
    * The ID of the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PetScanImagingLesionDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PetScanImagingLesionDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PetScanImagingLesionDeleteResponse(); }
}

/**
* Form - Pet Scan Imaging Lesion
*/
// @Route("/opms/form/pet-scan-imaging-lesion/delete/ids", "DELETE")
// @Api(Description="Form - Pet Scan Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class PetScanImagingLesionDeleteWithIds implements IReturn<PetScanImagingLesionDeleteResponse>
{
    /**
    * The Patient ID of the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PetScanImagingLesionDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PetScanImagingLesionDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PetScanImagingLesionDeleteResponse(); }
}

/**
* Form - Pet Scan Imaging Lesion
*/
// @Route("/opms/form/pet-scan-imaging-lesion/delete/codes", "DELETE")
// @Api(Description="Form - Pet Scan Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class PetScanImagingLesionDeleteByCodes implements IReturn<PetScanImagingLesionDeleteResponse>
{
    /**
    * The Patient Study Number of the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PetScanImagingLesionDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PetScanImagingLesionDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PetScanImagingLesionDeleteResponse(); }
}

/**
* Form - Pet Scan Imaging Lesion
*/
// @Route("/opms/form/pet-scan-imaging-lesion/validate", "POST")
// @Api(Description="Form - Pet Scan Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionValidationResponse)", StatusCode=500)
// @DataContract
export class PetScanImagingLesionPostValidate implements IReturn<PetScanImagingLesionValidationResponse>
{
    /**
    * The pet scan imaging lesion to validate.
    */
    // @DataMember(Order=1)
    public form: PetScanImagingLesion;

    public constructor(init?: Partial<PetScanImagingLesionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PetScanImagingLesionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PetScanImagingLesionValidationResponse(); }
}

/**
* Form - Pet Scan Imaging Lesion
*/
// @Route("/opms/form/pet-scan-imaging-lesion/validate/ids", "POST")
// @Api(Description="Form - Pet Scan Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionValidationResponse)", StatusCode=500)
// @DataContract
export class PetScanImagingLesionPostValidateWithIds implements IReturn<PetScanImagingLesionValidationResponse>
{
    /**
    * The pet scan imaging lesion to validate.
    */
    // @DataMember(Order=1)
    public form: PetScanImagingLesion;

    /**
    * The ID of the Institution associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PetScanImagingLesionPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PetScanImagingLesionPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PetScanImagingLesionValidationResponse(); }
}

/**
* Form - Pet Scan Imaging Lesion
*/
// @Route("/opms/form/pet-scan-imaging-lesion/validate/codes", "POST")
// @Api(Description="Form - Pet Scan Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PetScanImagingLesionValidationResponse)", StatusCode=500)
// @DataContract
export class PetScanImagingLesionPostValidateWithCodes implements IReturn<PetScanImagingLesionValidationResponse>
{
    /**
    * The pet scan imaging lesion to validate.
    */
    // @DataMember(Order=1)
    public form: PetScanImagingLesion;

    /**
    * The Code of the Institution associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the pet scan imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PetScanImagingLesionPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PetScanImagingLesionPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PetScanImagingLesionValidationResponse(); }
}

/**
* Form - Pre Mri Planning
*/
// @Route("/opms/form/pre-mri-planning/single/id/{Id}", "GET")
// @Api(Description="Form - Pre Mri Planning")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=500)
// @DataContract
export class PreMriPlanningGetSingleById implements IReturn<PreMriPlanningSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the pre mri planning to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PreMriPlanningGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreMriPlanningGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreMriPlanningSingleResponse(); }
}

/**
* Form - Pre Mri Planning
*/
// @Route("/opms/form/pre-mri-planning/single/ids", "GET")
// @Api(Description="Form - Pre Mri Planning")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=500)
// @DataContract
export class PreMriPlanningGetSingleByIds implements IReturn<PreMriPlanningSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the pre mri planning to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the pre mri planning to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the pre mri planning to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new pre mri planning if no pre mri planning is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PreMriPlanningGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreMriPlanningGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreMriPlanningSingleResponse(); }
}

/**
* Form - Pre Mri Planning
*/
// @Route("/opms/form/pre-mri-planning/single/codes", "GET")
// @Api(Description="Form - Pre Mri Planning")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=500)
// @DataContract
export class PreMriPlanningGetSingleByCodes implements IReturn<PreMriPlanningSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the pre mri planning to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the pre mri planning to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the pre mri planning to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new pre mri planning if no pre mri planning is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PreMriPlanningGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreMriPlanningGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreMriPlanningSingleResponse(); }
}

/**
* Form - Pre Mri Planning
*/
// @Route("/opms/form/pre-mri-planning/collection", "GET")
// @Api(Description="Form - Pre Mri Planning")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreMriPlanningCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreMriPlanningCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreMriPlanningCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreMriPlanningCollectionResponse)", StatusCode=500)
// @DataContract
export class PreMriPlanningGetCollection implements IReturn<PreMriPlanningCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<PreMriPlanningGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreMriPlanningGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreMriPlanningCollectionResponse(); }
}

/**
* Form - Pre Mri Planning
*/
// @Route("/opms/form/pre-mri-planning/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Pre Mri Planning")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreMriPlanningCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreMriPlanningCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreMriPlanningCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreMriPlanningCollectionResponse)", StatusCode=500)
// @DataContract
export class PreMriPlanningGetCollectionByIds implements IReturn<PreMriPlanningCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the pre mri planning to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PreMriPlanningGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreMriPlanningGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreMriPlanningCollectionResponse(); }
}

/**
* Form - Pre Mri Planning
*/
// @Route("/opms/form/pre-mri-planning/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Pre Mri Planning")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreMriPlanningCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreMriPlanningCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreMriPlanningCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreMriPlanningCollectionResponse)", StatusCode=500)
// @DataContract
export class PreMriPlanningGetCollectionByCodes implements IReturn<PreMriPlanningCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PreMriPlanningGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreMriPlanningGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreMriPlanningCollectionResponse(); }
}

/**
* Form - Pre Mri Planning
*/
// @Route("/opms/form/pre-mri-planning/save", "POST")
// @Api(Description="Form - Pre Mri Planning")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=500)
// @DataContract
export class PreMriPlanningPostSave implements IReturn<PreMriPlanningSingleResponse>
{
    /**
    * The pre mri planning to save.
    */
    // @DataMember(Order=1)
    public form: PreMriPlanning;

    public constructor(init?: Partial<PreMriPlanningPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreMriPlanningPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreMriPlanningSingleResponse(); }
}

/**
* Form - Pre Mri Planning
*/
// @Route("/opms/form/pre-mri-planning/save/ids", "POST")
// @Api(Description="Form - Pre Mri Planning")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=500)
// @DataContract
export class PreMriPlanningPostSaveWithIds implements IReturn<PreMriPlanningSingleResponse>
{
    /**
    * The pre mri planning to save.
    */
    // @DataMember(Order=1)
    public form: PreMriPlanning;

    /**
    * The ID of the Institution associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the pre mri planning to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PreMriPlanningPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreMriPlanningPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreMriPlanningSingleResponse(); }
}

/**
* Form - Pre Mri Planning
*/
// @Route("/opms/form/pre-mri-planning/save/codes", "POST")
// @Api(Description="Form - Pre Mri Planning")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreMriPlanningSingleResponse)", StatusCode=500)
// @DataContract
export class PreMriPlanningPostSaveWithCodes implements IReturn<PreMriPlanningSingleResponse>
{
    /**
    * The pre mri planning to save.
    */
    // @DataMember(Order=1)
    public form: PreMriPlanning;

    /**
    * The Code of the Institution associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the pre mri planning to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PreMriPlanningPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreMriPlanningPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreMriPlanningSingleResponse(); }
}

/**
* Form - Pre Mri Planning
*/
// @Route("/opms/form/pre-mri-planning/delete", "DELETE")
// @Api(Description="Form - Pre Mri Planning")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreMriPlanningDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreMriPlanningDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreMriPlanningDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreMriPlanningDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreMriPlanningDeleteResponse)", StatusCode=500)
// @DataContract
export class PreMriPlanningDelete implements IReturn<PreMriPlanningDeleteResponse>
{
    /**
    * The pre mri planning to delete.
    */
    // @DataMember(Order=1)
    public form: PreMriPlanning;

    public constructor(init?: Partial<PreMriPlanningDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreMriPlanningDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PreMriPlanningDeleteResponse(); }
}

/**
* Form - Pre Mri Planning
*/
// @Route("/opms/form/pre-mri-planning/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Pre Mri Planning")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreMriPlanningDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreMriPlanningDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreMriPlanningDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreMriPlanningDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreMriPlanningDeleteResponse)", StatusCode=500)
// @DataContract
export class PreMriPlanningDeleteWithId implements IReturn<PreMriPlanningDeleteResponse>
{
    /**
    * The ID of the pre mri planning to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PreMriPlanningDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreMriPlanningDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PreMriPlanningDeleteResponse(); }
}

/**
* Form - Pre Mri Planning
*/
// @Route("/opms/form/pre-mri-planning/delete/ids", "DELETE")
// @Api(Description="Form - Pre Mri Planning")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreMriPlanningDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreMriPlanningDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreMriPlanningDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreMriPlanningDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreMriPlanningDeleteResponse)", StatusCode=500)
// @DataContract
export class PreMriPlanningDeleteWithIds implements IReturn<PreMriPlanningDeleteResponse>
{
    /**
    * The Patient ID of the pre mri planning to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the pre mri planning to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PreMriPlanningDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreMriPlanningDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PreMriPlanningDeleteResponse(); }
}

/**
* Form - Pre Mri Planning
*/
// @Route("/opms/form/pre-mri-planning/delete/codes", "DELETE")
// @Api(Description="Form - Pre Mri Planning")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreMriPlanningDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreMriPlanningDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreMriPlanningDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreMriPlanningDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreMriPlanningDeleteResponse)", StatusCode=500)
// @DataContract
export class PreMriPlanningDeleteByCodes implements IReturn<PreMriPlanningDeleteResponse>
{
    /**
    * The Patient Study Number of the pre mri planning to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the pre mri planning to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PreMriPlanningDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreMriPlanningDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PreMriPlanningDeleteResponse(); }
}

/**
* Form - Pre Mri Planning
*/
// @Route("/opms/form/pre-mri-planning/validate", "POST")
// @Api(Description="Form - Pre Mri Planning")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreMriPlanningValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreMriPlanningValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreMriPlanningValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreMriPlanningValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreMriPlanningValidationResponse)", StatusCode=500)
// @DataContract
export class PreMriPlanningPostValidate implements IReturn<PreMriPlanningValidationResponse>
{
    /**
    * The pre mri planning to validate.
    */
    // @DataMember(Order=1)
    public form: PreMriPlanning;

    public constructor(init?: Partial<PreMriPlanningPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreMriPlanningPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreMriPlanningValidationResponse(); }
}

/**
* Form - Pre Mri Planning
*/
// @Route("/opms/form/pre-mri-planning/validate/ids", "POST")
// @Api(Description="Form - Pre Mri Planning")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreMriPlanningValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreMriPlanningValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreMriPlanningValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreMriPlanningValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreMriPlanningValidationResponse)", StatusCode=500)
// @DataContract
export class PreMriPlanningPostValidateWithIds implements IReturn<PreMriPlanningValidationResponse>
{
    /**
    * The pre mri planning to validate.
    */
    // @DataMember(Order=1)
    public form: PreMriPlanning;

    /**
    * The ID of the Institution associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PreMriPlanningPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreMriPlanningPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreMriPlanningValidationResponse(); }
}

/**
* Form - Pre Mri Planning
*/
// @Route("/opms/form/pre-mri-planning/validate/codes", "POST")
// @Api(Description="Form - Pre Mri Planning")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreMriPlanningValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreMriPlanningValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreMriPlanningValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreMriPlanningValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreMriPlanningValidationResponse)", StatusCode=500)
// @DataContract
export class PreMriPlanningPostValidateWithCodes implements IReturn<PreMriPlanningValidationResponse>
{
    /**
    * The pre mri planning to validate.
    */
    // @DataMember(Order=1)
    public form: PreMriPlanning;

    /**
    * The Code of the Institution associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the pre mri planning to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PreMriPlanningPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreMriPlanningPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreMriPlanningValidationResponse(); }
}

/**
* Form - Preregistration
*/
// @Route("/opms/form/preregistration/single/id/{Id}", "GET")
// @Api(Description="Form - Preregistration")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=500)
// @DataContract
export class PreregistrationGetSingleById implements IReturn<PreregistrationSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the preregistration to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PreregistrationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreregistrationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreregistrationSingleResponse(); }
}

/**
* Form - Preregistration
*/
// @Route("/opms/form/preregistration/single/ids", "GET")
// @Api(Description="Form - Preregistration")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=500)
// @DataContract
export class PreregistrationGetSingleByIds implements IReturn<PreregistrationSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the preregistration to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the preregistration to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the preregistration to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the preregistration to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the preregistration to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new preregistration if no preregistration is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PreregistrationGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreregistrationGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreregistrationSingleResponse(); }
}

/**
* Form - Preregistration
*/
// @Route("/opms/form/preregistration/single/codes", "GET")
// @Api(Description="Form - Preregistration")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=500)
// @DataContract
export class PreregistrationGetSingleByCodes implements IReturn<PreregistrationSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the preregistration to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the preregistration to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the preregistration to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the preregistration to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the preregistration to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new preregistration if no preregistration is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<PreregistrationGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreregistrationGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreregistrationSingleResponse(); }
}

/**
* Form - Preregistration
*/
// @Route("/opms/form/preregistration/collection", "GET")
// @Api(Description="Form - Preregistration")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreregistrationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreregistrationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreregistrationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreregistrationCollectionResponse)", StatusCode=500)
// @DataContract
export class PreregistrationGetCollection implements IReturn<PreregistrationCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<PreregistrationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreregistrationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreregistrationCollectionResponse(); }
}

/**
* Form - Preregistration
*/
// @Route("/opms/form/preregistration/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Preregistration")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreregistrationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreregistrationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreregistrationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreregistrationCollectionResponse)", StatusCode=500)
// @DataContract
export class PreregistrationGetCollectionByIds implements IReturn<PreregistrationCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the preregistration to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the preregistration to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the preregistration to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PreregistrationGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreregistrationGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreregistrationCollectionResponse(); }
}

/**
* Form - Preregistration
*/
// @Route("/opms/form/preregistration/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Preregistration")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreregistrationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreregistrationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreregistrationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreregistrationCollectionResponse)", StatusCode=500)
// @DataContract
export class PreregistrationGetCollectionByCodes implements IReturn<PreregistrationCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the preregistration to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the preregistration to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the preregistration to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<PreregistrationGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreregistrationGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PreregistrationCollectionResponse(); }
}

/**
* Form - Preregistration
*/
// @Route("/opms/form/preregistration/save", "POST")
// @Api(Description="Form - Preregistration")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=500)
// @DataContract
export class PreregistrationPostSave implements IReturn<PreregistrationSingleResponse>
{
    /**
    * The preregistration to save.
    */
    // @DataMember(Order=1)
    public form: Preregistration;

    public constructor(init?: Partial<PreregistrationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreregistrationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreregistrationSingleResponse(); }
}

/**
* Form - Preregistration
*/
// @Route("/opms/form/preregistration/save/ids", "POST")
// @Api(Description="Form - Preregistration")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=500)
// @DataContract
export class PreregistrationPostSaveWithIds implements IReturn<PreregistrationSingleResponse>
{
    /**
    * The preregistration to save.
    */
    // @DataMember(Order=1)
    public form: Preregistration;

    /**
    * The ID of the Institution associated with the preregistration to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the preregistration to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the preregistration to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the preregistration to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the preregistration to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PreregistrationPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreregistrationPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreregistrationSingleResponse(); }
}

/**
* Form - Preregistration
*/
// @Route("/opms/form/preregistration/save/codes", "POST")
// @Api(Description="Form - Preregistration")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreregistrationSingleResponse)", StatusCode=500)
// @DataContract
export class PreregistrationPostSaveWithCodes implements IReturn<PreregistrationSingleResponse>
{
    /**
    * The preregistration to save.
    */
    // @DataMember(Order=1)
    public form: Preregistration;

    /**
    * The Code of the Institution associated with the preregistration to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the preregistration to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the preregistration to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the preregistration to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the preregistration to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<PreregistrationPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreregistrationPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreregistrationSingleResponse(); }
}

/**
* Form - Preregistration
*/
// @Route("/opms/form/preregistration/delete", "DELETE")
// @Api(Description="Form - Preregistration")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreregistrationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreregistrationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreregistrationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreregistrationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreregistrationDeleteResponse)", StatusCode=500)
// @DataContract
export class PreregistrationDelete implements IReturn<PreregistrationDeleteResponse>
{
    /**
    * The preregistration to delete.
    */
    // @DataMember(Order=1)
    public form: Preregistration;

    public constructor(init?: Partial<PreregistrationDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreregistrationDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PreregistrationDeleteResponse(); }
}

/**
* Form - Preregistration
*/
// @Route("/opms/form/preregistration/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Preregistration")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreregistrationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreregistrationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreregistrationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreregistrationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreregistrationDeleteResponse)", StatusCode=500)
// @DataContract
export class PreregistrationDeleteWithId implements IReturn<PreregistrationDeleteResponse>
{
    /**
    * The ID of the preregistration to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PreregistrationDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreregistrationDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PreregistrationDeleteResponse(); }
}

/**
* Form - Preregistration
*/
// @Route("/opms/form/preregistration/delete/ids", "DELETE")
// @Api(Description="Form - Preregistration")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreregistrationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreregistrationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreregistrationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreregistrationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreregistrationDeleteResponse)", StatusCode=500)
// @DataContract
export class PreregistrationDeleteWithIds implements IReturn<PreregistrationDeleteResponse>
{
    /**
    * The Patient ID of the preregistration to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the preregistration to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the preregistration to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the preregistration to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PreregistrationDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreregistrationDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PreregistrationDeleteResponse(); }
}

/**
* Form - Preregistration
*/
// @Route("/opms/form/preregistration/delete/codes", "DELETE")
// @Api(Description="Form - Preregistration")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreregistrationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreregistrationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreregistrationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreregistrationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreregistrationDeleteResponse)", StatusCode=500)
// @DataContract
export class PreregistrationDeleteByCodes implements IReturn<PreregistrationDeleteResponse>
{
    /**
    * The Patient Study Number of the preregistration to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the preregistration to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the preregistration to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the preregistration to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<PreregistrationDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreregistrationDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PreregistrationDeleteResponse(); }
}

/**
* Form - Preregistration
*/
// @Route("/opms/form/preregistration/validate", "POST")
// @Api(Description="Form - Preregistration")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreregistrationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreregistrationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreregistrationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreregistrationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreregistrationValidationResponse)", StatusCode=500)
// @DataContract
export class PreregistrationPostValidate implements IReturn<PreregistrationValidationResponse>
{
    /**
    * The preregistration to validate.
    */
    // @DataMember(Order=1)
    public form: Preregistration;

    public constructor(init?: Partial<PreregistrationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreregistrationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreregistrationValidationResponse(); }
}

/**
* Form - Preregistration
*/
// @Route("/opms/form/preregistration/validate/ids", "POST")
// @Api(Description="Form - Preregistration")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreregistrationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreregistrationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreregistrationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreregistrationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreregistrationValidationResponse)", StatusCode=500)
// @DataContract
export class PreregistrationPostValidateWithIds implements IReturn<PreregistrationValidationResponse>
{
    /**
    * The preregistration to validate.
    */
    // @DataMember(Order=1)
    public form: Preregistration;

    /**
    * The ID of the Institution associated with the preregistration to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the preregistration to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the preregistration to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the preregistration to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PreregistrationPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreregistrationPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreregistrationValidationResponse(); }
}

/**
* Form - Preregistration
*/
// @Route("/opms/form/preregistration/validate/codes", "POST")
// @Api(Description="Form - Preregistration")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.PreregistrationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.PreregistrationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.PreregistrationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.PreregistrationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.PreregistrationValidationResponse)", StatusCode=500)
// @DataContract
export class PreregistrationPostValidateWithCodes implements IReturn<PreregistrationValidationResponse>
{
    /**
    * The preregistration to validate.
    */
    // @DataMember(Order=1)
    public form: Preregistration;

    /**
    * The Code of the Institution associated with the preregistration to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the preregistration to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the preregistration to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the preregistration to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<PreregistrationPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PreregistrationPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PreregistrationValidationResponse(); }
}

/**
* Form - Procomida
*/
// @Route("/opms/form/procomida/single/id/{Id}", "GET")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaGetSingleById implements IReturn<ProcomidaSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the procomida to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProcomidaGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaSingleResponse(); }
}

/**
* Form - Procomida
*/
// @Route("/opms/form/procomida/single/ids", "GET")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaGetSingleByIds implements IReturn<ProcomidaSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the procomida to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the procomida to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the procomida to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the procomida to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the procomida to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new procomida if no procomida is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ProcomidaGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaSingleResponse(); }
}

/**
* Form - Procomida
*/
// @Route("/opms/form/procomida/single/codes", "GET")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaGetSingleByCodes implements IReturn<ProcomidaSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the procomida to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the procomida to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the procomida to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the procomida to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the procomida to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new procomida if no procomida is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ProcomidaGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaSingleResponse(); }
}

/**
* Form - Procomida
*/
// @Route("/opms/form/procomida/collection", "GET")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=500)
// @DataContract
export class ProcomidaGetCollection implements IReturn<ProcomidaCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ProcomidaGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaCollectionResponse(); }
}

/**
* Form - Procomida
*/
// @Route("/opms/form/procomida/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=500)
// @DataContract
export class ProcomidaGetCollectionByIds implements IReturn<ProcomidaCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the procomida to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the procomida to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the procomida to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaCollectionResponse(); }
}

/**
* Form - Procomida
*/
// @Route("/opms/form/procomida/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=500)
// @DataContract
export class ProcomidaGetCollectionByCodes implements IReturn<ProcomidaCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the procomida to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the procomida to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the procomida to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaCollectionResponse(); }
}

/**
* Form - Procomida
*/
// @Route("/opms/form/procomida/save", "POST")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaPostSave implements IReturn<ProcomidaSingleResponse>
{
    /**
    * The procomida to save.
    */
    // @DataMember(Order=1)
    public form: Procomida;

    public constructor(init?: Partial<ProcomidaPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaSingleResponse(); }
}

/**
* Form - Procomida
*/
// @Route("/opms/form/procomida/save/ids", "POST")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaPostSaveWithIds implements IReturn<ProcomidaSingleResponse>
{
    /**
    * The procomida to save.
    */
    // @DataMember(Order=1)
    public form: Procomida;

    /**
    * The ID of the Institution associated with the procomida to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the procomida to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the procomida to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the procomida to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the procomida to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ProcomidaPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaSingleResponse(); }
}

/**
* Form - Procomida
*/
// @Route("/opms/form/procomida/save/codes", "POST")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaPostSaveWithCodes implements IReturn<ProcomidaSingleResponse>
{
    /**
    * The procomida to save.
    */
    // @DataMember(Order=1)
    public form: Procomida;

    /**
    * The Code of the Institution associated with the procomida to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the procomida to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the procomida to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the procomida to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the procomida to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ProcomidaPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaSingleResponse(); }
}

/**
* Form - Procomida
*/
// @Route("/opms/form/procomida/delete", "DELETE")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaDelete implements IReturn<ProcomidaDeleteResponse>
{
    /**
    * The procomida to delete.
    */
    // @DataMember(Order=1)
    public form: Procomida;

    public constructor(init?: Partial<ProcomidaDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaDeleteResponse(); }
}

/**
* Form - Procomida
*/
// @Route("/opms/form/procomida/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaDeleteWithId implements IReturn<ProcomidaDeleteResponse>
{
    /**
    * The ID of the procomida to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProcomidaDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaDeleteResponse(); }
}

/**
* Form - Procomida
*/
// @Route("/opms/form/procomida/delete/ids", "DELETE")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaDeleteWithIds implements IReturn<ProcomidaDeleteResponse>
{
    /**
    * The Patient ID of the procomida to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the procomida to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the procomida to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the procomida to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ProcomidaDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaDeleteResponse(); }
}

/**
* Form - Procomida
*/
// @Route("/opms/form/procomida/delete/codes", "DELETE")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaDeleteByCodes implements IReturn<ProcomidaDeleteResponse>
{
    /**
    * The Patient Study Number of the procomida to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the procomida to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the procomida to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the procomida to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ProcomidaDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaDeleteResponse(); }
}

/**
* Form - Procomida
*/
// @Route("/opms/form/procomida/validate", "POST")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=500)
// @DataContract
export class ProcomidaPostValidate implements IReturn<ProcomidaValidationResponse>
{
    /**
    * The procomida to validate.
    */
    // @DataMember(Order=1)
    public form: Procomida;

    public constructor(init?: Partial<ProcomidaPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaValidationResponse(); }
}

/**
* Form - Procomida
*/
// @Route("/opms/form/procomida/validate/ids", "POST")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=500)
// @DataContract
export class ProcomidaPostValidateWithIds implements IReturn<ProcomidaValidationResponse>
{
    /**
    * The procomida to validate.
    */
    // @DataMember(Order=1)
    public form: Procomida;

    /**
    * The ID of the Institution associated with the procomida to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the procomida to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the procomida to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the procomida to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaValidationResponse(); }
}

/**
* Form - Procomida
*/
// @Route("/opms/form/procomida/validate/codes", "POST")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=500)
// @DataContract
export class ProcomidaPostValidateWithCodes implements IReturn<ProcomidaValidationResponse>
{
    /**
    * The procomida to validate.
    */
    // @DataMember(Order=1)
    public form: Procomida;

    /**
    * The Code of the Institution associated with the procomida to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the procomida to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the procomida to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the procomida to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaValidationResponse(); }
}

/**
* Form - Procomida Assistance
*/
// @Route("/opms/form/procomida-assistance/single/id/{Id}", "GET")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistanceGetSingleById implements IReturn<ProcomidaAssistanceSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the procomida assistance to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProcomidaAssistanceGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistanceGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaAssistanceSingleResponse(); }
}

/**
* Form - Procomida Assistance
*/
// @Route("/opms/form/procomida-assistance/single/ids", "GET")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistanceGetSingleByIds implements IReturn<ProcomidaAssistanceSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the procomida assistance to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the procomida assistance to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the procomida assistance to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new procomida assistance if no procomida assistance is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ProcomidaAssistanceGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistanceGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaAssistanceSingleResponse(); }
}

/**
* Form - Procomida Assistance
*/
// @Route("/opms/form/procomida-assistance/single/codes", "GET")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistanceGetSingleByCodes implements IReturn<ProcomidaAssistanceSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the procomida assistance to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the procomida assistance to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the procomida assistance to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new procomida assistance if no procomida assistance is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ProcomidaAssistanceGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistanceGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaAssistanceSingleResponse(); }
}

/**
* Form - Procomida Assistance
*/
// @Route("/opms/form/procomida-assistance/collection", "GET")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistanceGetCollection implements IReturn<ProcomidaAssistanceCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ProcomidaAssistanceGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistanceGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaAssistanceCollectionResponse(); }
}

/**
* Form - Procomida Assistance
*/
// @Route("/opms/form/procomida-assistance/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistanceGetCollectionByIds implements IReturn<ProcomidaAssistanceCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the procomida assistance to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaAssistanceGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistanceGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaAssistanceCollectionResponse(); }
}

/**
* Form - Procomida Assistance
*/
// @Route("/opms/form/procomida-assistance/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistanceGetCollectionByCodes implements IReturn<ProcomidaAssistanceCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaAssistanceGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistanceGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaAssistanceCollectionResponse(); }
}

/**
* Form - Procomida Assistance
*/
// @Route("/opms/form/procomida-assistance/save", "POST")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistancePostSave implements IReturn<ProcomidaAssistanceSingleResponse>
{
    /**
    * The procomida assistance to save.
    */
    // @DataMember(Order=1)
    public form: ProcomidaAssistance;

    public constructor(init?: Partial<ProcomidaAssistancePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistancePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaAssistanceSingleResponse(); }
}

/**
* Form - Procomida Assistance
*/
// @Route("/opms/form/procomida-assistance/save/ids", "POST")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistancePostSaveWithIds implements IReturn<ProcomidaAssistanceSingleResponse>
{
    /**
    * The procomida assistance to save.
    */
    // @DataMember(Order=1)
    public form: ProcomidaAssistance;

    /**
    * The ID of the Institution associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the procomida assistance to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ProcomidaAssistancePostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistancePostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaAssistanceSingleResponse(); }
}

/**
* Form - Procomida Assistance
*/
// @Route("/opms/form/procomida-assistance/save/codes", "POST")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistancePostSaveWithCodes implements IReturn<ProcomidaAssistanceSingleResponse>
{
    /**
    * The procomida assistance to save.
    */
    // @DataMember(Order=1)
    public form: ProcomidaAssistance;

    /**
    * The Code of the Institution associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the procomida assistance to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ProcomidaAssistancePostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistancePostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaAssistanceSingleResponse(); }
}

/**
* Form - Procomida Assistance
*/
// @Route("/opms/form/procomida-assistance/delete", "DELETE")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistanceDelete implements IReturn<ProcomidaAssistanceDeleteResponse>
{
    /**
    * The procomida assistance to delete.
    */
    // @DataMember(Order=1)
    public form: ProcomidaAssistance;

    public constructor(init?: Partial<ProcomidaAssistanceDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistanceDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaAssistanceDeleteResponse(); }
}

/**
* Form - Procomida Assistance
*/
// @Route("/opms/form/procomida-assistance/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistanceDeleteWithId implements IReturn<ProcomidaAssistanceDeleteResponse>
{
    /**
    * The ID of the procomida assistance to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProcomidaAssistanceDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistanceDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaAssistanceDeleteResponse(); }
}

/**
* Form - Procomida Assistance
*/
// @Route("/opms/form/procomida-assistance/delete/ids", "DELETE")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistanceDeleteWithIds implements IReturn<ProcomidaAssistanceDeleteResponse>
{
    /**
    * The Patient ID of the procomida assistance to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the procomida assistance to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ProcomidaAssistanceDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistanceDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaAssistanceDeleteResponse(); }
}

/**
* Form - Procomida Assistance
*/
// @Route("/opms/form/procomida-assistance/delete/codes", "DELETE")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistanceDeleteByCodes implements IReturn<ProcomidaAssistanceDeleteResponse>
{
    /**
    * The Patient Study Number of the procomida assistance to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the procomida assistance to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ProcomidaAssistanceDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistanceDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaAssistanceDeleteResponse(); }
}

/**
* Form - Procomida Assistance
*/
// @Route("/opms/form/procomida-assistance/validate", "POST")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistancePostValidate implements IReturn<ProcomidaAssistanceValidationResponse>
{
    /**
    * The procomida assistance to validate.
    */
    // @DataMember(Order=1)
    public form: ProcomidaAssistance;

    public constructor(init?: Partial<ProcomidaAssistancePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistancePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaAssistanceValidationResponse(); }
}

/**
* Form - Procomida Assistance
*/
// @Route("/opms/form/procomida-assistance/validate/ids", "POST")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistancePostValidateWithIds implements IReturn<ProcomidaAssistanceValidationResponse>
{
    /**
    * The procomida assistance to validate.
    */
    // @DataMember(Order=1)
    public form: ProcomidaAssistance;

    /**
    * The ID of the Institution associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaAssistancePostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistancePostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaAssistanceValidationResponse(); }
}

/**
* Form - Procomida Assistance
*/
// @Route("/opms/form/procomida-assistance/validate/codes", "POST")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistancePostValidateWithCodes implements IReturn<ProcomidaAssistanceValidationResponse>
{
    /**
    * The procomida assistance to validate.
    */
    // @DataMember(Order=1)
    public form: ProcomidaAssistance;

    /**
    * The Code of the Institution associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the procomida assistance to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaAssistancePostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistancePostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaAssistanceValidationResponse(); }
}

/**
* Form - Procomida Incomplete
*/
// @Route("/opms/form/procomida-incomplete/single/id/{Id}", "GET")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompleteGetSingleById implements IReturn<ProcomidaIncompleteSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the procomida incomplete to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProcomidaIncompleteGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompleteGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaIncompleteSingleResponse(); }
}

/**
* Form - Procomida Incomplete
*/
// @Route("/opms/form/procomida-incomplete/single/ids", "GET")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompleteGetSingleByIds implements IReturn<ProcomidaIncompleteSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the procomida incomplete to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the procomida incomplete to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the procomida incomplete to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new procomida incomplete if no procomida incomplete is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ProcomidaIncompleteGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompleteGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaIncompleteSingleResponse(); }
}

/**
* Form - Procomida Incomplete
*/
// @Route("/opms/form/procomida-incomplete/single/codes", "GET")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompleteGetSingleByCodes implements IReturn<ProcomidaIncompleteSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the procomida incomplete to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the procomida incomplete to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the procomida incomplete to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new procomida incomplete if no procomida incomplete is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ProcomidaIncompleteGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompleteGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaIncompleteSingleResponse(); }
}

/**
* Form - Procomida Incomplete
*/
// @Route("/opms/form/procomida-incomplete/collection", "GET")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompleteGetCollection implements IReturn<ProcomidaIncompleteCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ProcomidaIncompleteGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompleteGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaIncompleteCollectionResponse(); }
}

/**
* Form - Procomida Incomplete
*/
// @Route("/opms/form/procomida-incomplete/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompleteGetCollectionByIds implements IReturn<ProcomidaIncompleteCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the procomida incomplete to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaIncompleteGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompleteGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaIncompleteCollectionResponse(); }
}

/**
* Form - Procomida Incomplete
*/
// @Route("/opms/form/procomida-incomplete/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompleteGetCollectionByCodes implements IReturn<ProcomidaIncompleteCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaIncompleteGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompleteGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaIncompleteCollectionResponse(); }
}

/**
* Form - Procomida Incomplete
*/
// @Route("/opms/form/procomida-incomplete/save", "POST")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompletePostSave implements IReturn<ProcomidaIncompleteSingleResponse>
{
    /**
    * The procomida incomplete to save.
    */
    // @DataMember(Order=1)
    public form: ProcomidaIncomplete;

    public constructor(init?: Partial<ProcomidaIncompletePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompletePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaIncompleteSingleResponse(); }
}

/**
* Form - Procomida Incomplete
*/
// @Route("/opms/form/procomida-incomplete/save/ids", "POST")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompletePostSaveWithIds implements IReturn<ProcomidaIncompleteSingleResponse>
{
    /**
    * The procomida incomplete to save.
    */
    // @DataMember(Order=1)
    public form: ProcomidaIncomplete;

    /**
    * The ID of the Institution associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the procomida incomplete to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ProcomidaIncompletePostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompletePostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaIncompleteSingleResponse(); }
}

/**
* Form - Procomida Incomplete
*/
// @Route("/opms/form/procomida-incomplete/save/codes", "POST")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompletePostSaveWithCodes implements IReturn<ProcomidaIncompleteSingleResponse>
{
    /**
    * The procomida incomplete to save.
    */
    // @DataMember(Order=1)
    public form: ProcomidaIncomplete;

    /**
    * The Code of the Institution associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the procomida incomplete to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ProcomidaIncompletePostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompletePostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaIncompleteSingleResponse(); }
}

/**
* Form - Procomida Incomplete
*/
// @Route("/opms/form/procomida-incomplete/delete", "DELETE")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompleteDelete implements IReturn<ProcomidaIncompleteDeleteResponse>
{
    /**
    * The procomida incomplete to delete.
    */
    // @DataMember(Order=1)
    public form: ProcomidaIncomplete;

    public constructor(init?: Partial<ProcomidaIncompleteDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompleteDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaIncompleteDeleteResponse(); }
}

/**
* Form - Procomida Incomplete
*/
// @Route("/opms/form/procomida-incomplete/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompleteDeleteWithId implements IReturn<ProcomidaIncompleteDeleteResponse>
{
    /**
    * The ID of the procomida incomplete to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProcomidaIncompleteDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompleteDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaIncompleteDeleteResponse(); }
}

/**
* Form - Procomida Incomplete
*/
// @Route("/opms/form/procomida-incomplete/delete/ids", "DELETE")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompleteDeleteWithIds implements IReturn<ProcomidaIncompleteDeleteResponse>
{
    /**
    * The Patient ID of the procomida incomplete to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the procomida incomplete to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ProcomidaIncompleteDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompleteDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaIncompleteDeleteResponse(); }
}

/**
* Form - Procomida Incomplete
*/
// @Route("/opms/form/procomida-incomplete/delete/codes", "DELETE")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompleteDeleteByCodes implements IReturn<ProcomidaIncompleteDeleteResponse>
{
    /**
    * The Patient Study Number of the procomida incomplete to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the procomida incomplete to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ProcomidaIncompleteDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompleteDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaIncompleteDeleteResponse(); }
}

/**
* Form - Procomida Incomplete
*/
// @Route("/opms/form/procomida-incomplete/validate", "POST")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompletePostValidate implements IReturn<ProcomidaIncompleteValidationResponse>
{
    /**
    * The procomida incomplete to validate.
    */
    // @DataMember(Order=1)
    public form: ProcomidaIncomplete;

    public constructor(init?: Partial<ProcomidaIncompletePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompletePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaIncompleteValidationResponse(); }
}

/**
* Form - Procomida Incomplete
*/
// @Route("/opms/form/procomida-incomplete/validate/ids", "POST")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompletePostValidateWithIds implements IReturn<ProcomidaIncompleteValidationResponse>
{
    /**
    * The procomida incomplete to validate.
    */
    // @DataMember(Order=1)
    public form: ProcomidaIncomplete;

    /**
    * The ID of the Institution associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaIncompletePostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompletePostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaIncompleteValidationResponse(); }
}

/**
* Form - Procomida Incomplete
*/
// @Route("/opms/form/procomida-incomplete/validate/codes", "POST")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompletePostValidateWithCodes implements IReturn<ProcomidaIncompleteValidationResponse>
{
    /**
    * The procomida incomplete to validate.
    */
    // @DataMember(Order=1)
    public form: ProcomidaIncomplete;

    /**
    * The Code of the Institution associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the procomida incomplete to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaIncompletePostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompletePostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaIncompleteValidationResponse(); }
}

/**
* Form - Ultrasound Imaging Lesion
*/
// @Route("/opms/form/ultrasound-imaging-lesion/single/id/{Id}", "GET")
// @Api(Description="Form - Ultrasound Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class UltrasoundImagingLesionGetSingleById implements IReturn<UltrasoundImagingLesionSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<UltrasoundImagingLesionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UltrasoundImagingLesionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UltrasoundImagingLesionSingleResponse(); }
}

/**
* Form - Ultrasound Imaging Lesion
*/
// @Route("/opms/form/ultrasound-imaging-lesion/single/ids", "GET")
// @Api(Description="Form - Ultrasound Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class UltrasoundImagingLesionGetSingleByIds implements IReturn<UltrasoundImagingLesionSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new ultrasound imaging lesion if no ultrasound imaging lesion is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<UltrasoundImagingLesionGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UltrasoundImagingLesionGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UltrasoundImagingLesionSingleResponse(); }
}

/**
* Form - Ultrasound Imaging Lesion
*/
// @Route("/opms/form/ultrasound-imaging-lesion/single/codes", "GET")
// @Api(Description="Form - Ultrasound Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class UltrasoundImagingLesionGetSingleByCodes implements IReturn<UltrasoundImagingLesionSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new ultrasound imaging lesion if no ultrasound imaging lesion is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<UltrasoundImagingLesionGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UltrasoundImagingLesionGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UltrasoundImagingLesionSingleResponse(); }
}

/**
* Form - Ultrasound Imaging Lesion
*/
// @Route("/opms/form/ultrasound-imaging-lesion/collection", "GET")
// @Api(Description="Form - Ultrasound Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class UltrasoundImagingLesionGetCollection implements IReturn<UltrasoundImagingLesionCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<UltrasoundImagingLesionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UltrasoundImagingLesionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UltrasoundImagingLesionCollectionResponse(); }
}

/**
* Form - Ultrasound Imaging Lesion
*/
// @Route("/opms/form/ultrasound-imaging-lesion/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Ultrasound Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class UltrasoundImagingLesionGetCollectionByIds implements IReturn<UltrasoundImagingLesionCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<UltrasoundImagingLesionGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UltrasoundImagingLesionGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UltrasoundImagingLesionCollectionResponse(); }
}

/**
* Form - Ultrasound Imaging Lesion
*/
// @Route("/opms/form/ultrasound-imaging-lesion/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Ultrasound Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionCollectionResponse)", StatusCode=500)
// @DataContract
export class UltrasoundImagingLesionGetCollectionByCodes implements IReturn<UltrasoundImagingLesionCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<UltrasoundImagingLesionGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UltrasoundImagingLesionGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UltrasoundImagingLesionCollectionResponse(); }
}

/**
* Form - Ultrasound Imaging Lesion
*/
// @Route("/opms/form/ultrasound-imaging-lesion/save", "POST")
// @Api(Description="Form - Ultrasound Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class UltrasoundImagingLesionPostSave implements IReturn<UltrasoundImagingLesionSingleResponse>
{
    /**
    * The ultrasound imaging lesion to save.
    */
    // @DataMember(Order=1)
    public form: UltrasoundImagingLesion;

    public constructor(init?: Partial<UltrasoundImagingLesionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UltrasoundImagingLesionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UltrasoundImagingLesionSingleResponse(); }
}

/**
* Form - Ultrasound Imaging Lesion
*/
// @Route("/opms/form/ultrasound-imaging-lesion/save/ids", "POST")
// @Api(Description="Form - Ultrasound Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class UltrasoundImagingLesionPostSaveWithIds implements IReturn<UltrasoundImagingLesionSingleResponse>
{
    /**
    * The ultrasound imaging lesion to save.
    */
    // @DataMember(Order=1)
    public form: UltrasoundImagingLesion;

    /**
    * The ID of the Institution associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<UltrasoundImagingLesionPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UltrasoundImagingLesionPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UltrasoundImagingLesionSingleResponse(); }
}

/**
* Form - Ultrasound Imaging Lesion
*/
// @Route("/opms/form/ultrasound-imaging-lesion/save/codes", "POST")
// @Api(Description="Form - Ultrasound Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionSingleResponse)", StatusCode=500)
// @DataContract
export class UltrasoundImagingLesionPostSaveWithCodes implements IReturn<UltrasoundImagingLesionSingleResponse>
{
    /**
    * The ultrasound imaging lesion to save.
    */
    // @DataMember(Order=1)
    public form: UltrasoundImagingLesion;

    /**
    * The Code of the Institution associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<UltrasoundImagingLesionPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UltrasoundImagingLesionPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UltrasoundImagingLesionSingleResponse(); }
}

/**
* Form - Ultrasound Imaging Lesion
*/
// @Route("/opms/form/ultrasound-imaging-lesion/delete", "DELETE")
// @Api(Description="Form - Ultrasound Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class UltrasoundImagingLesionDelete implements IReturn<UltrasoundImagingLesionDeleteResponse>
{
    /**
    * The ultrasound imaging lesion to delete.
    */
    // @DataMember(Order=1)
    public form: UltrasoundImagingLesion;

    public constructor(init?: Partial<UltrasoundImagingLesionDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UltrasoundImagingLesionDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new UltrasoundImagingLesionDeleteResponse(); }
}

/**
* Form - Ultrasound Imaging Lesion
*/
// @Route("/opms/form/ultrasound-imaging-lesion/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Ultrasound Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class UltrasoundImagingLesionDeleteWithId implements IReturn<UltrasoundImagingLesionDeleteResponse>
{
    /**
    * The ID of the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<UltrasoundImagingLesionDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UltrasoundImagingLesionDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new UltrasoundImagingLesionDeleteResponse(); }
}

/**
* Form - Ultrasound Imaging Lesion
*/
// @Route("/opms/form/ultrasound-imaging-lesion/delete/ids", "DELETE")
// @Api(Description="Form - Ultrasound Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class UltrasoundImagingLesionDeleteWithIds implements IReturn<UltrasoundImagingLesionDeleteResponse>
{
    /**
    * The Patient ID of the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<UltrasoundImagingLesionDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UltrasoundImagingLesionDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new UltrasoundImagingLesionDeleteResponse(); }
}

/**
* Form - Ultrasound Imaging Lesion
*/
// @Route("/opms/form/ultrasound-imaging-lesion/delete/codes", "DELETE")
// @Api(Description="Form - Ultrasound Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionDeleteResponse)", StatusCode=500)
// @DataContract
export class UltrasoundImagingLesionDeleteByCodes implements IReturn<UltrasoundImagingLesionDeleteResponse>
{
    /**
    * The Patient Study Number of the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<UltrasoundImagingLesionDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UltrasoundImagingLesionDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new UltrasoundImagingLesionDeleteResponse(); }
}

/**
* Form - Ultrasound Imaging Lesion
*/
// @Route("/opms/form/ultrasound-imaging-lesion/validate", "POST")
// @Api(Description="Form - Ultrasound Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionValidationResponse)", StatusCode=500)
// @DataContract
export class UltrasoundImagingLesionPostValidate implements IReturn<UltrasoundImagingLesionValidationResponse>
{
    /**
    * The ultrasound imaging lesion to validate.
    */
    // @DataMember(Order=1)
    public form: UltrasoundImagingLesion;

    public constructor(init?: Partial<UltrasoundImagingLesionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UltrasoundImagingLesionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UltrasoundImagingLesionValidationResponse(); }
}

/**
* Form - Ultrasound Imaging Lesion
*/
// @Route("/opms/form/ultrasound-imaging-lesion/validate/ids", "POST")
// @Api(Description="Form - Ultrasound Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionValidationResponse)", StatusCode=500)
// @DataContract
export class UltrasoundImagingLesionPostValidateWithIds implements IReturn<UltrasoundImagingLesionValidationResponse>
{
    /**
    * The ultrasound imaging lesion to validate.
    */
    // @DataMember(Order=1)
    public form: UltrasoundImagingLesion;

    /**
    * The ID of the Institution associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<UltrasoundImagingLesionPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UltrasoundImagingLesionPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UltrasoundImagingLesionValidationResponse(); }
}

/**
* Form - Ultrasound Imaging Lesion
*/
// @Route("/opms/form/ultrasound-imaging-lesion/validate/codes", "POST")
// @Api(Description="Form - Ultrasound Imaging Lesion")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.UltrasoundImagingLesionValidationResponse)", StatusCode=500)
// @DataContract
export class UltrasoundImagingLesionPostValidateWithCodes implements IReturn<UltrasoundImagingLesionValidationResponse>
{
    /**
    * The ultrasound imaging lesion to validate.
    */
    // @DataMember(Order=1)
    public form: UltrasoundImagingLesion;

    /**
    * The Code of the Institution associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the ultrasound imaging lesion to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<UltrasoundImagingLesionPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UltrasoundImagingLesionPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UltrasoundImagingLesionValidationResponse(); }
}

/**
* Form - Eligibility Assessment
*/
// @Route("/opms/form/eligibility-assessment/single/id/{Id}", "GET")
// @Api(Description="Form - Eligibility Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityAssessmentGetSingleById implements IReturn<EligibilityAssessmentSingleResponse>, IFormGetSingleById
{
    /**
    * The ID of the eligibility assessment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EligibilityAssessmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityAssessmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityAssessmentSingleResponse(); }
}

/**
* Form - Eligibility Assessment
*/
// @Route("/opms/form/eligibility-assessment/single/ids", "GET")
// @Api(Description="Form - Eligibility Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityAssessmentGetSingleByIds implements IReturn<EligibilityAssessmentSingleResponse>, IFormGetSingleByIds
{
    /**
    * The Patient ID of the eligibility assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eligibility assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The ID of the Institution associated to the eligibility assessment to retrieve.
    */
    // @DataMember(Order=5)
    public institutionId?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new eligibility assessment if no eligibility assessment is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<EligibilityAssessmentGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityAssessmentGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityAssessmentSingleResponse(); }
}

/**
* Form - Eligibility Assessment
*/
// @Route("/opms/form/eligibility-assessment/single/codes", "GET")
// @Api(Description="Form - Eligibility Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityAssessmentGetSingleByCodes implements IReturn<EligibilityAssessmentSingleResponse>, IFormGetSingleByCodes
{
    /**
    * The Patient Study Number of the eligibility assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eligibility assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    /**
    * The Code of the Institution associated to the eligibility assessment to retrieve.
    */
    // @DataMember(Order=5)
    public institutionCode: string;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=6)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no event is found.
    */
    // @DataMember(Order=7)
    public createEvent?: boolean;

    /**
    * Whether or not to create a new eligibility assessment if no eligibility assessment is found.
    */
    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<EligibilityAssessmentGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityAssessmentGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityAssessmentSingleResponse(); }
}

/**
* Form - Eligibility Assessment
*/
// @Route("/opms/form/eligibility-assessment/collection", "GET")
// @Api(Description="Form - Eligibility Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class EligibilityAssessmentGetCollection implements IReturn<EligibilityAssessmentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<EligibilityAssessmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityAssessmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityAssessmentCollectionResponse(); }
}

/**
* Form - Eligibility Assessment
*/
// @Route("/opms/form/eligibility-assessment/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Eligibility Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class EligibilityAssessmentGetCollectionByIds implements IReturn<EligibilityAssessmentCollectionResponse>, IFormGetCollectionByIds
{
    /**
    * The Patient ID of the eligibility assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the event associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the event associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityAssessmentGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityAssessmentGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityAssessmentCollectionResponse(); }
}

/**
* Form - Eligibility Assessment
*/
// @Route("/opms/form/eligibility-assessment/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Eligibility Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentCollectionResponse)", StatusCode=500)
// @DataContract
export class EligibilityAssessmentGetCollectionByCodes implements IReturn<EligibilityAssessmentCollectionResponse>, IFormGetCollectionByCodes
{
    /**
    * The Patient Study Number of the patient associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the event associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the event associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityAssessmentGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityAssessmentGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EligibilityAssessmentCollectionResponse(); }
}

/**
* Form - Eligibility Assessment
*/
// @Route("/opms/form/eligibility-assessment/save", "POST")
// @Api(Description="Form - Eligibility Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityAssessmentPostSave implements IReturn<EligibilityAssessmentSingleResponse>
{
    /**
    * The eligibility assessment to save.
    */
    // @DataMember(Order=1)
    public form: EligibilityAssessment;

    public constructor(init?: Partial<EligibilityAssessmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityAssessmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityAssessmentSingleResponse(); }
}

/**
* Form - Eligibility Assessment
*/
// @Route("/opms/form/eligibility-assessment/save/ids", "POST")
// @Api(Description="Form - Eligibility Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityAssessmentPostSaveWithIds implements IReturn<EligibilityAssessmentSingleResponse>
{
    /**
    * The eligibility assessment to save.
    */
    // @DataMember(Order=1)
    public form: EligibilityAssessment;

    /**
    * The ID of the Institution associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the eligibility assessment to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<EligibilityAssessmentPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityAssessmentPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityAssessmentSingleResponse(); }
}

/**
* Form - Eligibility Assessment
*/
// @Route("/opms/form/eligibility-assessment/save/codes", "POST")
// @Api(Description="Form - Eligibility Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentSingleResponse)", StatusCode=500)
// @DataContract
export class EligibilityAssessmentPostSaveWithCodes implements IReturn<EligibilityAssessmentSingleResponse>
{
    /**
    * The eligibility assessment to save.
    */
    // @DataMember(Order=1)
    public form: EligibilityAssessment;

    /**
    * The Code of the Institution associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    /**
    * The Repeat number of the eligibility assessment to retrieve.
    */
    // @DataMember(Order=6)
    public formRepeat?: number;

    /**
    * Whether or not to create a new patient if no Patient ID is provided.
    */
    // @DataMember(Order=7)
    public createPatient?: boolean;

    /**
    * Whether or not to create a new event if no Event Repeat is provided.
    */
    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<EligibilityAssessmentPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityAssessmentPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityAssessmentSingleResponse(); }
}

/**
* Form - Eligibility Assessment
*/
// @Route("/opms/form/eligibility-assessment/delete", "DELETE")
// @Api(Description="Form - Eligibility Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityAssessmentDelete implements IReturn<EligibilityAssessmentDeleteResponse>
{
    /**
    * The eligibility assessment to delete.
    */
    // @DataMember(Order=1)
    public form: EligibilityAssessment;

    public constructor(init?: Partial<EligibilityAssessmentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityAssessmentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityAssessmentDeleteResponse(); }
}

/**
* Form - Eligibility Assessment
*/
// @Route("/opms/form/eligibility-assessment/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Eligibility Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityAssessmentDeleteWithId implements IReturn<EligibilityAssessmentDeleteResponse>
{
    /**
    * The ID of the eligibility assessment to retrieve.
    */
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EligibilityAssessmentDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityAssessmentDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityAssessmentDeleteResponse(); }
}

/**
* Form - Eligibility Assessment
*/
// @Route("/opms/form/eligibility-assessment/delete/ids", "DELETE")
// @Api(Description="Form - Eligibility Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityAssessmentDeleteWithIds implements IReturn<EligibilityAssessmentDeleteResponse>
{
    /**
    * The Patient ID of the eligibility assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientId?: number;

    /**
    * The Event Definition ID of the Event associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    /**
    * The Event Repeat number of the Event associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eligibility assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<EligibilityAssessmentDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityAssessmentDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityAssessmentDeleteResponse(); }
}

/**
* Form - Eligibility Assessment
*/
// @Route("/opms/form/eligibility-assessment/delete/codes", "DELETE")
// @Api(Description="Form - Eligibility Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentDeleteResponse)", StatusCode=500)
// @DataContract
export class EligibilityAssessmentDeleteByCodes implements IReturn<EligibilityAssessmentDeleteResponse>
{
    /**
    * The Patient Study Number of the eligibility assessment to retrieve.
    */
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    /**
    * The Event Definition Code of the Event associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=3)
    public eventRepeat?: number;

    /**
    * The Form Repeat number of the eligibility assessment to retrieve.
    */
    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<EligibilityAssessmentDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityAssessmentDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EligibilityAssessmentDeleteResponse(); }
}

/**
* Form - Eligibility Assessment
*/
// @Route("/opms/form/eligibility-assessment/validate", "POST")
// @Api(Description="Form - Eligibility Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class EligibilityAssessmentPostValidate implements IReturn<EligibilityAssessmentValidationResponse>
{
    /**
    * The eligibility assessment to validate.
    */
    // @DataMember(Order=1)
    public form: EligibilityAssessment;

    public constructor(init?: Partial<EligibilityAssessmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityAssessmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityAssessmentValidationResponse(); }
}

/**
* Form - Eligibility Assessment
*/
// @Route("/opms/form/eligibility-assessment/validate/ids", "POST")
// @Api(Description="Form - Eligibility Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class EligibilityAssessmentPostValidateWithIds implements IReturn<EligibilityAssessmentValidationResponse>
{
    /**
    * The eligibility assessment to validate.
    */
    // @DataMember(Order=1)
    public form: EligibilityAssessment;

    /**
    * The ID of the Institution associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionId: number;

    /**
    * The ID of the Patient associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientId?: number;

    /**
    * The ID of the Event Definition of the Event associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionId: number;

    /**
    * The Event Repeat number of the Event associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityAssessmentPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityAssessmentPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityAssessmentValidationResponse(); }
}

/**
* Form - Eligibility Assessment
*/
// @Route("/opms/form/eligibility-assessment/validate/codes", "POST")
// @Api(Description="Form - Eligibility Assessment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.EligibilityAssessmentValidationResponse)", StatusCode=500)
// @DataContract
export class EligibilityAssessmentPostValidateWithCodes implements IReturn<EligibilityAssessmentValidationResponse>
{
    /**
    * The eligibility assessment to validate.
    */
    // @DataMember(Order=1)
    public form: EligibilityAssessment;

    /**
    * The Code of the Institution associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=2)
    public institutionCode: string;

    /**
    * The Study Number of the Patient associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=3)
    public patientStudyNumber: string;

    /**
    * The Code of the Event Definition associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    /**
    * The Event Repeat number of the Event associated with the eligibility assessment to retrieve.
    */
    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<EligibilityAssessmentPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EligibilityAssessmentPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EligibilityAssessmentValidationResponse(); }
}

// @Route("/opms/file/view/{Id}", "GET")
export class GetFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<GetFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file/download/{Id}", "GET")
export class DownloadFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<DownloadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file/download-all/{PatientId}/{FormDefinitionId}/{FormId}", "GET")
export class DownloadAllFiles implements IReturn<Blob>, IGet
{
    public patientId?: number;
    public formDefinitionId?: number;
    public formId?: number;

    public constructor(init?: Partial<DownloadAllFiles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadAllFiles'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

export class PostHasOpmsPermissionsByIds implements IReturn<HasOpmsPermissionCollectionResponse>
{
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public institutionId?: number;
    public patientId?: number;
    public permissions: Permission[];

    public constructor(init?: Partial<PostHasOpmsPermissionsByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasOpmsPermissionsByIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HasOpmsPermissionCollectionResponse(); }
}

export class PostHasOpmsPermissionsByCodes implements IReturn<HasOpmsPermissionCollectionResponse>
{
    public masterGroupCode: string;
    public coordinatingGroupCode: string;
    public institutionCode: string;
    public patientStudyNumber: string;
    public permissions: Permission[];

    public constructor(init?: Partial<PostHasOpmsPermissionsByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasOpmsPermissionsByCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HasOpmsPermissionCollectionResponse(); }
}

export class GetInstitutionPersonnelNames implements IReturn<InstitutionPersonnelNamesResponse>, IGet
{
    public institutionCode: string;
    public roles: number[];

    public constructor(init?: Partial<GetInstitutionPersonnelNames>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInstitutionPersonnelNames'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionPersonnelNamesResponse(); }
}

export class GetTrialPersonnelHasActiveRole implements IReturn<TrialPersonnelActiveRoleResponse>, IGet
{
    public personId: number;
    public institutionCode: string;
    public roles: number[];

    public constructor(init?: Partial<GetTrialPersonnelHasActiveRole>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTrialPersonnelHasActiveRole'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TrialPersonnelActiveRoleResponse(); }
}

