/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to style components
 */
import { useMediaQuery, useTheme } from '@material-ui/core';

/**
 * Used for the basic page layout.
 */
import {
    Field,
    ToggleButtonGroup,
    RadioGroup,
    RatingTable,
    RatingRow,
    ProForm,
    useAuthenticatedUser,
    UserType,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormBreadcrumbs,
    FormContext
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import SourceDocuments from '../../components/SourceDocuments';
import { useHistory } from 'react-router-dom';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IDecisionConflictScaleProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsProUpdate,
    Dtos.Permission.OpmsProAdminister
];


const DecisionConflictScale: React.FunctionComponent<IDecisionConflictScaleProps> = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

    const [user] = useAuthenticatedUser();

    const isPatient = React.useMemo(() => {
        return user?.type === UserType.Patient;
    }, [user]);

    const showSteps = isPatient || isMobile;

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdatePro, canAdministerPro], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);


    const history = useHistory();
    const { form } = React.useContext(FormContext);

    if (form && (form as Dtos.IProForm).completed && isPatient) {
        history.push(`/`);
    }

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    const canEdit = canUpdatePro || canAdministerPro;

    return (
        <>
            {
                !isPatient && (
                    <FormBreadcrumbs />
                )
            }
            <ProForm
                formType={Dtos.DecisionConflictScale}
                validateOn="onChange"
                useSteps={showSteps}
                canEdit={canEdit}
                canAdministerProForm={canAdministerPro}
            >
                {
                    showSteps ?
                        //<FieldGroup>
                        //    <Alert severity="info" icon={<FontAwesomeIcon fixedWidth icon={faInfoCircle} />} >
                        //        Please select the ONE box that best describes your health TODAY.
                        //    </Alert>
                        //    {

                        //    }
                        //</FieldGroup>
                        //<FieldGroup>
                        //    <Alert severity="info" icon={<FontAwesomeIcon fixedWidth icon={faInfoCircle} />} >
                        //        Please select the ONE box that best describes your health TODAY.
                        //    </Alert>
                        //    {
                                isExtraSmall ?
                                    <Field
                                        name="preferedTreatmentOptionId"
                                        component={ToggleButtonGroup}
                                        orientation="vertical"
                                        spacing={3}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    /> :
                                    <Field
                                        name="preferedTreatmentOptionId"
                                        component={RadioGroup}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    /> :
                        //    }
                        //</FieldGroup> :
                        <Field
                            name="preferedTreatmentOptionId"
                            component={RadioGroup}
                            xs={12}
                            sm={12}
                            md={6}
                            lg={5}
                            xl={5}
                        />
                }
                {
                    (showSteps) && (
                        isExtraSmall ?
                            <Field
                                name="availableOptionsKnownId"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="availableOptionsKnownId"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    (showSteps) && (
                        isExtraSmall ?
                            <Field
                                name="benefitsKnownId"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="benefitsKnownId"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    (showSteps) && (
                        isExtraSmall ?
                            <Field
                                name="risksAndSideEffectsKnownId"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="risksAndSideEffectsKnownId"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    (showSteps) && (
                        isExtraSmall ?
                            <Field
                                name="benefitsMatterMostId"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="benefitsMatterMostId"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    (showSteps) && (
                        isExtraSmall ?
                            <Field
                                name="risksAndSideEffectsMatterMostId"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="risksAndSideEffectsMatterMostId"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    (showSteps) && (
                        isExtraSmall ?
                            <Field
                                name="moreImportantId"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="moreImportantId"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    (showSteps) && (
                        isExtraSmall ?
                            <Field
                                name="enoughSupportToChooseId"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="enoughSupportToChooseId"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    (showSteps) && (
                        isExtraSmall ?
                            <Field
                                name="choosingWithoutPressureId"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="choosingWithoutPressureId"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    (showSteps) && (
                        isExtraSmall ?
                            <Field
                                name="enoughAdviceToChooseId"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="enoughAdviceToChooseId"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    (showSteps) && (
                        isExtraSmall ?
                            <Field
                                name="bestDecisionClearId"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="bestDecisionClearId"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    (showSteps) && (
                        isExtraSmall ?
                            <Field
                                name="sureAboutDecisionId"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="sureAboutDecisionId"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    (showSteps) && (
                        isExtraSmall ?
                            <Field
                                name="decisionEasyId"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="decisionEasyId"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    (showSteps) && (
                        isExtraSmall ?
                            <Field
                                name="informedDecisionId"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="informedDecisionId"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    (showSteps) && (
                        isExtraSmall ?
                            <Field
                                name="decisionShowsImportanceId"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="decisionShowsImportanceId"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    (showSteps) && (
                        isExtraSmall ?
                            <Field
                                name="stickWithDecisionId"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="stickWithDecisionId"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    (showSteps) && (
                        isExtraSmall ?
                            <Field
                                name="satisfiedWithDecisionId"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="satisfiedWithDecisionId"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    !showSteps && (
                        <RatingTable>
                            <RatingRow name="availableOptionsKnownId" />
                            <RatingRow name="benefitsKnownId" />
                            <RatingRow name="risksAndSideEffectsKnownId" />
                            <RatingRow name="benefitsMatterMostId" />
                            <RatingRow name="risksAndSideEffectsMatterMostId" />
                            <RatingRow name="moreImportantId" />
                            <RatingRow name="enoughSupportToChooseId" />
                            <RatingRow name="choosingWithoutPressureId" />
                            <RatingRow name="enoughAdviceToChooseId" />
                            <RatingRow name="bestDecisionClearId" />
                            <RatingRow name="sureAboutDecisionId" />
                            <RatingRow name="decisionEasyId" />
                            <RatingRow name="informedDecisionId" />
                            <RatingRow name="decisionShowsImportanceId" />
                            <RatingRow name="stickWithDecisionId" />
                            <RatingRow name="satisfiedWithDecisionId" />
                        </RatingTable>
                    )
                }
                {
                    canAdministerPro && (
                        <SourceDocuments />
                    )
                }
            </ProForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default DecisionConflictScale;
