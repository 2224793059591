import 'core-js';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { enableES5 } from 'immer';

import { OnlinePatientManagementProvider } from '@ngt/opms';
import onlinePatientManagement from './onlinePatientManagement';
import { Switch, Route } from 'react-router-dom';

enableES5();

declare let global: any;

if (typeof global === 'undefined') {
    (window as any).global = window;
}

if (!!onlinePatientManagement.store.getState().authenticatedUser.user) {
    ReactDOM.render(
        <OnlinePatientManagementProvider value={onlinePatientManagement}>
            <Switch>
                <Route path="/">
                    <App />
                </Route>
            </Switch>
        </OnlinePatientManagementProvider>,
        document.getElementById('root')
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
