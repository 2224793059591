/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    DatePicker,
    Select,
    ToggleButtonGroup,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    Checkbox,
    StaticGrid,
    StaticGridRow,
    FormGrid,
    Input,
    Numeric,
    IStaticGridCellProps,
    IStaticGridHeaderProps,
    FormLabel,
    FormBreadcrumbs,
    IFormGridCell,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    getParentPropertyPath,
    FieldProvider
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

import { Typography, makeStyles, InputAdornment } from '@material-ui/core';

import { NumberFormatValues } from 'react-number-format';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import SourceDocuments from '../../components/SourceDocuments';
import { get } from 'lodash-es';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IDiagnosticAndImagingProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    subHeading: {
        padding: theme.spacing(3, 3, 1, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */


const isMammogramDone = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiagnosticAndImaging>) => formState?.values.mammogramNotDone !== true;

const isIndexLesionsIdentifiableOnMammogram = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiagnosticAndImaging>) => formState?.values.mammogramNotDone !== true && formState?.values.indexLesionsIdentifiableOnMammogram === Dtos.LookupYesNoType.Yes;

const isMammogramDoneLeft = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiagnosticAndImaging>) => formState?.values.mammogramNotDone !== true && formState?.values.indexLesionsIdentifiableOnMammogram === Dtos.LookupYesNoType.Yes && formState.values.mammogramLesionLocationLeft === true;

const isMammogramDoneRight = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiagnosticAndImaging>) => formState?.values.mammogramNotDone !== true && formState?.values.indexLesionsIdentifiableOnMammogram === Dtos.LookupYesNoType.Yes && formState.values.mammogramLesionLocationRight === true;

const isSpectralMammogramDone = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiagnosticAndImaging>) => GetFieldLookupItem(lookups, 'spectralMammogramPerformed', formState?.values.spectralMammogramPerformed)?.type === Dtos.LookupYesNoType.Yes;

//const isSpectralMammogramDoneLeft = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiagnosticAndImaging>) => GetFieldLookupItem(lookups, 'spectralMammogramPerformed', formState?.values.spectralMammogramPerformed)?.type === Dtos.LookupYesNoType.Yes && formState?.values.spectralMammogramLesionLocationLeft === true;

//const isSpectralMammogramDoneRight = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiagnosticAndImaging>) => GetFieldLookupItem(lookups, 'spectralMammogramPerformed', formState?.values.spectralMammogramPerformed)?.type === Dtos.LookupYesNoType.Yes && formState?.values.spectralMammogramLesionLocationRight === true;

const isUltrasoundMammogramDone = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiagnosticAndImaging>) => GetFieldLookupItem(lookups, 'ultrasoundMammogramPerformed', formState?.values.ultrasoundMammogramPerformed)?.type === Dtos.LookupYesNoType.Yes;

const isIndexLesionsIdentifiableOnUltrasound = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiagnosticAndImaging>) => GetFieldLookupItem(lookups, 'ultrasoundMammogramPerformed', formState?.values.ultrasoundMammogramPerformed)?.type === Dtos.LookupYesNoType.Yes && GetFieldLookupItem(lookups, 'indexLesionsIdentifiableOnUltrasound', formState?.values.indexLesionsIdentifiableOnUltrasound)?.type === Dtos.LookupYesNoType.Yes;

const isUltrasoundMammogramDoneLeft = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiagnosticAndImaging>) => GetFieldLookupItem(lookups, 'ultrasoundMammogramPerformed', formState?.values.ultrasoundMammogramPerformed)?.type === Dtos.LookupYesNoType.Yes && GetFieldLookupItem(lookups, 'indexLesionsIdentifiableOnUltrasound', formState?.values.indexLesionsIdentifiableOnUltrasound)?.type === Dtos.LookupYesNoType.Yes && formState?.values.ultrasoundMammogramLesionLocationLeft === true;

const isUltrasoundMammogramDoneRight = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiagnosticAndImaging>) => GetFieldLookupItem(lookups, 'ultrasoundMammogramPerformed', formState?.values.ultrasoundMammogramPerformed)?.type === Dtos.LookupYesNoType.Yes && GetFieldLookupItem(lookups, 'indexLesionsIdentifiableOnUltrasound', formState?.values.indexLesionsIdentifiableOnUltrasound)?.type === Dtos.LookupYesNoType.Yes && formState?.values.ultrasoundMammogramLesionLocationRight === true;

//const isPetScanDone = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiagnosticAndImaging>) => GetFieldLookupItem(lookups, 'petScanPerformed', formState?.values.petScanPerformed)?.type === Dtos.LookupYesNoType.Yes;

//const isPetScanDoneLeft = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiagnosticAndImaging>) => GetFieldLookupItem(lookups, 'petScanPerformed', formState?.values.petScanPerformed)?.type === Dtos.LookupYesNoType.Yes && formState?.values.petScanLesionLocationLeft === true;

//const isPetScanDoneRight = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiagnosticAndImaging>) => GetFieldLookupItem(lookups, 'petScanPerformed', formState?.values.petScanPerformed)?.type === Dtos.LookupYesNoType.Yes && formState?.values.petScanLesionLocationRight === true;

const isOtherImagingDone = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiagnosticAndImaging>) => GetFieldLookupItem(lookups, 'otherImagingPerformed', formState?.values.otherImagingPerformed)?.type === Dtos.LookupYesNoType.Yes;

//const isOtherImagingDoneLeft = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiagnosticAndImaging>) => GetFieldLookupItem(lookups, 'otherImagingPerformed', formState?.values.otherImagingPerformed)?.type === Dtos.LookupYesNoType.Yes && formState?.values.otherImagingLesionLocationLeft === true;

//const isOtherImagingDoneRight = ({ lookups, formState }: ICrfConditionParameters<Dtos.DiagnosticAndImaging>) => GetFieldLookupItem(lookups, 'otherImagingPerformed', formState?.values.otherImagingPerformed)?.type === Dtos.LookupYesNoType.Yes && formState?.values.otherImagingLesionLocationRight === true;

//const isPetScanLesionSizeSpecified = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.DiagnosticAndImaging>) => {
//    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
//    const parentPropertyPath = getParentPropertyPath(parentFieldName);

//    if (!parentPropertyPath) {
//        return false;
//    }

//    const row: Dtos.PetScanImagingLesion = get(formState?.values, parentPropertyPath);

//    if (!row) {
//        return false;
//    }

//    return row.sizeNotSpecified !== true;
//};

//const isOtherImagingLesionSizeSpecified = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.DiagnosticAndImaging>) => {
//    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
//    const parentPropertyPath = getParentPropertyPath(parentFieldName);

//    if (!parentPropertyPath) {
//        return false;
//    }

//    const row: Dtos.OtherImagingLesion = get(formState?.values, parentPropertyPath);

//    if (!row) {
//        return false;
//    }

//    return row.sizeNotSpecified !== true;
//};


const isBreastMammogramLesionSizeNotAvailable = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.DiagnosticAndImaging>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.BreastMammogramLesion = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.sizeNotAvailable !== true;
};

const isBreastMammogramLesionDensityNotAvailable = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.DiagnosticAndImaging>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.BreastMammogramLesion = get(formState?.values, parentPropertyPath);

    if (!row) {
        return false;
    }

    return row.densityNotAvailable !== true;
};

export const THREE_DIGIT_POSITIVE_INTEGER = {
    decimalScale: 0,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 1000) {
            return true;
        }

        return false;
    }
}

//const headerEmpty: IStaticGridHeaderProps[] = [
//    {
//        columnName: 'question',
//        content: null,
//        width: '50%',
//        minWidth: 300
//    },
//    {
//        columnName: 'leftbreast',
//        content: null,
//        width: '25%',
//        minWidth: 200
//    },
//    {
//        columnName: 'rightbreast',
//        content: null,
//        width: '25%',
//        minWidth: 200
//    }
//];

const leftRightHeader: IStaticGridHeaderProps[] = [
    {
        columnName: 'question',
        content: '',
        width: '50%',
        minWidth: 300
    },
    {
        columnName: 'leftbreast',
        content: 'Left Breast',
        width: '25%',
        minWidth: 200
    },
    {
        columnName: 'rightbreast',
        content: 'Right Breast',
        width: '25%',
        minWidth: 200
    }
];

//const rowMammogramLesionLocation: IStaticGridCellProps[] = [
//    {
//        columnName: 'question',
//        content: (
//            <FormLabel />
//        ),
//        fieldName: 'mammogramLesionLocationLeft'
//    },
//    {
//        columnName: 'leftbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.DiagnosticAndImaging}
//                condition={isIndexLesionsIdentifiableOnMammogram}
//                mode="Enable"
//                subscription={{ values: true }}
//            >
//                <Input
//                    component={Checkbox}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'mammogramLesionLocationLeft'
//    },
//    {
//        columnName: 'rightbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.DiagnosticAndImaging}
//                condition={isIndexLesionsIdentifiableOnMammogram}
//                mode="Enable"
//                subscription={{ values: true }}
//            >
//                <Input
//                    component={Checkbox}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'mammogramLesionLocationRight'
//    }
//];

const rowMammogramFocality: IStaticGridCellProps[] = [
    {
        columnName: 'question',
        content: (
            <FormLabel />
        ),
        fieldName: 'mammogramFocalityLeft'
    },
    {
        columnName: 'leftbreast',
        content: (
            <CrfCondition
                type={Dtos.DiagnosticAndImaging}
                condition={isMammogramDoneLeft}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Select}
                />
            </CrfCondition>
        ),
        fieldName: 'mammogramFocalityLeft'
    },
    {
        columnName: 'rightbreast',
        content: (
            <CrfCondition
                type={Dtos.DiagnosticAndImaging}
                condition={isMammogramDoneRight}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Select}
                />
            </CrfCondition>
        ),
        fieldName: 'mammogramFocalityRight'
    }
];

//const rowMammogramShowsProminentCalcification: IStaticGridCellProps[] = [
//    {
//        columnName: 'question',
//        content: (
//            <FormLabel />
//        ),
//        fieldName: 'mammogramShowsProminentCalcificationLeft'
//    },
//    {
//        columnName: 'leftbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.DiagnosticAndImaging}
//                condition={isMammogramDoneLeft}
//                mode="Enable"
//                subscription={{ values: true }}
//            >
//                <Input
//                    component={ToggleButtonGroup}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'mammogramShowsProminentCalcificationLeft'
//    },
//    {
//        columnName: 'rightbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.DiagnosticAndImaging}
//                condition={isMammogramDoneRight}
//                mode="Enable"
//                subscription={{ values: true }}
//            >
//                <Input
//                    component={ToggleButtonGroup}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'mammogramShowsProminentCalcificationRight'
//    }
//];

//const rowSpectralMammogramLesionLocation: IStaticGridCellProps[] = [
//    {
//        columnName: 'question',
//        content: (
//            <FormLabel />
//        ),
//        fieldName: 'spectralMammogramLesionLocationLeft'
//    },
//    {
//        columnName: 'leftbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.DiagnosticAndImaging}
//                condition={isSpectralMammogramDone}
//                mode="Enable"
//                subscription={{ values: true }}
//            >
//                <Input
//                    component={Checkbox}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'spectralMammogramLesionLocationLeft'
//    },
//    {
//        columnName: 'rightbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.DiagnosticAndImaging}
//                condition={isSpectralMammogramDone}
//                mode="Enable"
//                subscription={{ values: true }}
//            >
//                <Input
//                    component={Checkbox}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'spectralMammogramLesionLocationRight'
//    }
//];

//const rowSpectralMammogramFocality: IStaticGridCellProps[] = [
//    {
//        columnName: 'question',
//        content: (
//            <FormLabel />
//        ),
//        fieldName: 'spectralMammogramFocalityLeft'
//    },
//    {
//        columnName: 'leftbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.DiagnosticAndImaging}
//                condition={isSpectralMammogramDoneLeft}
//                mode="Enable"
//                subscription={{ values: true }}
//            >
//                <Input
//                    component={Select}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'spectralMammogramFocalityLeft'
//    },
//    {
//        columnName: 'rightbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.DiagnosticAndImaging}
//                condition={isSpectralMammogramDoneRight}
//                mode="Enable"
//                subscription={{ values: true }}
//            >
//                <Input
//                    component={Select}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'spectralMammogramFocalityRight'
//    }
//];

//const rowUltrasoundMammogramLesionLocation: IStaticGridCellProps[] = [
//    {
//        columnName: 'question',
//        content: (
//            <FormLabel />
//        ),
//        fieldName: 'ultrasoundMammogramLesionLocationLeft'
//    },
//    {
//        columnName: 'leftbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.DiagnosticAndImaging}
//                condition={isIndexLesionsIdentifiableOnUltrasound}
//                mode="Enable"
//                subscription={{ values: true }}
//            >
//                <Input
//                    component={Checkbox}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'ultrasoundMammogramLesionLocationLeft'
//    },
//    {
//        columnName: 'rightbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.DiagnosticAndImaging}
//                condition={isIndexLesionsIdentifiableOnUltrasound}
//                mode="Enable"
//                subscription={{ values: true }}
//            >
//                <Input
//                    component={Checkbox}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'ultrasoundMammogramLesionLocationRight'
//    }
//];

const rowUltrasoundMammogramFocality: IStaticGridCellProps[] = [
    {
        columnName: 'question',
        content: (
            <FormLabel />
        ),
        fieldName: 'ultrasoundMammogramFocalityLeft'
    },
    {
        columnName: 'leftbreast',
        content: (
            <CrfCondition
                type={Dtos.DiagnosticAndImaging}
                condition={isUltrasoundMammogramDoneLeft}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Select}
                />
            </CrfCondition>
        ),
        fieldName: 'ultrasoundMammogramFocalityLeft'
    },
    {
        columnName: 'rightbreast',
        content: (
            <CrfCondition
                type={Dtos.DiagnosticAndImaging}
                condition={isUltrasoundMammogramDoneRight}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Select}
                />
            </CrfCondition>
        ),
        fieldName: 'ultrasoundMammogramFocalityRight'
    }
];

//const rowPetScanLesionLocation: IStaticGridCellProps[] = [
//    {
//        columnName: 'question',
//        content: (
//            <FormLabel />
//        ),
//        fieldName: 'petScanLesionLocationLeft'
//    },
//    {
//        columnName: 'leftbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.DiagnosticAndImaging}
//                condition={isPetScanDone}
//                mode="Enable"
//                subscription={{ values: true }}
//            >
//                <Input
//                    component={Checkbox}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'petScanLesionLocationLeft'
//    },
//    {
//        columnName: 'rightbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.DiagnosticAndImaging}
//                condition={isPetScanDone}
//                mode="Enable"
//                subscription={{ values: true }}
//            >
//                <Input
//                    component={Checkbox}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'petScanLesionLocationRight'
//    }
//];

//const rowPetScanFocality: IStaticGridCellProps[] = [
//    {
//        columnName: 'question',
//        content: (
//            <FormLabel />
//        ),
//        fieldName: 'petScanFocalityLeft'
//    },
//    {
//        columnName: 'leftbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.DiagnosticAndImaging}
//                condition={isPetScanDoneLeft}
//                mode="Enable"
//                subscription={{ values: true }}
//            >
//                <Input
//                    component={Select}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'petScanFocalityLeft'
//    },
//    {
//        columnName: 'rightbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.DiagnosticAndImaging}
//                condition={isPetScanDoneRight}
//                mode="Enable"
//                subscription={{ values: true }}
//            >
//                <Input
//                    component={Select}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'petScanFocalityRight'
//    }
//];

//const rowOtherImagingLesionLocation: IStaticGridCellProps[] = [
//    {
//        columnName: 'question',
//        content: (
//            <FormLabel />
//        ),
//        fieldName: 'otherImagingesionLocationLeft'
//    },
//    {
//        columnName: 'leftbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.DiagnosticAndImaging}
//                condition={isOtherImagingDone}
//                mode="Enable"
//                subscription={{ values: true }}
//            >
//                <Input
//                    component={Checkbox}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'otherImagingLesionLocationLeft'
//    },
//    {
//        columnName: 'rightbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.DiagnosticAndImaging}
//                condition={isOtherImagingDone}
//                mode="Enable"
//                subscription={{ values: true }}
//            >
//                <Input
//                    component={Checkbox}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'otherImagingLesionLocationRight'
//    }
//];

//const rowOtherImagingFocality: IStaticGridCellProps[] = [
//    {
//        columnName: 'question',
//        content: (
//            <FormLabel />
//        ),
//        fieldName: 'otherImagingFocalityLeft'
//    },
//    {
//        columnName: 'leftbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.DiagnosticAndImaging}
//                condition={isOtherImagingDoneLeft}
//                mode="Enable"
//                subscription={{ values: true }}
//            >
//                <Input
//                    component={Select}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'otherImagingFocalityLeft'
//    },
//    {
//        columnName: 'rightbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.DiagnosticAndImaging}
//                condition={isOtherImagingDoneRight}
//                mode="Enable"
//                subscription={{ values: true }}
//            >
//                <Input
//                    component={Select}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'otherImagingFocalityRight'
//    }
//];

const breastMammogramLesionsColumns: Array<IFormGridCell<Dtos.BreastMammogramLesion>> = [
    {
        name: 'side',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <Input
                component={Select}
                onChange={() => console.log('change')}
                onBlur={() => console.log('blur')}
                onFocus={() => console.log('focus')}
            />
        )
    },
    {
        name: 'location',
        minWidth: 250,
        maxWidth: 250,
        width: 250,
        content: (
            <Input
                component={Select}
            />
        )
    },
    {
        name: 'size',
        content: (
            <CrfCondition
                type={Dtos.DiagnosticAndImaging}
                condition={isBreastMammogramLesionSizeNotAvailable}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Numeric}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                mm&nbsp;
                            </InputAdornment>
                        )
                    }}
                    numberFormat={THREE_DIGIT_POSITIVE_INTEGER}
                />
            </CrfCondition>
        )
    },
    {
        name: 'sizeNotAvailable',
        minWidth: 220,
        maxWidth: 220,
        width: 220,
        header: ' ',
        content: (
            <Input
                component={Checkbox}
            />
        )
    },
    {
        name: 'density',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <CrfCondition
                type={Dtos.DiagnosticAndImaging}
                condition={isBreastMammogramLesionDensityNotAvailable}
                mode="Enable"
                subscription={{ values: true }}
            >
                <Input
                    component={Select}
                />
            </CrfCondition>
        )
    },
    {
        name: 'densityNotAvailable',
        minWidth: 220,
        maxWidth: 220,
        width: 220,
        header: ' ',
        content: (
            <Input
                component={Checkbox}
            />
        )
    },
]

const ultrasoundImagingLesionColumns: Array<IFormGridCell<Dtos.UltrasoundImagingLesion>> = [
    {
        name: 'side',
        minWidth: 140,
        maxWidth: 140,
        width: 140,
        content: (
            <Input
                component={Select}
            />
        )
    },
    {
        name: 'location',
        minWidth: 250,
        maxWidth: 250,
        width: 250,
        content: (
            <Input
                component={Select}
            />
        )
    },
    {
        name: 'size',
        content: (
            <Input
                component={Numeric}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            mm&nbsp;
                        </InputAdornment>
                    )
                }}
                numberFormat={THREE_DIGIT_POSITIVE_INTEGER}
            />
        )
    }
]

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate
];

const DiagnosticAndImaging: React.FunctionComponent<IDiagnosticAndImagingProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    const canEdit = canUpdateCrf &&
        !!institution &&
        !institution.recruitmentDisabled;

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.DiagnosticAndImaging}
                validateOn="onChange"
                canEdit={canEdit}
            >
                <FieldGroup>
                    <Typography
                        variant="h2"
                        color="primary"
                        className={classes.subHeading}
                    >
                        Breast Mammogram
                    </Typography>
                    <CrfCondition
                        type={Dtos.DiagnosticAndImaging}
                        condition={isMammogramDone}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="mammogramDate"
                            component={DatePicker}
                        />
                    </CrfCondition>
                    <Field
                        name="mammogramNotDone"
                        label={null}
                        component={Checkbox}
                    />
                    <CrfCondition
                        type={Dtos.DiagnosticAndImaging}
                        condition={isMammogramDone}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="mammogramType"
                            component={Select}
                        />
                        <Field
                            name="indexLesionsIdentifiableOnMammogram"
                            component={ToggleButtonGroup}
                        />
                        <StaticGrid
                            headers={leftRightHeader}
                        >
                            <StaticGridRow
                                cells={rowMammogramFocality}
                            />
                        </StaticGrid>
                        <CrfCondition
                            type={Dtos.DiagnosticAndImaging}
                            condition={isIndexLesionsIdentifiableOnMammogram}
                            mode="Enable"
                            subscription={{ values: true }}
                        >
                            <Typography
                                variant="h3"
                                color="secondary"
                                className={classes.subHeading}
                            >
                                Breast Mammogram Imaging Lesions
                            </Typography>
                            <FormGrid
                                type={Dtos.BreastMammogramLesion}
                                name="breastMammogramLesions"
                                columns={breastMammogramLesionsColumns}
                            />
                        </CrfCondition>
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <Typography
                        variant="h2"
                        color="primary"
                        className={classes.subHeading}
                    >
                        Other Breast Imaging - Ultrasound
                        </Typography>
                    <Field
                        name="ultrasoundMammogramPerformed"
                        component={ToggleButtonGroup}
                    />
                    <CrfCondition
                        type={Dtos.DiagnosticAndImaging}
                        condition={isUltrasoundMammogramDone}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="ultrasoundMammogramDate"
                            component={DatePicker}
                        />
                        <Field
                            name="indexLesionsIdentifiableOnUltrasound"
                            component={ToggleButtonGroup}
                        />
                        <StaticGrid
                            headers={leftRightHeader}
                        >
                            <StaticGridRow
                                cells={rowUltrasoundMammogramFocality}
                            />
                        </StaticGrid>
                        <CrfCondition
                            type={Dtos.DiagnosticAndImaging}
                            condition={isIndexLesionsIdentifiableOnUltrasound}
                            mode="Enable"
                            subscription={{ values: true }}
                        >
                            <Typography
                                variant="h3"
                                color="secondary"
                                className={classes.subHeading}
                            >
                                Ultrasound Imaging Lesions
                            </Typography>
                            <FieldProvider name="ultrasoundImagingLesions" />
                            <FormGrid
                                type={Dtos.UltrasoundImagingLesion}
                                name="ultrasoundImagingLesions"
                                columns={ultrasoundImagingLesionColumns}
                            />
                        </CrfCondition>
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <Typography
                        variant="h2"
                        color="primary"
                        className={classes.subHeading}
                    >
                        Breast Imaging - Contrast Enhanced Mammography
                    </Typography>
                    <Field
                        name="spectralMammogramPerformed"
                        component={ToggleButtonGroup}
                    />
                    <CrfCondition
                        type={Dtos.DiagnosticAndImaging}
                        condition={isSpectralMammogramDone}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="spectralMammogramDate"
                            component={DatePicker}
                        />
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <Typography
                        variant="h2"
                        color="primary"
                        className={classes.subHeading}
                    >
                        Breast Imaging - Other Type
                    </Typography>
                    <Field
                        name="otherImagingPerformed"
                        component={ToggleButtonGroup}
                    />
                    <CrfCondition
                        type={Dtos.DiagnosticAndImaging}
                        condition={isOtherImagingDone}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="otherImagingDate"
                            component={DatePicker}
                        />
                        <Field
                            name="otherImagingType"
                            component={Text}
                        />
                    </CrfCondition>
                </FieldGroup>
                <SourceDocuments />
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default DiagnosticAndImaging;
