/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to style components
 */
import { makeStyles, Button, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid, Tooltip } from '@material-ui/core';

/**
 * Used for the basic page layout.
 */
import {
    PatientSummaryList,
    PatientContext,
    PatientValidationContext,
    PatientBreadcrumbs,
    PatientInformation,
    PatientInformationFn,
    ProgressButton,
    InstitutionContext,
    MasterGroupContext,
    CollaboratingGroupContext,
    RouteLoading,
    ValidationResultType,
    useSnackbar,
    IPatientSummaryActionProps,
    OnlinePatientManagementContext,
    IPatient
} from '@ngt/opms';

import Alert from '@material-ui/lab/Alert';

import AlertTitle from '@material-ui/lab/AlertTitle';
import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons/faInfoCircle';
import { RequestState } from '@ngt/request-utilities';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */
import PatientActiveDirectory from './PatientActiveDirectory';

/*
 * Used to type patient state.
 */
import * as Dtos from '../api/dtos';
import { usePermissionsByIds } from '@ngt/opms-bctapi';
import { JsonServiceClient } from '@servicestack/client';
import * as classNames from 'classnames';
import { useParams } from 'react-router-dom';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IPatientSummaryParams {
    masterGroupCode?: string
    collaboratingGroupCode?: string
    countryCode?: string
    institutionCode?: string
}

interface IPatientSummaryProps {
    showAdminPage?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    title: {
        paddingBottom: theme.spacing(3),

        '&:last-child': {
            textAlign: 'right'
        },
        '&:nth-last-child(2)': {
            textAlign: 'right'
        },
        '&:first-child': {
            textAlign: 'left'
        },
    },
    buttonGroup: {
        padding: theme.spacing(3, 0, 0, 0),
        textAlign: 'center',

        '& > *': {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1)
        },

        [theme.breakpoints.up('sm')]: {
            textAlign: 'right',
            '& > *': {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(0)
            }
        }
    },
    button: {
        height: "fit-content",
        marginLeft: theme.spacing(3),

        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end"
    }
}));

const useEventActionStyles = makeStyles(theme => ({
    button: {
        minWidth: 140,
        borderRadius: 0,
        whiteSpace: 'nowrap',
        '&:first-child': {
            marginLeft: theme.spacing(0)
        }
    },
    tooltip: {
        display: 'flex'
    },
    wrapper: {
        display: 'flex'
    }
}));

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsAdminister,
    Dtos.Permission.OpmsPatientUpdate,
    Dtos.Permission.OpmsPatientImpersonate,
    Dtos.Permission.OpmsPatientAccountManagement
];

const patientCaption: PatientInformationFn = (patient) => (patient as Dtos.Patient)?.initials
const patientStateCaption: PatientInformationFn = (patient) => DateTime.fromISO((patient as Dtos.Patient)?.registrationDate ?? patient?.enteredDate ?? DateTime.local().toISO({ includeOffset: false })).toFormat('dd/MM/yyyy')

enum DialogState {
    None = 0,
    Create = 1,
    CreateAndNotify = 2,
    Resend = 3
}

const useCreateAndNotifyActions = (
    updateTo: Dtos.PatientStateType,
    hideButton: (patient: IPatient | null) => boolean,
    eventTitle: string,
    eventName: string,
    saving: boolean,
    updatePatientState: (state: Dtos.PatientStateType, skipPatientEmail: boolean) => void,
    enqueueSnackbar: ReturnType<typeof useSnackbar>['enqueueSnackbar'],
    classes: ReturnType<typeof useEventActionStyles>,
    client: JsonServiceClient,
    patient: IPatient | null,
    patientValid: boolean
) => {
    const [dialogState, setDialogState] = React.useState(DialogState.None);

    const openCreateDialog = React.useCallback(() => {
        setDialogState(DialogState.Create);
    }, [setDialogState]);

    const openCreateAndNotifyDialog = React.useCallback(() => {
        setDialogState(DialogState.CreateAndNotify);
    }, [setDialogState]);

    const openResendDialog = React.useCallback(() => {
        setDialogState(DialogState.Resend);
    }, [setDialogState]);

    const closeDialog = React.useCallback(() => {
        setDialogState(DialogState.None);
    }, [setDialogState]);

    const onCreateAndNotifyClick = React.useCallback(async () => {
        try {
            await updatePatientState(updateTo, false);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {eventTitle} Event Created
                    </AlertTitle>
                    The {eventName} event was successfully created.
                </>,
                { variant: 'success' }
            );

            closeDialog();
        }
        catch (error) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {eventTitle} Event Not Created
                    </AlertTitle>
                    An error occurred while attempting to create the {eventName} event.
                </>,
                { variant: 'critical' }
            );

            closeDialog();
        }
    }, [updatePatientState, enqueueSnackbar, updateTo, eventTitle, eventName, closeDialog]);

    const onCreateClick = React.useCallback(async () => {
        try {
            await updatePatientState(updateTo, true);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {eventTitle} Event Created
                    </AlertTitle>
                    The {eventName} event was successfully created.
                </>,
                { variant: 'success' }
            );

            closeDialog();
        }
        catch (error) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {eventTitle} Event Not Created
                    </AlertTitle>
                    An error occurred while attempting to create the {eventName} event.
                </>,
                { variant: 'critical' }
            );

            closeDialog();
        }
    }, [updatePatientState, enqueueSnackbar, updateTo, eventTitle, eventName, closeDialog]);

    const [sendingEmail, setSendingEmail] = React.useState(false);

    const onResendClick = React.useCallback(async () => {
        setSendingEmail(true);

        try {
            await client.post(new Dtos.SendPatientReminder({ patientId: patient?.id }));

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {eventTitle} Notification Sent
                    </AlertTitle>
                    The {eventName} notification was successfully sent to the patient.
                </>,
                { variant: 'success' }
            );

            closeDialog();
        }
        catch (error) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        {eventTitle} Notification Not Sent
                    </AlertTitle>
                    An error occurred while attempting to create the {eventName} notification to the patient.
                </>,
                { variant: 'critical' }
            );

            closeDialog();
        }

        setSendingEmail(false);
    }, [patient, enqueueSnackbar, client, setSendingEmail, eventName, eventTitle, closeDialog]);

    const action: React.FunctionComponent<IPatientSummaryActionProps> = React.useCallback(({
        patient: actionPatient,
        eventDefinition,
        validation
    }) => {
        if (hideButton(actionPatient)) {
            return null;
        }

        const hasAccount = (actionPatient as Dtos.Patient).account;


        if (actionPatient.patientStateId === updateTo) {
            if (!hasAccount) {
                return null;
            }

            return (
                <>
                    <ProgressButton
                        loading={saving || sendingEmail}
                        variant="contained"
                        color="primary"
                        onClick={openResendDialog}
                        spacing={0}
                        className={classes.button}
                    >
                        Resend
                    </ProgressButton>
                    <Dialog
                        open={dialogState === DialogState.Resend}
                        onClose={closeDialog}
                        aria-labelledby="resend-dialog-title"
                        aria-describedby="resend-dialog-description"
                    >
                        <DialogTitle id="resend-dialog-title">Resend {eventTitle} Notification?</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="resend-dialog-description">
                                Resending the {eventName} notification will email the patient.
                                <br /><br />
                                Are you sure you wish to continue?
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closeDialog} color="secondary">
                                No
                        </Button>
                            <Button onClick={onResendClick} color="primary" variant="contained" autoFocus>
                                Yes
                        </Button>
                        </DialogActions>
                    </Dialog>
                </>
            );
        }

        const createButton = (
            <ProgressButton
                loading={saving}
                variant="contained"
                color="primary"
                onClick={openCreateDialog}
                spacing={0}
                className={classes.button}
                disabled={!patientValid || saving}
            >
                Create
            </ProgressButton>
        );

        const createAndNotifyButton = (
            <ProgressButton
                loading={saving}
                variant="contained"
                color="primary"
                onClick={openCreateAndNotifyDialog}
                spacing={0}
                className={classes.button}
                disabled={!patientValid || saving}
            >
                Create & Notify
            </ProgressButton>
        );

        if (patientValid) {
            return <>
                {createButton}
                {hasAccount && createAndNotifyButton}
                <Dialog
                    open={dialogState === DialogState.Create || dialogState === DialogState.CreateAndNotify}
                    onClose={closeDialog}
                    aria-labelledby="create-dialog-title"
                    aria-describedby="create-dialog-description"
                >
                    <DialogTitle id="create-dialog-title">Create {eventTitle} Event?</DialogTitle>
                    <DialogContent>
                        {
                            dialogState === DialogState.CreateAndNotify ?
                                <DialogContentText id="create-dialog-description">
                                    Creating the {eventName} Event will send an email notification to the patient.
                                    <br /><br />
                                    Are you sure you wish to continue?
                                </DialogContentText> :
                                <DialogContentText id="create-dialog-description">
                                    Are you sure you wish to create the {eventName} event?
                                </DialogContentText>

                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeDialog} color="secondary">
                            No
                        </Button>
                        <Button onClick={dialogState === DialogState.CreateAndNotify ? onCreateAndNotifyClick : onCreateClick} color="primary" variant="contained" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </>;
        }

        return (

            <Tooltip
                className={classes.tooltip}
                title="Complete the previous event to continue"
            >
                <div
                    className={classes.wrapper}
                >
                    {createButton}
                    {hasAccount && createAndNotifyButton}
                </div>
            </Tooltip>
        );
    }, [onCreateClick, onResendClick, classes, saving, sendingEmail, patientValid, updateTo, hideButton, closeDialog, dialogState, eventName, eventTitle, openCreateAndNotifyDialog, openCreateDialog, openResendDialog, onCreateAndNotifyClick]);

    return action;
};

const hidePostMriActions = (actionPatient: IPatient | null) => !actionPatient || (actionPatient.patientStateId !== Dtos.PatientStateType.Registered && actionPatient.patientStateId !== Dtos.PatientStateType.PostMri);
const hidePostSurgeryActions = (actionPatient: IPatient | null) => !actionPatient || (actionPatient.patientStateId !== Dtos.PatientStateType.PostMri && actionPatient.patientStateId !== Dtos.PatientStateType.PostSurgery);
const hideTwelveMonthFollowUpActions = (actionPatient: IPatient | null) => !actionPatient || (actionPatient.patientStateId !== Dtos.PatientStateType.PostSurgery && actionPatient.patientStateId !== Dtos.PatientStateType.TwelveMonthFollowUp);

const PatientSummary: React.FunctionComponent<IPatientSummaryProps> = ({
    showAdminPage
}) => {
    const classes = useStyles();
    const eventActionClasses = useEventActionStyles();

    const params = useParams<IPatientSummaryParams>();

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const [registrationModalOpen, setRegistrationModalOpen] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar()

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient, actions, saveState } = React.useContext(PatientContext);

    const { validation, actions: validationActions, loadState } = React.useContext(PatientValidationContext)

    const saving = saveState.state === RequestState.Pending || loadState.state === RequestState.Pending;

    const updatePatientState = React.useCallback(async (patientStateId: Dtos.PatientStateType, skipPatientEmail?: boolean) => {
        await actions.asyncSave(new Dtos.Patient({ ...patient, patientStateId: patientStateId, skipEmailingPatient: skipPatientEmail }));
        validationActions.load();
    }, [actions, validationActions, patient]);

    const onRegisterClick = React.useCallback(async () => {
        setRegistrationModalOpen(false);

        try {
            await updatePatientState(Dtos.PatientStateType.Registered);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Patient Registered
                    </AlertTitle>
                    The patient was successfully registered.
                </>,
                { variant: 'success' }
            );
        }
        catch (error) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Patient Not Registered
                    </AlertTitle>
                    An error occurred while attempting to register the patient.
                </>,
                { variant: 'critical' }
            );
        }
    }, [updatePatientState, setRegistrationModalOpen, enqueueSnackbar]);

    const onIneligibleClick = React.useCallback(async () => {
        try {
            await updatePatientState(Dtos.PatientStateType.Ineligible);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Patient Marked As Ineligible
                    </AlertTitle>
                    The patient was successfully marked ineligible.
                </>,
                { variant: 'success' }
            );
        }
        catch (error) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Patient Not Marked As Ineligible
                    </AlertTitle>
                    An error occurred while attempting to mark the patient as ineligible.
                </>,
                { variant: 'critical' }
            );
        }
    }, [updatePatientState, enqueueSnackbar]);

    const onRestoreClick = React.useCallback(async () => {
        try {
            await updatePatientState(Dtos.PatientStateType.Preregistered);

            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Patient Restored
                    </AlertTitle>
                    The patient was successfully restored.
                </>,
                { variant: 'success' }
            );
        }
        catch (error) {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Patient Not Restored
                    </AlertTitle>
                    An error occurred while attempting to restore the patient.
                </>,
                { variant: 'critical' }
            );
        }
    }, [updatePatientState, enqueueSnackbar]);

    const toggleRegistrationModal = React.useCallback(() => {
        setRegistrationModalOpen(state => !state);
    }, [setRegistrationModalOpen])

    const [
        [
            canAdministerOpms,
            canUpdatePatient,
            canImpersonatePatient,
            canManagePatientAccount
        ],
        permissionLoadState
    ] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const patientValid = validation?.result === ValidationResultType.Valid;

    const postMriEventActions = useCreateAndNotifyActions(Dtos.PatientStateType.PostMri, hidePostMriActions, 'Post MRI', 'post MRI', saving, updatePatientState, enqueueSnackbar, eventActionClasses, onlinePatientManagement.serviceStackClient, patient, patientValid);
    const postSurgeryEventActions = useCreateAndNotifyActions(Dtos.PatientStateType.PostSurgery, hidePostSurgeryActions, 'Post Surgery', 'post surgery', saving, updatePatientState, enqueueSnackbar, eventActionClasses, onlinePatientManagement.serviceStackClient, patient, patientValid);
    const twelveMonthFollowUpEventActions = useCreateAndNotifyActions(Dtos.PatientStateType.TwelveMonthFollowUp, hideTwelveMonthFollowUpActions, '12 Month Follow Up', '12 month follow up', saving, updatePatientState, enqueueSnackbar, eventActionClasses, onlinePatientManagement.serviceStackClient, patient, patientValid);

    const eventActionMapping = React.useMemo(() => {
        return {
            'post-mri': postMriEventActions,
            'post-surgery': postSurgeryEventActions,
            '12-month-follow-up': twelveMonthFollowUpEventActions
        };
    }, [postMriEventActions, postSurgeryEventActions, twelveMonthFollowUpEventActions ]);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }


    //const patientIneligible = validation?.result === ValidationResultType.Ineligible;
    const patientError = validation?.result === ValidationResultType.Error;

    const canRegister = !saving &&
        (patientValid || ((validation?.result ?? ValidationResultType.Critical) <= ValidationResultType.Error && canAdministerOpms)) &&
        institution &&
        !institution.recruitmentDisabled;

    const canRestore = !saving &&
        canAdministerOpms &&
        institution &&
        !institution.recruitmentDisabled;

    return (
        <>
            <PatientBreadcrumbs />
            <div
                className={classes.container}
            >
                <PatientInformation
                    patientCaption={patientCaption}
                    patientStateCaption={patientStateCaption}
                />
                <br />
                <br />
                <Grid
                    container
                    alignItems="center"
                >
                    <Grid
                        item
                        xs={12}
                        sm
                        className={classNames(classes.title, classes.header)}
                    >
                        <Typography
                            variant="h1"
                            color="secondary"
                        >
                            Patient Summary
                        </Typography>
                    </Grid>

                    {
                        (
                            (patient?.patientStateId === Dtos.PatientStateType.Registered ||
                            patient?.patientStateId === Dtos.PatientStateType.PostMri ||
                            patient?.patientStateId === Dtos.PatientStateType.PostSurgery ||
                                patient?.patientStateId === Dtos.PatientStateType.TwelveMonthFollowUp) &&
                            canAdministerOpms
                        ) && (
                            <Grid
                                item
                                sm={2}
                                xs={12}
                                className={classNames(classes.title, classes.buttonContainer)}
                            >
                                <Button
                                    href={`/registration/${params.institutionCode}/${patient?.studyNumber}/staff/1/patient-transfer/1`}
                                    variant="contained"
                                    color="primary"
                                    component="a"
                                    className={classes.button}
                                >
                                    Transfer Patient
                                </Button>
                            </Grid>
                        )
                    }
                    
                    <Grid
                        item
                        sm={2}
                        xs={12}
                        className={classes.title}
                    >
                        <PatientActiveDirectory
                            canImpersonatePatient={canImpersonatePatient}
                            canManagePatientAccount={canManagePatientAccount}
                        />
                    </Grid>
                    {
                        (
                            patient?.patientStateId === Dtos.PatientStateType.Registered ||
                            patient?.patientStateId === Dtos.PatientStateType.PostMri ||
                            patient?.patientStateId === Dtos.PatientStateType.PostSurgery ||
                            patient?.patientStateId === Dtos.PatientStateType.TwelveMonthFollowUp
                        ) && (
                            <Grid
                                item
                                sm={2}
                                xs={12}
                                className={classes.title}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component="a"
                                    className={classes.button}
                                    href={`/${patient?.id}/forms-due-schedule`}
                                >
                                    Forms Due Schedule
                                </Button>
                            </Grid>
                        )
                    }
                </Grid>
                <PatientSummaryList eventActions={eventActionMapping} />
                {
                    patient?.patientStateId === Dtos.PatientStateType.NewPatient && (
                        <Alert
                            icon={<FontAwesomeIcon icon={faInfoCircle} fixedWidth />}
                            severity="info"
                        >
                            <AlertTitle>
                                <strong>
                                    Note
                                </strong>
                            </AlertTitle>
                            <Typography>
                                Complete the Pre-registration form to continue patient registration.
                            </Typography>
                        </Alert>
                    )
                }
                <Dialog
                    open={registrationModalOpen}
                    onClose={toggleRegistrationModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Register Patient?</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            The patient has outstanding {patientError ? 'errors' : 'ineligibility warnings'} that should be corrected.
                            <br /><br />
                            Register the patient?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={toggleRegistrationModal} color="secondary">
                            No
                        </Button>
                        <Button onClick={onRegisterClick} color="primary" variant="contained" autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <div
                    className={classes.buttonGroup}
                >
                    {
                        patient?.patientStateId === Dtos.PatientStateType.Preregistered && canUpdatePatient && patientValid && (
                            <ProgressButton
                                loading={saving}
                                variant="contained"
                                color="primary"
                                onClick={onRegisterClick}
                                disabled={!canRegister}
                            >
                                Register
                            </ProgressButton>
                        )
                    }
                    {
                        patient?.patientStateId === Dtos.PatientStateType.Preregistered && canUpdatePatient && !patientValid && (
                            <ProgressButton
                                loading={saving}
                                variant="contained"
                                color="primary"
                                onClick={toggleRegistrationModal}
                                disabled={!canRegister}
                            >
                                Register
                            </ProgressButton>
                        )
                    }
                    {
                        patient?.patientStateId === Dtos.PatientStateType.Preregistered && canAdministerOpms && (
                            <ProgressButton
                                loading={saving}
                                variant="contained"
                                color="primary"
                                onClick={onIneligibleClick}
                            >
                                Mark As Ineligible
                            </ProgressButton>
                        )
                    }
                    {
                        patient?.patientStateId === Dtos.PatientStateType.Ineligible && canAdministerOpms && (
                            <ProgressButton
                                loading={saving}
                                variant="contained"
                                color="primary"
                                onClick={onRestoreClick}
                                disabled={!canRestore}
                            >
                                Restore Patient
                            </ProgressButton>
                        )
                    }
                </div>
            </div>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default PatientSummary;
