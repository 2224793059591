/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to style components
 */
import { makeStyles, Typography } from '@material-ui/core';

/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    ToggleButtonGroup,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    Checkbox,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import SourceDocuments from '../../components/SourceDocuments';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IEligibilityChecklistProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 1, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const isFemaleOlderThanEighteenNewlyDiagnosedYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.EligibilityChecklist>) => GetFieldLookupItem(lookups, 'isFemaleOlderThanEighteenNewlyDiagnosed', formState?.values.isFemaleOlderThanEighteenNewlyDiagnosed)?.type === Dtos.LookupYesNoType.Yes;


/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate
];

const EligibilityChecklist: React.FunctionComponent<IEligibilityChecklistProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    const canEdit = canUpdateCrf &&
        !!institution &&
        !institution.recruitmentDisabled;

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.EligibilityChecklist}
                validateOn="onChange"
                canEdit={canEdit}
            >
                <Typography
                    variant="h2"
                    color="primary"
                    className={classes.subHeading}
                >
                    Inclusion Criteria
                </Typography>
                <FieldGroup>
                    <Field
                        name="isFemaleOlderThanEighteenNewlyDiagnosed"
                        component={ToggleButtonGroup}
                    />
                    <CrfCondition
                        type={Dtos.EligibilityChecklist}
                        condition={isFemaleOlderThanEighteenNewlyDiagnosedYes}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="withUltrasoundMammography"
                            label={'Select all that apply'}
                            sm={9}
                            md={9}
                            xl={9}
                            lg={9}
                            component={Checkbox}
                        />
                        <Field
                            name="lessThanSeventyWithLobularCancer"
                            label={' '}
                            sm={9}
                            md={9}
                            xl={9}
                            lg={9}
                            component={Checkbox}
                        />
                        <Field
                            name="lessThanFiftyYears"
                            label={' '}
                            sm={9}
                            md={9}
                            xl={9}
                            lg={9}
                            component={Checkbox}
                        />
                        <Field
                            name="withReportedExtremeBreastDensity"
                            label={' '}
                            sm={9}
                            md={9}
                            xl={9}
                            lg={9}
                            component={Checkbox}
                        />
                    </CrfCondition>
                </FieldGroup>
                <Field
                    name="hasPreMriTreatmentPlanConfirmedAndDocumented"
                    component={ToggleButtonGroup}
                />
                <Field
                    name="isAccessibleForFollowUp"
                    component={ToggleButtonGroup}
                />
                <Field
                    name="hasCompletedBaselinePROMs"
                    component={ToggleButtonGroup}
                />
                <Typography
                    variant="h2"
                    color="primary"
                    className={classes.subHeading}
                >
                    Exclusion Criteria
                </Typography>
                <Field
                    name="hasDistantMetastaticDisease"
                    component={ToggleButtonGroup}
                />
                <Field
                    name="hasLocallyAdvancedInoperableBreastCancer"
                    component={ToggleButtonGroup}
                />
                <Field
                    name="hasNonMalignantSystemicDiseases"
                    component={ToggleButtonGroup}
                />
                <Field
                    name="hasUndergoneStudySpecificMRIBefore"
                    component={ToggleButtonGroup}
                />
                <Field
                    name="hasHadPrevioudBreastCancerDiagnosisOnSameSide"
                    component={ToggleButtonGroup}
                />
                <SourceDocuments />
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default EligibilityChecklist;
