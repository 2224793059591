/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to style components
 */
import { makeStyles, fade, useTheme, useMediaQuery, Typography, Paper, Grid } from '@material-ui/core';

/**
 * Used to display icons
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Used for the copyright icon.
 */
import { faCopyright } from '@fortawesome/pro-regular-svg-icons/faCopyright';

import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';

/**
 * Used for the basic page layout.
 */
import {
    Field,
    StaticField,
    ToggleButtonGroup,
    RadioGroup,
    Slider,
    FieldGroup,
    ProForm,
    useAuthenticatedUser,
    UserType,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormBreadcrumbs,
    FormContext,
    IStaticFieldComponentProps,
    FormLabel
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';

import SourceDocuments from '../../components/SourceDocuments';

import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IEq5d5lProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(0, 3)
    },
    footer: {
        padding: theme.spacing(1),
        textAlign: 'center',
        background: theme.palette.grey[100],
        borderBottom: `1px solid ${theme.palette.grey[300]}`
    },
    sliderExtra: {
        textAlign: 'center',
        fontWeight: 'bold',

        '&:first-child': {
            paddingBottom: theme.spacing(2)
        },

        '&:last-child': {
            paddingTop: theme.spacing(2)
        }
    }
}));

const useYourHealthTodayStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(1.5),
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        width: 120,
        height: 120,
        textAlign: 'center'
    }
}));

const useSliderStyles = makeStyles(theme => ({
    root: {
        width: 2,
        padding: '0 15px',
    },
    active: {},
    valueLabel: {
        top: 'calc(-50% + 12px) !important',
        left: '-22px !important',
        '& *': {
            background: 'transparent',
            color: '#000',
        },
    },
    track: {
        width: '2px !important',
    },
    mark: {
        backgroundColor: fade(theme.palette.primary.main, 0.3),
        width: 8,
        height: 1,
        marginLeft: '-4px !important',
        '&:nth-child(4n-4)': {
            width: 16,
            height: 1,
            marginLeft: '-8px !important',
        }
    },
    markActive: {
        opacity: 1,
        backgroundColor: 'currentColor',
    },
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsProUpdate,
    Dtos.Permission.OpmsProAdminister
];

const marks = [
    {
        value: 0,
        label: '0'
    },
    {
        value: 5,
        label: ''
        //label: '5'
    },
    {
        value: 10,
        label: '10'
    },
    {
        value: 15,
        label: ''
        //label: '15'
    },
    {
        value: 20,
        label: '20'
    },
    {
        value: 25,
        label: ''
        //label: '25'
    },
    {
        value: 30,
        label: '30'
    },
    {
        value: 35,
        label: ''
        //label: '35'
    },
    {
        value: 40,
        label: '40'
    },
    {
        value: 45,
        label: ''
        //label: '45'
    },
    {
        value: 50,
        label: '50'
    },
    {
        value: 55,
        label: ''
        //label: '55'
    },
    {
        value: 60,
        label: '60'
    },
    {
        value: 65,
        label: ''
        //label: '65'
    },
    {
        value: 70,
        label: '70'
    },
    {
        value: 75,
        label: ''
        //label: '75'
    },
    {
        value: 80,
        label: '80'
    },
    {
        value: 85,
        label: ''
        //label: '85'
    },
    {
        value: 90,
        label: '90'
    },
    {
        value: 95,
        label: ''
        //label: '95'
    },
    {
        value: 100,
        label: '100'
    }
]

const yourHealthTodaySubscription = { value: true };

const YourHealthToday: React.FunctionComponent<IStaticFieldComponentProps> = ({
    fieldState: { value }
}) => {
    const classes = useYourHealthTodayStyles();

    return (
        <Paper
            className={classes.paper}
        >
            <FormLabel simpleLabel />
            <Typography
                variant="h2"
                variantMapping={{
                    h2: 'div'
                }}
            >
                <strong>{value}</strong>
            </Typography>
        </Paper>
    );
};


const Eq5d5l: React.FunctionComponent<IEq5d5lProps> = () => {
    const classes = useStyles();
    const sliderClasses = useSliderStyles();

    const theme = useTheme();


    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

    const [user] = useAuthenticatedUser();

    const isPatient = React.useMemo(() => {
        return user?.type === UserType.Patient;
    }, [user]);

    const showSteps = isPatient || isMobile;

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdatePro, canAdministerPro], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const history = useHistory();
    const { form } = React.useContext(FormContext);

    if (form && (form as Dtos.IProForm).completed && isPatient) {
        history.push(`/`);
    }

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    const canEdit = canUpdatePro || canAdministerPro;

    return (
        <>
            {
                !isPatient && (
                    <FormBreadcrumbs />
                )
            }
            <ProForm
                formType={Dtos.Eq5d5l}
                validateOn="onChange"
                useSteps={showSteps}
                footer={
                    showSteps && (
                        <Typography variant="caption" className={classes.footer}>
                            <FontAwesomeIcon icon={faCopyright} fixedWidth /> EuroQol Research Foundation. EQ-5D<sup>TM</sup> is a trade mark of the EuroQol Research Foundation
                        </Typography>
                    )
                }
                canEdit={canEdit}
                canAdministerProForm={canAdministerPro}
            >
                {
                    showSteps ?
                        <FieldGroup>
                            <Alert severity="info" icon={<FontAwesomeIcon fixedWidth icon={faInfoCircle} />} >
                                Please click the ONE box that best describes your health TODAY.
                            </Alert>
                            {
                                isExtraSmall ?
                                    <Field
                                        name="mobilityId"
                                        component={ToggleButtonGroup}
                                        orientation="vertical"
                                        spacing={3}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    /> :
                                    <Field
                                        name="mobilityId"
                                        component={RadioGroup}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    />
                            }
                        </FieldGroup> :
                        <Field
                            name="mobilityId"
                            component={RadioGroup}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        />
                }

                {
                    showSteps ?
                        <FieldGroup>
                            <Alert severity="info" icon={<FontAwesomeIcon fixedWidth icon={faInfoCircle} />} >
                                Please click the ONE box that best describes your health TODAY.
                            </Alert>
                            {
                                isExtraSmall ?
                                    <Field
                                        name="selfCareId"
                                        component={ToggleButtonGroup}
                                        orientation="vertical"
                                        spacing={3}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    /> :
                                    <Field
                                        name="selfCareId"
                                        component={RadioGroup}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    />
                            }
                        </FieldGroup> :
                        <Field
                            name="selfCareId"
                            component={RadioGroup}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        />
                }

                {
                    showSteps ?
                        <FieldGroup>
                            <Alert severity="info" icon={<FontAwesomeIcon fixedWidth icon={faInfoCircle} />} >
                                Please click the ONE box that best describes your health TODAY.
                            </Alert>
                            {
                                isExtraSmall ?
                                    <Field
                                        name="usualActivitiesId"
                                        component={ToggleButtonGroup}
                                        orientation="vertical"
                                        spacing={3}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    /> :
                                    <Field
                                        name="usualActivitiesId"
                                        component={RadioGroup}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    />
                            }
                        </FieldGroup> :
                        <Field
                            name="usualActivitiesId"
                            component={RadioGroup}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        />
                }

                {
                    showSteps ?
                        <FieldGroup>
                            <Alert severity="info" icon={<FontAwesomeIcon fixedWidth icon={faInfoCircle} />} >
                                Please click the ONE box that best describes your health TODAY.
                            </Alert>
                            {
                                isExtraSmall ?
                                    <Field
                                        name="painAndDiscomfortId"
                                        component={ToggleButtonGroup}
                                        orientation="vertical"
                                        spacing={3}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    /> :
                                    <Field
                                        name="painAndDiscomfortId"
                                        component={RadioGroup}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    />
                            }
                        </FieldGroup> :
                        <Field
                            name="painAndDiscomfortId"
                            component={RadioGroup}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        />
                }

                {
                    showSteps ?
                        <FieldGroup>
                            <Alert severity="info" icon={<FontAwesomeIcon fixedWidth icon={faInfoCircle} />} >
                                Please click the ONE box that best describes your health TODAY.
                            </Alert>
                            {
                                isExtraSmall ?
                                    <Field
                                        name="anxietyAndDepressionId"
                                        component={ToggleButtonGroup}
                                        orientation="vertical"
                                        spacing={3}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    /> :
                                    <Field
                                        name="anxietyAndDepressionId"
                                        component={RadioGroup}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    />
                            }
                        </FieldGroup> :
                        <Field
                            name="anxietyAndDepressionId"
                            component={RadioGroup}
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                        />
                }
              
                {
                    showSteps && (
                        <Field
                            name="healthRating"
                            noErrors
                            noErrorFlag
                            autoRegister={false}
                        />
                    )
                }
                {
                    showSteps ?
                        <FieldGroup>
                            <Alert severity="info" icon={<FontAwesomeIcon fixedWidth icon={faInfoCircle} />} >
                                Please click on the scale to indicate how your health is TODAY.
                            </Alert>
                            <div
                                className={classes.container}
                            >
                                <Grid
                                    container
                                    alignItems="center"
                                >
                                    <Grid
                                        item
                                        xs={4}
                                    >
                                        <StaticField name="healthRating" component={YourHealthToday} subscription={yourHealthTodaySubscription} />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={8}
                                    >
                                        <Field
                                            name="healthRating"
                                            component={Slider}
                                            orientation="vertical"
                                            length={300}
                                            marks={marks}
                                            track={false}
                                            label={null}
                                            classes={sliderClasses}
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            before={
                                                <div
                                                    className={classes.sliderExtra}
                                                >
                                                    The best health you can imagine
                                                </div>
                                            }
                                            after={
                                                <div
                                                    className={classes.sliderExtra}
                                                >
                                                    The worst health you can imagine
                                                </div>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </FieldGroup> :
                        <FieldGroup>
                            <Field
                                label={
                                    <>
                                        <FormLabel />
                                        <br />
                                        <StaticField name="healthRating" component={YourHealthToday} subscription={yourHealthTodaySubscription} prependParentName={false} />
                                    </>
                                }
                                name="healthRating"
                                component={Slider}
                                orientation="vertical"
                                length={400}
                                marks={marks}
                                track={false}
                                classes={sliderClasses}
                                before={
                                    <div
                                        className={classes.sliderExtra}
                                    >
                                        The best health you can imagine
                                    </div>
                                }
                                after={
                                    <div
                                        className={classes.sliderExtra}
                                    >
                                        The worst health you can imagine
                                    </div>
                                }
                            />
                        </FieldGroup>
                }
                {
                    canAdministerPro && (
                        <SourceDocuments />
                    )
                }
            </ProForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default Eq5d5l;
