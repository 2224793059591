/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae landing page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { useParams, useHistory } from 'react-router';

import { Theme, makeStyles, Typography, Grid } from '@material-ui/core';

import { SaeBreadcrumbs, SaeCollapsibleTable, SaeStatus, useSaes } from '@ngt/opms-sae'

import { usePermissionsByIds } from '@ngt/opms-bctapi'

import { RequestState } from '@ngt/request-utilities';

import {
    InstitutionsContext,
    ALL_INSTITUTIONS_CODE,
    TrialContextSelector
} from '@ngt/opms';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../api/dtos';

/*
 * used to get column spec for the sae table.
 */
import useSaeColumns from '../../hooks/sae/useSaeColumns';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface ISaesPageParams {
    institutionCode?: string;
}

interface ISaesPageProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    ml1: {
        marginLeft: theme.spacing(1),
    },
    mt1: {
        marginTop: theme.spacing(1),
    },
    mt3: {
        marginTop: theme.spacing(3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Constants
 * ---------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.SaeView,
    Dtos.Permission.SaeReview,
    Dtos.Permission.SaeAdminister,
    Dtos.Permission.MedicalReviewPerform
];

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const SaesPage: React.FunctionComponent<ISaesPageProps> = () => {
    const classes = useStyles();

    const history = useHistory();


    const { institutionCode } = useParams<Record<string, string>>();

    const contextInstitutions = React.useContext(InstitutionsContext);

    const institutions = contextInstitutions?.institutions;

    useSaes(institutionCode, true);

    const institution = React.useMemo(() => {
        return institutions?.find(i => i.code === institutionCode);
    }, [institutions, institutionCode]);

    const [[canViewSae, /* canReviewSae */, canAdministerSae, /*canMedicallyReviewSae */], permissionLoadState] = usePermissionsByIds(permissions, null, null, institution?.id, null, institution ? true : false);

    const onSelect = React.useCallback((newMasterGroupCode?: string | null, newCollaboratingGroupCode?: string | null, newCountryCode?: string | null, newInstitutionCode?: string | null) => {
        if (newInstitutionCode) {
            history?.push(`/sae/${newInstitutionCode}`);
        }
        else {
            history?.push(`/sae`);
        }

        return;
    }, [history]);

    const showInstitutions = React.useMemo(() => {
        return !institutionCode
    }, [institutionCode]);

    const saeDataColumns = useSaeColumns(contextInstitutions.institutions, showInstitutions);

    React.useEffect(() => {
        if (permissionLoadState.state !== RequestState.Pending &&
            permissionLoadState.state !== RequestState.None) {
            if (!canViewSae) {
                history.replace(`/error/403`);
            }
            else if (!canAdministerSae &&
                institutions && institutions.length > 0) {
                history.replace(`/sae/${institutions[0].code}`)
            }
        }
    }, [institutions, canViewSae, canAdministerSae, permissionLoadState, history]);

    return (
        <>
            <SaeBreadcrumbs institution={institution} />

            <div
                className={classes.container}
            >
                <Typography
                    variant="h1"
                    color="secondary"
                >
                    Breast MRI SAE Electronic Review and Investigator Signature
                </Typography>

                <Grid item xs={12} className={classes.mt3}>
                    <TrialContextSelector
                        onChange={onSelect}
                        allowAllMasterGroups={false}
                        allowAllCollaboratingGroups={false}
                        allowAllCountries={false}
                        allowAllInstitutions={true}
                        hideMasterGroups={true}
                        hideCollaboratingGroups={true}
                        hideCountries={true}
                        masterGroupCode={null}
                        collaboratingGroupCode={null}
                        countryCode={null}
                        institutionCode={institution?.code ?? ALL_INSTITUTIONS_CODE}
                    />
                </Grid>

                {
                    !!canAdministerSae &&
                    <SaeCollapsibleTable
                        columns={saeDataColumns}
                        status={[SaeStatus.New, SaeStatus.Added]}
                        title={"New SAE"}
                        showInvestigatorColumn={true}
                    />
                }

                <SaeCollapsibleTable
                    columns={saeDataColumns}
                    status={[SaeStatus.AwaitingForReview]}
                    title={"Awaiting review SAE"}
                    showInvestigatorColumn={true}
                />

                <SaeCollapsibleTable
                    columns={saeDataColumns}
                    status={[SaeStatus.Approved]}
                    title={"Approved SAE"}
                    showInvestigatorColumn={false}
                />

                <SaeCollapsibleTable
                    columns={saeDataColumns}
                    status={[SaeStatus.Rejected]}
                    title={"Rejected SAE"}
                    showInvestigatorColumn={false}
                />

                <SaeCollapsibleTable
                    columns={saeDataColumns}
                    status={[SaeStatus.Incorrect]}
                    title={"Incorrectly Identified SAE"}
                    showInvestigatorColumn={false}
                />
                
            </div>

           

            {
                // <SaePage institution={institution} />
                //<br /> <br />
                //{JSON.stringify(contextInstitutions?.institutions, null, '\t\n')}
                //<br /> <br />
                //{JSON.stringify(contextInstitutions?.institutions?.find(i => i.code === institutionCode), null, '\n')}
                //<br /><br />
                //{JSON.stringify(institution, null, '\n')}
            }

            {
            //  Permissions
            //  {JSON.stringify(permissionLoadState.state, null, '\n')}
            //  {JSON.stringify(canViewSae, null, '\n')}
            }
        </>
    );
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default SaesPage;