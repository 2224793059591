/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to style components
 */
import { makeStyles, Typography, InputAdornment } from '@material-ui/core';

/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Text,
    ToggleButtonGroup,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    StaticGrid,
    StaticGridRow,
    Input,
    Checkbox,
    FormLabel,
    Numeric,
    IStaticGridCellProps,
    IStaticGridHeaderProps,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    DEFAULT_SHOW_ERROR_FUNCTION,
    ShowErrorFunction
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import { NumberFormatValues } from 'react-number-format';
import SourceDocuments from '../../components/SourceDocuments';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IGeneralProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 1, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const isLeft = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => GetFieldLookupItem(lookups, 'breastCancerConfirmLeft', formState?.values.breastCancerConfirmLeft)?.type === Dtos.LookupYesNoNaType.Yes;

const isRight = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => GetFieldLookupItem(lookups, 'breastCancerConfirmRight', formState?.values.breastCancerConfirmRight)?.type === Dtos.LookupYesNoNaType.Yes;

const isLeftSymptomaticPresentationLeft = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => GetFieldLookupItem(lookups, 'breastCancerConfirmLeft', formState?.values.breastCancerConfirmLeft)?.type === Dtos.LookupYesNoNaType.Yes && GetFieldLookupItem(lookups, 'symptomaticPresentationLeft', formState?.values.symptomaticPresentationLeft)?.type === Dtos.LookupYesNoNaType.Yes;

const isRightSymptomaticPresentationLeft = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => GetFieldLookupItem(lookups, 'breastCancerConfirmRight', formState?.values.breastCancerConfirmRight)?.type === Dtos.LookupYesNoNaType.Yes && GetFieldLookupItem(lookups, 'symptomaticPresentationRight', formState?.values.symptomaticPresentationRight)?.type === Dtos.LookupYesNoNaType.Yes;

const isLeftOtherMode = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => GetFieldLookupItem(lookups, 'breastCancerConfirmLeft', formState?.values.breastCancerConfirmLeft)?.type === Dtos.LookupYesNoNaType.Yes && formState?.values.otherModeOfDetection === true;

const isRightOtherMode = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => GetFieldLookupItem(lookups, 'breastCancerConfirmRight', formState?.values.breastCancerConfirmRight)?.type === Dtos.LookupYesNoNaType.Yes && formState?.values.otherModeOfDetection === true;

//const isLeftLesionPalpable = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => GetFieldLookupItem(lookups, 'breastCancerConfirmLeft', formState?.values.breastCancerConfirmLeft)?.type === Dtos.LookupYesNoNaType.Yes && GetFieldLookupItem(lookups, 'lesionPalpableLeft', formState?.values.lesionPalpableLeft)?.type === Dtos.LookupYesNoNaType.Yes;

//const isRightLesionPalpable = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => GetFieldLookupItem(lookups, 'breastCancerConfirmRight', formState?.values.breastCancerConfirmRight)?.type === Dtos.LookupYesNoNaType.Yes && GetFieldLookupItem(lookups, 'lesionPalpableRight', formState?.values.lesionPalpableRight)?.type === Dtos.LookupYesNoNaType.Yes;

const isLeftSymptomaticSelectionPalpableMass = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => GetFieldLookupItem(lookups, 'breastCancerConfirmRight', formState?.values.breastCancerConfirmLeft)?.type === Dtos.LookupYesNoNaType.Yes && formState?.values.symptomaticSelectionPalpableMassLeft === true;

const isRightSymptomaticSelectionPalpableMass = ({ lookups, formState }: ICrfConditionParameters<Dtos.General>) => GetFieldLookupItem(lookups, 'breastCancerConfirmRight', formState?.values.breastCancerConfirmRight)?.type === Dtos.LookupYesNoNaType.Yes && formState?.values.symptomaticSelectionPalpableMassRight === true;

const createCheckboxShowError = (errorCode: string, checkboxFields: string[]): ShowErrorFunction => {
    return (name, error, touched, errors, validating, isAlwaysShown) => {
        if (isAlwaysShown) {
            return true;
        }

        if (error.code === errorCode) {
            return checkboxFields.some(field => touched[field]);
        }

        return DEFAULT_SHOW_ERROR_FUNCTION(name, error, touched, errors, validating, isAlwaysShown);
    }
};

const leftSymptomaticShowError = createCheckboxShowError('F-GEN-007', [
    'symptomaticSelectionPalpableMassLeft',
    'symptomaticSelectionNippleChangeDischargeLeft',
    'symptomaticSelectionBreastDistortionLeft',
    'symptomaticSelectionAxillaryMassLeft',
    'symptomaticSelectionOtherLeft'
])

const rightSymptomaticShowError = createCheckboxShowError('F-GEN-008', [
    'symptomaticSelectionPalpableMassRight',
    'symptomaticSelectionNippleChangeDischargeRight',
    'symptomaticSelectionBreastDistortionRight',
    'symptomaticSelectionAxillaryMassRight',
    'symptomaticSelectionOtherRight'
])

const combinedSymptomaticShowError: ShowErrorFunction = (name, error, touched, errors, validating, isAlwaysShown) => {
    if (isAlwaysShown) {
        return true;
    }

    if (error.code === 'F-GEN-007') {
        return [
            'symptomaticSelectionPalpableMassLeft',
            'symptomaticSelectionNippleChangeDischargeLeft',
            'symptomaticSelectionBreastDistortionLeft',
            'symptomaticSelectionAxillaryMassLeft',
            'symptomaticSelectionOtherLeft'
        ].some(field => touched[field]);
    }

    if (error.code === 'F-GEN-008') {
        return [
            'symptomaticSelectionPalpableMassRight',
            'symptomaticSelectionNippleChangeDischargeRight',
            'symptomaticSelectionBreastDistortionRight',
            'symptomaticSelectionAxillaryMassRight',
            'symptomaticSelectionOtherRight'
        ].some(field => touched[field]);
    }

    return DEFAULT_SHOW_ERROR_FUNCTION(name, error, touched, errors, validating, isAlwaysShown);
};
/*
 * ----------------------------------------------------------------------------------
 * Constants
 * ----------------------------------------------------------------------------------
 */

export const THREE_DIGIT_POSITIVE_INTEGER = {
    decimalScale: 0,
    allowNegative: false,
    isAllowed: (values: NumberFormatValues) => {
        if (!values.floatValue) {
            return true;
        }

        if (values.floatValue < 1000) {
            return true;
        }

        return false;
    }
}

const clinicalPresentationHeader: IStaticGridHeaderProps[] = [
    {
        columnName: 'question',
        content: 'Breast cancer laterality',
        width: '50%',
        minWidth: 300
    },
    {
        columnName: 'leftbreast',
        content: 'Left Breast',
        width: '25%',
        minWidth: 200
    },
    {
        columnName: 'rightbreast',
        content: 'Right Breast',
        width: '25%',
        minWidth: 200
    }
];

const detectHeader: IStaticGridHeaderProps[] = [
    {
        columnName: 'question',
        content: 'How was the primary tumour initially detected?',
        width: '50%',
        minWidth: 300
    },
    {
        columnName: 'leftbreast',
        content: 'Left Breast',
        width: '25%',
        minWidth: 200
    },
    {
        columnName: 'rightbreast',
        content: 'Right Breast',
        width: '25%',
        minWidth: 200
    }
];

//const detailsHeader: IStaticGridHeaderProps[] = [
//    {
//        columnName: 'question',
//        content: 'Details of Clinical Exam',
//        width: '50%',
//        minWidth: 300
//    },
//    {
//        columnName: 'leftbreast',
//        content: 'Left Breast',
//        width: '25%',
//        minWidth: 200
//    },
//    {
//        columnName: 'rightbreast',
//        content: 'Right Breast',
//        width: '25%',
//        minWidth: 200
//    }
//];

const breastCancerConfirmRow: IStaticGridCellProps[] = [
    {
        columnName: 'question',
        content: (
            <FormLabel
                label="Breast cancer laterality. Confirm which breast the cancer was diagnosed"
            />
        )
    },
    {
        columnName: 'leftbreast',
        content: (
            <Input
                component={ToggleButtonGroup}
            />
        ),
        fieldName: 'breastCancerConfirmLeft'
    },
    {
        columnName: 'rightbreast',
        content: (
            <Input
                component={ToggleButtonGroup}
            />
        ),
        fieldName: 'breastCancerConfirmRight'
    }
];

//const screenDetectedRow: IStaticGridCellProps[] = [
//    {
//        columnName: 'question',
//        content: (
//            <FormLabel />
//        ),
//        fieldName: 'screenDetectedLeft'
//    },
//    {
//        columnName: 'leftbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.General}
//                condition={isLeft}
//                mode="Enable"
//                subscription={{ values: true }}>
//                <Input
//                    component={ToggleButtonGroup}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'screenDetectedLeft'
//    },
//    {
//        columnName: 'rightbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.General}
//                condition={isRight}
//                mode="Enable"
//                subscription={{ values: true }}>
//                <Input
//                    component={ToggleButtonGroup}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'screenDetectedRight'
//    }
//];

const symptomaticPresentationRow: IStaticGridCellProps[] = [
    {
        columnName: 'question',
        content: (
            <FormLabel />
        ),
        fieldName: 'symptomaticPresentationLeft'
    },
    {
        columnName: 'leftbreast',
        content: (
            <CrfCondition
                type={Dtos.General}
                condition={isLeft}
                mode="Enable"
                subscription={{ values: true }}>
                <Input
                    component={ToggleButtonGroup}
                    showErrorFlagExt={leftSymptomaticShowError}
                />
            </CrfCondition>
        ),
        fieldName: 'symptomaticPresentationLeft'
    },
    {
        columnName: 'rightbreast',
        content: (

            <CrfCondition
                type={Dtos.General}
                condition={isRight}
                mode="Enable"
                subscription={{ values: true }}>
                <Input
                    component={ToggleButtonGroup}
                    showErrorFlagExt={rightSymptomaticShowError}
                />
            </CrfCondition>
        ),
        fieldName: 'symptomaticPresentationRight'
    }
];

const symptomaticSelectionPalpableMassRow: IStaticGridCellProps[] = [
    {
        columnName: 'question',
        content: (
            <FormLabel
                label="Select all that apply"
            />
        )
    },
    {
        columnName: 'leftbreast',
        content: (
            <CrfCondition
                type={Dtos.General}
                condition={isLeftSymptomaticPresentationLeft}
                mode="Enable"
                subscription={{ values: true }}>
                <Input
                    component={Checkbox}
                />
            </CrfCondition>
        ),
        fieldName: 'symptomaticSelectionPalpableMassLeft'
    },
    {
        columnName: 'rightbreast',
        content: (
            <CrfCondition
                type={Dtos.General}
                condition={isRightSymptomaticPresentationLeft}
                mode="Enable"
                subscription={{ values: true }}>
                <Input
                    component={Checkbox}
                />
            </CrfCondition>
        ),
        fieldName: 'symptomaticSelectionPalpableMassRight'
    }
];

const symptomaticSelectionPalpableMassSizeRow: IStaticGridCellProps[] = [
    {
        columnName: 'leftbreast',
        content: (
            <CrfCondition
                type={Dtos.General}
                condition={isLeftSymptomaticSelectionPalpableMass}
                mode="Enable"
                subscription={{ values: true }}>
                <Input
                    component={Numeric}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                cm&nbsp;
                            </InputAdornment>
                        )
                    }}
                    numberFormat={THREE_DIGIT_POSITIVE_INTEGER}
                />
            </CrfCondition>
        ),
        fieldName: 'symptomaticSelectionPalpableMassSizeLeft'
    },
    {
        columnName: 'rightbreast',
        content: (
            <CrfCondition
                type={Dtos.General}
                condition={isRightSymptomaticSelectionPalpableMass}
                mode="Enable"
                subscription={{ values: true }}>
                <Input
                    component={Numeric}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                cm&nbsp;
                            </InputAdornment>
                        )
                    }}
                    numberFormat={THREE_DIGIT_POSITIVE_INTEGER}
                />
            </CrfCondition>
        ),
        fieldName: 'symptomaticSelectionPalpableMassSizeRight'
    }
];

const symptomaticSelectionNippleChangeDischargeRow: IStaticGridCellProps[] = [
    {
        columnName: 'leftbreast',
        content: (
            <CrfCondition
                type={Dtos.General}
                condition={isLeftSymptomaticPresentationLeft}
                mode="Enable"
                subscription={{ values: true }}>
                <Input
                    component={Checkbox}
                />
            </CrfCondition>
        ),
        fieldName: 'symptomaticSelectionNippleChangeDischargeLeft'
    },
    {
        columnName: 'rightbreast',
        content: (
            <CrfCondition
                type={Dtos.General}
                condition={isRightSymptomaticPresentationLeft}
                mode="Enable"
                subscription={{ values: true }}>
                <Input
                    component={Checkbox}
                />
            </CrfCondition>
        ),
        fieldName: 'symptomaticSelectionNippleChangeDischargeRight'
    }
];

const symptomaticSelectionBreastDistortionRow: IStaticGridCellProps[] = [
    {
        columnName: 'leftbreast',
        content: (
            <CrfCondition
                type={Dtos.General}
                condition={isLeftSymptomaticPresentationLeft}
                mode="Enable"
                subscription={{ values: true }}>
                <Input
                    component={Checkbox}
                />
            </CrfCondition>
        ),
        fieldName: 'symptomaticSelectionBreastDistortionLeft'
    },
    {
        columnName: 'rightbreast',
        content: (
            <CrfCondition
                type={Dtos.General}
                condition={isRightSymptomaticPresentationLeft}
                mode="Enable"
                subscription={{ values: true }}>
                <Input
                    component={Checkbox}
                />
            </CrfCondition>
        ),
        fieldName: 'symptomaticSelectionBreastDistortionRight'
    }
];

//const symptomaticSelectionInflammatoryRow: IStaticGridCellProps[] = [
//    {
//        columnName: 'leftbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.General}
//                condition={isLeftSymptomaticPresentationLeft}
//                mode="Enable"
//                subscription={{ values: true }}>
//                <Input
//                    component={Checkbox}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'symptomaticSelectionInflammatoryLeft'
//    },
//    {
//        columnName: 'rightbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.General}
//                condition={isRightSymptomaticPresentationLeft}
//                mode="Enable"
//                subscription={{ values: true }}>
//                <Input
//                    component={Checkbox}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'symptomaticSelectionInflammatoryRight'
//    }
//];

const symptomaticSelectionAxillaryMassRow: IStaticGridCellProps[] = [
    {
        columnName: 'leftbreast',
        content: (
            <CrfCondition
                type={Dtos.General}
                condition={isLeftSymptomaticPresentationLeft}
                mode="Enable"
                subscription={{ values: true }}>
                <Input
                    component={Checkbox}
                />
            </CrfCondition>
        ),
        fieldName: 'symptomaticSelectionAxillaryMassLeft'
    },
    {
        columnName: 'rightbreast',
        content: (
            <CrfCondition
                type={Dtos.General}
                condition={isRightSymptomaticPresentationLeft}
                mode="Enable"
                subscription={{ values: true }}>
                <Input
                    component={Checkbox}
                />
            </CrfCondition>
        ),
        fieldName: 'symptomaticSelectionAxillaryMassRight'
    }
];

const symptomaticSelectionOtherRow: IStaticGridCellProps[] = [
    {
        columnName: 'leftbreast',
        content: (
            <CrfCondition
                type={Dtos.General}
                condition={isLeftSymptomaticPresentationLeft}
                mode="Enable"
                subscription={{ values: true }}>
                <Input
                    component={Checkbox}
                />
            </CrfCondition>
        ),
        fieldName: 'symptomaticSelectionOtherLeft'
    },
    {
        columnName: 'rightbreast',
        content: (
            <CrfCondition
                type={Dtos.General}
                condition={isRightSymptomaticPresentationLeft}
                mode="Enable"
                subscription={{ values: true }}>
                <Input
                    component={Checkbox}
                />
            </CrfCondition>
        ),
        fieldName: 'symptomaticSelectionOtherRight'
    }
];

const otherModeOfDetectionRow: IStaticGridCellProps[] = [
    {
        columnName: 'question',
        content: (
            <Input
                component={Checkbox}
            />
        ),
        fieldName: 'otherModeOfDetection'
    },
    {
        columnName: 'leftbreast',
        content: (
            <CrfCondition
                type={Dtos.General}
                condition={isLeftOtherMode}
                mode="Enable"
                subscription={{ values: true }}>
                <Input
                    component={Text}
                />
            </CrfCondition>
        ),
        fieldName: 'otherModeOfDetectionLeft'
    },
    {
        columnName: 'rightbreast',
        content: (
            <CrfCondition
                type={Dtos.General}
                condition={isRightOtherMode}
                mode="Enable"
                subscription={{ values: true }}>
                <Input
                    component={Text}
                />
            </CrfCondition>
        ),
        fieldName: 'otherModeOfDetectionRight'
    }
];

//const lesionPalpableRow: IStaticGridCellProps[] = [
//    {
//        columnName: 'question',
//        content: (
//            <FormLabel />
//        ),
//        fieldName: 'lesionPalpableLeft'
//    },
//    {
//        columnName: 'leftbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.General}
//                condition={isLeft}
//                mode="Enable"
//                subscription={{ values: true }}>
//                <Input
//                    component={ToggleButtonGroup}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'lesionPalpableLeft'
//    },
//    {
//        columnName: 'rightbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.General}
//                condition={isRight}
//                mode="Enable"
//                subscription={{ values: true }}>
//                <Input
//                    component={ToggleButtonGroup}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'lesionPalpableRight'
//    }
//];

//const lesionPalpableSizeRow: IStaticGridCellProps[] = [
//    {
//        columnName: 'question',
//        content: (
//            <FormLabel />
//        ),
//        fieldName: 'lesionPalpableSizeLeft'
//    },
//    {
//        columnName: 'leftbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.General}
//                condition={isLeftLesionPalpable}
//                mode="Enable"
//                subscription={{ values: true }}>
//                <Input
//                    component={Numeric}
//                    InputProps={{
//                        endAdornment: (
//                            <InputAdornment position="end">
//                                cm&nbsp;
//                            </InputAdornment>
//                        )
//                    }}
//                    numberFormat={THREE_DIGIT_POSITIVE_INTEGER}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'lesionPalpableSizeLeft'
//    },
//    {
//        columnName: 'rightbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.General}
//                condition={isRightLesionPalpable}
//                mode="Enable"
//                subscription={{ values: true }}>
//                <Input
//                    component={Numeric}
//                    InputProps={{
//                        endAdornment: (
//                            <InputAdornment position="end">
//                                cm&nbsp;
//                            </InputAdornment>
//                        )
//                    }}
//                    numberFormat={THREE_DIGIT_POSITIVE_INTEGER}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'lesionPalpableSizeRight'
//    }
//];

//const breastDistortionRow: IStaticGridCellProps[] = [
//    {
//        columnName: 'question',
//        content: (
//            <FormLabel />
//        ),
//        fieldName: 'breastDistortionLeft'
//    },
//    {
//        columnName: 'leftbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.General}
//                condition={isLeft}
//                mode="Enable"
//                subscription={{ values: true }}>
//                <Input
//                    component={ToggleButtonGroup}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'breastDistortionLeft'
//    },
//    {
//        columnName: 'rightbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.General}
//                condition={isRight}
//                mode="Enable"
//                subscription={{ values: true }}>
//                <Input
//                    component={ToggleButtonGroup}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'breastDistortionRight'
//    }
//];

//const inflammationRow: IStaticGridCellProps[] = [
//    {
//        columnName: 'question',
//        content: (
//            <FormLabel />
//        ),
//        fieldName: 'inflammationLeft'
//    },
//    {
//        columnName: 'leftbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.General}
//                condition={isLeft}
//                mode="Enable"
//                subscription={{ values: true }}>
//                <Input
//                    component={ToggleButtonGroup}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'inflammationLeft'
//    },
//    {
//        columnName: 'rightbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.General}
//                condition={isRight}
//                mode="Enable"
//                subscription={{ values: true }}>
//                <Input
//                    component={ToggleButtonGroup}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'inflammationRight'
//    }
//];

//const nippleDischargeRow: IStaticGridCellProps[] = [
//    {
//        columnName: 'question',
//        content: (
//            <FormLabel />
//        ),
//        fieldName: 'nippleDischargeLeft'
//    },
//    {
//        columnName: 'leftbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.General}
//                condition={isLeft}
//                mode="Enable"
//                subscription={{ values: true }}>
//                <Input
//                    component={ToggleButtonGroup}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'nippleDischargeLeft'
//    },
//    {
//        columnName: 'rightbreast',
//        content: (
//            <CrfCondition
//                type={Dtos.General}
//                condition={isRight}
//                mode="Enable"
//                subscription={{ values: true }}>
//                <Input
//                    component={ToggleButtonGroup}
//                />
//            </CrfCondition>
//        ),
//        fieldName: 'nippleDischargeRight'
//    }
//];

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate
];

const General: React.FunctionComponent<IGeneralProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    const canEdit = canUpdateCrf &&
        !!institution &&
        !institution.recruitmentDisabled;

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.General}
                validateOn="onChange"
                canEdit={canEdit}
            >
                <Typography
                    variant="h2"
                    color="primary"
                    className={classes.subHeading}
                >
                    Clinical Presentation
                </Typography>
                <FieldGroup>
                    <StaticGrid
                        headers={clinicalPresentationHeader}
                    >
                        <StaticGridRow
                            cells={breastCancerConfirmRow}
                        />
                    </StaticGrid>
                </FieldGroup>
                <FieldGroup>
                    <StaticGrid
                        headers={detectHeader}
                    >
                        <StaticGridRow
                            cells={symptomaticPresentationRow}
                            showError={combinedSymptomaticShowError}
                        />
                        <StaticGridRow
                            cells={symptomaticSelectionPalpableMassRow}
                        />
                        <StaticGridRow
                            cells={symptomaticSelectionPalpableMassSizeRow}
                        />
                        <StaticGridRow
                            cells={symptomaticSelectionNippleChangeDischargeRow}
                        />
                        <StaticGridRow
                            cells={symptomaticSelectionBreastDistortionRow}
                        />
                        <StaticGridRow
                            cells={symptomaticSelectionAxillaryMassRow}
                        />
                        <StaticGridRow
                            cells={symptomaticSelectionOtherRow}
                        />
                        <StaticGridRow
                            cells={otherModeOfDetectionRow}
                        />
                    </StaticGrid>
                </FieldGroup>
                <SourceDocuments />
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default General;
