/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use sae columns.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type columns
 */
import { Column } from 'material-table';

import { makeStyles, Theme } from '@material-ui/core';

/*
 * Used to format datetimes
 */
import { DateTime } from 'luxon';



/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    textWrap: {
        whiteSpace: 'normal',
        wordWrap: 'break-word'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

const useSaeColumns = (institutions: Dtos.IInstitution[] | null, showSite: boolean): Array<Column<Dtos.Sae<Dtos.SaeForm, Dtos.Patient>>> => {
    const classes = useStyles();

    const columns = React.useMemo(() => {
        const cols: Array<Column<Dtos.Sae<Dtos.SaeForm, Dtos.Patient>> | boolean> = [
            {
                field: 'patient.studyNumber',
                title: 'Study Number',
                render: (sae, type) => (sae.patient.studyNumber),
                //width: 110
            },
            {
                field: 'form.saeNumber',
                title: 'SAE No',
                render: (sae, type) => (sae.form.saeNumber),
                //width: 80
            },
            {
                field: 'form.dateOfSAE',
                title: 'Onset Date',
                render: (sae, type) => ( sae.form.dateOfSAE ? DateTime.fromISO(sae.form.dateOfSAE).toFormat('dd/MM/yyyy') : '' ),
                //width: 100
            },
            {
                field: 'form.mainEvent',
                title: 'Main Event',
                render: (sae, type) => (<div className={classes.textWrap}>{sae.form.ctcaeTerm ?? sae.form.mainEvent}</div>),
                customSort: (data1, data2, type) => (data1.form.ctcaeTerm ?? data1.form.mainEvent) < (data2.form.ctcaeTerm ?? data2.form.mainEvent) ? -1 : 1,
                //width: 165
            },
            showSite && {
                field: 'institutionId',
                title: 'Site',
                render: (sae, type) => (<div className={classes.textWrap}>{institutions?.find(i => i.id === (sae.patient.institutionId))?.name}</div>),
                customSort: (data1, data2, type) => !!institutions && ((institutions.find(i => i.id === (data1.patient.institutionId)) as Dtos.IInstitution).name) < ((institutions.find(i => i.id === (data2.patient.institutionId)) as Dtos.IInstitution).name) ? -1 : 1,
                //width: 165
            },
            {
                field: 'form.eventStatus',
                title: 'Status',
                render: (sae, type) => (
                    <div className={classes.textWrap}>
                    {
                        (sae.form.followUpNumber as number > 0 ? 'Follow-up no.' + sae.form.followUpNumber : 'Initial') + ' ' +
                        (sae.form.eventStatus ? '(' + (sae.form.eventStatus.match(/Ongoing.*/) ? 'Ongoing' : sae.form.eventStatus) + ')' : '')
                    }
                    </div >
                ),
                //width: 100
            }
        ];

        return cols.filter(column => !!column) as Array<Column<Dtos.Sae<Dtos.SaeForm, Dtos.Patient>>>;
    }, [institutions, showSite, classes]);

    return columns;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useSaeColumns;
