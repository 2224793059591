/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    DatePicker,
    Select,
    CrfCondition,
    ICrfConditionParameters,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormGrid,
    IFormGridCell,
    Input,
    TextArea,
    IFormState,
    IFormActions,
    getParentPropertyPath,
    useRelatedField,
    useFormActions,
    useFormState,
    IValidationError,
    ProgressButton
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import { Typography, makeStyles, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import SourceDocuments from '../../components/SourceDocuments';
import { IFormAllowSubmit, IFormSubmitValidationFailed } from '@ngt/opms/dist/form/Form';
import get from 'lodash-es/get';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IPatientTransferProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    subHeading: {
        padding: theme.spacing(3, 3, 1, 3)
    }
}));

const isEnabled = ({ lookups, formState, parentFieldName }: ICrfConditionParameters<Dtos.PatientTransfer>) => {
    // parent field name will be the cell in the grid, we want to get the row, so get the parent of the parent.
    const parentPropertyPath = getParentPropertyPath(parentFieldName);

    if (!parentPropertyPath) {
        return false;
    }

    const row: Dtos.PatientTransferEntry = get(formState?.values, parentPropertyPath);
    const arrayPropertyPath = getParentPropertyPath(parentPropertyPath);

    if (!arrayPropertyPath) {
        return false;
    }

    const array: Dtos.PatientTransferEntry[] = get(formState?.values, arrayPropertyPath);

    if (!row || array.indexOf(row) === 0) {
        return false;
    }

    return !(row.id !== undefined);
};

const columns: Array<IFormGridCell<Dtos.PatientTransferEntry>> = [
    {
        name: 'transferDate',
        maxWidth: 200,
        minWidth: 200,
        width: 200,
        content: (
            <CrfCondition
                type={Dtos.PatientTransfer}
                condition={isEnabled}
            >
                <Input component={DatePicker} />
            </CrfCondition>
        )
    },
    {
        name: 'institutionId',
        maxWidth: 450,
        minWidth: 450,
        width: 450,
        content: (
            <CrfCondition
                type={Dtos.PatientTransfer}
                condition={isEnabled}
            >
                <Input component={Select} />
            </CrfCondition>
        )
    },
    {
        name: 'comments',
        content: (
            <Input component={TextArea} />
        )
    }
]

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate,
    Dtos.Permission.OpmsAdminister,
];

const PatientTransferDialog: React.FunctionComponent = () => {

    const { state: { value: warningModal = false }, context: { setValue: setWarningModal } } = useRelatedField<boolean, IValidationError>('warningModal', { value: true }, false);
    const { submitting, validating } = useFormState({ submitting: true, validating: true });
    const { submit } = useFormActions();

    const toggleTransferModal = React.useCallback(() => {
        setWarningModal(false);
    }, [setWarningModal])

    return <Dialog
        open={warningModal!}
        onClose={toggleTransferModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">Patient Transfer</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Before compelting this transfer ensure:
                                            <ul>
                    <li>All Open Clinica queries have been addressed and resolved by the original institution</li>
                    <br />
                    <li>Export Open Clinica data for this patient and forward to the original institution for appropriate record retention</li>
                </ul>
                <br />
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={toggleTransferModal} color="secondary">
                Cancel
            </Button>
            <ProgressButton
                disabled={validating}
                loading={submitting}
                onClick={() => submit()}
                variant="contained"
                color="primary"
            >
                Transfer
            </ProgressButton>
        </DialogActions>
    </Dialog>
}

const PatientTransfer: React.FunctionComponent<IPatientTransferProps> = () => {
    const classes = useStyles();
    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf, canAdminsterOpms], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const allowSubmit = React.useCallback(async (formState: IFormState<Dtos.PatientTransfer, Dtos.IValidationError>, formActions: IFormActions<Dtos.PatientTransfer, Dtos.IValidationError>, allowSubmitBase: IFormAllowSubmit<Dtos.PatientTransfer, Dtos.IValidationError>) => {
        if (await allowSubmitBase(formState, formActions)) {
            const modalState: { warningModal: boolean } = formState.values as any;

            if (modalState.warningModal !== true && !formState.values.entries[formState.values.entries.length - 1].id) {
                formActions.setFieldValue('warningModal', true);
                return false;
            }

            return true;
        }

        return false;
    }, [])

    const onValidationFailed = React.useCallback(async (formState: IFormState<Dtos.PatientTransfer, Dtos.IValidationError>, validationError: boolean, onValidationFailedBase: IFormSubmitValidationFailed<Dtos.PatientTransfer, Dtos.IValidationError>) => {

        const criticalErrors = Object
            .keys(formState.errors)
            .reduce((array: Dtos.IValidationError[], key: string) => {
                const propertyErrors = formState.errors[key]?.reduce((propertyArray: Dtos.IValidationError[], e: Dtos.IValidationError) => {
                    if (e.type === Dtos.ValidationErrorType.Critical) {
                        return [...propertyArray, e]
                    }

                    return propertyArray;
                }, [])

                return [...array, ...propertyErrors]
            }, []);

        if (!criticalErrors?.length && !validationError) {
            const modalState: { warningModal: boolean } = formState.values as any;

            if (modalState.warningModal === true) {
                return;
            }
        }

        await onValidationFailedBase(formState, validationError)
    }, [])



    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.PatientTransfer}
                validateOn="onChange"
                canEdit={canUpdateCrf && canAdminsterOpms}
                allowSubmit={allowSubmit}
                onFormSubmitValidationFailure={onValidationFailed}
                fieldErrors="default"
            >
                <FieldGroup>
                    <Typography
                        variant="h2"
                        color="primary"
                        className={classes.subHeading}
                    >
                        Transfers
                    </Typography>
                    <FormGrid
                        name="entries"
                        columns={columns}
                        type={Dtos.PatientTransferEntry}
                        allowDelete={(value, index) => {
                            if (!value) {
                                return false;
                            }

                            return !value[index]?.id
                         }}
                    />
                </FieldGroup>
                <PatientTransferDialog/>
                <SourceDocuments />
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default PatientTransfer;
