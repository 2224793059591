/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';


/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    ToggleButtonGroup,
    CrfCondition,
    ICrfConditionParameters,
    GetFieldLookupItem,
    FieldGroup,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormContext,
    OnlinePatientManagementContext,
    Checkbox,
    FieldProvider
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

import { get } from 'lodash-es';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import { Typography, makeStyles, Grid } from '@material-ui/core';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IProcomidaProps {
}


/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    subHeading: {
        padding: theme.spacing(3, 3, 1, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

//const hasAustralianInstitution = (params: ICrfConditionParameters<Dtos.Procomida>) => params?.country?.code === 'australia';
//const hasSignedMainConsentYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.Procomida>) => GetFieldLookupItem(lookups, 'signedMainConsentId', formState?.values.signedMainConsentId)?.type === Dtos.LookupYesNoType.Yes;
//const hasPatientHasEmailYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.Procomida>) => GetFieldLookupItem(lookups, 'patientHasEmail', formState?.values.patientHasEmail)?.type === Dtos.LookupYesNoType.Yes;
//const hasPatientHasEmailNo = ({ lookups, formState }: ICrfConditionParameters<Dtos.Procomida>) => GetFieldLookupItem(lookups, 'patientHasEmail', formState?.values.patientHasEmail)?.type === Dtos.LookupYesNoType.No;

const hasIncompleteForms = ({ lookups, formState }: ICrfConditionParameters<Dtos.Procomida>) => GetFieldLookupItem(lookups, 'completedId', formState?.values.completedId)?.type === Dtos.LookupYesNoType.No;
const hasAssistedForms = ({ lookups, formState }: ICrfConditionParameters<Dtos.Procomida>) => GetFieldLookupItem(lookups, 'assistanceId', formState?.values.assistanceId)?.type === Dtos.LookupYesNoType.Yes;


const isIncompleteOther = ({ formState, parentFieldName }: ICrfConditionParameters<Dtos.Procomida>) => {
    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.ProcomidaIncomplete = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.other === true;
};

const isReasonOther = ({ formState, parentFieldName }: ICrfConditionParameters<Dtos.Procomida>) => {
    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.ProcomidaAssistance = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.reasonOther === true;
};

const isAssistedByOther = ({ formState, parentFieldName }: ICrfConditionParameters<Dtos.Procomida>) => {
    if (!parentFieldName) {
        return false;
    }

    const row: Dtos.ProcomidaAssistance = get(formState?.values, parentFieldName);

    if (!row) {
        return false;
    }

    return row.assistedByOther === true;
};

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate
];

const Procomida: React.FunctionComponent<IProcomidaProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);
    const { formMetadata } = React.useContext(OnlinePatientManagementContext);

    const { form: untypedForm } = React.useContext(FormContext);

    const form: Dtos.Procomida | null = untypedForm as unknown as Dtos.Procomida;

    const [[canUpdateCrf], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const incompleteCount = React.useMemo(() => {
        return form?.incompleteForms?.length ?? 0;
    }, [form]);

    const assistedCount = React.useMemo(() => {
        return form?.assistedForms?.length ?? 0;
    }, [form]);

    const showIncompleteSection = incompleteCount > 0;

    // const showIncompleteSame = incompleteCount > 1;

    const showAssistedSection = assistedCount > 0;

    // const showAssistedSame = incompleteCount > 1;

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    const canEdit = canUpdateCrf &&
        !!institution &&
        !institution.recruitmentDisabled;

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.Procomida}
                validateOn="onChange"
                canEdit={canEdit}
            >
                {
                    showIncompleteSection && (
                        <FieldGroup>
                            <Typography
                                variant="h2"
                                color="primary"
                                className={classes.subHeading}
                            >
                                Incomplete Forms
                            </Typography>
                            <Field name="completedId" component={ToggleButtonGroup} />
                            <CrfCondition
                                type={Dtos.Procomida}
                                condition={hasIncompleteForms}
                                subscription={{ values: true }}
                                mode="Show"
                            >
                                <FieldProvider
                                    name="incompleteForms"
                                    autoRegister={false}
                                >
                                    {
                                        form?.incompleteForms?.map((f, i) => {
                                            const metadata = formMetadata.find(fm => fm.formDefinitionId === f.formDefinitionId);
                                            const title = metadata?.title ?? `Form ${f.formDefinitionId}`;

                                            return (
                                                <FieldProvider
                                                    key={f.formDefinitionId}
                                                    name={`[${i}]`}
                                                    autoRegister={false}
                                                >
                                                    <Typography
                                                        variant="h3"
                                                        color="secondary"
                                                        className={classes.subHeading}
                                                    >
                                                        {title}
                                                    </Typography>
                                                    <Field
                                                        label="Indicate primary reason(s) why form was not completed:"
                                                        name="patientRefusal"
                                                        component={Checkbox}
                                                        paddingBottom={0}
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    />
                                                    <Field
                                                        hideLabel
                                                        name="patientIll"
                                                        component={Checkbox}
                                                        paddingBottom={0}
                                                        paddingTop={0}
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    />
                                                    <Grid
                                                        container
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={5}
                                                            md={4}
                                                            lg={3}
                                                            xl={3}
                                                        >
                                                            <Field
                                                                hideLabel
                                                                name="other"
                                                                component={Checkbox}
                                                                paddingBottom={0}
                                                                paddingTop={0}
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                xl={12}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={7}
                                                            md={8}
                                                            lg={9}
                                                            xl={9}
                                                        >
                                                            <CrfCondition
                                                                type={Dtos.Procomida}
                                                                condition={isIncompleteOther}
                                                                subscription={{ values: true }}
                                                            >
                                                                <Field
                                                                    hideLabel
                                                                    name="otherSpecify"
                                                                    component={Text}
                                                                    paddingTop={0}
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={12}
                                                                    lg={12}
                                                                    xl={12}
                                                                />
                                                            </CrfCondition>
                                                        </Grid>
                                                    </Grid>
                                                </FieldProvider>
                                            )
                                        })
                                    }
                                </FieldProvider>
                            </CrfCondition>
                        </FieldGroup>
                    )
                }
                {
                    showAssistedSection && (
                        <FieldGroup>
                            <Typography
                                variant="h2"
                                color="primary"
                                className={classes.subHeading}
                            >
                                Assisted Forms
                            </Typography>
                            <Field name="assistanceId" component={ToggleButtonGroup} />
                            <CrfCondition
                                type={Dtos.Procomida}
                                condition={hasAssistedForms}
                                subscription={{ values: true }}
                                mode="Show"
                            >
                                <FieldProvider
                                    name="assistedForms"
                                    autoRegister={false}
                                >
                                    {
                                        form?.assistedForms?.map((f, i) => {
                                            const metadata = formMetadata.find(fm => fm.formDefinitionId === f.formDefinitionId);
                                            const title = metadata?.title ?? `Form ${f.formDefinitionId}`;

                                            return (
                                                <FieldProvider
                                                    key={f.formDefinitionId}
                                                    name={`[${i}]`}
                                                    autoRegister={false}
                                                >
                                                    <Typography
                                                        variant="h3"
                                                        color="secondary"
                                                        className={classes.subHeading}
                                                    >
                                                        {title}
                                                    </Typography>
                                                    <Field
                                                        label="Indicate how the patient was assisted:"
                                                        name="assistanceReadAllowed"
                                                        component={Checkbox}
                                                        paddingBottom={0}
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    />
                                                    <Field
                                                        hideLabel
                                                        name="assistanceClarificationRequired"
                                                        component={Checkbox}
                                                        paddingBottom={0}
                                                        paddingTop={0}
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    />
                                                    <Field
                                                        hideLabel
                                                        name="assistanceIndependentlyCompleted"
                                                        component={Checkbox}
                                                        paddingBottom={0}
                                                        paddingTop={0}
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    />
                                                    <Field
                                                        hideLabel
                                                        name="assistanceOther"
                                                        component={Checkbox}
                                                        paddingBottom={0}
                                                        paddingTop={0}
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    />
                                                    <Field
                                                        label="Indicate the primary reason(s) for assistance:"
                                                        name="reasonCouldNotRead"
                                                        component={Checkbox}
                                                        paddingBottom={0}
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    />
                                                    <Grid
                                                        container
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={5}
                                                            md={4}
                                                            lg={3}
                                                            xl={3}
                                                        >
                                                            <Field
                                                                hideLabel
                                                                name="reasonOther"
                                                                component={Checkbox}
                                                                paddingBottom={0}
                                                                paddingTop={0}
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                xl={12}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={7}
                                                            md={8}
                                                            lg={9}
                                                            xl={9}
                                                        >
                                                            <CrfCondition
                                                                type={Dtos.Procomida}
                                                                condition={isReasonOther}
                                                                subscription={{ values: true }}
                                                            >
                                                                <Field
                                                                    hideLabel
                                                                    name="reasonOtherSpecify"
                                                                    component={Text}
                                                                    paddingTop={0}
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={12}
                                                                    lg={12}
                                                                    xl={12}
                                                                />
                                                            </CrfCondition>
                                                        </Grid>
                                                    </Grid>
                                                    <Field
                                                        label="Indicate who assisted or completed the assessment:"
                                                        name="assistedByStaff"
                                                        component={Checkbox}
                                                        paddingBottom={0}
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    />
                                                    <Field
                                                        hideLabel
                                                        name="assistedByFamily"
                                                        component={Checkbox}
                                                        paddingBottom={0}
                                                        paddingTop={0}
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    />
                                                    <Field
                                                        hideLabel
                                                        name="assistedByFriend"
                                                        component={Checkbox}
                                                        paddingBottom={0}
                                                        paddingTop={0}
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    />
                                                    <Grid
                                                        container
                                                    >
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={5}
                                                            md={4}
                                                            lg={3}
                                                            xl={3}
                                                        >
                                                            <Field
                                                                hideLabel
                                                                name="assistedByOther"
                                                                component={Checkbox}
                                                                paddingBottom={0}
                                                                paddingTop={0}
                                                                xs={12}
                                                                sm={12}
                                                                md={12}
                                                                lg={12}
                                                                xl={12}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={7}
                                                            md={8}
                                                            lg={9}
                                                            xl={9}
                                                        >
                                                            <CrfCondition
                                                                type={Dtos.Procomida}
                                                                condition={isAssistedByOther}
                                                                subscription={{ values: true }}
                                                            >
                                                                <Field
                                                                    hideLabel
                                                                    name="assistedByOtherSpecify"
                                                                    component={Text}
                                                                    paddingTop={0}
                                                                    xs={12}
                                                                    sm={12}
                                                                    md={12}
                                                                    lg={12}
                                                                    xl={12}
                                                                />
                                                            </CrfCondition>
                                                        </Grid>
                                                    </Grid>
                                                </FieldProvider>
                                            )
                                        })
                                    }
                                </FieldProvider>
                            </CrfCondition>
                        </FieldGroup>
                    )
                }
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default Procomida;
