/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/**
 * Used for the basic page layout.
 */
import {
    CrfForm,
    Field,
    Text,
    Select,
    ToggleButtonGroup,
    CrfCondition,
    ICrfConditionParameters,
    Checkbox,
    FieldGroup,
    GetFieldLookupItem,
    FormBreadcrumbs,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    ShowErrorFunction,
    DEFAULT_SHOW_ERROR_FUNCTION,
    KeyboardDatePicker
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';
import SourceDocuments from '../../components/SourceDocuments';
import { Typography, makeStyles } from '@material-ui/core';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IPreMriPlanningProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    subHeading: {
        padding: theme.spacing(3, 3, 1, 3)
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */
const isPlannedBreastAxillarySurgeryYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.PreMriPlanning>) => GetFieldLookupItem(lookups, 'plannedBreastAxillarySurgery', formState?.values.plannedBreastAxillarySurgery)?.type === Dtos.LookupYesNoType.Yes;
const isPlannedSurgeryMastectomyBilateral = ({ formState }: ICrfConditionParameters<Dtos.PreMriPlanning>) => formState?.values.plannedSurgeryMastectomyBilateral === true;
const isPlannedTypeOfAxillarySurgeryYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.PreMriPlanning>) => GetFieldLookupItem(lookups, 'plannedTypeOfAxillarySurgery', formState?.values.plannedTypeOfAxillarySurgery)?.type === Dtos.LookupYesNoType.Yes;
const isPlannedTypeOther = ({ formState }: ICrfConditionParameters<Dtos.PreMriPlanning>) => formState?.values.plannedTypeOther === true;
const isPlannedRadiotherapyYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.PreMriPlanning>) => GetFieldLookupItem(lookups, 'plannedRadiotherapy', formState?.values.plannedRadiotherapy)?.type === Dtos.LookupYesNoType.Yes;
const isPlannedRadiotherapyOther = ({ formState }: ICrfConditionParameters<Dtos.PreMriPlanning>) => formState?.values.plannedRadiotherapyOther === true;
const isPlannedSystemicTherapyYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.PreMriPlanning>) => GetFieldLookupItem(lookups, 'plannedSystemicTherapy', formState?.values.plannedSystemicTherapy)?.type === Dtos.LookupYesNoType.Yes;
const isPlannedSystemicTherapyOther = ({ formState }: ICrfConditionParameters<Dtos.PreMriPlanning>) => formState?.values.plannedSystemicTherapyOther === true;
const isMriScheduled = ({ formState }: ICrfConditionParameters<Dtos.PreMriPlanning>) => formState?.values.mriNotScheduled !== true;
//const isWhyWasMRISuggestedYes = ({ lookups, formState }: ICrfConditionParameters<Dtos.PreMriPlanning>) => GetFieldLookupItem(lookups, 'whyWasMRISuggested', formState?.values.whyWasMRISuggested)?.type === Dtos.LookupYesNoType.Yes;

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsCrfUpdate
];

const createCheckboxShowError = (errorCode: string, checkboxFields: string[]): ShowErrorFunction => {
    return (name, error, touched, errors, validating, isAlwaysShown) => {
        if (isAlwaysShown) {
            return true;
        }

        if (error.code === errorCode) {
            return checkboxFields.some(field => touched[field]);
        }

        return DEFAULT_SHOW_ERROR_FUNCTION(name, error, touched, errors, validating, isAlwaysShown);
    }
};

const PreMriPlanning: React.FunctionComponent<IPreMriPlanningProps> = () => {
    const classes = useStyles();

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdateCrf], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const showBreastSurgeryError: ShowErrorFunction = React.useMemo(() => {
        return createCheckboxShowError('F-PMP-002', [
            'plannedSurgeryMastectomyUnilateral',
            'plannedSurgeryMastectomyBilateral',
            'plannedSurgeryReconstruction',
            'plannedSurgeryBreastConservationSurgery',
            'plannedSurgeryBCSPlusLevelTwoOrHigherOncoplasticSurgery'
        ]);
    }, []);

    const showAxillarySurgeryError: ShowErrorFunction = React.useMemo(() => {
        return createCheckboxShowError('F-PMP-005', [
            'plannedTypeSentinelLymphNodeBiopsy',
            'plannedTypeAxillaryClearance',
            'plannedTypeOther'
        ]);
    }, []);

    const showRadiotherapyError: ShowErrorFunction = React.useMemo(() => {
        return createCheckboxShowError('F-PMP-008', [
            'plannedRadiotherapyChestWall',
            'plannedRadiotherapyBreast',
            'plannedRadiotherapyRegionalNodes',
            'plannedRadiotherapyOther'
        ]);
    }, []);

    const showSystemicTherapyError: ShowErrorFunction = React.useMemo(() => {
        return createCheckboxShowError('F-PMP-011', [
            'plannedSystemicTherapyChemotherapy',
            'plannedSystemicTherapyHERDirected',
            'plannedSystemicTherapyEndocrine',
            'plannedSystemicTherapyNeoadjuvant',
            'plannedSystemicTherapyOther'
        ]);
    }, []);

    const showSuggestedError: ShowErrorFunction = React.useMemo(() => {
        return createCheckboxShowError('F-PMP-014', [
            'whyWasMRISuggestedYoungAge',
            'whyWasMRISuggestedSizeDiscrepancy',
            'whyWasMRISuggestedDenseBreasts',
            'whyWasMRISuggestedMultifocality',
            'whyWasMRISuggestedLobularCancer',
            'whyWasMRISuggestedMutationCarrier',
            'whyWasMRISuggestedNeoadjuvantTherapyPlanned',
            'whyWasMRISuggestedPatientRequest'
        ]);
    }, []);


    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    const canEdit = canUpdateCrf &&
        !!institution &&
        !institution.recruitmentDisabled;

    return (
        <>
            <FormBreadcrumbs />
            <CrfForm
                formType={Dtos.PreMriPlanning}
                validateOn="onChange"
                canEdit={canEdit}
            >
                <FieldGroup>
                    <CrfCondition
                        type={Dtos.PreMriPlanning}
                        condition={isMriScheduled}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="dateStudySpecificMriPlanned"
                            component={KeyboardDatePicker}
                        />
                    </CrfCondition>
                    <Field
                        name="mriNotScheduled"
                        component={Checkbox}
                        label={null}
                    />
                </FieldGroup>
                <Typography
                    variant="h2"
                    color="primary"
                    className={classes.subHeading}
                >
                    MDT suggested Pre-MRI Treatment Plan
                </Typography>
                <FieldGroup>
                    <Field
                        name="plannedBreastAxillarySurgery"
                        component={ToggleButtonGroup}
                        showError={showBreastSurgeryError}
                    />
                    <CrfCondition
                        type={Dtos.PreMriPlanning}
                        condition={isPlannedBreastAxillarySurgeryYes}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="plannedSurgeryMastectomyUnilateral"
                            label={'Select all that apply'}
                            sm={9}
                            md={9}
                            xl={9}
                            lg={9}
                            component={Checkbox}
                        />
                        <Field
                            name="plannedSurgeryMastectomyBilateral"
                            label={null}
                            sm={9}
                            md={9}
                            xl={9}
                            lg={9}
                            component={Checkbox}
                        />
                        <CrfCondition
                            type={Dtos.PreMriPlanning}
                            condition={isPlannedSurgeryMastectomyBilateral}
                            mode="Enable"
                            subscription={{ values: true }}
                        >
                            <Field
                                name="plannedSurgeryMastectomyBilateralReason"
                                label={null}
                                sm={9}
                                md={9}
                                xl={9}
                                lg={9}
                                component={Select}
                            />
                        </CrfCondition>
                        <Field
                            name="plannedSurgeryReconstruction"
                            label={null}
                            sm={9}
                            md={9}
                            xl={9}
                            lg={9}
                            component={Checkbox}
                        />
                        <Field
                            name="plannedSurgeryBreastConservationSurgery"
                            label={null}
                            sm={9}
                            md={9}
                            xl={9}
                            lg={9}
                            component={Checkbox}
                        />
                        <Field
                            name="plannedSurgeryBCSPlusLevelTwoOrHigherOncoplasticSurgery"
                            label={null}
                            sm={9}
                            md={9}
                            xl={9}
                            lg={9}
                            component={Checkbox}
                        />
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <Field
                        name="plannedTypeOfAxillarySurgery"
                        component={ToggleButtonGroup}
                        showError={showAxillarySurgeryError}
                    />
                    <CrfCondition
                        type={Dtos.PreMriPlanning}
                        condition={isPlannedTypeOfAxillarySurgeryYes}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="plannedTypeSentinelLymphNodeBiopsy"
                            label={'Select all that apply'}
                            sm={9}
                            md={9}
                            xl={9}
                            lg={9}
                            component={Checkbox}
                        />
                        <Field
                            name="plannedTypeAxillaryClearance"
                            label={null}
                            sm={9}
                            md={9}
                            xl={9}
                            lg={9}
                            component={Checkbox}
                        />
                        <Field
                            name="plannedTypeOther"
                            label={null}
                            sm={9}
                            md={9}
                            xl={9}
                            lg={9}
                            component={Checkbox}
                        />
                        <CrfCondition
                            type={Dtos.PreMriPlanning}
                            condition={isPlannedTypeOther}
                            mode="Enable"
                            subscription={{ values: true }}
                        >
                            <Field
                                name="plannedTypeOtherSpecify"
                                label={null}
                                sm={9}
                                md={9}
                                xl={9}
                                lg={9}
                                component={Text}
                            />
                        </CrfCondition>
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <Field
                        name="plannedRadiotherapy"
                        component={ToggleButtonGroup}
                        showError={showRadiotherapyError}
                    />
                    <CrfCondition
                        type={Dtos.PreMriPlanning}
                        condition={isPlannedRadiotherapyYes}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="plannedRadiotherapyChestWall"
                            label={'Select all that apply'}
                            sm={9}
                            md={9}
                            xl={9}
                            lg={9}
                            component={Checkbox}
                        />
                        <Field
                            name="plannedRadiotherapyBreast"
                            label={null}
                            sm={9}
                            md={9}
                            xl={9}
                            lg={9}
                            component={Checkbox}
                        />
                        <Field
                            name="plannedRadiotherapyRegionalNodes"
                            label={null}
                            sm={9}
                            md={9}
                            xl={9}
                            lg={9}
                            component={Checkbox}
                        />
                        <Field
                            name="plannedRadiotherapyOther"
                            label={null}
                            sm={9}
                            md={9}
                            xl={9}
                            lg={9}
                            component={Checkbox}
                        />
                        <CrfCondition
                            type={Dtos.PreMriPlanning}
                            condition={isPlannedRadiotherapyOther}
                            mode="Enable"
                            subscription={{ values: true }}
                        >
                            <Field
                                name="plannedRadiotherapyOtherSpecify"
                                label={null}
                                sm={9}
                                md={9}
                                xl={9}
                                lg={9}
                                component={Text}
                            />
                        </CrfCondition>
                    </CrfCondition>
                </FieldGroup>
                <FieldGroup>
                    <Field
                        name="plannedSystemicTherapy"
                        component={ToggleButtonGroup}
                        showError={showSystemicTherapyError}
                    />
                    <CrfCondition
                        type={Dtos.PreMriPlanning}
                        condition={isPlannedSystemicTherapyYes}
                        mode="Enable"
                        subscription={{ values: true }}
                    >
                        <Field
                            name="plannedSystemicTherapyChemotherapy"
                            label={'Select all that apply'}
                            sm={9}
                            md={9}
                            xl={9}
                            lg={9}
                            component={Checkbox}
                        />
                        <Field
                            name="plannedSystemicTherapyHERDirected"
                            label={null}
                            sm={9}
                            md={9}
                            xl={9}
                            lg={9}
                            component={Checkbox}
                        />
                        <Field
                            name="plannedSystemicTherapyEndocrine"
                            label={null}
                            sm={9}
                            md={9}
                            xl={9}
                            lg={9}
                            component={Checkbox}
                        />
                        <Field
                            name="plannedSystemicTherapyNeoadjuvant"
                            label={null}
                            sm={9}
                            md={9}
                            xl={9}
                            lg={9}
                            component={Checkbox}
                        />
                        <Field
                            name="plannedSystemicTherapyOther"
                            label={null}
                            sm={9}
                            md={9}
                            xl={9}
                            lg={9}
                            component={Checkbox}
                        />
                        <CrfCondition
                            type={Dtos.PreMriPlanning}
                            condition={isPlannedSystemicTherapyOther}
                            mode="Enable"
                            subscription={{ values: true }}
                        >
                            <Field
                                name="plannedSystemicTherapyOtherSpecify"
                                label={null}
                                sm={9}
                                md={9}
                                xl={9}
                                lg={9}
                                component={Text}
                            />
                        </CrfCondition>
                    </CrfCondition>
                </FieldGroup>
                <Typography
                    variant="h2"
                    color="primary"
                    className={classes.subHeading}
                >
                    MRI
                </Typography>
                <FieldGroup>
                    <Field
                        name="whyWasMRISuggested"
                        showError={showSuggestedError}
                    />
                    <Field
                        name="whyWasMRISuggestedYoungAge"
                        label={'Select all that apply'}
                        sm={9}
                        md={9}
                        xl={9}
                        lg={9}
                        component={Checkbox}
                    />
                    <Field
                        name="whyWasMRISuggestedSizeDiscrepancy"
                        label={null}
                        sm={9}
                        md={9}
                        xl={9}
                        lg={9}
                        component={Checkbox}
                    />
                    <Field
                        name="whyWasMRISuggestedDenseBreasts"
                        label={null}
                        sm={9}
                        md={9}
                        xl={9}
                        lg={9}
                        component={Checkbox}
                    />
                    <Field
                        name="whyWasMRISuggestedMultifocality"
                        label={null}
                        sm={9}
                        md={9}
                        xl={9}
                        lg={9}
                        component={Checkbox}
                    />
                    <Field
                        name="whyWasMRISuggestedLobularCancer"
                        label={null}
                        sm={9}
                        md={9}
                        xl={9}
                        lg={9}
                        component={Checkbox}
                    />
                    <Field
                        name="whyWasMRISuggestedMutationCarrier"
                        label={null}
                        sm={9}
                        md={9}
                        xl={9}
                        lg={9}
                        component={Checkbox}
                    />
                    <Field
                        name="whyWasMRISuggestedNeoadjuvantTherapyPlanned"
                        label={null}
                        sm={9}
                        md={9}
                        xl={9}
                        lg={9}
                        component={Checkbox}
                    />
                    <Field
                        name="whyWasMRISuggestedPatientRequest"
                        label={null}
                        sm={9}
                        md={9}
                        xl={9}
                        lg={9}
                        component={Checkbox}
                    />
                </FieldGroup>
                <SourceDocuments />
            </CrfForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default PreMriPlanning;
