/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to style components
 */
import { useTheme, useMediaQuery } from '@material-ui/core';

/**
 * Used for the basic page layout.
 */
import {
    Field,
    ToggleButtonGroup,
    RadioGroup,
    RatingTable,
    RatingRow,
    ProForm,
    useAuthenticatedUser,
    UserType,
    MasterGroupContext,
    CollaboratingGroupContext,
    InstitutionContext,
    PatientContext,
    RouteLoading,
    FormBreadcrumbs,
    FormContext
} from '@ngt/opms';

import { usePermissionsByIds } from '@ngt/opms-bctapi';

import { RequestState } from '@ngt/request-utilities';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../../api/dtos';

import SourceDocuments from '../../components/SourceDocuments';
import { useHistory } from 'react-router-dom';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IDecisionRegretScaleProps {
}

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Dtos.Permission[] = [
    Dtos.Permission.OpmsProUpdate,
    Dtos.Permission.OpmsProAdminister
];

const DecisionRegretScale: React.FunctionComponent<IDecisionRegretScaleProps> = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

    const [user] = useAuthenticatedUser();

    const isPatient = React.useMemo(() => {
        return user?.type === UserType.Patient;
    }, [user]);

    const showSteps = isPatient || isMobile;

    const { masterGroup } = React.useContext(MasterGroupContext);
    const { collaboratingGroup } = React.useContext(CollaboratingGroupContext);
    const { institution } = React.useContext(InstitutionContext);
    const { patient } = React.useContext(PatientContext);

    const [[canUpdatePro, canAdministerPro], permissionLoadState] = usePermissionsByIds(permissions, masterGroup?.id, collaboratingGroup?.id, institution?.id, patient?.id, true);

    const history = useHistory();
    const { form } = React.useContext(FormContext);

    if (form && (form as Dtos.IProForm).completed && isPatient) {
        history.push(`/`);
    }

    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    const canEdit = canUpdatePro || canAdministerPro;

    return (
        <>
            {
                !isPatient && (
                    <FormBreadcrumbs />
                )
            }
            <ProForm
                formType={Dtos.DecisionConflictScale}
                validateOn="onChange"
                useSteps={showSteps}
                canEdit={canEdit}
                canAdministerProForm={canAdministerPro}
            >
                {
                    showSteps && (
                        isExtraSmall?
                            <Field
                                name= "rightDecisionId"
                                component = { ToggleButtonGroup }
                                orientation = "vertical"
                                spacing = { 3 }
                                xs = { 12 }
                                sm = { 12 }
                                md = { 12 }
                                lg = { 12 }
                                xl = { 12 }
                            /> :
                            <Field
                                name="rightDecisionId"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="regretDecisionId"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="regretDecisionId"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="sameDecisionAgainId"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="sameDecisionAgainId"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="decisionCausedHarmId"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="decisionCausedHarmId"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    showSteps && (
                        isExtraSmall ?
                            <Field
                                name="decisionWiseId"
                                component={ToggleButtonGroup}
                                orientation="vertical"
                                spacing={3}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            /> :
                            <Field
                                name="decisionWiseId"
                                component={RadioGroup}
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            />
                    )
                }
                {
                    !showSteps && (
                        <RatingTable>
                            <RatingRow name="rightDecisionId" />
                            <RatingRow name="regretDecisionId" />
                            <RatingRow name="sameDecisionAgainId" />
                            <RatingRow name="decisionCausedHarmId" />
                            <RatingRow name="decisionWiseId" />
                        </RatingTable>
                    )
                }
                {
                    canAdministerPro && (
                        <SourceDocuments />
                    )
                }
            </ProForm>
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default DecisionRegretScale;
