/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the hook to use screening log columns.
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/*
 * Used to create a context.
 */
import * as React from 'react';

/*
 * Used to type columns
 */
import { Column } from 'material-table';

import { makeStyles, Theme } from '@material-ui/core';

/*
 * Used to format datetimes
 */
import { DateTime } from 'luxon';

import { usePatientsByCodes } from '@ngt/opms';


/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */

import * as Dtos from '../../api/dtos';

import { LookupScreeningLogReasonType } from '../../api/dtos';

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    textWrap: {
        whiteSpace: 'normal',
        wordWrap: 'break-word'
    }
}));

/*
 * ---------------------------------------------------------------------------------
 * Functions
 * ---------------------------------------------------------------------------------
 */

export const getReasonValue = (reason: number | undefined) => {
    switch (reason) {
        case LookupScreeningLogReasonType.PatientIsIneligible: { return 'Patients ineligible for registration'; }
        case LookupScreeningLogReasonType.ClinicianUnsuitable: { return 'Clinician/MDT deemed unsuitable'; }
        case LookupScreeningLogReasonType.PatientDeclinedTrial: { return 'Patient declined trial because she wants standard of care'; }
        case LookupScreeningLogReasonType.PatientDeclinedNoReason: { return 'Patient declined no reason given or not interested in the trial'; }
        default: { return ''; }
    }
}

const useScreeningLogColumns = (institutions: Dtos.IInstitution[] | null, showSite: boolean): Array<Column<Dtos.ScreeningLog>> => {
    const classes = useStyles();

    const [patients, , ] = usePatientsByCodes(null, null, null, null, null, true);

    const columns = React.useMemo(() => {
        const cols: Array<Column<Dtos.ScreeningLog> | boolean> = [
            {
                field: 'screeningDate',
                title: 'Screening Date',
                render: (screeningLog, type) => (screeningLog.screeningDate ? DateTime.fromISO(screeningLog.screeningDate).toFormat('dd/MM/yyyy') : ''),
            },
            showSite && {
                field: 'institutionId',
                title: 'Site',
                render: (screeningLog, type) => (<div className={classes.textWrap}>{institutions?.find(i => i.id === (screeningLog.institutionId))?.name}</div>),
                customSort: (data1, data2, type) => !!institutions && ((institutions.find(i => i.id === (data1.institutionId)) as Dtos.IInstitution).name) < ((institutions.find(i => i.id === (data2.institutionId)) as Dtos.IInstitution).name) ? -1 : 1,
            },
            {
                field: 'initials',
                title: 'Initials',
                render: (screeningLog, type) => (screeningLog.initials),
            },
            {
                field: 'dateOfBirth',
                title: 'Date of Birth',
                render: (screeningLog, type) => (screeningLog.dateOfBirth ? DateTime.fromISO(screeningLog.dateOfBirth).toFormat('dd/MM/yyyy') : ''),
            },
            {
                field: 'patientId',
                title: 'Study Number',
                render: (screeningLog, type) => ((patients?.find(p => p.id === screeningLog.patientId) as Dtos.Patient)?.studyNumber),
                customSort: (data1, data2, type) => !!patients && ((patients?.find(p => p.id === data1.patientId) as Dtos.Patient)?.studyNumber ?? '') < ((patients?.find(p => p.id === data2.patientId) as Dtos.Patient)?.studyNumber ?? '') ? -1 : 1,
            },
            {
                field: 'patientId',
                title: 'Registration Date',
                render: (screeningLog, type) => (
                    (patients?.find(p => p.id === screeningLog.patientId) as Dtos.Patient)?.registrationDate ?
                        DateTime.fromISO(((patients?.find(p => p.id === screeningLog.patientId) as Dtos.Patient)?.registrationDate!!)).toFormat('dd/MM/yyyy')
                        : ''
                ),
                customSort: (data1, data2, type) => !!patients && ((patients?.find(p => p.id === data1.patientId) as Dtos.Patient)?.registrationDate ?? '') < ((patients?.find(p => p.id === data2.patientId) as Dtos.Patient)?.registrationDate ?? '') ? -1 : 1,
            },
            {
                field: 'reason',
                title: 'Reason',
                render: (screeningLog, type) => (<div className={classes.textWrap}>{getReasonValue(screeningLog.reason)}</div>),
                customSort: (data1, data2, type) => (getReasonValue(data1.reason) < getReasonValue(data2.reason) ? -1 : 1)
            },
            {
                field: 'reasonSpecify',
                title: 'Failed Eligibility Criteria',
                render: (screeningLog, type) => (<div className={classes.textWrap}>{screeningLog.reasonSpecify}</div>),
                
            }
        ];

        return cols.filter(column => !!column) as Array<Column<Dtos.ScreeningLog>>;
    }, [patients, institutions, showSite, classes]);

    return columns;
};

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */

export default useScreeningLogColumns;
