/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae landing page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import { Field, FieldGroup, Select, Text, TextArea, CrfCondition, ICrfConditionParameters, KeyboardDatePicker } from '@ngt/opms';
import * as React from 'react';
/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import * as Dtos from '../../api/dtos';
import { MenuItem, makeStyles, Theme } from '@material-ui/core';
import { LookupScreeningLogReasonType } from '../../api/dtos';
import { getReasonValue } from '../../hooks/screeningLogs/useScreeningLogColumns';


/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IScreeningLogFieldGroupProps {
    screeningLog?: Dtos.ScreeningLog;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    disabled: {
        background: '#f2f2f2'
    },
}));

/*
* ---------------------------------------------------------------------------------
* Functions
* ---------------------------------------------------------------------------------
*/
const isReasonPatientIneligible = ({ formState }: ICrfConditionParameters<Dtos.ScreeningLog>) => formState?.values.reason === Dtos.LookupScreeningLogReasonType.PatientIsIneligible;

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */
const ScreeningLogFieldGroup = ({
    screeningLog
}: IScreeningLogFieldGroupProps) => {
    const classes = useStyles();

    return (
        <>
            <FieldGroup>
                <Field
                    name="screeningDate"
                    component={KeyboardDatePicker}
                    sm={8}
                    md={8}
                    xl={8}
                    lg={8}
                    simpleLabel
                />
                <Field
                    name="initials"
                    component={Text}
                    sm={8}
                    md={8}
                    xl={8}
                    lg={8}
                    simpleLabel
                />
                <Field
                    name="dateOfBirth"
                    component={KeyboardDatePicker}
                    sm={8}
                    md={8}
                    xl={8}
                    lg={8}
                    simpleLabel
                />
                <Field
                    name="reason"
                    component={Select}
                    sm={8}
                    md={8}
                    xl={8}
                    lg={8}
                    simpleLabel
                >
                    <MenuItem
                        key={LookupScreeningLogReasonType.PatientIsIneligible}
                        value={LookupScreeningLogReasonType.PatientIsIneligible}
                    >
                        {getReasonValue(LookupScreeningLogReasonType.PatientIsIneligible)}
                    </MenuItem>
                    <MenuItem
                        key={LookupScreeningLogReasonType.ClinicianUnsuitable}
                        value={LookupScreeningLogReasonType.ClinicianUnsuitable}
                    >
                        {getReasonValue(LookupScreeningLogReasonType.ClinicianUnsuitable)}
                    </MenuItem>
                    <MenuItem
                        key={LookupScreeningLogReasonType.PatientDeclinedTrial}
                        value={LookupScreeningLogReasonType.PatientDeclinedTrial}
                    >
                        {getReasonValue(LookupScreeningLogReasonType.PatientDeclinedTrial)}
                    </MenuItem>
                    <MenuItem
                        key={LookupScreeningLogReasonType.PatientDeclinedNoReason}
                        value={LookupScreeningLogReasonType.PatientDeclinedNoReason}
                    >
                        {getReasonValue(LookupScreeningLogReasonType.PatientDeclinedNoReason)}
                    </MenuItem>
                </Field>
                <CrfCondition
                    type={Dtos.ScreeningLog}
                    condition={isReasonPatientIneligible}
                    mode="Enable"
                    subscription={{ values: true }}
                >
                    <Field
                        name="reasonSpecify"
                        component={TextArea}
                        variant="outlined"
                        multiline={true}
                        rows={5}
                        sm={8}
                        md={8}
                        xl={8}
                        lg={8}
                        simpleLabel
                        InputProps={{
                            classes: {
                                disabled: classes.disabled
                            }
                        }}
                        />
                </CrfCondition>
            </FieldGroup>
        </>
    );
}


/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default ScreeningLogFieldGroup;