/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/**
 * Used for the basic page layout.
 */
import {
    OnlinePatientManagementLayout,
    IMenuItem,
    OnlinePatientManagementContext,
    InstitutionsByCodesRoute,
    RouteLoading,
    CrfFormByCodesRoutes,
    PatientSummaryByStudyNumberRoute,
    useIsMobile,
    useAuthenticatedUser,
    //UserType,
    ICrfFormMapping,
    UserType
} from '@ngt/opms';

import { SaeByPatientStudyNumberRoute } from '@ngt/opms-sae'

import { Permission, usePermissionsByIds } from '@ngt/opms-bctapi'

import { RequestState } from '@ngt/request-utilities';

/**
 * Used for font styling.
 */
import { Typography, Collapse, makeStyles, Theme } from '@material-ui/core';

/**
 * Used to display icons
 */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Used for the copyright icon.
 */
import { faCopyright } from '@fortawesome/pro-regular-svg-icons/faCopyright';

/**
 * Used for the patient icon.
 */
import { faUser } from '@fortawesome/pro-duotone-svg-icons/faUser';

/**
 * Used for the ecrf icon.
 */
import { faList } from '@fortawesome/pro-duotone-svg-icons/faList';

/**
 * Used for the sae icon.
 */
import { faFileMedical as saeIcon } from '@fortawesome/pro-duotone-svg-icons/faFileMedical';

/**
 * Used for the screening log icon.
 */
import { faFileAlt as screeningLogIcon } from '@fortawesome/pro-duotone-svg-icons/faFileAlt';

import { Switch, Redirect } from 'react-router-dom';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

//const Registration = React.lazy(() => import('./containers/Registration'));

//const Preregistration = React.lazy(() => import('./containers/form/Preregistration'));

//const DecisionConflictScale = React.lazy(() => import('./containers/pro/DecisionConflictScale'));

//const DecisionRegretScale = React.lazy(() => import('./containers/pro/DecisionRegretScale'));

//const Eq5d5l = React.lazy(() => import('./containers/pro/Eq5d5l'));

//const ImpactOfMri = React.lazy(() => import('./containers/pro/ImpactOfMri'));

//const PreferenceForSurgery = React.lazy(() => import('./containers/pro/PreferenceForSurgery'));

import Registration from './containers/Registration';

import PatientSummary from './containers/PatientSummary';

import PatientPros from './containers/PatientPros';

import Preregistration from './containers/form/Preregistration';

import PreMriPlanning from './containers/form/PreMriPlanning';

import General from './containers/form/General';

import EligibilityChecklist from './containers/form/EligibilityChecklist';

import DiagnosticAndPathology from './containers/form/DiagnosticAndPathology';

import DiagnosticAndImaging from './containers/form/DiagnosticAndImaging';

import DecisionConflictScale from './containers/pro/DecisionConflictScale';

import DecisionRegretScale from './containers/pro/DecisionRegretScale';

import Eq5d5l from './containers/pro/Eq5d5l';

import ImpactOfMri from './containers/pro/ImpactOfMri';

import PreferenceForSurgery from './containers/pro/PreferenceForSurgery';

import Procomida from './containers/form/Procomida';

import SaesPage from './containers/sae/SaesPage'

import SaeViewPage from './containers/sae/SaeViewPage'

import SaeNotifyPage from './containers/sae/SaeNotifyPage'

import ScreeningLogPage from './containers/screeninglog/ScreeningLogPage';


import * as Dtos from './api/dtos';
import PatientTransfer from './containers/staff/PatientTransfer';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import ErrorPage, { ErrorTypeEnum } from './containers/error/ErrorPage';



/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    banner: {
        borderRadius: 0,
        width: '100%'
    },
    bannerContainer: {
        position: 'fixed',
        top: '0px',
        width: '100%',
        zIndex: 10
    }
}))

/*
 * ----------------------------------------------------------------------------------
 * constants
 * ----------------------------------------------------------------------------------
 */

const formMapping: Record<string, ICrfFormMapping> = {
    "pre-registration": {
        components: <Preregistration />
    },
    "pre-mri-planning": {
        components: <PreMriPlanning />
    },
    "general": {
        components: <General />
    },
    "eligibility-checklist": {
        components: <EligibilityChecklist />
    },
    "diagnostic-and-pathology": {
        components: <DiagnosticAndPathology />
    },
    "diagnostic-and-imaging": {
        components: <DiagnosticAndImaging />
    },
    "decision-conflict-scale": {
        components: <DecisionConflictScale />
    },
    "decision-regret-scale": {
        components: <DecisionRegretScale />
    },
    "eq-5d-5l": {
        components: <Eq5d5l />
    },
    "impact-of-mri": {
        components: <ImpactOfMri />
    },
    "preference-for-surgery": {
        components: <PreferenceForSurgery />
    },
    "procomida": {
        components: <Procomida />
    },
    "patient-transfer": {
        components: <PatientTransfer />
    }
}

const permissions: Permission[] = [
    Permission.SaeView,
    Permission.SaeReview,
    Permission.SaeAdminister,
    Permission.MedicalReviewPerform,
    Permission.ScreeningLogView,
    Permission.ScreeningLogUpdate
];

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const App: React.FunctionComponent = () => {
    const classes = useStyles();

    const [user] = useAuthenticatedUser();

    const isPatient = user?.type === UserType.Patient;

    const [[canViewSae, , , , canViewScreeningLog, , , , ], permissionLoadState] = usePermissionsByIds(permissions, null, null, null, null, true);

    const menuItems: IMenuItem[] = React.useMemo(() => {

        if (isPatient) {
            return [];
        }

        let items: IMenuItem[] = [];

        items.push(
            {
                url: '/registration',
                label: 'Registration',
                external: false,
                newTab: false,
                icon: faUser
            },
            {
                url: 'https://ecrf.breastcancertrials.org.au',
                label: 'eCRF',
                external: true,
                newTab: true,
                icon: faList
            }
        );

        if (canViewSae) {
            items.push({
                url: '/sae',
                label: 'SAE Electronic Review',
                external: false,
                newTab: false,
                icon: saeIcon
            });
        }

        if (canViewScreeningLog) {
            items.push({
                url: '/screeninglog',
                label: 'Screening Log',
                external: false,
                newTab: false,
                icon: screeningLogIcon
            });
        }

        return items;
    }, [isPatient, canViewSae, canViewScreeningLog]);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const isMobile = useIsMobile();

    const [bannerOpen, setBannerOpen] = React.useState(false);

    React.useEffect(() => {
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf('MSIE '); //IE 10 or older
        const trident = ua.indexOf('Trident/'); //IE 11

        if (msie > 0 || trident > 0) {
            // IE 11 or older, show banner
            setBannerOpen(true);
        }
    }, [setBannerOpen]);

    const canView = (permissionLoadState.state === RequestState.Cancel || 
        permissionLoadState.state === RequestState.Failure || 
        permissionLoadState.state === RequestState.Success) &&
        (canViewSae || canViewScreeningLog);


    if (permissionLoadState.state === RequestState.None || permissionLoadState.state === RequestState.Pending) {
        return (
            <RouteLoading />
        );
    }

    return (
        <>
            <Collapse in={bannerOpen} className={classes.bannerContainer}>
                <Alert severity="warning" className={classes.banner} square>
                    <AlertTitle>Your browser is not supported</AlertTitle>
                    Some features of this website may not work correctly with Internet Explorer. Please try using a different browser.
                </Alert>
            </Collapse>
            <OnlinePatientManagementLayout
                menuItems={menuItems}
                footer={
                    isMobile ?
                        undefined :
                        <Typography variant="caption">
                            Copyright <FontAwesomeIcon icon={faCopyright} fixedWidth /> 2021 ANZ Breast Cancer Trials Group Ltd | ABN 64 051 369 496
                        </Typography>
                }
            >
                <React.Suspense fallback={<RouteLoading />}>
                    {
                        !isPatient ?
                            <Switch>
                                <CrfFormByCodesRoutes
                                    path={[
                                        `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/add-patient`
                                    ]}
                                    eventDefinitionCode="pre-registration"
                                    eventRepeat={1}
                                    formDefinitionCode="pre-registration"
                                    formRepeat={1}
                                    createPatient
                                    resolveBeforeLoad
                                    formMapping={formMapping}
                                />
                                <CrfFormByCodesRoutes
                                    path={[
                                        `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/:${onlinePatientManagement.routeParameters.eventDefinitionCode}/:${onlinePatientManagement.routeParameters.eventRepeat}/:${onlinePatientManagement.routeParameters.formDefinitionCode}/:${onlinePatientManagement.routeParameters.formRepeat}`
                                    ]}
                                    resolveBeforeLoad
                                    formMapping={formMapping}
                                />
                                <PatientSummaryByStudyNumberRoute
                                    path={[
                                        `/registration/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}`
                                    ]}
                                    resolveBeforeLoad
                                >
                                    <PatientSummary />
                                </PatientSummaryByStudyNumberRoute>
                                <InstitutionsByCodesRoute
                                    path={[
                                        `/registration/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                        '/registration'
                                    ]}
                                >
                                    <Registration />
                                </InstitutionsByCodesRoute>
                                <SaeByPatientStudyNumberRoute
                                    path={[
                                        `/sae/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/:saeNumber/notify/:notifyMode`,
                                    ]}
                                    resolveBeforeLoad
                                    key="sae-notification-page"
                                >
                                    <SaeNotifyPage />
                                </SaeByPatientStudyNumberRoute>
                                <SaeByPatientStudyNumberRoute
                                    path={[
                                        `/sae/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/:saeNumber/:viewMode`,
                                        `/sae/:${onlinePatientManagement.routeParameters.institutionCode}/:${onlinePatientManagement.routeParameters.patientStudyNumber}/:saeNumber`,
                                    ]}
                                    resolveBeforeLoad
                                    key="sae-view-page"
                                >
                                    <SaeViewPage />
                                </SaeByPatientStudyNumberRoute>
                                <InstitutionsByCodesRoute
                                    path={[
                                        `/sae/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                        '/sae'
                                    ]}
                                >
                                    <SaesPage />
                                </InstitutionsByCodesRoute>
                                <InstitutionsByCodesRoute
                                    path={[
                                        `/screeninglog/:${onlinePatientManagement.routeParameters.institutionCode}`,
                                        '/screeninglog'
                                    ]}
                                >
                                    <ScreeningLogPage />
                                </InstitutionsByCodesRoute>
                                <Redirect path="*" to="/registration" />
                                <Redirect path="*" to="/registration/admin" />
                            </Switch> :
                            <Switch>
                                <CrfFormByCodesRoutes
                                    path={[
                                        `/:${onlinePatientManagement.routeParameters.eventDefinitionCode}/:${onlinePatientManagement.routeParameters.eventRepeat}/:${onlinePatientManagement.routeParameters.formDefinitionCode}/:${onlinePatientManagement.routeParameters.formRepeat}`
                                    ]}
                                    patientStudyNumber={user?.userName}
                                    resolveBeforeLoad
                                    formMapping={formMapping}
                                />
                                <PatientSummaryByStudyNumberRoute
                                    path={[
                                        `/`,
                                        `/:next`
                                    ]}
                                    exact
                                    resolveBeforeLoad
                                    patientStudyNumber={user?.userName}
                                >
                                    <PatientPros />
                                </PatientSummaryByStudyNumberRoute>
                                <Redirect path="*" to="/" />
                            </Switch>
                    }
                </React.Suspense>
            </OnlinePatientManagementLayout>
        </>
    );
}



/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default App;